import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { FlashService } from '../../components/flash/flash.service';
import { ProviderService } from '../providers.service';
import * as _ from 'lodash';
import { AuthHttpService } from '../../core/auth-http.service';
import { Provider } from '../provider';

@Component({
  selector: 'app-service-fees-edit',
  templateUrl: './service-fees-edit.component.html',
  styleUrls: ['./service-fees-edit.component.scss']
})
export class ServiceFeesEditComponent implements OnInit {
  editServiceFeesForm: FormGroup;
  error: string;
  edited = false;
  isEdit = false;
  hasConflict = false;
  serviceOptions = {};
  getServiceName = Provider.getServiceName;
  @Input() data: any;
  @Input() vehicleTypes: any;
  @Input() vehicleValueTypeMap: any;
  requiredOptions = ['type', 'base_rate', 'mileage_rate', 'number_of_vehicles'];

  constructor(
    private providerService: ProviderService,
    private flashService: FlashService,
    private http: AuthHttpService
  ) {}

  ngOnInit() {
    this.serviceOptions = Object.keys(this.vehicleTypes).map(key => {
      return { name: this.vehicleTypes[key], value: key };
    });
  }

  save() {
    this.checkDuplicateVehicleType();
    if (this.hasConflict) {
      return;
    }
    this.updateData()
      .then(data => this.saveData(data))
      .then(res => {
        this.edited = true;
      })
      .catch(err => {
        this.http.handleError(err);
      });
    this.toggleEdit();
  }

  private updateData(): Promise<any> {
    const data = Object.assign({}, this.data);

    return new Promise((resolve, reject) => {
      // set service values
      if (this.editServiceFeesForm.value.services) {
        this.setVehicleType();
        data.properties.services = this.editServiceFeesForm.value.services;
      }
      // set state medicaid number
      if (this.editServiceFeesForm.value.npi_number) {
        data.properties.npi_number = this.editServiceFeesForm.value.npi_number;
      }

      resolve(data);
    });
  }

  private saveData(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = this.editServiceFeesForm.value.services;

      this.providerService.updateProviderServices(data.id, { data: payload })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  private generateFormControls() {
    const formControls = {};

    const services = this.data.properties.services.map(service => {
      const formGroupOptions = {};
      Object.keys(service).forEach(key => {
        const value = this.getFormControlsValue(service, key);
        formGroupOptions[key] = new FormControl(
          value,
          this.requiredOptions.includes(key) ? Validators.required : null
        );
      });
      return new FormGroup(formGroupOptions);
    });
    formControls['services'] = new FormArray(services);
    formControls['npi_number'] = new FormControl(this.data.properties.npi_number);

    this.editServiceFeesForm = new FormGroup(formControls);
  }

  get servicesForm() {
    return this.editServiceFeesForm.get('services') as FormArray;
  }

  toggleEdit() {
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.generateFormControls();
    }
  }

  addVehicleType() {
    this.servicesForm.push(new FormGroup({
      type: new FormControl(null, Validators.required),
      base_rate: new FormControl(null, Validators.required),
      mileage_rate: new FormControl(null, Validators.required),
      number_of_vehicles: new FormControl(null, Validators.required)
    }));
  }

  removeVehicleType(index: number) {
    this.servicesForm.removeAt(index);
    this.checkDuplicateVehicleType();
  }

  setVehicleType() {
    this.editServiceFeesForm.value.services.map(service => {
      service['value'] = service['type'];
      service['type'] = this.vehicleValueTypeMap[service.value];
      return service;
    });
  }

  getFormControlsValue(service, key) {
    let value;
    if (key === 'type') {
      value = service['value'] || service['type'];
    } else {
      value = service[key];
    }

    return value;
  }

  checkDuplicateVehicleType() {
    const types = _.map(this.editServiceFeesForm.getRawValue().services, 'type');
    this.hasConflict = (types.length !== _.uniq(types).length);
  }
}
