import { Component, Input, OnInit } from '@angular/core';
import { HospitalService } from '../hospital.service';
import { Geofence } from './geofence';
import { FILE_SIZE_UNIT } from '../../providers/provider';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { ConfirmService } from '../../components/confirm/confirm.service';

@Component({
  selector: 'app-geofence',
  templateUrl: './geofence.component.html',
  styleUrls: ['./geofence.component.scss']
})
export class GeofenceComponent implements OnInit {
  modalRef: BsModalRef;
  geofenceFile: File;
  geofenceExists = false;
  enableGeofencing = false;
  selectedFileName: string;
  disableSave = true;
  geofenceProcessing = false;
  geofenceFileSizeUnit: string;
  geofenceFileSize: number;
  canShowFileUploadButton = true;
  errorMessage: string;
  geofenceRulesPresent: boolean;
  @Input() geofence: Geofence;
  @Input() hospitalId: number;

  constructor(
    private hospitalService: HospitalService,
    private confirmService: ConfirmService,
  ) {}

  ngOnInit() {
    this.setVariables();
  }

  setVariables() {
    this.geofenceExists = Boolean(this.geofence.hospital_id)

    if(this.geofenceExists) {
      this.geofenceFile = null;
      this.enableGeofencing = this.geofence.geofence_enabled;
      this.canShowFileUploadButton = false;
      this.geofenceProcessing = this.isProcessing(this.geofence.status);

      if(this.geofence.status === 'error') {
        this.canShowFileUploadButton = true;
        this.errorMessage = 'Something went wrong while processing `' + this.geofence.geofence_file_name + '` file.';
      }

      this.setGeofenceFileSizeAndUnit();
    }
  }

  isProcessing(status: string): boolean {
    return (status === 'processing' || status === 'pending');
  }

  // Api sends Geofence file size in bytes from backend,
  // by this method we convert that size to the appropriate unit(Byte, KB, MB, GB).
  setGeofenceFileSizeAndUnit() {
    this.geofenceFileSize = this.geofence.geofence_file_size;
    let i = Math.floor(Math.log(this.geofenceFileSize) / Math.log(1024));
    this.geofenceFileSize = (this.geofenceFileSize) / Math.pow(1024, i);
    this.geofenceFileSizeUnit = FILE_SIZE_UNIT[i];
  }

  onGeofenceStatusChange(enableGeofencing: boolean) {
    if((!this.geofenceProcessing && this.geofenceFile) || (this.geofence.geofence_enabled !== enableGeofencing)) {
      this.disableSave = false;
    }
    else {
      this.disableSave = true;
    }
    this.enableGeofencing = enableGeofencing;
  }

  geofenceFileChangeEvent(files: FileList): void {
    this.geofenceFile = files.item(0);
    this.selectedFileName = this.geofenceFile.name;
    this.disableSave = false;
  }

  removeGeofence(): void {
    this.canShowFileUploadButton = true;
    this.geofenceFile = null;
    this.selectedFileName = null;
  }

  onSaveClick() {
    if(this.geofenceExists && !this.geofenceProcessing && this.geofenceFile) {
      let confirmComponent = new ConfirmComponent();
      confirmComponent.hideTitleArea = true;
      confirmComponent.message = 'Upload new geofence? You will lose all restriction rules from the previous geofence if they exist. The rules can’t be recovered if a new geofence is uploaded.';
      confirmComponent.buttons = [
        {
          label: 'Yes, upload it.',
          type: 'danger'
        },
        {
          label: 'No',
          type: 'secondary'
        }
      ];
      this.modalRef = this.confirmService.show(confirmComponent);
      this.modalRef.content.onClose.subscribe(result => {
        if(result === 'Yes, upload it.') {
          this.saveGeofence();
        }
        this.modalRef.hide();
      });
    }
    else {
      this.saveGeofence();
    }
  }

  saveGeofence(): void {
    const uploadData = new FormData();
    if(this.geofenceExists && (this.enableGeofencing !== this.geofence.geofence_enabled)) {
      uploadData.append('geofence_enabled', this.enableGeofencing.toString());
    }
    if(!this.geofenceProcessing && this.geofenceFile) {
      uploadData.append('geofence', this.geofenceFile);
    }
    this.hospitalService.updateGeofence(this.hospitalId, uploadData).subscribe(
      response => {
        this.geofence = response.data;
        this.disableSave = true;
        this.setVariables();
      },
      () => {
        this.canShowFileUploadButton = true;
        this.enableGeofencing = this.geofence.geofence_enabled;
      }
    );

    if(this.geofenceFile) {
      this.errorMessage = null;
    }
  }
}
