import {Component, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { HospitalProgram } from '../hospital-program';
import { HospitalProgramService } from '../hospital-program.service';
import {Hospital} from '../../hospital';

@Component({
  selector: 'app-new-program-modal',
  templateUrl: './new-program.component.html',
  styleUrls: ['./new-program.component.scss']
})
export class NewHospitalProgramModalComponent implements OnInit {
  afterCreate = {
    next: (v) => {
      this.data = v.program;
      this.added = true;
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };
  afterUpdate = {
    next: () => {
      this.added = true;
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };
  afterDelete = {
    next: () => {
      this.deleted = true;
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };

  hospitalProgram: HospitalProgram;
  hospital: Hospital;
  editingProgram: boolean;
  added = false;
  deleted = false;
  data = {}

  constructor(
    public modalRef: BsModalRef,
    private HospitalProgramService: HospitalProgramService
  ) {}

  ngOnInit() {
    if (!this.hospitalProgram) {
      this.hospitalProgram = this.blankHospitalProgram();
    }
  }

  addUpdateHospitalProgram() {
    if (this.hospitalProgram.id) {
      this.HospitalProgramService.update(this.hospital.id, this.hospitalProgram).subscribe(this.afterUpdate);
    } else {
      this.HospitalProgramService.create(this.hospital.id, this.hospitalProgram).subscribe(this.afterCreate);
    }
  }

  deleteHospitalProgram(){
    if (this.hospitalProgram.id){
      this.HospitalProgramService.delete(this.hospital.id, this.hospitalProgram).subscribe(this.afterDelete);
    }
  }

  private blankHospitalProgram(): HospitalProgram {
    return <HospitalProgram>{
      name: ''
    };
  }
}
