import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Note } from '../payer';

@Component({
  selector: 'app-view-carrier',
  templateUrl: './view-carrier.component.html',
  styleUrls: ['./view-carrier.component.scss']
})

export class ViewCarrierComponent implements OnInit {
  carrier: any;
  notes: Array<Note>;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.carrier = data.payer.data;
      this.notes = data.notes.data;
    });
  }

  carrierUpdated(event) {
    this.carrier = event;
  }
}
