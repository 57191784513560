import { inject } from '@angular/core';

import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ReasonTypeService } from './reason-type.service';

export const ReasonTypesResolverService: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> => {
    const reasonTypeService: ReasonTypeService = inject(ReasonTypeService);
    return reasonTypeService.getReasonTypes();
  }
