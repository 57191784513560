<ol class="breadcrumb">
  <li class="active">Plans</li>
  <li>
    <a (click)="openNewActivePlanModal()">+ Add</a>
  </li>
</ol>

<span class="heading">Plans</span>

<tabset #tabset>
  <tab (select)="onActiveTabSelection()">
    <ng-template tabHeading>
      Active
    </ng-template>

    <app-active-plan-list></app-active-plan-list>
  </tab>

  <tab (select)="onPendingTabSelection()">
    <ng-template tabHeading>
      Pending
    </ng-template>

    <app-pending-plan-list></app-pending-plan-list>
  </tab>
</tabset>
