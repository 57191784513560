import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FlashService } from '../components/flash/flash.service';

@Injectable()
export class AuthHttpService {

  constructor(
    private http: HttpClient,
    private flashService: FlashService) {
    }

  get<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(url);
  }
  getV3<T>(url: string): Observable<T> {
    let headers =  {
      'accept': 'application/json',
      'content-type': 'application/json',
    }
    return this.http.get<T>(url, {headers});
  }
  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }
  postV3<T>(url: string, body: any): Observable<T> {
    let headers =  {
      'accept': 'application/json',
      'content-type': 'application/json',
    }
    return this.http.post<T>(url, body, {headers});
  }

  update<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(url, body);
  }
  updateV3<T>(url: string, body: any): Observable<T> {
    let headers =  {
      'accept': 'application/json',
      'content-type': 'application/json',
    }
    return this.http.patch<T>(url, body, {headers});
  }
  patch<T>(url: string, body: any): Observable<T> {
    return this.http.patch<T>(url, body);
  }
  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url);
  }
  deleteV3<T>(url: string): Observable<T> {
    let headers =  {
      'accept': 'application/json',
      'content-type': 'application/json',
    }
    return this.http.delete<T>(url, { headers });
  }

  handleError(error: HttpErrorResponse | any): Observable<string> {
    let errMsg = '';

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.flashService.add({
          type: 'danger',
          message: 'Your session has expired. Please log in again!'
        });
      }
      const err = error.error.errors;
      if (err) {
        if (typeof err === 'string') {
          errMsg = err;
        } else {
          for (const e in err) {
            if (err.hasOwnProperty(e)) {
              errMsg += `<strong>${e}:</strong> ${err[e]} `;
            }
          }
        }
      } else {
        errMsg = `${error.status} (${error.statusText || ''})`;
      }

      this.flashService.add({
        type: 'danger',
        message: errMsg
      });
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return observableThrowError(() => new Error(errMsg));
  }
}
