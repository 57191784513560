<app-loader *ngIf="loading"></app-loader>
<div class="row">
  <div class="col-xs-12">
    <p-table
        [value]="federatedIdentityGroups"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="rows"
        [first]="first"
        [showCurrentPageReport]="true"
        selectionMode="single"
        [(selection)]="selectedFederatedIdentityGroup"
        (onRowSelect)="onRowSelect($event)"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
        stateStorage="session" 
        stateKey="state-session-federated-identity-groups"
      >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th style="width:25%">
          <p-columnFilter 
          type="text" 
          field="group_name"
          [placeholder]="placeholders['group_name']"
          ariaLabel="Filter group name" 
          [showMenu]="false"
          [showClearButton]="false"
          />
          </th>
          <th style="width:25%">
            <p-columnFilter 
            type="text" 
            field="roles_string"
            [placeholder]="placeholders['roles_string']"
            ariaLabel="Filter roles name" 
            [showMenu]="false"
            [showClearButton]="false"
            />
            </th>
            <th style="width:25%">
              <p-columnFilter 
              type="text" 
              field="hospitals_string"
              [placeholder]="placeholders['hospitals_string']"
              ariaLabel="Filter hospitals name" 
              [showMenu]="false"
              [showClearButton]="false"
              />
              </th>
              <th style="width:25%">
                <p-columnFilter 
                type="text" 
                field="created_on"
                [placeholder]="placeholders['created_on']"
                ariaLabel="Filter created on" 
                [showMenu]="false"
                [showClearButton]="false"
                />
                </th>
          
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-federatedIdentityGroup>
      <tr [pSelectableRow]="federatedIdentityGroup">
        <td *ngFor="let key of keys">{{ federatedIdentityGroup?.[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
    <button class="btn btn-primary" (click)="addGroup($event)">
      New Group
    </button>
  </div>
</div>