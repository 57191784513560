<div class="row">
  <div class="col-xs-12">
    <p-table
    [value]="plans"
    [lazy]="true"
    [totalRecords]="totalRecords"
    [paginator]="true"
    (onLazyLoad)="loadPlans($event)"
    [rows]="rows"
    [first]="first"
    [showCurrentPageReport]="true"
    selectionMode="single"
    [(selection)]="selectedPlan"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="null" 
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    (onPage)="pageChange($event)"
    [rowsPerPageOptions]="[10, 25, 50]"
    stateStorage="session" 
    stateKey="state-session-pending-plans"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th style="width:25%">
          <p-columnFilter 
          type="text" 
          field="carrier_name"
          [placeholder]="placeholders['carrier_name']"
          ariaLabel="Filter carrier name" 
          [showMenu]="false"
          [showClearButton]="false"
          />
          </th>
          <th style="width:25%">
            <p-columnFilter 
            type="text" 
            field="plan_name"
            [placeholder]="placeholders['plan_name']"
            ariaLabel="Filter plan name" 
            [showMenu]="false"
            [showClearButton]="false"
            />
          </th>
        <th style="width:25%">
          <p-columnFilter 
          type="text" 
          field="organization"
          [placeholder]="placeholders['organization']"
          ariaLabel="Filter organization" 
          [showMenu]="false"
          [showClearButton]="false"
          />
        </th>
        <th>
          <p-dropdown 
              [ngModel]="selectedUpdatedAt" 
              [placeholder]="placeholders['created_at']"
              [options]="monthList" 
              optionLabel="title"
              [showClear]="true"
              (onChange)="pageChange($event, 'created_at')"
              >
          </p-dropdown>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-plan>
      <tr [pSelectableRow]="plan">
        <td *ngFor="let key of keys">{{ plan[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>

