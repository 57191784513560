import {Injectable} from '@angular/core';
import {AuthHttpService} from '../core/auth-http.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {PromoCode} from './promo-code';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PromoCodeService {
  constructor(private http: AuthHttpService) {}

  list(): Observable<any> {
    return this.http.get(`${environment.apiurl}/promo_codes`).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  create(code: PromoCode): Observable<PromoCode> {
    return this.http.post<PromoCode>(`${environment.apiurl}/promo_codes`, code);
  }

  get(id): Observable<any> {
    return this.http.get(`${environment.apiurl}/promo_codes/:id`.replace(':id', id)).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  delete(id): Observable<any> {
    return this.http.delete(`${environment.apiurl}/promo_codes/:id`.replace(':id', id)).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  update(code: PromoCode): Observable<PromoCode | string> {
    return this.http.update<PromoCode>(`${environment.apiurl}/promo_codes/:id`.replace(':id', code.id), code).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  activatePromoCode(id): Observable<PromoCode | string> {
    const url = `${environment.apiurl}/promo_codes/:id/enable`.replace(':id', id);

    return this.http
      .update<PromoCode>(url, { }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  deactivatePromoCode(id): Observable<PromoCode | string> {
    const url = `${environment.apiurl}/promo_codes/:id`.replace(':id', id);

    return this.http
      .delete<PromoCode>(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

}
