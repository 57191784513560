import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReasonTypeService } from '../reason-type.service';
import { ReasonType } from '../reason-type';

@Component({
  selector: 'app-new-modal',
  templateUrl: './new-modal.component.html',
})

export class NewReasonModalComponent {
  reason: ReasonType = this.blankReason();
  added = false;
  reasonType = '';

  constructor(
    public modalRef: BsModalRef,
    private reasonTypeService: ReasonTypeService,
  ) {}

  addReasonType() {
    this.reasonTypeService.create(this.reason).subscribe(() => {
      this.added = true;
      this.reasonType = this.reason.name;
      this.modalRef.hide();
    }, () => {
      this.modalRef.hide();
    });
  }

  private blankReason() {
    return {
      name: '',
    };
  }
}
