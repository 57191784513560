import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HospitalService } from './hospital.service';

@Injectable()
export class HospitalReasonTypesResolverService  {
  constructor(private hospitalService: HospitalService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any> {
    return this.hospitalService.getHospitalTripReasons(route.params.id);
  }
}
