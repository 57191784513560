<div *ngIf="data.length > 0">
  <div class="column" *ngFor="let column of data">
    <span class="bold">{{column.type}}</span>

    <ul class="columnar-list">
      <li *ngFor="let subtype of column.subtypes">
        {{subtype}}
      </li>
    </ul>
  </div>
</div>

<span *ngIf="data.length === 0">{{ defaultDisplayValue }}</span>
