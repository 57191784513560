import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment} from '../../../environments/environment';
import { AuthHttpService } from '../../core/auth-http.service';
import { Locations } from './locations';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LocationsService {

  constructor(private http: AuthHttpService) {}

  create(hospitalId: number, Locations: Locations): Observable<Locations | {}> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/locations`;
    return this.http.post(url, this.getPayLoad(Locations)).pipe(
      map((x: any) => this.formatData(x.data)),
      catchError((err) => this.http.handleError(err))
    );
  }

  update(hospitalId: number, Locations: Locations): Observable<Locations | {}> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/locations/${Locations.id}`;
    return this.http.update<Locations>(url, this.getPayLoad(Locations)).pipe(
      map((x: any) => this.formatData(x.data)),
      catchError((err) => this.http.handleError(err))
    );
  }

  list(hospitalId: string): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/locations`;

    return this.http
      .get(url).pipe(
        map((x: any) => this.formatListData(x.data)),
        catchError(err => this.http.handleError(err))
      );
  }

  delete(hospitalId: number, location: Locations): Observable<any> {
    return this.http.delete(
      `${environment.apiurl}/hospitals/${hospitalId}/locations/:id`.replace(':id', String(location.id))
    ).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  getVenues(lat: any, lng: any) {
    const url = `${environment.apiurl}/location-venues?lat=${lat}&lng=${lng}`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getPayLoad(location) {
    return {
      latitude: location.latitude,
      longitude: location.longitude,
      state: location.state,
      city: location.city,
      zipcode: location.zipcode,
      street_address: location.street_address,
      venue_mapping_id: location.venue_mapping['id'],
      name: location.name
    };
  }

  formatListData(data) {
    const formattedData = [];
    data.data.forEach(response => formattedData.push(this.formatData({data: response})));
    return {data: formattedData};
  }

  formatData(data) {
    if (!data.data) {
      return {};
    }

    const formattedData = {};
    const response = data.data.attributes;
    for ( const key in response) {
      if (response.hasOwnProperty(key)) {
        if (typeof(response[key]) === 'object' && response[key]) {
          formattedData[key] = this.formatData(response[key]);
        } else {
          formattedData[key] = response[key];
        }
      }
    }
    formattedData['id'] = data.data.id;
    return formattedData;
  }

  tableConfig() {
    return {
      columns: {
        id: {
          title: 'ID',
          width: '75px'
        },

        name: {
          title: 'Name'
        },

        zone_name: {
          title: 'Venue Name'
        },

        created_on: {
          title: 'Created On',
          width: '200px',
          compareFunction: (direction, a, b) => {
            return (moment(a).isAfter(b) ? 1 : -1) * direction;
          }
        }
      },

      actions: {
        add: false,
        edit: false,
        delete: false
      }
    };
  }

  mutateData(location) {
    location['created_on'] = moment(location['created_at']).format('LLL');
    location['zone_name'] = location.venue_mapping.zone_name;
    location['current_address'] = location.street_address + ', ' + location.city + ' ' + location.state;
    location['latitude'] = parseFloat(location.latitude).toFixed(7);
    location['longitude'] = parseFloat(location.longitude).toFixed(7);
  }

  sortData(locations) {
    locations.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }

      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }

      return 0;
    });
  }

}
