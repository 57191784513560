import { Injectable } from '@angular/core';

@Injectable()
export class ProviderCsvService {
  constructor() { }

  generateDataFromList(list): any[] {
    const data = [];

    if (list.length) {
      list.forEach((x: any) => {
        const {
          name,
          address,
          address_2,
          city,
          state,
          zipcode,
          tax_code,
          created_at,
          approved_at
        } = x;

        let first_name = '';
        let last_name = '';
        let email = '';

        if (x.properties.dispatcher) {
          first_name = x.properties.dispatcher.first_name;
          last_name = x.properties.dispatcher.last_name;
          email = x.properties.dispatcher.email;
        }

        const {
          general_liability,
          automobile_liability,
          workers_compensation,
          has_active_medicaid_creds,
          type_of_dispatch_software,
          certifications
        } = x.properties;

        let row = {
          name: `"${name}"`,
          address,
          address_2,
          city,
          state,
          zipcode,
          tax_code,
          first_name,
          last_name,
          email,
          general_liability,
          automobile_liability,
          workers_compensation,
          has_active_medicaid_creds,
          type_of_dispatch_software,
          application_date: created_at,
          approval_date: approved_at
        };

        row = this.addServices(row, x);
        row['certifications'] = certifications ? `"${certifications.join(', ')}"` : '';

        data.push(row);
      });
    }

    return data;
  }

  private addServices(row, obj) {
    row = Object.assign(
      row,
      this.getServiceInfo(obj, 'medical_sedan'),
      this.getServiceInfo(obj, 'taxi'),
      this.getServiceInfo(obj, 'psych_transport'),
      this.getServiceInfo(obj, 'wheelchair_accessible'),
      this.getServiceInfo(obj, 'wheelchair'),
      this.getServiceInfo(obj, 'stretcher_van'),
      this.getServiceInfo(obj, 'bls'),
      this.getServiceInfo(obj, 'bls_bariatric'),
      this.getServiceInfo(obj, 'als'),
      this.getServiceInfo(obj, 'als_bariatric'),
      this.getServiceInfo(obj, 'cct'),
      this.getServiceInfo(obj, 'rotor_wing'),
      this.getServiceInfo(obj, 'fixed_wing')
    );

    return row;
  }

  private getServiceInfo(obj: any, type: string): any {
    const services = obj.properties && obj.properties.services || [];
    const title = type.toLowerCase().replace(/ /g, '_');
    const service_info = {};

    service_info[`${title}_count`] = 0;
    service_info[`${title}_base_rate`] = 0;
    service_info[`${title}_mileage_rate`] = 0;

    if (services) {
      const service = services.find(x => x.value === type);

      if (service) {
        service_info[`${title}_count`] = service.number_of_vehicles;
        service_info[`${title}_base_rate`] = service.base_rate;
        service_info[`${title}_mileage_rate`] = service.mileage_rate;
      }
    }

    return service_info;
  }

}
