<ol class="breadcrumb">
  <li><a routerLink="/customers">Customers</a></li>
  <li class="active">{{customer.name}}</li>
</ol>
<h1>{{customer.name}}</h1>
<tabset #tabset>
  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Customer Details
    </ng-template>
    <app-edit-customer></app-edit-customer>
  </tab>
  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Identity Groups
    </ng-template>
    <app-federated-identity-groups-view></app-federated-identity-groups-view>
  </tab>
</tabset>