import {Component, ViewChild, ElementRef, NgZone, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Provider} from '../provider';
import STATES_OPTIONS from '../provider';
import {ProviderService} from '../providers.service';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { FlashService } from '../../components/flash/flash.service';
import {AuthHttpService} from '../../core/auth-http.service';

@Component({
  selector: 'app-provider-new',
  templateUrl: './provider-new.component.html',
  styleUrls: ['./provider-new.component.scss']
})
export class NewProviderComponent implements OnInit {
  provider = new Provider();
  added = false;
  newProviderForm: FormGroup;
  error: String;
  statesOptions = STATES_OPTIONS.filters;
  constructor(
    public modalRef: BsModalRef,
    private providerService: ProviderService,
    private ngZone: NgZone,
    private flashService: FlashService,
    private http: AuthHttpService
  ) {}

  ngOnInit() {
    this.newProviderForm = new FormGroup ({
      name: new FormControl('', Validators.required),
      state: new FormControl(''),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^[\+\d]+(?:[\d-.\s()]*)$/), Validators.minLength(8), Validators.maxLength(14)])
    });

  }

  addNewProvider() {
    this.providerService.create(this.newProviderForm.value).subscribe(
      (response) => {
        this.added = true;
        this.modalRef.hide();
        this.provider.setData(response['data']);
      },
      (response) => {
          this.modalRef.hide();
          this.http.handleError(response);
      });
  }
}
