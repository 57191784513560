import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'rt-ng2-smart-table';

@Component({
  templateUrl: './custom-payer-render.component.html',
  styleUrls: ['./custom-payer-render.component.scss']
})
export class CustomPayerRenderComponent implements ViewCell, OnInit {

  @Input() value: any;
  @Input() rowData: any;

  ngOnInit() {
  }
}
