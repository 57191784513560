import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Carrier, PendingPlan } from '../payer';

@Component({
  selector: 'app-view-pending-plan',
  templateUrl: './view-pending-plan.component.html',
  styleUrls: ['./view-pending-plan.component.scss']
})
export class ViewPendingPlanComponent implements OnInit {
  pendingPlan: PendingPlan;
  carriers: Array<Carrier>;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.pendingPlan = data.pendingPlan.data;
      this.carriers = data.carriers.data;
    });
  }
}
