import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';
import { FlashService } from '../../components/flash/flash.service';
import { NewReasonModalComponent } from '../new-modal/new-modal.component';
import { ReasonTypeService } from '../reason-type.service';
import { ReasonType } from '../reason-type';

interface Filter {
  value: any;
}

interface Event {
  first: number;
  rows: number;
  filters: { [key: string]: Filter };
  originalEvent:any;
  value?: {label: string, value: any};
}

@Component({
  selector: 'app-reason-type',
  templateUrl: './reason-type.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
  ],
})

export class ReasonTypeDashboardComponent implements OnInit {
  reasonTypes: ReasonType[];
  selectedReasonType: ReasonType;
  newReasonModal: BsModalRef;
  loading = false;
  selectedCreatedAt: any;
  first = 0;
  last: number;
  rows = 10;
  totalRecords: number = 0;
  pageOffset: number = 0;
  page: number = 1;
  searchCriteria: any[] = [];

  placeholders = {
    name: 'Trip Reason',
    created_at: ' ',
  };

  monthList = [
    {value: 'January', title: 'January', label: 'created_at'},
    {value: 'February', title: 'February', label: 'created_at'},
    {value: 'March', title: 'March', label: 'created_at'},
    {value: 'April', title: 'April', label: 'created_at'},
    {value: 'May', title: 'May', label: 'created_at'},
    {value: 'June', title: 'June', label: 'created_at'},
    {value: 'July', title: 'July', label: 'created_at'},
    {value: 'August', title: 'August', label: 'created_at'},
    {value: 'September', title: 'September', label: 'created_at'},
    {value: 'October', title: 'October', label: 'created_at'},
    {value: 'November', title: 'November', label: 'created_at'},
    {value: 'December', title: 'December', label: 'created_at'}
  ]

  tableConfig = {
    columns: {
      name: {
        title: 'Trip Reason',
      },

      created_at: {
        title: 'Created On',
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private flashService: FlashService,
    private reasonTypeService: ReasonTypeService,
  ) {}

  keys = Object.keys(this.tableConfig.columns);

  applyPagination(first: number, rows: number): void {
    this.reasonTypes = this.reasonTypes.slice(first, first + rows);
  }

  applyFilters(filters: { [key: string]: Filter }): void {
    if (filters.name?.value) {
      const filterValue = filters.name.value.toLowerCase();
      this.reasonTypes = this.reasonTypes.filter((reasonType: any) =>
        reasonType.name.toLowerCase().includes(filterValue)
      );
    }
  }

  applyMonthFilter(month: string): void {
    this.reasonTypes = this.reasonTypes.filter((reasonType: any) =>
      moment(reasonType.created_at).format('MMMM') === month
    );
  }
  
  loadReasons(event?:Event) {
    this.route.data.subscribe((data) => {
      this.reasonTypes = this.mutateReasonTypes(data.reasonTypes?.data);
      this.totalRecords = this.reasonTypes?.length;
      if (event?.first) {
        this.applyPagination(event.first, event.rows);
      }
      if(event?.filters){
        this.applyFilters(event.filters);
      }
      if(event?.value) {
        this.applyMonthFilter(event.value.value);
      }
    this.totalRecords = this.reasonTypes.length;
   });
  }

  ngOnInit(): void {
    this.loadReasons();
  }

  pageChange(event: any) {
    this.loadReasons(event);
  }

  public openNewReasonModal() {
    this.newReasonModal = this.modalService.show(NewReasonModalComponent);

    const hiddenSubscription = this.modalService.onHidden.subscribe((_reason: any) => {
      if (this.newReasonModal.content.added) {
        this.loading = true;
        this.newReasonModal.content.added = false;

        this.flashService.add({
          type: 'success',
          message:`${this.newReasonModal.content.reasonType} successfully added!`
        });
      }

      this.reasonTypeService.getReasonTypes()
        .subscribe((reasonTypes: any) => {
          this.mutateReasonTypes(reasonTypes.data);
          this.loading = false;
        });

      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  private mutateReasonTypes(reasonTypes: ReasonType[]) {
    this.reasonTypes = reasonTypes.map((reasonType) => {
      reasonType['created_at'] = moment(reasonType['created_at']).format('LLL');
      return reasonType;
    });
    return this.reasonTypes
  }
}
