import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthHttpService } from '../core/auth-http.service';
import { RideshareAvailability } from './rideshare-availbility';

@Injectable()
export class RideshareAvailabilityService {

  constructor(private http: AuthHttpService) {}

  get(): Observable<any> {
    let url = `${environment.apiurl}/rideshare_availability`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  patch(payload: RideshareAvailability[]): Observable<any> {
    const url = `${environment.apiurl}/rideshare_availability`;

    return this.http.patch(url, payload)
      .pipe(catchError((err) => this.http.handleError(err)));
  }
}
