import {
    Component,
    OnInit,
  } from '@angular/core';
  import * as _ from 'lodash';
  import { FlashService } from '../../components/flash/flash.service';
  import { ActivatedRoute, Router } from '@angular/router';
  import { forkJoin } from 'rxjs';
import { FederatedIdentityGroup } from '../federated-identity-group';
import { FederatedIdentityGroupService } from '../federated-identity-group.service';
import { Customer } from '../../customers/customer';
  
  @Component({
    selector: 'app-new-federated-identity-group',
    templateUrl: './new.component.html',
    styleUrls: ['./new.component.scss']
  })
  export class NewFederatedIdentityGroupComponent implements OnInit {
    group: FederatedIdentityGroup = this.blankGroup();
    customer: Customer;
    hospitalDropdownSettings;
    hospitals;
    roleDropdownSettings;
    roles;
    selectedHospitals = [];
    selectedRoles = [];
    rolesToAdd = [];
    rolesToRemove = [];
    hospitalsToAdd = [];
    hospitalsToRemove = [];
    afterCreate = {
      next: (response) => {
        this.addGroupHospitals(response.federated_identity_group.id);
        this.addGroupRoles(response.federated_identity_group.id);
        this.router.navigate(['/customers', this.customer.id]);
        this.showFlashMessage("success", `${this.group.group_name} has been created.`)
      },
      error: () => {
      }
    };
  
    constructor(
      protected groupService: FederatedIdentityGroupService,
      private flashService: FlashService,
      private router: Router,
      private route: ActivatedRoute
    ) {
    }
  
    ngOnInit() {
      this.hospitalDropdownSettings = {
        singleSelection: false,
        text: 'Select Hospitals',
        selectAllText: 'Select All',
        unselectAllText: 'Unselect All',
        enableSearchFilter: true,
        badgeShowLimit: 4,
        autoPosition: false,
        tagToBody: false
      };

      this.roleDropdownSettings = {
        singleSelection: false,
        text: 'Select Roles',
        selectAllText: 'Select All',
        unselectAllText: 'Unselect All',
        enableSearchFilter: false,
        badgeShowLimit: 4,
        autoPosition: false,
        tagToBody: false
      };

      this.route.data.subscribe(response => {
        this.hospitals = this.mapHospitals(response.hospitals.hospitals);
        this.roles = this.mapRoles(response.roles.roles);
        this.customer = response.customers.customer;
      });

      this.selectedHospitals = [];
    }

    showFlashMessage(type: string, message: string){
      this.flashService.add({
        type: type,
        message: message
      });
    }

    addGroup() {  
      this.create();
    }

    onHospitalItemSelect(item: any) {
      if (this.hospitalsToRemove.some(v => v.id === item.id)) {
        this.hospitalsToRemove = this.hospitalsToRemove.filter(v => v.id === item.id);
      } else {
        this.hospitalsToAdd.push(item);
      }
    }
  
    onHospitalItemDeSelect(item: any) {
      if (this.hospitalsToAdd.some(v => v.id === item.id)) {
        this.hospitalsToAdd = this.hospitalsToAdd.filter(v => v.id === item.id);
      } else {
        this.hospitalsToRemove.push(item);
      }
    }
  
    onHospitalSelectAll(items: any){
      items.forEach(item => {
        this.onHospitalItemSelect(item);
      });
    }
  
    onHospitalDeSelectAll(items: any){
      items.forEach(item => {
        this.onHospitalItemDeSelect(item);
      });
    }
  
    onRoleItemSelect(item: any) {
      if (this.rolesToRemove.some(v => v.id === item.id)) {
        this.rolesToRemove = this.rolesToRemove.filter(v => v.id === item.id);
      } else {
        this.rolesToAdd.push(item);
      }
    }
  
    onRoleItemDeSelect(item: any) {
      if (this.rolesToAdd.some(v => v.id === item.id)) {
        this.rolesToAdd = this.rolesToAdd.filter(v => v.id === item.id);
      } else {
        this.rolesToRemove.push(item);
      }
    }
  
    onRoleSelectAll(items: any){
      items.forEach(item => {
        this.onRoleItemSelect(item);
      });
    }
  
    onRoleDeSelectAll(items: any){
      items.forEach(item => {
        this.onRoleItemDeSelect(item);
      });
    }

    private mapHospitals(data) {
      return _.orderBy(
        _.map(data, function(item) {
          return { id: item.id, itemName: item.name };
        }), 'itemName', 'asc');
    }
  
    private mapRoles(data) {
      return _.orderBy(
        _.map(data, function(item) {
          return { id: item.id, itemName: item.name };
        }), 'itemName', 'asc');
    }
  
    private blankGroup(): FederatedIdentityGroup {
      return <FederatedIdentityGroup>{
        group_name: '',
        description: '',
        limited_access: false,
        created_at: null,
        updated_at: null,
        id: null,
        roles: [],
        hospitals: [],
        customer_id: null,
        customer_name: ''
      };
    }
  
    private create() {
      this.groupService.create(this.customer.id, this.group).subscribe(this.afterCreate);
    }

    private addGroupHospitals(groupId) {
      forkJoin(this.groupService.createGroupHospitals(groupId, this.hospitalsToAdd)).subscribe(() => {
        this.hospitalsToAdd = [];
      });
    }

    private addGroupRoles(groupId) {
      forkJoin(this.groupService.createGroupRoles(groupId, this.rolesToAdd)).subscribe(() => {
        this.rolesToAdd = [];
      });
    }
  }
  