import {
    Component,
    OnInit,
  } from '@angular/core';
  
  import { Customer } from '../customer';
  import { CustomerService } from '../customer.service';
  import * as _ from 'lodash';
  import { FlashService } from '../../components/flash/flash.service';
  import { ActivatedRoute, Router } from '@angular/router';
  import { forkJoin } from 'rxjs';
  
  @Component({
    selector: 'app-new-customer',
    templateUrl: './new.component.html',
    styleUrls: ['./new.component.scss']
  })
  export class NewCustomerComponent implements OnInit {
    customer: Customer = this.blankCustomer();
    organizationDropdownSettings;
    parentOrganizations;
    selectedOrganizations = [];
    organizationsToRemove = [];
    organizationsToAdd = [];
    afterCreate = {
      next: (response) => {
        this.addLinkedOrganizations(response.customer.id);
        this.router.navigate(['/customers']);
        this.showFlashMessage("success", `${this.customer.name} has been created.`)
      },
      error: () => {
      }
    };
  
    constructor(
      protected customerService: CustomerService,
      private flashService: FlashService,
      private router: Router,
      private route: ActivatedRoute
    ) {
    }
  
    ngOnInit() {
      this.organizationDropdownSettings = {
        singleSelection: false,
        text: 'Select Linked Organizations',
        selectAllText: 'Select All',
        unselectAllText: 'Unselect All',
        enableSearchFilter: true,
        badgeShowLimit: 4,
        autoPosition: false,
        tagToBody: false
      };

      this.route.data.subscribe(response => {
        this.parentOrganizations = this.mapOrganizations(response.organizations.hospitals);
      });

      this.selectedOrganizations = [];
    }

    showFlashMessage(type: string, message: string){
      this.flashService.add({
        type: type,
        message: message
      });
    }
  
    addCustomer() {  
      this.create();
    }

    onOrganizationItemSelect(item: any) {
      if (this.organizationsToRemove.some(v => v.id === item.id)) {
        this.organizationsToRemove = this.organizationsToRemove.filter(v => v.id === item.id);
      } else {
        this.organizationsToAdd.push(item);
      }
    }
  
    onOrganizationItemDeSelect(item: any) {
      if (this.organizationsToAdd.some(v => v.id === item.id)) {
        this.organizationsToAdd = this.organizationsToAdd.filter(v => v.id === item.id);
      } else {
        this.organizationsToRemove.push(item);
      }
    }

    onOrganizationSelectAll(items: any){
      items.forEach(item => {
        this.onOrganizationItemSelect(item);
      });
    }
  
    onOrganizationDeSelectAll(items: any){
      items.forEach(item => {
        this.onOrganizationItemDeSelect(item);
      });
    }

    private mapOrganizations(data) {
      return _.orderBy(
        _.map(data, function(item) {
          return { id: item.id, itemName: item.name, disabled: (item.customer_id !== null) };
        }), 'itemName', 'asc');
    }
  
    private blankCustomer(): Customer {
      return <Customer>{
        name: '',
        clientId: '',
        connectionName: ''
      };
    }
  
    private create() {
      this.customerService.create(this.customer).subscribe(this.afterCreate);
    }

    private addLinkedOrganizations(customerId) {
      forkJoin(this.customerService.updateHospitalCustomers(customerId, this.organizationsToAdd)).subscribe(() => {
        this.organizationsToAdd = [];
      });
    }
  }
  