<div *ngIf="!isEdit">

  <div class="row">
    <div class="col-lg-6">
      <h3>Service Fees</h3>
    </div>

    <div class="col-lg-6">
      <button type="button"
              class="btn btn-primary btn-wrap pull-right"
              (click)="toggleEdit()">
        Edit
      </button>
    </div>
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th>Service</th>
        <th>Base Rate</th>
        <th>Mileage Rate</th>
        <th># Vehicles</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let service of data.properties.services">
        <td>{{ getServiceName(service) }}</td>
        <td>{{ service.base_rate | currency:'USD':'$' }}</td>
        <td>{{ service.mileage_rate | currency:'USD':'$' }}</td>
        <td>{{ service.number_of_vehicles }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="isEdit">

  <div class="row">
    <div class="col-lg-6">
      <h3>Service Fees</h3>
    </div>

    <div class="col-lg-6">
      <button type="button"
              class="btn btn-primary btn-wrap pull-right"
              [disabled]="editServiceFeesForm.invalid || hasConflict"
              (click)="save()">
        Update
      </button>
      <button type="button"
              class="btn btn-default btn-wrap pull-right"
              (click)="toggleEdit()">
        Close
      </button>
    </div>
  </div>

  <form class="form" [formGroup]="editServiceFeesForm" novalidate>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Service</th>
          <th>Base Rate</th>
          <th>Mileage Rate</th>
          <th># Vehicles</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container formArrayName="services">
          <tr *ngFor="let service of servicesForm.controls; let i=index" [formGroupName]="i" >
            <td>
              <select class="form-control" id="type" formControlName="type" (change)="checkDuplicateVehicleType()">
                <option *ngFor="let option of serviceOptions" [ngValue]="option.value">
                  {{ option.name }}
                </option>
              </select>
            </td>

            <td>
              <input type="number"
                    class="form-control"
                    formControlName="base_rate"
                    placeholder="Base Rate">
            </td>

            <td>
              <input type="number"
                    class="form-control"
                    formControlName="mileage_rate"
                    placeholder="Mileage Rate">
            </td>

            <td>
              <input type="number"
                    class="form-control"
                    formControlName="number_of_vehicles"
                    placeholder="Number of Vehicles">
            </td>

            <td class="align-middle">
              <a (click)="removeVehicleType(i)" class="btn px-0">
                <img src="assets/images/remove_circle-24px.svg" class="img-circle"
                    alt="Remove">
              </a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>
  <div class="row">
    <div class="col-lg-12">
      <button type="button" class="btn btn-success pull-right"
              (click)="addVehicleType()">
        Add vehicle type
      </button>
    </div>
  </div>
</div>
