export class AdminServerSourceConf {

  protected static readonly SORT_FIELD_KEY = 'sort';
  protected static readonly SORT_DIR_KEY = 'order';
  protected static readonly PAGER_PAGE_KEY = 'page';
  protected static readonly PAGER_LIMIT_KEY = 'per_page';
  protected static readonly FILTER_FIELD_KEY = '#field#';
  protected static readonly TOTAL_KEY = 'x-total-count';
  protected static readonly DATA_KEY = '';
  protected static readonly FORMAT_DATA_FUNCTION = undefined;
  protected static readonly META_CALLBACK_FUNCTION = undefined;
  protected static readonly FILTER_FIELD_DATA_TYPE = undefined;

  endPoint: string;

  sortFieldKey: string;
  sortDirKey: string;
  pagerPageKey: string;
  pagerLimitKey: string;
  filterFieldKey: string;
  totalKey: string;
  dataKey: string;
  formatDataFunction: any;
  metaCallbackFunction: any;
  filterFieldDataType: any;

  constructor(
    { endPoint = '', sortFieldKey = '', sortDirKey = '',
      pagerPageKey = '', pagerLimitKey = '', filterFieldKey = '', totalKey = '', dataKey = '',
      formatDataFunction = '', metaCallbackFunction = '', filterFieldDataType = ''} = {}) {

    this.endPoint = endPoint ? endPoint : '';

    this.sortFieldKey = sortFieldKey ? sortFieldKey : AdminServerSourceConf.SORT_FIELD_KEY;
    this.sortDirKey = sortDirKey ? sortDirKey : AdminServerSourceConf.SORT_DIR_KEY;
    this.pagerPageKey = pagerPageKey ? pagerPageKey : AdminServerSourceConf.PAGER_PAGE_KEY;
    this.pagerLimitKey = pagerLimitKey ? pagerLimitKey : AdminServerSourceConf.PAGER_LIMIT_KEY;
    this.filterFieldKey = filterFieldKey ? filterFieldKey : AdminServerSourceConf.FILTER_FIELD_KEY;
    this.totalKey = totalKey ? totalKey : AdminServerSourceConf.TOTAL_KEY;
    this.dataKey = dataKey ? dataKey : AdminServerSourceConf.DATA_KEY;
    this.formatDataFunction = formatDataFunction ? formatDataFunction : AdminServerSourceConf.FORMAT_DATA_FUNCTION;
    this.metaCallbackFunction = metaCallbackFunction ? metaCallbackFunction : AdminServerSourceConf.META_CALLBACK_FUNCTION;
    this.filterFieldDataType = filterFieldDataType ? filterFieldDataType : AdminServerSourceConf.FILTER_FIELD_DATA_TYPE;
  }
}
