import { inject } from '@angular/core';

import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';

import { HospitalService } from './hospital.service';

export const HospitalDetailsResolver: ResolveFn<any> =(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> => {
    const hospitalService: HospitalService = inject(HospitalService)
    return hospitalService.get(route.params.id);
  }
