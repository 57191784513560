import {Venue} from '../../venues/venues';

export class Locations {
  id: number;
  name: string;
  current_address?: String;
  latitude?: any;
  longitude?: any;
  venue_mapping?: Venue;
  created_at?: Date;
  updated_at?: Date;
  driver_instruction?: string;
  zipcode?: string;
  state?: string;
  city?: string;
  street_address?: string;

  constructor() {
    const keys = [
      'id', 'name', 'created_at', 'updated_at', 'current_address', 'latitude', 'longitude', 'venue_mapping',
      'zipcode', 'state', 'city', 'street_address'
    ];
    keys.forEach((key) => {
      if (key === 'venue_mapping') {
        this[key] = new Venue();
      } else {
        this[key] = null;
      }
    });
  }

  setData(locationData) {
    Object.keys(locationData).map((item) => {
      if (item === 'venue_mapping') {
        const newVenue = new Venue();
        newVenue.setData(locationData[item]);
        this[item] = newVenue;
      } else {
        this[item] = locationData[item];
      }
    });
  }
}
