<div *ngIf="!isEditMode" [ngClass]="{'disable': disable}">
  <div class="row">
    <div class="col-lg-6">
      <h3>Set up restriction rules <i class="font-20">optional</i></h3>
    </div>
    <div class="col-lg-6">
      <button type="button"
              class="btn btn-primary btn-wrap pull-right"
              [ngClass]="{'disableEditButton': disable}"
              (click)="toggleEdit()">
        Edit
      </button>
    </div>
  </div>
  <table class="table table-striped">
    <tbody>
      <tr>
        <th class="width-200">Enable Restriction Rules</th>
        <td>
          <ui-switch [disabled]="true" [ngModel]="data.geofenceRulesEnabled"></ui-switch>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table table-striped" *ngIf="!noGeofenceRulePresent()">
    <thead>
      <tr>
        <th class="width-200">Pickup area</th>
        <th class="width-200">Dropoff area</th>
        <th>Trip reasons</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rule of data.geofenceRules">
        <td class="ellipsis">{{ rule.pickupBoundaryType.label }}</td>
        <td class="ellipsis">{{ rule.dropoffBoundaryType.label }}</td>
        <td>
          {{showReasons(rule.reasons)}}
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="noGeofenceRulePresent()" class="no-geofence-rules-container">
    There are no restriction rules for this geofence.
  </div>
</div>
<div *ngIf="isEditMode">
  <div class="row">
    <div class="col-lg-6">
      <h3>Set up restriction rules <i class="font-20">optional</i></h3>
    </div>

    <div class="col-lg-6">
      <button type="button"
              class="btn btn-primary btn-wrap pull-right tooltip-button"
              [disabled]="geofenceRulesForm.invalid || duplicateRulesPresent"
              (click)="save()">
        Update
      </button>
      <button type="button"
              class="btn btn-default btn-wrap pull-right"
              (click)="toggleEdit()">
        Close
      </button>
    </div>
  </div>
    <form class="form" [formGroup]="geofenceRulesForm" novalidate>
      <table class="table table-striped">
        <tbody>
          <tr>
            <th class="width-200">Enable Restriction Rules</th>
            <td>
              <ui-switch
                formControlName="geofenceRulesEnabled">
              </ui-switch>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="width-200">Pickup area</th>
            <th class="width-200">Dropoff area</th>
            <th class="width-500">Trip reasons</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tbody>
            <ng-container formArrayName="geofenceRules">
              <tr *ngFor="let rule of rulesForm.controls; let index=index" [formGroupName]="index">
                <td>
                  <dropdown
                    [options]="data.boundaryTypes"
                    [currentValue]="data.geofenceRules[index] && data.geofenceRules[index].pickupBoundaryType.value"
                    formControlName="pickupBoundaryTypeId"
                    (change)="checkDuplicateRulesPresent(index)">
                  </dropdown>
                </td>
                <td>
                  <dropdown
                    [options]="data.boundaryTypes"
                    [currentValue]="data.geofenceRules[index] && data.geofenceRules[index].dropoffBoundaryType.value"
                    formControlName="dropoffBoundaryTypeId"
                    (change)="checkDuplicateRulesPresent(index)">
                  </dropdown>
                </td>
                <td>
                  <angular2-multiselect
                    [data]="reasonList"
                    [settings]="reasonTypesDropDownSettings"
                    formControlName="reasons">
                  </angular2-multiselect>
                </td>
                <td class="align-middle">
                  <a (click)="removeRestrictionRules(index)" class="btn px-0">
                    <img src="assets/images/remove_circle-24px.svg" class="img-circle"
                          alt="Remove">
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
      </table>
    </form>
    <div class="row">
      <div class="col-lg-12">
        <button type="button"
                class="btn btn-success pull-right"
                (click)="addRestrictionRules()">
          Add Restriction Rules
        </button>
      </div>
    </div>  
</div>
