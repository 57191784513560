<ol class="breadcrumb">
  <li><a routerLink="/providers">Transport Companies</a></li>
  <li *ngIf="provider.active"><a routerLink="/providers">Approved</a></li>
  <li *ngIf="!provider.active"><a routerLink="/providers-pending">Pending</a></li>
  <li class="active">{{provider.name}}</li>
</ol>

<h1>{{provider.name}}</h1>

<ul class="nav nav-tabs">
  <li>
    <a routerLink="/providers/{{provider.id}}/">
      Details
    </a>
  </li>

  <li class="active">
    <a routerLink="/providers/{{provider.id}}/users/">
      Users
    </a>
  </li>
</ul>

<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="dispatchers"
      (userRowSelect)="onRowSelect($event)"
    >
    </ng2-smart-table>
  </div>
</div>
