import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FederatedIdentityGroup } from '../federated-identity-group';

import { FlashService } from '../../components/flash/flash.service';
import { forkJoin } from 'rxjs';
import { FederatedIdentityGroupService } from '../federated-identity-group.service';
import { Customer } from '../../customers/customer';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditFederatedIdentityGroupComponent implements OnInit {
  group: FederatedIdentityGroup;
  customer: Customer;
  isEdit = false;
  hospitalDropdownSettings;
  roleDropdownSettings;
  hospitals;
  selectedHospitals = [];
  hospitalsToRemove = [];
  hospitalsToAdd = [];
  roles;
  selectedRoles = [];
  rolesToRemove = [];
  rolesToAdd = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private federatedIdentityGroupService: FederatedIdentityGroupService,
    private flashService: FlashService
  ) {
  }

  ngOnInit() {
    this.hospitalDropdownSettings = {
      singleSelection: false,
      text: 'Select Hospitals',
      selectAllText: 'Select All',
      unselectAllText: 'Unselect All',
      enableSearchFilter: false,
      badgeShowLimit: 4,
      autoPosition: false,
      tagToBody: false
    };

    this.roleDropdownSettings = {
      singleSelection: false,
      text: 'Select Roles',
      selectAllText: 'Select All',
      unselectAllText: 'Unselect All',
      enableSearchFilter: false,
      badgeShowLimit: 4,
      autoPosition: false,
      tagToBody: false
    };

    this.route.data.subscribe(data => {
      const group = data.federatedIdentityGroup.federated_identity_group;
      this.group = {
        id: group.id,
        group_name: group.group_name,
        description: group.description,
        created_at: group.created_at,
        updated_at: group.updated_at,
        hospitals: group.hospitals,
        roles: group.roles,
        limited_access: group.limited_access,
        customer_id: group.customer_id,
        customer_name: group.customer_name
      }
      this.group['created_on'] = moment(group['created_at']).format('LLL');
      this.group['updated_on'] = moment(group['updated_at']).format('LLL');

      this.customer = data.customers.customer;

      this.selectedRoles = this.mapGroupRoles(data.groupRoles.federated_identity_group_roles);
      this.roles = this.mapRoles(data.roles.roles);

      this.selectedHospitals = this.mapGroupHospitals(data.groupHospitals.federated_identity_group_hospitals);
      this.hospitals = this.mapHospitals(data.hospitals.hospitals);
    });
  }

  showFlashMessage(type: string, message: string){
    this.flashService.add({
      type: type,
      message: message
    });
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
  }

  update(): void {
    forkJoin(this.federatedIdentityGroupService.createGroupHospitals(this.group.id, this.hospitalsToAdd)).subscribe(() => {
      this.hospitalsToAdd = [];
    });
    forkJoin(this.federatedIdentityGroupService.removeGroupHospitals(this.group.id, this.hospitalsToRemove)).subscribe(() => {
      this.hospitalsToRemove = [];
    });

    forkJoin(this.federatedIdentityGroupService.createGroupRoles(this.group.id, this.rolesToAdd)).subscribe(() => {
      this.rolesToAdd = [];
    });
    forkJoin(this.federatedIdentityGroupService.removeGroupRoles(this.group.id, this.rolesToRemove)).subscribe(() => {
      this.rolesToRemove = [];
    });
    this.federatedIdentityGroupService.update(this.group.id, this.constructGroupRequest()).subscribe(() => {
      this.showFlashMessage("success", `${this.group.group_name} has been updated.`)
      this.isEdit = false;
    })
  }

  delete(): void {
    this.federatedIdentityGroupService.delete(this.group.id).subscribe(() => {
      this.router.navigate(['/customers', this.customer.id]);
      this.showFlashMessage("success", `${this.group.group_name} has been deleted.`)
    })
  }

  constructGroupRequest(): any {
    return {
      id: this.group.id,
      group_name: this.group.group_name,
      description: this.group.description,
      limited_access: this.group.limited_access,
    }
  }

  onHospitalItemSelect(item: any) {
    if (this.hospitalsToRemove.some(v => v.id === item.id)) {
      this.hospitalsToRemove = this.hospitalsToRemove.filter(v => v.id === item.id);
    } else {
      this.hospitalsToAdd.push(item);
    }
  }

  onHospitalItemDeSelect(item: any) {
    if (this.hospitalsToAdd.some(v => v.id === item.id)) {
      this.hospitalsToAdd = this.hospitalsToAdd.filter(v => v.id === item.id);
    } else {
      this.hospitalsToRemove.push(item);
    }
  }

  onHospitalSelectAll(items: any){
    items.forEach(item => {
      this.onHospitalItemSelect(item);
    });
  }

  onHospitalDeSelectAll(items: any){
    items.forEach(item => {
      this.onHospitalItemDeSelect(item);
    });
  }

  onRoleItemSelect(item: any) {
    if (this.rolesToRemove.some(v => v.id === item.id)) {
      this.rolesToRemove = this.rolesToRemove.filter(v => v.id === item.id);
    } else {
      this.rolesToAdd.push(item);
    }
  }

  onRoleItemDeSelect(item: any) {
    if (this.rolesToAdd.some(v => v.id === item.id)) {
      this.rolesToAdd = this.rolesToAdd.filter(v => v.id === item.id);
    } else {
      this.rolesToRemove.push(item);
    }
  }

  onRoleSelectAll(items: any){
    items.forEach(item => {
      this.onRoleItemSelect(item);
    });
  }

  onRoleDeSelectAll(items: any){
    items.forEach(item => {
      this.onRoleItemDeSelect(item);
    });
  }

  private mapHospitals(data) {
    return _.orderBy(
      _.map(data, function(item) {
        return { id: item.id, itemName: item.name };
      }), 'itemName', 'asc');
  }

  private mapRoles(data) {
    return _.orderBy(
      _.map(data, function(item) {
        return { id: item.id, itemName: item.name };
      }), 'itemName', 'asc');
  }

  private mapGroupHospitals(data) {
    return _.orderBy(
      _.map(data, function(item) {
        return { id: item.hospital.id, itemName: item.hospital.name };
      }), 'itemName', 'asc');
  }

  private mapGroupRoles(data) {
    return _.orderBy(
      _.map(data, function(item) {
        return { id: item.role.id, itemName: item.role.name };
      }), 'itemName', 'asc');
  }
}

