import { inject } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ResolveFn
} from '@angular/router';

import { Observable } from 'rxjs';

import { Provider } from './provider';
import { ProviderService } from './providers.service';

export const ActiveProviderDropdownResolver: ResolveFn<Provider[]> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Provider[]> => {
    const providerService: ProviderService = inject(ProviderService)
    return providerService.dropdownActive();
  }
