import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Customer } from '../customer';

import { FlashService } from '../../components/flash/flash.service';
import { CustomerService } from '../customer.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditCustomerComponent implements OnInit {
  customer: Customer;
  isEdit = false;
  organizationDropdownSettings;
  parentOrganizations;
  selectedOrganizations = [];
  organizationsToRemove = [];
  organizationsToAdd = [];

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private flashService: FlashService
  ) {
  }

  ngOnInit() {
    this.organizationDropdownSettings = {
      singleSelection: false,
      text: 'Select Linked Organizations',
      selectAllText: 'Select All',
      unselectAllText: 'Unselect All',
      enableSearchFilter: true,
      badgeShowLimit: 4,
      autoPosition: false,
      tagToBody: false
    };

    this.route.data.subscribe(data => {
      const customer = data.customers.customer;
      this.customer = {
        id: customer.id,
        name: customer.name,
        clientId: customer.partner_api_client_id,
        connectionName: customer.connection_name,
        createdAt: customer.created_at,
        updatedAt: customer.updated_at
      }

      this.selectedOrganizations = this.mapOrganizations(data.customerOrganizations.hospitals);
      this.parentOrganizations = this.mapOrganizations(data.organizations.hospitals);
    });
  }

  showFlashMessage(type: string, message: string){
    this.flashService.add({
      type: type,
      message: message
    });
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
  }

  update(): void {
    forkJoin(this.customerService.updateHospitalCustomers(this.customer.id, this.organizationsToAdd)).subscribe(() => {
      this.organizationsToAdd = [];
    });
    forkJoin(this.customerService.updateHospitalCustomers(null, this.organizationsToRemove)).subscribe(() => {
      this.organizationsToRemove = [];
    });
    this.customerService.update(this.constructCustomerRequest()).subscribe(() => {
      this.showFlashMessage("success", `${this.customer.name} has been updated.`)
      this.isEdit = false;
    })
  }

  constructCustomerRequest(): any {
    return {
      id: this.customer.id,
      name: this.customer.name,
      partner_api_client_id: this.customer.clientId,
      connection_name: this.customer.connectionName,
    }
  }

  onOrganizationItemSelect(item: any) {
    if (this.organizationsToRemove.some(v => v.id === item.id)) {
      this.organizationsToRemove = this.organizationsToRemove.filter(v => v.id === item.id);
    } else {
      this.organizationsToAdd.push(item);
    }
  }

  onOrganizationItemDeSelect(item: any) {
    if (this.organizationsToAdd.some(v => v.id === item.id)) {
      this.organizationsToAdd = this.organizationsToAdd.filter(v => v.id === item.id);
    } else {
      this.organizationsToRemove.push(item);
    }
  }

  onOrganizationSelectAll(items: any){
    items.forEach(item => {
      this.onOrganizationItemSelect(item);
    });
  }

  onOrganizationDeSelectAll(items: any){
    items.forEach(item => {
      this.onOrganizationItemDeSelect(item);
    });
  }

  private mapOrganizations(data) {
    const customer = this.customer;
    return _.orderBy(
      _.map(data, function(item) {
        return { id: item.id, itemName: item.name, disabled: (item.customer_id !== null && item.customer_id !== customer.id) };
      }), 'itemName', 'asc');
  }
}

