import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PayerService } from '../../payer.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../utils';
import { FlashService } from '../../../components/flash/flash.service';
import { PAYER_HIDDEN_KEYS, PAYER_TYPES, VEHICLE_TYPES, VEHICLE_TYPES_DISPLAY, PAYER_KEYS } from '../../payer';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss']
})
export class PlanDetailsComponent implements OnInit {
  @Output() onUpdate = new EventEmitter<any>();
  payerOriginal: any;
  formFields = {
    'general': [
      {
        label: 'Plan name',
        name: 'name',
        editType: 'text',
        viewType: 'hidden',
        disabled: false
      },
      {
        label: 'Carrier name',
        name: 'carrier_name',
        editType: 'text',
        viewType: 'default',
        disabled: true
      },
      {
        label: 'Contact number',
        name: 'authorization_contact',
        editType: 'text',
        viewType: 'default',
        disabled: false,
        validators: [
          Validators.pattern(/^[\+\d]+(?:[\d-.\s()]*)$/),
          Validators.minLength(8),
          Validators.maxLength(14)
        ]
      },
      {
        label: 'Insurance category',
        name: 'insurance_categories',
        editType: 'multi-select',
        viewType: 'multi-select',
        disabled: false,
        options: PAYER_TYPES
      },
      {
        label: 'Has NEMT benefit?',
        name: 'nemt_benefit',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Covered vehicle types',
        name: 'covered_vehicles',
        editType: 'multi-select',
        viewType: 'columnar',
        disabled: false,
        options: VEHICLE_TYPES
      },
      {
        label: 'Organizations',
        name: 'organizations',
        editType: 'hidden',
        viewType: 'comma-separated',
        disabled: true
      }
    ],
    'authorization': [
      {
        label: 'Authorization required',
        name: 'auth_required',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Pre-authorization needed before transport',
        name: 'preauth_required',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'In-network benefit',
        name: 'in_network_benefit',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'In-network auth required',
        name: 'in_network_auth_required',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Out-of network benefit',
        name: 'out_of_network_benefit',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Out-of network auth required',
        name: 'out_of_network_auth_required',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Vehicle types requiring authorization',
        name: 'vehicles_requiring_auth',
        editType: 'multi-select',
        viewType: 'columnar',
        disabled: false,
        options: VEHICLE_TYPES
      },
      {
        label: 'Same-day auth required',
        name: 'same_day_auth_required',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Post-transport auth window',
        name: 'post_transport_auth_window_in_days',
        editType: 'number',
        viewType: 'default',
        disabled: false,
        validators: Validators.min(0)
      },
      {
        label: 'Auth/Clinical form required',
        name: 'auth_clinical_form_required',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Fax documents to',
        name: 'fax_number',
        editType: 'text',
        viewType: 'default',
        disabled: false,
        validators: [
          Validators.pattern(/^[\+\d]+(?:[\d-.\s()]*)$/),
          Validators.minLength(10),
          Validators.maxLength(12)
        ]
      }
    ],
    'broker': [
      {
        label: 'Brokered transport',
        name: 'brokered_transport',
        editType: 'ui-switch',
        viewType: 'boolean',
        disabled: false
      },
      {
        label: 'Broker name',
        name: 'broker_name',
        editType: 'text',
        viewType: 'default',
        disabled: false
      },
      {
        label: 'Broker phone',
        name: 'broker_phone',
        editType: 'text',
        viewType: 'default',
        disabled: false,
        validators: [
          Validators.pattern(/^[\+\d]+(?:[\d-.\s()]*)$/),
          Validators.minLength(8),
          Validators.maxLength(14)
        ]
      },
      {
        label: 'Broker\'s advance notice',
        name: 'brokers_advance_notice_in_days',
        editType: 'number',
        viewType: 'default',
        disabled: false,
        validators: Validators.min(0)
      },
      {
        label: 'Brokered vehicle types',
        name: 'brokered_vehicle_types',
        editType: 'multi-select',
        viewType: 'columnar',
        disabled: false,
        options: VEHICLE_TYPES
      }
    ]
  };

  readonly fields = Object.keys(this.formFields)
    .map(key => ({label: key, value: this.formFields[key]}));
  payerForm: FormGroup;
  isEdit = false;
  hiddenElements = {};
  payerKeys = PAYER_KEYS;

  constructor(
    private route: ActivatedRoute,
    private flashService: FlashService,
    private payerService: PayerService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.payerOriginal = data.payer.data;
    });

    this.modifyMultiSelectItems(this.payerOriginal);
    Utils.toggleConditionalElements(this.payerOriginal, this.hiddenElements);
  }

  updatePayer() {
    const payload = _.cloneDeep(this.payerForm.value);

    payload.insurance_categories = _.map(payload.insurance_categories, 'id');
    payload.vehicles_requiring_auth = _.map(payload.vehicles_requiring_auth, 'id');
    payload.covered_vehicles = _.map(payload.covered_vehicles, 'id');
    payload.brokered_vehicle_types = _.map(payload.brokered_vehicle_types, 'id');

    Utils.setDefaultValueToHiddenElements(payload, this.hiddenElements);
    Utils.setDefaultValueToHiddenElements(this.payerForm.value, this.hiddenElements);

    this.payerService.updateActivePlan(this.payerOriginal.id, payload).subscribe(
      (response) => {
        this.payerForm.value.id = this.payerOriginal.id;
        const originalName = this.payerOriginal.name;
        this.payerForm.value.carrier_name = this.payerOriginal.carrier_name;
        this.payerForm.value.organizations = this.payerOriginal.organizations;
        this.payerOriginal = this.payerForm.value;
        this.flashService.add({
          type: 'success',
          message: `${originalName} has been updated.`
        });
        this.toggleEdit();
        this.onUpdate.emit(this.payerForm.value);
      },
      (response) => {
        if (response.status === 400) {
          const errors = JSON.parse(response['_body']).errors;
          Object.keys(errors).forEach(prop => {
            const formControl = this.payerForm.get(prop);
            if (formControl) {
              formControl.setErrors({
                serverError: errors[prop]
              });
            }
          });
        }
      }
    );
  }

  toggleEdit() {
    this.isEdit = !this.isEdit;

    if (this.isEdit) {
      this.generateFormControls();
    } else {
      Utils.toggleConditionalElements(this.payerOriginal, this.hiddenElements);
    }
  }

  generateFormControls() {
    const formControls = {};
    this.fields.forEach(group => {
      group.value.forEach(control => {
        formControls[control.name] = new FormControl(
          {
            value: _.cloneDeep(this.payerOriginal[control.name]),
            disabled: control.disabled
          },
          control.validators
        );
      });
    });

    this.payerForm = new FormGroup(formControls);
  }

  displayValue(key, viewType) {
    if (this.isEmpty(key)) {
      return this.defaultDisplayValue(viewType);
    }

    switch (viewType) {
      case 'columnar': {
        const displayValue = {};
        for (const id of _.map(this.payerOriginal[key], 'id')) {
          const vehicleType = VEHICLE_TYPES_DISPLAY[id];
          if (displayValue[vehicleType.type]) {
            displayValue[vehicleType.type].subtypes.push(vehicleType.displayName);
          } else {
            displayValue[vehicleType.type] = {subtypes: [vehicleType.displayName], type: vehicleType.type};
          }
        }
        return _.values(displayValue);
      }
      case 'multi-select' : {
        return this.payerOriginal[key].map(item => item.itemName).join(', ');
      }
      case 'comma-separated': {
        return this.payerOriginal[key].join(', ');
      }
      case 'boolean': {
        return this.payerOriginal[key] ? 'Yes' : 'No';
      }
      default: {
        return this.payerOriginal[key];
      }
    }
  }

  isEmpty(key) {
    const value = this.payerOriginal[key];

    return (
      value == null
      || value === ''
      || (value instanceof Array && value.length === 0)
    );
  }

  defaultDisplayValue(viewType) {
    switch (viewType) {
      case 'columnar': {
        return [];
      }
      default: {
        return '-';
      }
    }
  }

  onChange(key: string, event: boolean) {
    if (key === 'preauth_required') {
      if (event) {
        this.payerForm.get('in_network_auth_required').setValue(false);
        this.payerForm.get('out_of_network_auth_required').setValue(false);
        this.payerForm.get('same_day_auth_required').setValue(false);
      }
      return;
    }

    if (PAYER_HIDDEN_KEYS[key]) {
      this.payerForm.value[key] = event;
      Utils.toggleConditionalElements(this.payerForm.value, this.hiddenElements);
    }
  }

  modifyMultiSelectItems(payerData) {
    payerData.insurance_categories = this.filterItems(PAYER_TYPES, payerData.insurance_categories);
    payerData.covered_vehicles = this.filterItems(VEHICLE_TYPES, payerData.covered_vehicles);
    payerData.vehicles_requiring_auth = this.filterItems(VEHICLE_TYPES, payerData.vehicles_requiring_auth);
    payerData.brokered_vehicle_types  = this.filterItems(VEHICLE_TYPES, payerData.brokered_vehicle_types);
  }

  filterItems(list, selectedItemIds) {
    if (selectedItemIds == null) {
      return;
    }

    return _.filter(list, listItem => selectedItemIds.indexOf(listItem['id']) !== -1);
  }

  isHidden(key: any, type = '') {
    return this.hiddenElements[key] || type === 'hidden';
  }

}
