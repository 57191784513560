<div class="sidebar" *ngIf="active">
  <ul class="nav nav-pills nav-stacked">
    <li [ngClass]="{'active': isSelected(['/hospitals'])}">
      <a routerLink="/hospitals">
        <app-icon icon="clipboard" iconClass="nav-icon"></app-icon> Organizations
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/customers'])}">
      <a routerLink="/customers">
        <app-icon icon="clipboard" iconClass="nav-icon"></app-icon> Customers
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/users'])}">
      <a routerLink="/users">
        <app-icon icon="users" iconClass="nav-icon"></app-icon> Users
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/b2c'])}">
      <a routerLink="/b2c">
        <app-icon icon="users" iconClass="nav-icon"></app-icon> B2C/B2B2C
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/venues'])}">
      <a routerLink="/venues">
        <app-icon icon="map-pin" iconClass="nav-icon"></app-icon> Venues
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/providers'])}">
      <a routerLink="/providers">
        <app-icon icon="compass" iconClass="nav-icon"></app-icon> Transport Companies
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/promo-codes'])}">
      <a routerLink="/promo-codes">
        <app-icon icon="package" iconClass="nav-icon"></app-icon> Promo Codes
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/payer'])}">
      <a routerLink="/payer/plans">
        <img class="nav-icon" src="assets/images/payers.svg" /> Payers
      </a>
      <ul class="nested-nav">
        <li [ngClass]="{'active': isSelected('/payer/plans')}">
          <a routerLink="/payer/plans">Plans</a>
        </li>
        <li [ngClass]="{'active': isSelected('/payer/carriers')}">
          <a routerLink="/payer/carriers">Carriers</a>
        </li>
      </ul>
    </li>

    <li [ngClass]="{'active': isSelected(['/reports'])}">
      <a routerLink="/reports">
        <app-icon icon="package" iconClass="nav-icon"></app-icon> Reports
      </a>
    </li>

    <li [ngClass]="{'active': isSelected(['/rideshare-availability'])}">
      <a routerLink="/rideshare-availability">
        <app-icon icon="package" iconClass="nav-icon"></app-icon> Rideshare Availability
      </a>
    </li>
  </ul>
</div>
