const STATES_OPTIONS = {
  filters: [
    {
      'value': 'AL',
      'title': 'Alabama'
    },
    {
      'value': 'AK',
      'title': 'Alaska'
    },
    {
      'value': 'AS',
      'title': 'American Samoa'
    },
    {
      'value': 'AZ',
      'title': 'Arizona'
    },
    {
      'value': 'AR',
      'title': 'Arkansas'
    },
    {
      'value': 'CA',
      'title': 'California'
    },
    {
      'value': 'CO',
      'title': 'Colorado'
    },
    {
      'value': 'CT',
      'title': 'Connecticut'
    },
    {
      'value': 'DE',
      'title': 'Delaware'
    },
    {
      'value': 'DC',
      'title': 'District of Columbia'
    },
    {
      'value': 'FL',
      'title': 'Florida'
    },
    {
      'value': 'GA',
      'title': 'Georgia'
    },
    {
      'value': 'GU',
      'title': 'Guam'
    },
    {
      'value': 'HI',
      'title': 'Hawaii'
    },
    {
      'value': 'ID',
      'title': 'Idaho'
    },
    {
      'value': 'IL',
      'title': 'Illinois'
    },
    {
      'value': 'IN',
      'title': 'Indiana'
    },
    {
      'value': 'IA',
      'title': 'Iowa'
    },
    {
      'value': 'KS',
      'title': 'Kansas'
    },
    {
      'value': 'KY',
      'title': 'Kentucky'
    },
    {
      'value': 'LA',
      'title': 'Louisiana'
    },
    {
      'value': 'ME',
      'title': 'Maine'
    },
    {
      'value': 'MD',
      'title': 'Maryland'
    },
    {
      'value': 'MA',
      'title': 'Massachusetts'
    },
    {
      'value': 'MI',
      'title': 'Michigan'
    },
    {
      'value': 'MN',
      'title': 'Minnesota'
    },
    {
      'value': 'MS',
      'title': 'Mississippi'
    },
    {
      'value': 'MO',
      'title': 'Missouri'
    },
    {
      'value': 'MT',
      'title': 'Montana'
    },
    {
      'value': 'NE',
      'title': 'Nebraska'
    },
    {
      'value': 'NV',
      'title': 'Nevada'
    },
    {
      'value': 'NH',
      'title': 'New Hampshire'
    },
    {
      'value': 'NJ',
      'title': 'New Jersey'
    },
    {
      'value': 'NM',
      'title': 'New Mexico'
    },
    {
      'value': 'NY',
      'title': 'New York'
    },
    {
      'value': 'NC',
      'title': 'North Carolina'
    },
    {
      'value': 'ND',
      'title': 'North Dakota'
    },
    {
      'value': 'OH',
      'title': 'Ohio'
    },
    {
      'value': 'OK',
      'title': 'Oklahoma'
    },
    {
      'value': 'OR',
      'title': 'Oregon'
    },
    {
      'value': 'PA',
      'title': 'Pennsylvania'
    },
    {
      'value': 'PR',
      'title': 'Puerto Rico'
    },
    {
      'value': 'RI',
      'title': 'Rhode Island'
    },
    {
      'value': 'SC',
      'title': 'South Carolina'
    },
    {
      'value': 'SD',
      'title': 'South Dakota'
    },
    {
      'value': 'TN',
      'title': 'Tennessee'
    },
    {
      'value': 'TX',
      'title': 'Texas'
    },
    {
      'value': 'UT',
      'title': 'Utah'
    },
    {
      'value': 'VT',
      'title': 'Vermont'
    },
    {
      'value': 'VI',
      'title': 'Virgin Islands'
    },
    {
      'value': 'VA',
      'title': 'Virginia'
    },
    {
      'value': 'WA',
      'title': 'Washington'
    },
    {
      'value': 'WV',
      'title': 'West Virginia'
    },
    {
      'value': 'WI',
      'title': 'Wisconsin'
    },
    {
      'value': 'WY',
      'title': 'Wyoming'
    }],
  mapping: {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District of Columbia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
  }
};

const VEHICLE_TYPES = {
  medical_sedan: 'Medical Sedan',
  taxi: 'Taxi',
  psych_transport: 'Secure Psychiatric Transport Vehicle',
  wheelchair_accessible: 'Wheelchair (Wheel Chair Accessible Vehicle, self-transfer)',
  wheelchair: 'Wheelchair (Wheel Chair Van, with lift)',
  stretcher_van: 'Stretcher Van',
  bls: 'BLS Ambulance',
  bls_bariatric: 'BLS Ambulance (Bariatric)',
  als: 'ALS Ambulance',
  als_bariatric: 'ALS Ambulance (Bariatric)',
  cct: 'SCT/CCT Ambulance',
  rotor_wing: 'SCT/CCT Ambulance (Helicopter)',
  fixed_wing: 'SCT/CCT Ambulance (Airplane)'
};

// An object representing mapping from a service `value` to service `type`.
const VEHICLE_VALUE_TYPE_MAP = {
  medical_sedan: 'medical_sedan',
  taxi: 'taxi',
  psych_transport: 'psych_transport',
  wheelchair_accessible: 'wheelchair',
  wheelchair: 'wheelchair',
  stretcher_van: 'stretcher_van',
  bls: 'bls',
  bls_bariatric: 'bls',
  als: 'als',
  als_bariatric: 'als',
  cct: 'cct',
  rotor_wing: 'cct',
  fixed_wing: 'cct'
};

export {
  VEHICLE_TYPES,
  VEHICLE_VALUE_TYPE_MAP
};

export const DAYS = [
  {
    value: 'Sunday',
    label: 'Sunday'
  },
  {
    value: 'Monday',
    label: 'Monday'
  },
  {
    value: 'Tuesday',
    label: 'Tuesday'
  },
  {
    value: 'Wednesday',
    label: 'Wednesday'
  },
  {
    value: 'Thursday',
    label: 'Thursday'
  },
  {
    value: 'Friday',
    label: 'Friday'
  },
  {
    value: 'Saturday',
    label: 'Saturday'
  }
];

export default STATES_OPTIONS;

export const DISPATCH_SOFTWARE_OPTIONS = {
  cts: 'CTS',
  cyrun: 'Cyrun',
  dispatchbot: 'DispatchBot',
  excel_spreadsheet: 'Excel Spreadsheet',
  engraph: 'Engraph',
  firehouse: 'Firehouse',
  'infor_(geac)': 'Infor (GEAC)',
  logis: 'Logis',
  medapoint: 'Medapoint',
  mres_cad: 'MRES CAD',
  nemtcloud: 'NEMTCLOUD',
  pantonium: 'Pantonium',
  paraplan: 'Paraplan',
  routingbox: 'RoutingBox',
  schedule_viewer: 'Schedule Viewer',
  simpli_transport_trapeze: 'Simpli Transport Trapeze',
  traumasoft: 'Traumasoft',
  tripmaster: 'TripMaster',
  tripspark: 'TripSpark',
  tritech: 'TriTech',
  wellryde: 'WellRyde',
  zoll: 'ZOLL (RescueNet)',
  other: 'Other',
};

export const CERTIFICATIONS = [
  'Historically Underutilized Business (HUB)',
  'Woman Owned Business (WBE)',
  'Minority Owned Business (MBE)',
  'Disadvantaged Business Enterprise (DBE)',
  'Disabled Veteran Enterprise',
  'LGBT',
];

export const LANGUAGE_SPOKEN = [
  'Spanish', 'Russian', 'French', 'Mandarin', 'Arabic', 'Vietnamese', 'Other'
];

export const FILE_SIZE_UNIT = [
  'B', 'KB', 'MB', 'GB'
];

export class ContactInformation {
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;

  constructor(data: any = {}) {
    this.serialize(data);
  }

  serialize(data: any = {}) {
    const {
      phone,
      address: address1,
      address_2: address2,
      city,
      state,
      zipcode: zipCode,
    } = data;
    this.setContactInformation({phone, address1, address2, city, state, zipCode});
  }

  deserialize() {
    return {
      phone: this.phone,
      address: this.address1,
      address_2: this.address2,
      city: this.city,
      state: this.state,
      zipcode: this.zipCode,
    };
  }

  getContactInformation() {
    return {
      phone: this.phone,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: STATES_OPTIONS.mapping[this.state] || this.state,
      zipCode: this.zipCode,
    };
  }

  setContactInformation(data) {
    const {phone, address1, address2, city, state, zipCode} = data;
    this.phone = phone;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
  }
}

export class Counties {
  id: number;
  name: string;
}

export class State {
  abbreviation?: string;
  id: number;
  name: string;
}

export class ServiceArea {
  state: State;
  counties: Counties[];
}

export class Provider extends ContactInformation {
  id?: any;
  name: string;
  taxCode: string;
  properties: object;

  constructor(data: any = {}) {
    super(data);
    const {
      id,
      name,
      tax_code: taxCode,
      properties,
    } = data;

    this.setData({id, name, taxCode, properties});
  }

  static getServiceName(service) {
    return VEHICLE_TYPES[service.value || service.type];
  }

  setData(data) {
    super.setContactInformation(data);
    const {id, name, taxCode, properties} = data;
    this.id = id;
    this.name = name;
    this.taxCode = taxCode;
    this.properties = properties;
  }
}
