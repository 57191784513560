import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';

import { FederatedIdentityGroup } from '../federated-identity-group';

@Component({
  selector: 'app-federated-identity-groups-view',
  templateUrl: './view.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
    './view.component.scss'
  ]
})

export class FederatedIdentityGroupsViewComponent implements OnInit {
  newFederatedIdentityGroupModal: BsModalRef;
  selectedFederatedIdentityGroup: FederatedIdentityGroup;
  federatedIdentityGroups: FederatedIdentityGroup[];
  customerId;
  loading = false;
  totalRecords;
  first = 0;
  last: number;
  rows = 10;

  placeholders = {
    group_name: 'Group Name',
    roles_string: 'Roles',
    hospitals_string: 'Hospitals',
    created_on: 'Created On',
  };

  tableConfig = {
    columns: {
      group_name: {
        title: 'Group Name'
      },
      roles_string: {
        title: 'Roles'
      },
      hospitals_string: {
        title: 'Hospitals'
      },
      created_on: {
        title: 'Created On',
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  keys = Object.keys(this.tableConfig.columns);

  loadIdentityGroups(event?:Event) {
    this.route.data.subscribe((data) => {
      this.federatedIdentityGroups = data.federatedIdentityGroups.federated_identity_groups;
      this.mutateFederatedIdentityGroups(this.federatedIdentityGroups);
    });
    this.totalRecords = this.federatedIdentityGroups.length;
  }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.customerId = data.id;
    });
    this.loadIdentityGroups();
  }

  pageChange(event: any) {
    this.loadIdentityGroups(event);
  }

  public addGroup(event) {
    this.router.navigate([`customers/${this.customerId}/federated-identity-groups`, 'create']);
  }

  public onRowSelect(event) {
    this.router.navigate([`customers/${this.customerId}/federated-identity-groups`, event.data.id]);
  }
  
  private mutateFederatedIdentityGroups(federatedIdentityGroups: FederatedIdentityGroup[]) {
    if(!this.federatedIdentityGroups){
      this.federatedIdentityGroups = federatedIdentityGroups;
    }

    this.federatedIdentityGroups.forEach(group => {
        group['created_on'] = moment(group['created_at']).format('LLL');
        group['hospitals_string'] = group['hospitals'].map(hospital => hospital.name).join(', ');
        group['roles_string'] = group['roles'].map(role => role.name).join(', ');
    });
  }

}
