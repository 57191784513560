import { inject } from '@angular/core';

import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';

import { Hospital } from './hospital';
import { HospitalService } from './hospital.service';

export const HospitalResolver: ResolveFn<Hospital[]> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Hospital[]> => {
    const hospitalService: HospitalService = inject(HospitalService)
    return hospitalService.get();
  }
