import { Component, Input, OnInit } from '@angular/core';
import { CustomPayerRenderComponent } from '../../../custom-payer-render/custom-payer-render.component';
import * as moment from 'moment';
import { AdminServerDataSource } from '../../../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-associated-plans-table',
  templateUrl: './associated-plans-table.component.html',
  styleUrls: [
    './associated-plans-table.component.scss',
    '../../../../../themes/ng2-smart-table.scss'
  ]
})

export class AssociatedPlansTableComponent implements OnInit {
  @Input() carrierId: number;
  associatedPlans: any;

  tableConfig = {
    columns: {
      name: {
        title: 'Plan name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        filter: false
      },
      auth_required: {
        title: 'Auth Required?',
        filter: false
      },
      brokered_transport: {
        title: 'Brokered?',
        filter: false
      },
      updated_at: {
        title: 'Last Updated',
        filter: false,
      }
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
  };

  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.associatedPlans = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/carriers/${this.carrierId}/plans`,
      formatDataFunction: this.formatPlansData,
    });
  }


  formatPlansData = (associatedPlans) => {
    associatedPlans.forEach((plan) => {
      plan['name'] = plan.plan_name;
      plan.updated_at = moment(plan.updated_at).format('LL');
      plan.auth_required = plan.auth_required ? 'Yes' : 'No';
      plan.brokered_transport = plan.brokered_transport ? 'Yes' : 'No';
    });
  }

  onRowSelect(event) {
    this.router.navigate(['/payer/active-plans', event.data.id]);
  }
}
