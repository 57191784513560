import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { environment} from '../../../environments/environment';
import { AuthHttpService } from '../../core/auth-http.service';
import {HospitalProgram } from './hospital-program';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HospitalProgramService {

  constructor(private http: AuthHttpService) {}

  create(hospitalId: number, hospitalProgram: HospitalProgram): Observable<HospitalProgram | string> {
    const url = `${environment.apiv3url}/programs`;
    const payload = {
        hospital_id: hospitalId,
        name: hospitalProgram.name
    };

    return this.http.postV3<HospitalProgram>(url, payload).pipe(
      map(x => x),
      catchError((err) => this.http.handleError(err))
    );
  }

  update(hospitalId: number, hospitalProgram: HospitalProgram): Observable<HospitalProgram | string> {
    const url = `${environment.apiv3url}/programs/${hospitalProgram.id}`;
    const payload = {
        hospital_id: hospitalId,
        name: hospitalProgram.name
    };

    return this.http.updateV3<HospitalProgram>(url, payload).pipe(
      map(x => x),
      catchError((err) => this.http.handleError(err))
    );
  }

  restore(hospitalId: number, hospitalProgram: HospitalProgram): Observable<HospitalProgram | string> {
    const url = `${environment.apiv3url}/programs/${hospitalProgram.id}`;
    const payload = {
      hospital_id: hospitalId,
      deleted_at: null
  };
    return this.http.updateV3<HospitalProgram>(url, payload).pipe(
      map(x => x),
      catchError((err) => this.http.handleError(err))
    );
  }

  delete(hospitalId: number, hospitalProgram: HospitalProgram): Observable<HospitalProgram | string> {
    const url = `${environment.apiv3url}/programs/${hospitalProgram.id}`;
    return this.http.deleteV3<HospitalProgram>(url).pipe(
      catchError((err) => this.http.handleError(err)));
  } 

  listActive(hospitalId: number): Observable<HospitalProgram[]> {
    return this.http.getV3<HospitalProgram[]>(`${environment.apiv3url}/programs?hospital_id=${hospitalId}`);
  }

  listInactive(hospitalId: number): Observable<HospitalProgram[]> {
    return this.http.getV3<HospitalProgram[]>(`${environment.apiv3url}/programs?hospital_id=${hospitalId}&only_deleted=true`);
  }


  setProgramData(program, newData) {
    program.id = newData.id;
    program.name = newData.name;
    program.created_at = newData.created_at;
  }

  mutateData(program) {
    program['created_on'] = moment(program['created_at']).format('LLL');
  }
}
