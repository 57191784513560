import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';

import {
  RideshareAvailability,
  RideshareAvailabilityUnknownGrade,
  SOURCES,
} from '../rideshare-availbility';
import { RideshareAvailabilityService } from '../rideshare-availability.service';
import { RideshareAvailabilityCSVParserService } from '../rideshare-availability-csv-parser.service';

const CSV_MIME_TYPE = 'text/csv'


const validateFileType = (form: FormControl) => {
  const file = form.value
  if (!file) return null

  return file.type === CSV_MIME_TYPE ? null : { requiredFileType: true }
}

@Component({
  selector: 'app-new-modal',
  templateUrl: './new-modal.component.html',
  styleUrls: ['./new-modal.component.scss']
})
export class NewRideshareAvailabilityModalComponent {
  added = false;
  newRideshareAvailabilityForm: FormGroup;
  sources = SOURCES;

  constructor(
    public modalRef: BsModalRef,
    protected rideshareAvailabilityService: RideshareAvailabilityService,
    private modalService: BsModalService,
    private csvParser: RideshareAvailabilityCSVParserService,
  ) {
    this.newRideshareAvailabilityForm = new FormGroup({
      source: new FormControl(null, Validators.required),
      file: new FormControl(null, [
        Validators.required,
        validateFileType,
      ]),
    });
  }

  public get duplicateZipcodes(): string[] {
    return this.csvParser.duplicateZipcodes
  }

  public get processedZipcodes(): Record<string, true> {
    return this.csvParser.processedZipcodes
  }

  public get unknownGrades(): RideshareAvailabilityUnknownGrade[] {
    return this.csvParser.unknownGrades
  }

  public get invalidEntriesRowNumber(): number[] {
    return this.csvParser.invalidEntriesRowNumber
  }

  public get invalidZipcodes(): string[] {
    return this.csvParser.invalidZipcodes
  }

  handleSubmit() {
    if (this.newRideshareAvailabilityForm.invalid) {
      return;
    }

    const file = this.newRideshareAvailabilityForm.get('file').value

    const reader = new FileReader();
    reader.readAsText(file);

    return reader.onload = (event) => {
      const csv = event.target.result; // Content of CSV file
      const data = this.csvParser.parse(
        csv as string,
        this.newRideshareAvailabilityForm.get('source').value,
      );

      this.patch(data)
    }
  }

  handleFileSelect(e: Event) {
    const { files } = (<HTMLInputElement>e.target)

    if (files && files.length) {
      this.newRideshareAvailabilityForm.patchValue({
        file: files[0]
      })
    }
  }

  private patch(data: RideshareAvailability[]) {
    this.rideshareAvailabilityService
      .patch(data)
      .subscribe(
        () => {
          this.added = true;
          this.modalRef.hide();
        },
        () => this.modalRef.hide()
      );
  }
}
