import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '../components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TagInputModule } from 'ngx-chips';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Ng2SmartTableModule } from 'rt-ng2-smart-table';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TableModule } from 'primeng/table';

import { HospitalService } from './hospital.service';
import { HospitalResolver } from './hospital-resolver.service';
import { HospitalDetailsResolver } from './hospital-details-resolver.service';
import { HospitalPayerTypesResolverService } from './hospital-payer-types-resolver.service';
import { ReasonTypesResolverService } from '../reason-types/reason-types-resolver.service';

import { HospitalsViewComponent } from './view/view.component';
import { NewHospitalModalComponent } from './new-modal/new-modal.component';
import { HospitalsEditComponent } from './edit/edit.component';
import { HospitalProgramService } from './programs/hospital-program.service';
import { NewHospitalProgramModalComponent } from './programs/new-program/new-program.component';
import { LocationsModule } from './locations/locations.module';
import { VehicleResolver } from './vehicle-resolver.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HospitalCommonComponent } from './hospital-common';
import { VehicleRatesComponent } from './vehicle-rates/vehicle-rates.component';
import { HumanizeString } from '../pipes/humanize_string.pipe';
import { AddVehicleRatesModalComponent } from './vehicle-rates/modal/modal.component';
import { TigerConnectIntegrationComponent } from './tiger-connect-integration/tiger-connect-integration.component';
import { CommunitySettingsComponent } from './community-settings/community-settings.component';
import { PreferredProvidersComponent } from './preferred-providers/preferred-providers.component';
import { ProviderAccessComponent } from './provider-access/provider-access.component';
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { DraggableItemService, SortableModule } from 'ngx-bootstrap/sortable';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PreferredProviderRulesResolverService } from './preferred-provider-rules-resolver.service';
import { ProviderAccessRulesResolverService } from './provider-access-rules-resolver.service';
import { GeofenceResolver } from './geofence-resolver.service';
import { GeofenceComponent } from './geofence/geofence.component';
import { GeofenceRulesComponent } from './geofence-rules/geofence-rules.component';
import { HospitalReasonTypesResolverService } from './hospital-reason-types-resolver.service';
import { UserUploadComponent } from './user-upload/user-upload.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { RedoxSetupComponent } from './integrations/redox-setup/redox-setup.component';
import { PartnerSetupComponent } from './integrations/partner-setup/partner-setup.component';
import { BenefitManagementComponent } from './benefit-management/benefit-management.component';
import { QuicksightReportComponent } from './quicksight-reports/quicksight-reports.component';
import { CustomerListResolver } from '../customers/customer-list-resolver.service';
import { HospitalBrokerBypassVehicleTypesResolverService } from './hospital-broker-bypass-vehicle-types-resolver.service';
import { VehicleTypesResolverService } from './vehicle-types-resolver.service';


@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TagInputModule,
        TableModule,
        Ng2SmartTableModule,
        UiSwitchModule,
        LocationsModule,
        AngularMultiSelectModule,
        TypeaheadModule.forRoot(),
        SortableModule.forRoot(),
        CollapseModule.forRoot()
    ],
    declarations: [
        HospitalsViewComponent,
        NewHospitalModalComponent,
        AddVehicleRatesModalComponent,
        HospitalsEditComponent,
        NewHospitalProgramModalComponent,
        HospitalCommonComponent,
        VehicleRatesComponent,
        TigerConnectIntegrationComponent,
        HumanizeString,
        CommunitySettingsComponent,
        PreferredProvidersComponent,
        ProviderAccessComponent,
        GeofenceComponent,
        GeofenceRulesComponent,
        UserUploadComponent,
        IntegrationsComponent,
        RedoxSetupComponent,
        PartnerSetupComponent,
        BenefitManagementComponent,
        QuicksightReportComponent,
    ],
    exports: [
        HospitalsViewComponent,
        HospitalsEditComponent
    ],
    providers: [
        { provide: 'CustomerListResolver', useValue: CustomerListResolver },
        HospitalService,
        { provide: 'HospitalResolver', useValue: HospitalResolver },
        { provide: 'HospitalDetailsResolver', useValue: HospitalDetailsResolver },
        HospitalProgramService,
        HospitalPayerTypesResolverService,
        HospitalReasonTypesResolverService,
        HospitalBrokerBypassVehicleTypesResolverService,
        { provide: 'VehicleTypesResolverService', useValue: VehicleTypesResolverService },
        DatePipe,
        { provide: 'VehicleResolver', useValue: VehicleResolver },
        PreferredProvidersComponent,
        ProviderAccessComponent,
        { provide: 'PreferredProviderRulesResolverService', useValue: PreferredProviderRulesResolverService },
        { provide: 'ProviderAccessRulesResolverService', useValue: ProviderAccessRulesResolverService },
        DraggableItemService,
        { provide: 'ReasonTypesResolverService', useValue: ReasonTypesResolverService },
        { provide: 'GeofenceResolver', useValue: GeofenceResolver },
    ]
})
export class HospitalsModule { }
