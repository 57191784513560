<ol class="breadcrumb">
  <li><a routerLink="/payer/carriers">Carriers</a></li>
  <li class="active">{{ carrier.name }}</li>
</ol>

<div>
  <h1>
    {{ carrier.name }}
  </h1>
</div>

<tabset>
  <tab>
    <ng-template tabHeading>
      Details
    </ng-template>

    <app-carrier-details [carrier]="carrier" (carrierUpdated)="carrierUpdated($event)"></app-carrier-details>
  </tab>

  <tab>
    <ng-template tabHeading>
      Notes
    </ng-template>

    <app-carrier-notes [carrierId]="carrier.id" [notes]="notes"></app-carrier-notes>
  </tab>
</tabset>
