import { Component, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Router } from '@angular/router';

interface Filter {
  value: any;
}

interface Event {
  first: number;
  rows: number;
  filters: { [key: string]: Filter };
  originalEvent:any;
  value?: {label: string, value: any};
}

interface Plan {
  id: number;
  carrier_name: string;
  plan_name: string;
  organization: string;
  created_at: string;
}
@Component({
  selector: 'app-pending-plan-list',
  templateUrl: './pending-plan-list.component.html',
  styleUrls: [
    './pending-plan-list.component.scss',
    '../../../themes/primeng-table.scss'
  ]
})
export class PendingPlanListComponent implements OnDestroy {
  plans: Plan[];
  selectedPlan: Plan;
  loading = false;

  selectedCreatedAt: any;
  first = 0;
  last: number;
  rows = 10;
  totalRecords: number = 0;
  pageOffset: number = 0;
  page: number = 1;
  searchCriteria: any[] = [];

  placeholders = {
    carrier_name: 'Carrier name',
    plan_name: 'Plan name',
    organization: 'Organization',
    created_at: ' ',
  };
  
  monthList = [
    {value: 'January', title: 'January', label: 'created_at'},
    {value: 'February', title: 'February', label: 'created_at'},
    {value: 'March', title: 'March', label: 'created_at'},
    {value: 'April', title: 'April', label: 'created_at'},
    {value: 'May', title: 'May', label: 'created_at'},
    {value: 'June', title: 'June', label: 'created_at'},
    {value: 'July', title: 'July', label: 'created_at'},
    {value: 'August', title: 'August', label: 'created_at'},
    {value: 'September', title: 'September', label: 'created_at'},
    {value: 'October', title: 'October', label: 'created_at'},
    {value: 'November', title: 'November', label: 'created_at'},
    {value: 'December', title: 'December', label: 'created_at'}
  ]
  
  tableConfig = {
    columns: {
      carrier_name: {
        title: 'Carrier name'
      },

      plan_name: {
        title: 'Plan name'
      },

      organization: {
        title: 'Organization'
      },

      created_at: {
        title: 'Created on',
      },
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  constructor(protected http: HttpClient, private router: Router) { }

  keys = Object.keys(this.tableConfig.columns);

  resetPlaceHolder(filterName){
      this.placeholders[filterName] = ' ';
    }
      
  addOrUpdateSearchCriteria(searchTerm, value){
    const existingEntry = this.searchCriteria.find(entry => entry[searchTerm] !== undefined);
      if(existingEntry){
        existingEntry[searchTerm] = value
      }
      else {
        this.searchCriteria.push({[searchTerm]: value});
      }
  }

  appendPagination(event?: Event){
    this.pageOffset = event.first / event.rows;
    if(!this.pageOffset){
      this.pageOffset = 0;
    }
    this.page = this.pageOffset + 1;
    this.addOrUpdateSearchCriteria('page', this.page);
    this.addOrUpdateSearchCriteria('per_page', event.rows ? event.rows : this.rows);
  }
    
  removeSearchCriteria(filterName){
    const index = this.searchCriteria.findIndex(entry => entry[filterName] !== undefined);
    if (index !== -1) {
      this.searchCriteria.splice(index, 1);
    }
  }

  pageChange(event, filterName?: string) {
    if(event.value === null && filterName){
      this.removeSearchCriteria(filterName)
      this.resetPlaceHolder(filterName);
    }
    this.loadPlans(event);
  }
    
  processEventFilters(event?: Event){
    // Depending on the filter clicked, the event object will have a different structure
    console.log('event', event);
    if (event.filters){
      Object.entries(event.filters).forEach(([key, value]) => {
        if(value?.value !== null){
          this.addOrUpdateSearchCriteria(key, value.value);
        }
      });
    }
    if(event.value){
      this.addOrUpdateSearchCriteria(event.value.label, event.value.value);
    }
  }

  buildURL(event?: Event ){
    let url = '/insurance_plans/pending?'
    this.appendPagination(event);
    this.processEventFilters(event);
    this.searchCriteria.map((criteria) => {
      Object.entries(criteria).forEach(([key, value]) => {
        if(value !== null && value !== undefined && value !== ''){
          url += `&${key}=${value}`;
        }
      })
    })
    return url;
  }
    
  ngOnDestroy() {
    sessionStorage.setItem('searchCriteriaPendingPlans', JSON.stringify(this.searchCriteria));
  }

  processSavedCriteria(criterionKey: string, filterListKey: string, selectedKey: string) {
    const criterion = this.searchCriteria.find(criteria => criteria[criterionKey] !== undefined);
    if (criterion) {
      this[selectedKey] = this[filterListKey].find(criteria => criteria.value === criterion[criterionKey]).title;
      this.placeholders[criterionKey] = this[selectedKey];
    }
  }
    
  loadPlans(event?:Event) {
    if (!this.searchCriteria || this.searchCriteria.length === 0) {
      const savedSearchCriteria = sessionStorage.getItem('searchCriteriaPendingPlans');
      if (savedSearchCriteria) {
          this.searchCriteria = JSON.parse(savedSearchCriteria);
          this.processSavedCriteria('created_at', 'monthList', 'selectedCreatedAt');
      }
    }
    const url = this.buildURL(event);
    this.http.get(`${environment.apiurl}/${url}`, { observe: 'response' }).subscribe((response: any) => {
      console.log('response', response);
      this.totalRecords = response.body.meta['x-total-count'];
      this.plans = this.formatPendingPayerData(response.body.data);
    });
  }  

  formatPendingPayerData = (payers) => {
    payers.forEach(payer => {
      payer['created_at'] = moment(payer['created_at']).format('LL');
    });
    return payers;
  }

  onRowSelect(event) {
    this.router.navigate(['/payer/pending-plans', event.data.id]);
  }
}
