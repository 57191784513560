import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import { Provider, State } from '../provider';
import { ContactInformation } from '../provider';
import { FlashService } from '../../components/flash/flash.service';
import { ProviderService } from '../providers.service';
import { DeleteProviderComponent } from '../provider-delete/provider-delete.component';

import { VEHICLE_TYPES, VEHICLE_VALUE_TYPE_MAP } from '../provider';

@Component({
  selector: 'app-provider-view',
  templateUrl: './provider-view.component.html',
  styleUrls: ['./provider-view.component.scss']
})
export class ViewProviderComponent implements OnInit {
  providerData: any;
  contactInformation: ContactInformation;
  deleteProviderModal: BsModalRef;
  files: any[] = [];
  states: State[] = [];
  getServiceName = Provider.getServiceName;
  vehicleTypes = VEHICLE_TYPES;
  vehicleValueTypeMap = VEHICLE_VALUE_TYPE_MAP;

  constructor(
    private route: ActivatedRoute,
    private providerService: ProviderService,
    private modalService: BsModalService,
    private flashService: FlashService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.providerData = data.provider.data;
      this.states = data.states.data;
      if(this.providerData['phone']) {
        this.providerData['phone'] = this.providerData['phone'].slice(-10);
      }

      this.contactInformation = new ContactInformation(this.providerData);

      const countiesByStateId = _.groupBy(this.providerData['counties'], x => x.state.id);
      this.providerData['stateCounties'] = _.values(countiesByStateId).map(a => ({
          state: { id: a[0].state.id, name: a[0].state.name },
          counties: a.map(county => ({ id: county.id, name: county.name }))
        })
      );

      this.providerService.files(this.providerData.id).subscribe(files => {
        this.files = files.data;
      });
    });
  }

  public approveProvider() {
    this.providerService.approve(this.providerData)
      .subscribe((provider) => {
        this.flashService.add({
          type: 'success',
          message: '<strong>Congrats!</strong> The provider was approved!'
        });
      });
  }

  public confirmProviderDeleteModal() {
    this.deleteProviderModal = this.modalService.show(DeleteProviderComponent);
    this.deleteProviderModal.content.id = this.route.params['value']['id'];
    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.deleteProviderModal.content.deleted) {
        this.deleteProviderModal.content.deleted = false;

        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> Provider Deleted.'
        });
        this.router.navigate(['/providers']);
      }

      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  onUpdate(updatedProviderName) {
    this.providerData.name = updatedProviderName;
  }
}
