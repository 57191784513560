<div class="row">
  <div class="col-lg-6">
    <h3>Dispatcher Information</h3>
  </div>

  <div class="col-lg-6" *ngIf="!isEdit">
    <button type="button"
            class="btn btn-primary btn-wrap pull-right"
            (click)="toggleEdit()">
      Edit
    </button>
  </div>

  <div class="col-lg-6" *ngIf="isEdit">
    <button type="button"
            class="btn btn-primary btn-wrap pull-right"
            [disabled]="dispatcherInformationForm.invalid"
            (click)="save()">
      Update
    </button>
    <button type="button"
            class="btn btn-default btn-wrap pull-right"
            (click)="toggleEdit()">
      Close
    </button>
  </div>
</div>

<div *ngIf="!isEdit">
  <table class="table table-striped">
    <tbody>
      <tr>
        <td width="250px">Name</td>
        <td>{{data.properties.dispatcher?.first_name}} {{data.properties.dispatcher?.last_name}}</td>
      </tr>

      <tr>
        <td>Phone</td>
        <td>{{data.properties.dispatcher?.phone}}</td>
      </tr>

      <tr>
        <td>Email</td>
        <td>{{data.properties.dispatcher?.email}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="isEdit">
  <form class="form" [formGroup]="dispatcherInformationForm">
    <table class="table table-striped">
      <tbody>
        <tr>
          <td width="250px">First Name</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="first_name"
                  placeholder="First Name">
          </td>
        </tr>

        <tr>
          <td width="250px">Last Name</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="last_name"
                  placeholder="Last Name">
          </td>
        </tr>

        <tr>
          <td>Phone</td>
          <td>
            <input type="text"
                    class="form-control"
                    formControlName="phone"
                    placeholder="Phone">
          </td>
        </tr>

        <tr>
          <td>Email</td>
          <td>
            <input type="email"
                    class="form-control"
                    formControlName="email"
                    [email]= "true"
                    placeholder="Email">
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>
