<div>
  <ol class="breadcrumb">
    <li><a routerLink="/customers">Customers</a></li>
    <li class="active"><a routerLink="/customers/{{customer.id}}">{{customer.name}}</a></li>
  </ol>
  <h1>{{customer.name}} > {{group.group_name}}</h1>
  <tabset #tabset>
    <tab>
      <ng-template tabHeading>
        Group Details
      </ng-template>
    
  <div *ngIf="!isEdit">
    <table class="table table-striped">
      <tbody>
        <tr>
          <th>Group Name</th>
          <td>{{group.group_name}}</td>
        </tr>

        <tr>
          <th>Description</th>
          <td>{{group.description}}</td>
        </tr>

        <tr>
          <th>Roles</th>
          <td>
            <span class="value" *ngFor="let item of selectedRoles; let $last=last">{{ item.itemName }}<span *ngIf="!$last">, </span></span>
          </td>
        </tr>
        <tr>
          <th>Hospitals</th>
          <td>
            <span class="value" *ngFor="let item of selectedHospitals; let $last=last">{{ item.itemName }}<span *ngIf="!$last">, </span></span>
          </td>
        </tr>
        <tr>
          <th>Limited?</th>
          <td>
            <ui-switch 
            [(ngModel)]="group.limited_access"
            disabled="true"></ui-switch>
          </td>
        </tr>
        <tr>
          <th>Created On</th>
          <td>{{group.created_on}}</td>
        </tr>
        <tr>
          <th>Updated On</th>
          <td>{{group.updated_on}}</td>
        </tr>
      </tbody>
    </table>
    <div class="text-left">
      <button class="btn btn-primary" (click)="toggleEdit()">Edit</button>
    </div>      
  </div>
  <div *ngIf="isEdit">
    <form class="form-horizontal col col-sm-6" >
      <table class="table table-striped">
        <tbody>
          <tr>
            <th>Group Name</th>
            <td>
              <input type="text"
                    class="form-control"
                    name="name"
                    [(ngModel)]="group.group_name"
                    placeholder="Name">
            </td>
          </tr>

          <tr>
            <th>Description</th>
            <td>
              <input type="text"
                    class="form-control"
                    name="description"
                    [(ngModel)]="group.description"
                    placeholder="Description">
            </td>
          </tr>

          <tr>
            <th>Roles</th>
            <td>
              <angular2-multiselect [data]="roles" [(ngModel)]="selectedRoles"
                                      [settings]="roleDropdownSettings"
                                      (onSelect)="onRoleItemSelect($event)"
                                      (onDeSelect)="onRoleItemDeSelect($event)"
                                      name="selectedRoles"
                                      >
              </angular2-multiselect>
            </td>
          </tr>
          <tr>
            <th>Hospitals</th>
            <td>
              <angular2-multiselect [data]="hospitals" [(ngModel)]="selectedHospitals"
                                      [settings]="hospitalDropdownSettings"
                                      (onSelect)="onHospitalItemSelect($event)"
                                      (onDeSelect)="onHospitalItemDeSelect($event)"
                                      name="selectedHospitals"
                                      >
              </angular2-multiselect>
            </td>
          </tr>
          <tr>
            <th>Limited?</th>
            <td>
              <ui-switch [(ngModel)]="group.limited_access" name="limited_access">
              </ui-switch>
            </td>
          </tr>
          <tr>
            <th>Created On</th>
            <td>{{group.created_on}}</td>
          </tr>
          <tr>
            <th>Updated On</th>
            <td>{{group.updated_on}}</td>
          </tr>
        </tbody>
      </table>
      
      <div class="action-wrap text-left">
        <button type="button" class="btn btn-danger" (click)="delete()">Delete</button>
        <button type="button" class="btn btn-primary pull-right" (click)="update()">Save Changes</button>
        <button type="button" class="btn btn-default pull-right" (click)="toggleEdit()">Close</button>
      </div>
    </form>
  </div>
</tab>
</tabset>
</div>
