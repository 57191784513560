import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NewActivePlanModalComponent } from '../new-active-plan-modal/new-active-plan-modal.component';
import { ActivePlan, Carrier } from '../payer';

@Component({
  selector: 'app-plan-dashboard',
  templateUrl: './plan-dashboard.component.html',
  styleUrls: ['./plan-dashboard.component.scss']
})
export class PlanDashboardComponent implements OnInit {
  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  ACTIVE_TAB = 0;
  PENDING_TAB = 1;
  selectedTab: number;
  carriers: Array<Carrier>;
  newActivePlanModal: BsModalRef;

  constructor(
    protected http: HttpClient,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.carriers = data.carriers.data;
    });

    this.route.queryParams.subscribe(params => {
      if (params['selectTab'] && parseInt(params['selectTab'], 10) === this.PENDING_TAB) {
        // This will trigger `select` event on Pending tab and thus `onPendingTabSelection` method.
        this.tabset.tabs[this.PENDING_TAB].active = true;
      } else {
        // As the first tab is selected by defalut, so setting it as selected tab will not trigger
        // `select` event. Thus, we need to explicitly call the method to load the active plans list.
        this.onActiveTabSelection();
      }
    });
  }

  onActiveTabSelection() {
    this.selectedTab = this.ACTIVE_TAB;
  }

  onPendingTabSelection() {
    this.selectedTab = this.PENDING_TAB;
  }

  openNewActivePlanModal() {
    this.newActivePlanModal = this.modalService.show(NewActivePlanModalComponent, {
      initialState: {
        carrierOptions: this.carriers
      }
    });
    this.newActivePlanModal.content.onDone.subscribe((activePlan: ActivePlan) => {
      this.router.navigate(['/payer/active-plans', activePlan.id]);
    });
  }
}
