import { Component, OnInit } from '@angular/core';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import { HttpClient } from '@angular/common/http';
import { CustomPayerRenderComponent } from '../custom-payer-render/custom-payer-render.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { MONTHS_LIST } from '../payer';

@Component({
  selector: 'app-active-plan-list',
  templateUrl: './active-plan-list.component.html',
  styleUrls: [
    './active-plan-list.component.scss',
    '../../../themes/ng2-smart-table.scss'
  ]
})
export class ActivePlanListComponent implements OnInit {
  list: AdminServerDataSource;

  tableConfig = {
    columns: {
      carrier_name: {
        title: 'Carrier name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
      },
      plan_name: {
        title: 'Plan name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
      },
      organizations: {
        title: 'Organizations',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        sort: false
      },
      updated_at: {
        title: 'Last updated',
        filter: {
          type: 'list',
          config: {
            list: MONTHS_LIST
          }
        }
      }
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    pager: {
      perPage: 15
    },
    noDataMessage: ''
  };

  constructor(protected http: HttpClient, private router: Router) { }

  ngOnInit() {
    if(!this.list){
      this.list = new AdminServerDataSource(this.http, {
        endPoint: `${environment.apiurl}/carrier_plans/`,
        formatDataFunction: this.formatActivePayerData,
      });
    }
  }

  formatActivePayerData = (payers) => {
    payers.forEach(payer => {
      payer['organizations'] = payer['organizations'].join(', ');
      payer['updated_at'] = moment(payer['updated_at']).format('LL');
    });
  }

  onRowSelect(event) {
    this.router.navigate(['/payer/active-plans', event.data.id]);
  }
}
