import {
  Component,
  Output,
  EventEmitter
} from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ConfirmModalButton } from './confirm';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  @Output()
  onClose: EventEmitter<string> = new EventEmitter<string>();

  title: string;
  message: string;
  hideTitleArea: boolean;
  buttons: ConfirmModalButton[];

  close(label: string) {
    this.onClose.emit(label);
  }

}
