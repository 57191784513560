import { Component, Input, OnInit } from '@angular/core';
import { AdminServerDataSource } from '../../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import { CustomPayerRenderComponent } from '../../custom-payer-render/custom-payer-render.component';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-plan-aliases',
  templateUrl: './plan-aliases.component.html',
  styleUrls: [
    './plan-aliases.component.scss',
    '../../../../themes/ng2-smart-table.scss'
  ]
})
export class PlanAliasesComponent implements OnInit {
  @Input() carrierPlanId: string;
  aliases: AdminServerDataSource;

  tableConfig = {
    columns: {
      plan_name: {
        title: 'Alias name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        filter: false,
        sort: false,
      },
      organization: {
        title: 'Organization',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        filter: false,
        sort: false
      },
      matched_at: {
        title: 'Matched on',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        filter: false,
        sort: false
      }
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  constructor(protected http: HttpClient) { }

  ngOnInit() {
    this.aliases = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/carrier_plans/:id/aliases`.replace(':id', this.carrierPlanId),
      formatDataFunction: this.formatAliases
    });
  }

  formatAliases = (aliases) => {
    aliases.forEach(alias => {
      alias['matched_at'] = alias['matched_at'] ? moment(alias['matched_at']).format('LL') : alias['matched_at'];
    });
  }
}
