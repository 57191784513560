<ol class="breadcrumb">
  <li><a routerLink="/venues">Venues</a></li>
  <li class="active">{{venue.location_name}} - {{venue.zone_name}}</li>
</ol>

<h1>{{venue.location_name}} - {{venue.zone_name}}</h1>

<div class="row">
  <div class="col-sm-10">
    <form class="form-horizontal" action="" #addVenueForm="ngForm" >
      <div class="form-group">
        <label class="col-sm-3 control-label">Latitude</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" name="latitude" required
                  placeholder="Latitude" [(ngModel)]="venue.latitude" [readonly]="true">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label">Longitude</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" name="longitude" required
                  placeholder="Longitude" [(ngModel)]="venue.longitude" [readonly]="true">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label">Location Name</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" name="location-name" required
                  placeholder="Location Name" [(ngModel)]="venue.location_name" [readonly]="!isEditingVenue">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label">Venue Name</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" name="zone-name" required
                  placeholder="Venue Name" [(ngModel)]="venue.zone_name" [readonly]="!isEditingVenue">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label">Venue Latitude </label>
        <div class="col-sm-9">
          <input type="text" class="form-control" name="zone-latitude" required
                  placeholder="Venue Latitude" [(ngModel)]="venue.zone_latitude" [readonly]="!isEditingVenue">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label">Venue Longitude</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" name="zone-longitude" required
                  placeholder="Venue Longitude" [(ngModel)]="venue.zone_longitude" [readonly]="!isEditingVenue">
        </div>
      </div>
    </form>
    <div class="row action-wrap">
      <div class="col" *ngIf="!isEditingVenue">
        <button type="button" class="btn btn-primary" (click)="editVenue()">Edit</button>
        <button type="button" class="btn btn-danger" (click)="confirmVenueDeleteModal()">Delete</button>
      </div>
      <div class="col" *ngIf="isEditingVenue">
        <button type="button" class="btn btn-default" (click)="cancelEditing()">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateVenue()">Update</button>
      </div>
    </div>
  </div>
</div>
