import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { Hospital } from '../hospital';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-vehicle-rates',
  templateUrl: './vehicle-rates.component.html',
  styleUrls: ['./vehicle-rates.component.scss']
})
export class VehicleRatesComponent implements OnInit, OnChanges {

  vehicleRatesForm: FormGroup;
  ratesInitialized;
  @Input() hospital: Hospital;
  @Output() vehicleRatesUpdated: EventEmitter<any> = new EventEmitter();
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hospital']['currentValue']['transport_rates']) {
      this.hospital.transport_rates = changes['hospital']['currentValue']['transport_rates'];
      this.vehicleRatesForm = this.generateForm();
    }
  }

  generateForm() {
    const vehicles: any = {};

    this.hospital['transport_rates'].forEach(rates => {
      rates['base_field_name'] = `base_${rates['vehicle']}`;
      rates['mileage_field_name'] = `mileage_${rates['vehicle']}`;
      vehicles[`base_${rates['vehicle']}`] = new FormControl(rates['base'], [Validators.required, Validators.min(0.0001)]);
      vehicles[`mileage_${rates['vehicle']}`] = new FormControl(rates['mileage'], [Validators.required, Validators.min(0.0001)]);
    });
    return new FormGroup(vehicles);
  }

  reset() {
    this.hospital['transport_rates'].forEach(rates => {
      this.vehicleRatesForm.get(rates.base_field_name).setValue(rates['base']);
      this.vehicleRatesForm.get(rates.mileage_field_name).setValue(rates['mileage']);
    });
    this.resetRates();
  }

  ngOnInit() {
    this.vehicleRatesForm = this.generateForm();
  }

  updateRates() {
    this.hospital['transport_rates'].forEach((rates) => {
      rates['base'] = this.vehicleRatesForm.get(`base_${rates['vehicle']}`).value;
      rates['mileage'] = this.vehicleRatesForm.get(`mileage_${rates['vehicle']}`).value;
    });
    this.vehicleRatesUpdated.emit(this.hospital['transport_rates']);
  }

  resetRates() {
    this.modalClosed.emit();
  }

}
