import { Component, Input, SimpleChanges, OnInit } from '@angular/core';

import { Hospital } from '../hospital';

import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';

import { FlashService } from '../../components/flash/flash.service';
import { HospitalService } from '../hospital.service';

import * as _ from 'lodash';


@Component({
  selector: 'app-quicksight-reports',
  templateUrl: './quicksight-reports.component.html',
  styleUrls: ['./quicksight-reports.component.scss']
})
export class QuicksightReportComponent implements OnInit {
  @Input() hospital: Hospital;
  quicksightReportForm: FormGroup;
  editingMapping = false;
  reportMapping = [];

  constructor(private flashService: FlashService,
    protected hospitalService: HospitalService,
    private fb: FormBuilder) {
      this.quicksightReportForm = this.fb.group({
        reports: this.fb.array([])
      })
  }

  editMapping() {
    this.editingMapping = true;
  }

  cancelEditing() {
    this.quicksightReportForm.reset();
    this.editingMapping = false;
  }
  update() {
    let payload = {
      report_mapping : this.reportMapping
    };
    this.hospitalService.reportsUpdate(this.hospital.id, payload)
       .subscribe(
      (response) => {
        this.hospital = response.data;
        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> Associated Reports have been updated.'
        });
        this.editingMapping = false;
      },
      (error) => {
        this.editingMapping = false;
      });
  }

  ngOnInit() {
    this.cancelEditing();
    this.hospitalService.quicksightReports(this.hospital.id).subscribe(      
      (response) => {
      this.reportMapping = response.data;
      for (var report in response.data) {
        const control = new FormGroup({
          'value': new FormControl(null)
        });
        (<FormArray>this.quicksightReportForm.get('reports')).push(control);
      }
    },
    (error) => {
    });
  }
}

