<form [formGroup]="vehicleRatesForm">
  <table class="table table-striped">
    <thead>
    <tr>
      <th>Vehicle Type</th>
      <th>Base Rate</th>
      <th>Mileage Rate</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let transport of hospital['transport_rates']">
        <td>{{transport['vehicle'] | humanizeString}}</td>

        <td *ngIf="transport.base_field_name">
          <input type="number" class="form-control" formControlName="{{transport['base_field_name']}}" placeholder="Base Rate">
          <div class="form-error"
               *ngIf="vehicleRatesForm.get(transport.base_field_name) && vehicleRatesForm.get(transport.base_field_name).invalid && vehicleRatesForm.get(transport.base_field_name).touched">
            <p>Base rate can not be empty or zero</p>
          </div>
        </td>

        <td *ngIf="transport.mileage_field_name">
          <input type="number" class="form-control" formControlName="{{transport['mileage_field_name']}}" placeholder="Mileage Rate">
          <div class="form-error"
               *ngIf="vehicleRatesForm.get(transport.mileage_field_name) && vehicleRatesForm.get(transport.mileage_field_name).invalid && vehicleRatesForm.get(transport.mileage_field_name).touched">
            <p>Mileage rate can not be empty or zero</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="reset()">
    Close
  </button>
  <button type="button" class="btn btn-primary" [disabled]="!vehicleRatesForm.valid" (click)="updateRates()">
    Update Rates
  </button>
</div>
