import { inject } from '@angular/core';

import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';

import { Report } from './report';
import { ReportsService } from './reports.service';

export const ReportResolver: ResolveFn<Report[]> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Report[]> => {
    const reportService: ReportsService = inject(ReportsService);
    return reportService.list();
  }
