import { inject } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from './customer.service';
import { Customer } from './customer';

export const CustomerResolver: ResolveFn<Customer> = (
  route: ActivatedRouteSnapshot
): Observable<Customer> => {
  const customerService = inject(CustomerService);
  const customerId = route.params['customerId'] || route.params['id'];
  return customerService.get(customerId);
};
