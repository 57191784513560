import { Component, Input, OnInit } from '@angular/core';

import { Hospital } from '../hospital';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  @Input() hospital: Hospital;
  integrationsType: String;
  REDOX = 'redox';
  PARTNER = 'partner';
  integrationOptions = [
    {
      value: this.REDOX,
      label: 'Redox',
    },
    {
      value: this.PARTNER,
      label: 'Partner API/SSO'
    }];

  constructor() {
  }

  ngOnInit() {
    this.defaultIntegrationType();
  }

  defaultIntegrationType() {
    this.integrationsType = this.REDOX;
  }

  redoxIntegrationType() {
    return (this.integrationsType === this.REDOX);
  }

  partnerIntegrationType() {
    return (this.integrationsType === this.PARTNER);
  }

  changeIntegrationType(event) {
    this.integrationsType = event.target.value;
  }
}
