import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FlashService } from '../../../components/flash/flash.service';
import { ProviderService } from '../../providers.service';
import { VEHICLE_TYPES, VEHICLE_VALUE_TYPE_MAP } from '../../provider';
import { DAYS } from '../../provider';
import * as _ from 'lodash';

@Component({
  selector: 'provider-service-schedule',
  templateUrl: './service-schedule.component.html',
  styleUrls: ['./service-schedule.component.scss']
})

export class ServiceScheduleComponent implements OnInit {
  serviceScheduleForm: FormGroup;
  serviceOptions = [];
  availableVehicleTypes = {};
  isEdit = false;
  hasConflict = false;
  days = DAYS;
  @Input() providerId: any;
  @Input() data: any;

  requiredOptions = ['type', 'text', 'hour_from', 'hour_to'];

  constructor(
    private providerService: ProviderService,
    private flashService: FlashService
  ) {}

  ngOnInit() {
    this.setAvailableVehicleTypes();

    this.serviceOptions = Object.keys(this.availableVehicleTypes).map(key => {
      return { label: this.availableVehicleTypes[key], value: key };
    });
  }

  save() {
    this.validateServiceSchedules();
    if (this.hasConflict) {
      return;
    }
    const payload = this.generateRequestPayload();

    this.providerService.updateServiceSchedules(this.providerId, { data: payload })
      .subscribe(
        res => {
          this.data = res['data']['properties'];
          this.flashService.add({
            type: 'success',
            message: 'Service Schedule is Successfully updated'
          });
          this.toggleEdit();
        },
        err => this.toggleEdit()
      );
  }

  get scheduleForm() {
    return this.serviceScheduleForm.get('schedule') as FormArray;
  }

  private generateFormControls() {
    const formArrayControls = [];

    this.data.services.forEach(service => {
      if (!service.days) {
        return;
      }

      service.days.forEach(schedule => {
        const formGroupOptions = {};
        const type = this.getVehicleTypeKey(service);
        formGroupOptions['type'] = new FormControl(
          type,
          this.requiredOptions.includes('type') ? Validators.required : null
        );

        Object.keys(schedule).forEach(key =>{
          let value;

          if (key == 'hour_to' || key == 'hour_from') {
            if (schedule['all_day'] == true) {
              value = {
                value: this.convertTimeStringToDate(schedule[key]),
                disabled: true
              };
            } else {
              value = this.convertTimeStringToDate(schedule[key]);
            }
          } else {
            value = schedule[key];
          }

          formGroupOptions[key] = new FormControl(
            value,
            this.requiredOptions.includes(key) ? Validators.required : null
          );
        });

        formArrayControls.push(new FormGroup(formGroupOptions));
      });
    });

    this.serviceScheduleForm = new FormGroup({
      'schedule': new FormArray(formArrayControls)
    });
  }

  generateRequestPayload() {
    const serviceSchedules = [];

    this.scheduleForm.getRawValue().forEach(value => {
      value.hour_from = this.convertDateToTimeString(value.hour_from);
      value.hour_to = this.convertDateToTimeString(value.hour_to);
      serviceSchedules.push(value);
    });
    return serviceSchedules;
  }

  setAvailableVehicleTypes() {
    this.data.services.forEach(service => {
      const key = this.getVehicleTypeKey(service);
      this.availableVehicleTypes[key] = VEHICLE_TYPES[key];
    });
  }

  getVehicleTypeKey(service: any) {
    return service.value || service.type;
  }

  onChange(index: number, event: boolean) {
    const hourFromControl = this.scheduleForm.at(index).get('hour_from');
    const hourToControl = this.scheduleForm.at(index).get('hour_to');

    if (event) {
      hourFromControl.disable();
      hourToControl.disable();
    } else {
      hourFromControl.enable();
      hourToControl.enable();
    }

    hourFromControl.setValue(this.convertTimeStringToDate('00:00'));
    hourToControl.setValue(this.convertTimeStringToDate('23:59'));
  }

  addSchedule() {
    this.scheduleForm.push(new FormGroup({
      type: new FormControl(null, Validators.required),
      text: new FormControl(null, Validators.required),
      all_day: new FormControl(null, Validators.required),
      hour_from: new FormControl(null, Validators.required),
      hour_to: new FormControl(null, Validators.required)
    }));
  }

  removeSchedule(index: number) {
    this.scheduleForm.removeAt(index);
    this.validateServiceSchedules();
  }

  // It Converts a time string to Date Object as
  // Time Picker only shows date objects.
  // Time Format required is 'HH:MM'.
  convertTimeStringToDate(time: any) {
    const timeArr = time.split(':');
    const date = new Date();
    date.setMinutes(timeArr[1]);
    date.setHours(timeArr[0]);
    return date;
  }

  // It is Converting Date Object to time string.
  // Time format is 'HH:MM'
  convertDateToTimeString(date: Date) {
    const hours = date.getHours().toString().length == 1 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes().toString().length == 1 ? '0' + date.getMinutes() : date.getMinutes();

    return hours + ':' + minutes;
  }

  toggleEdit() {
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.generateFormControls();
    }
  }

  validateServiceSchedules() {
    const values = _.map(this.serviceScheduleForm.getRawValue().schedule, s => (s.type + s.text));
    this.hasConflict = (values.length !== _.uniq(values).length);
  }
}
