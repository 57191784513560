import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { ConfirmComponent } from './confirm.component';
import { ConfirmModalRef } from './confirm-modal-ref';
import {ConfirmModalConfig } from './confirm';

@Injectable()
export class ConfirmService {
  constructor(private modalService: BsModalService) {}

  show(config: ConfirmModalConfig): ConfirmModalRef {
    const modalRef: ConfirmModalRef = this.modalService.show(ConfirmComponent);

    Object.assign(modalRef.content, config);
    modalRef.onClose = modalRef.content.onClose;

    return modalRef as ConfirmModalRef;
  }

}
