import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthHttpService } from '../core/auth-http.service';
import { User } from './user';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserService {
  constructor(private http: AuthHttpService) {}

  create(user: User, roles:string[]): Observable<User | string> {
    const url = `${environment.apiurl}/users/create`;

    return this.http
      .post<User>(url, {user, roles}).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  update(user: User, roles: string[]): Observable<User | string> {
    const url = `${environment.apiurl}/users/${user.id}`;

    return this.http
      .update<User>(url, { user, roles }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  get(id?: string): Observable<any> {
    let url = `${environment.apiurl}/users`;

    if (id) {
      url += `/${id}`;
    }

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getCurrentUserRoles(id: string): Observable<any> {
    let url = `${environment.apiurl}/users/${id}/roles`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  b2c(): Observable<any> {
    const url = `${environment.apiurl}/users/b2c`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }
  updateB2C(user:User): Observable<any> {
    const url = `${environment.apiv3url}/users/${user.id}`;
    return this.http
      .updateV3<User>(url, user).pipe(
        catchError((err) => this.http.handleError(err)));
  }
  unconfirmed(): Observable<any> {
    const url = `${environment.apiurl}/users/unconfirmed`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  assignHospital(data: any): Observable<any> {
    const url = `${environment.apiurl}/users/assign_hospital`;

    return this.http
      .post(url, data).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  unassignHospital(data: any): Observable<any> {
    const url = `${environment.apiurl}/users/unassign_hospital`;

    return this.http
      .post(url, data).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  enableShowFacilityRides(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/enable_show_facility_rides`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  disableShowFacilityRides(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/disable_show_facility_rides`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  updateShowPatientRides(id: number, showPatientRides: boolean): Observable<User> {
    const url = `${environment.apiurl}/users/update-show-patient-rides`;

    return this.http
      .patch(url, { id , show_patient_rides: showPatientRides}).pipe(
        map((x: any) => x.data),
        catchError((err) => this.http.handleError(err))
      );
  }

  enableSuperUserFunctionality(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/enable_super_user_functionality`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  disableSuperUserFunctionality(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/disable_super_user_functionality`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  resetPassword(id: string, password: string): Observable<any> {
    const url = `${environment.apiurl}/users/reset_password/${id}`;

    return this.http
      .post(url, {password}).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  unlockAccount(id?: string): Observable<any> {
    let url = `${environment.apiurl}/users/unlock_account`;

    if (id) {
      url += `/${id}`;
    }

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  activateUser(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/activate_user`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  deactivateUser(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/deactivate_user`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  limitAccess(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/limit_access`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  unlimitAccess(id?: number): Observable<User | string> {
    const url = `${environment.apiurl}/users/unlimit_access`;

    return this.http
      .update<User>(url, { id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  updateTimezone(id: number, timezone: string): Observable<any> {
    let url = `${environment.apiurl}/users/:id/update_timezone`;
    url = url.replace(':id', String(id));
    return this.http
      .update(url, { time_zone: timezone }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  updateCurrentHospital(id: number, hospital_id: number): Observable<any> {
    let url = `${environment.apiurl}/users/:id/update_current_hospital`;
    url = url.replace(':id', String(id));
    return this.http
      .update(url, { hospital_id: hospital_id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }
}
