<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="map-pin" iconClass="button-icon"></app-icon> {{modalTitle}}
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal" action="" #addHospitaLocationForm="ngForm" [hidden]="showConfirmDeleteMessage">
    <div class="form-group">
      <label class="col-sm-3 control-label">Current Address</label>
      <div class="col-sm-9">
        <input ngx-google-places-autocomplete [options]='options' class="form-control" name="currentAddress" required [readonly]="!isEditing"
               [ngClass]="{'ng-invalid': errorInSelectedAddress}"
               placeholder="Current Address" #search [(ngModel)]="location.current_address"
               #currentAddress="ngModel" autocorrect="off" autocapitalize="off" spellcheck="off" (onAddressChange)="addressChanged($event)"/>
        <div class="form-error" *ngIf="errorInSelectedAddress">
          <p>Please select a specific address</p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Location Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" name="location-name" required [readonly]="!isEditing"
               placeholder="Location Name" [(ngModel)]="location.name">
      </div>
    </div>
    <div class="form-group" *ngIf="venuesList && venuesList.length">
      <label class="col-sm-3 control-label">Venue</label>
      <div class="col-sm-9">
        <select class="form-control" name="zone" [(ngModel)]="location.venue_mapping['id']" #venue_mapping="ngModel" [disabled]="!isEditing">
          <option value=null selected="selected" disabled>--Select--</option>
          <option *ngFor="let venue_mapping of venuesList" value="{{venue_mapping.id}}" [selected]="location.venue_mapping['id'] === venue_mapping.id">
            {{venue_mapping.zone_name}}
          </option>
        </select>
      </div>
    </div>
  </form>
  <p *ngIf="showConfirmDeleteMessage">Are you sure, you want to delete this location?</p>
</div>

<div class="modal-footer" *ngIf="isEditing && !showConfirmDeleteMessage">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="addUpdateLocation()" [disabled]="addHospitaLocationForm.form.invalid || errorInSelectedAddress">
    Save Changes
  </button>
</div>

<div class="modal-footer" *ngIf="showConfirmDeleteMessage">
  <button type="button" class="btn btn-default" (click)="closeDelete()">Close</button>
  <button type="button" class="btn btn-danger" (click)="deleteLocation()">
    Delete
  </button>
</div>

<div class="modal-footer" *ngIf="!isEditing">
  <button type="button" class="btn btn-danger" (click)="confirmLocationDeleteModal()">Delete</button>
  <button type="button" class="btn btn-primary" (click)="editLocation()">Edit</button>
</div>
