import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FlashService } from '../../../components/flash/flash.service';
import { ProviderService } from '../../providers.service';
import * as _ from 'lodash';

@Component({
  selector: 'provider-dispatcher-information',
  templateUrl: './dispatcher-information.component.html',
  styleUrls: ['./dispatcher-information.component.scss']
})
export class DispatcherInformationComponent implements OnInit {
  dispatcherInformationForm: FormGroup;
  isEdit = false;
  providerData: any;
  @Input() data: any;

  constructor(
    private providerService: ProviderService,
    private flashService: FlashService
  ) { }

  ngOnInit() {}

  save() {
    const payload = this.generatePayload(this.dispatcherInformationForm.value);

    this.providerService.update(this.data.id, payload)
      .subscribe(
        res => {
          this.data = res['data'];
          this.flashService.add({
            type: 'success',
            message: 'Dispatcher Information is successfully updated'
          });
          this.toggleEdit();
        },
        err => this.toggleEdit()
      );
  }

  private generatePayload(updatedData) {
    const payload = <any>{};

    payload.applicant_email = updatedData.email;
    payload.applicant_first_name = updatedData.first_name;
    payload.applicant_last_name = updatedData.last_name;
    payload.applicant_phone = updatedData.phone;

    return payload;
  }

  private generateFormControls() {
    const formControls = {};
    formControls['phone'] = new FormControl(this.data.properties.dispatcher.phone);
    formControls['email'] = new FormControl(this.data.properties.dispatcher.email);
    formControls['first_name'] = new FormControl(this.data.properties.dispatcher.first_name);
    formControls['last_name'] = new FormControl(this.data.properties.dispatcher.last_name);

    this.dispatcherInformationForm = new FormGroup(formControls);
  }

  toggleEdit() {
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.generateFormControls();
    }
  }
}
