import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlashService } from '../../../components/flash/flash.service';
import { ProviderService } from '../../providers.service';
import { ContactInformation } from '../../provider';
import { Option } from '../../../components/dropdown/dropdown';
import STATE_OPTIONS from '../../provider';


@Component({
  selector: 'provider-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {
  readonly stateList: Option[] = Object.keys(STATE_OPTIONS.mapping)
    .map(state => ({label: STATE_OPTIONS.mapping[state], value: state}));
  readonly FIELDS = [
    {
      name: 'address1',
      type: 'text',
      label: 'Address 1',
      validators: [Validators.required],
    },
    {
      name: 'address2',
      type: 'text',
      label: 'Address 2',
      validators: [],
    },
    {
      name: 'city',
      type: 'text',
      label: 'City',
      validators: [Validators.required],
    },
    {
      name: 'state',
      type: 'dropdown',
      label: 'State',
      options: this.stateList,
      validators: [Validators.required],
    },
    {
      name: 'zipCode',
      type: 'text',
      label: 'Zip Code',
      validators: [Validators.required],
    },
    {
      name: 'phone',
      type: 'text',
      label: 'Phone #',
      validators: [Validators.required],
    }
  ];

  @Input() providerId: any;
  @Input() contactInformation: ContactInformation;

  contactInformationForm: FormGroup;
  formData: object;
  isEditMode: boolean = false;

  constructor(
    private providerService: ProviderService,
    private flashService: FlashService,
  ) {
  }

  ngOnInit() {
    this.formData = this.contactInformation.getContactInformation();
  }

  generateForm() {
    const formControls = this.FIELDS.map((control) => {
      return {
        [control.name]: new FormControl(this.contactInformation[control.name], control.validators)
      };
    });
    this.contactInformationForm = new FormGroup(Object.assign({}, ...formControls));
  }

  public toggleEdit() {
    this.isEditMode = !this.isEditMode;
    this.generateForm();
  }

  public save() {
    this.contactInformation.setContactInformation(this.contactInformationForm.value);
    this.providerService.updateContactInformation(this.providerId, this.contactInformation)
      .subscribe(
        () => {
          this.formData = this.contactInformation.getContactInformation();
          this.toggleEdit();
          this.flashService.add({
              type: 'success',
              message: `<strong>Success!</strong> Contact information has been updated.`
            },
          );
        },
        error => this.toggleEdit()
      );
  }
}
