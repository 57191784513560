import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { FederatedIdentityGroupService } from './federated-identity-group.service';
import { FederatedIdentityGroup } from './federated-identity-group';

export const CustomerGroupsResolver: ResolveFn<FederatedIdentityGroup> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  federatedIdentityGroupService: FederatedIdentityGroupService = inject(FederatedIdentityGroupService)
): Observable<FederatedIdentityGroup> => federatedIdentityGroupService.getCustomerGroups(route.params.id);