import { inject } from '@angular/core';

import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';

import { RideshareAvailability } from './rideshare-availbility';
import { RideshareAvailabilityService } from './rideshare-availability.service';

export const RideshareAvailabilityResolver: ResolveFn<RideshareAvailability[]> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<RideshareAvailability[]> => {
    const rideshareAvailabilityService: RideshareAvailabilityService = inject(RideshareAvailabilityService)
    return rideshareAvailabilityService.get();
  }
