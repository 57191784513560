import { Observable } from "rxjs";
import { Injectable } from '@angular/core';

import { environment } from "../../../environments/environment";
import { AuthHttpService } from "../../core/auth-http.service";
import { catchError, map } from "rxjs/operators";


@Injectable()
export class TimezoneDropdownService {
  constructor(private http: AuthHttpService) {
  }

  get(): Observable<any> {
    let url = `${ environment.appurl }/api/v1/util/timezones`;
    return this.http
      .get(url).pipe(
        map((x: any) => x.data),
        catchError(err => this.http.handleError(err))
      );
  }
}
