<ol class="breadcrumb">
  <li><a routerLink="/customers">Customers</a></li>
  <li class="active"><a routerLink="/customers/{{customer.id}}">{{customer.name}}</a></li>
  <li class="active">Add Group</li>
</ol>

<div>
  <form class="form-horizontal col col-sm-6" >
    <table class="table table-striped">
      <tbody>
        <tr>
          <th>Group Name</th>
          <td>
            <input type="text"
                  class="form-control"
                  name="name"
                  [(ngModel)]="group.group_name"
                  placeholder="Name">
          </td>
        </tr>

        <tr>
          <th>Description</th>
          <td>
            <input type="text"
                  class="form-control"
                  name="description"
                  [(ngModel)]="group.description"
                  placeholder="Description">
          </td>
        </tr>

        <tr>
          <th>Roles</th>
          <td>
            <angular2-multiselect [data]="roles" [(ngModel)]="selectedRoles"
                                    [settings]="roleDropdownSettings"
                                    (onSelect)="onRoleItemSelect($event)"
                                    (onDeSelect)="onRoleItemDeSelect($event)"
                                    name="selectedRoles"
                                    >
            </angular2-multiselect>
          </td>
        </tr>
        <tr>
          <th>Hospitals</th>
          <td>
            <angular2-multiselect [data]="hospitals" [(ngModel)]="selectedHospitals"
                                    [settings]="hospitalDropdownSettings"
                                    (onSelect)="onHospitalItemSelect($event)"
                                    (onDeSelect)="onHospitalItemDeSelect($event)"
                                    name="selectedHospitals"
                                    >
            </angular2-multiselect>
          </td>
        </tr>
        <tr>
          <th>Limited?</th>
          <td>
            <ui-switch [(ngModel)]="group.limited_access" name="limited_access">
            </ui-switch>
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="action-wrap text-left">
      <button type="button" class="btn btn-default" routerLink="/customers">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="addGroup()">Save</button>
    </div>
  </form>
</div>
  