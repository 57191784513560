import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {Hospital} from '../../hospital';
import { LocationsService } from '../locations.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Locations } from '../locations';
import {Venue} from '../../../venues/venues';

@Component({
  selector: 'app-new',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss']
})
export class LocationModalComponent implements OnInit {
  @ViewChild('search', { static: false })
    public searchElementRef: ElementRef;

  afterAction = {
    next: (response) => {
      this.added = true;
      this.location.setData(response);
      this.locationService.mutateData(this.location);
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };

  location: Locations = new Locations();
  hospital: Hospital;
  added = false;
  isEditing = false;
  isDeleted = false;
  venuesList: Venue[];
  errorInSelectedAddress = false;
  showConfirmDeleteMessage = false;
  modalTitle = 'Add Location';
  options = {
    componentRestrictions: { country: 'us' }
  };

  constructor(
    public modalRef: BsModalRef,
    private locationService: LocationsService
  ) {}

  extractAddresses(addresss) {
    const addressComponent = {};
    addresss.forEach((address) => {
      const keys = address.types;
      if (keys.indexOf('postal_code') !== -1) {
        addressComponent['postal_code'] = address.long_name;
      } else if (keys.indexOf('administrative_area_level_1') !== -1) {
        addressComponent['administrative_area_level_1'] = address.long_name;
      } else if (keys.indexOf('locality') !== -1 || keys.indexOf('neighborhood') !== -1 || keys.indexOf('administrative_area_level_3') !== -1) {
        addressComponent['locality'] = address.long_name;
      } else if (keys.indexOf('street_number') !== -1) {
        addressComponent['street_number'] = address.long_name;
      } else if (keys.indexOf('route') !== -1) {
        addressComponent['route'] = address.long_name;
      }
    });
    return addressComponent;
  }

  setIsEditing() {
    this.isEditing = !this.location.id;
    this.modalTitle = this.location.id ? 'Edit Location' : 'Add Location';
  }

  ngOnInit() {
    this.setIsEditing();
  }

  addressChanged(place: any) {
    if (place.geometry === undefined || place.geometry === null) {
      this.errorInSelectedAddress = true;
      return;
    }
    const addressComponent = this.extractAddresses(place.address_components);
    const formattedAddressComponents = {
      zipcode: addressComponent['postal_code'],
      state: addressComponent['administrative_area_level_1'],
      city: addressComponent['locality'],
      streetAddress: [addressComponent['street_number'], addressComponent['route']].filter(val => val).join(' ')
    };
    if (!
        (formattedAddressComponents.zipcode && formattedAddressComponents.state && formattedAddressComponents.city)
    ) {
      this.errorInSelectedAddress = true;
    } else {
      this.errorInSelectedAddress = false;
      const lat = String(place.geometry.location.lat());
      const lng = String(place.geometry.location.lng());
      this.location.latitude = parseFloat(lat).toFixed(7);
      this.location.longitude = parseFloat(lng).toFixed(7);
      this.location.state = formattedAddressComponents.state;
      this.location.city = formattedAddressComponents.city;
      this.location.zipcode = formattedAddressComponents.zipcode;
      this.location.street_address = formattedAddressComponents.streetAddress;
      this.getVenues();
    }
  }

  getVenues() {
    this.locationService.getVenues(this.location.latitude, this.location.longitude)
      .subscribe((response: any) => {
        const venueFound = response.data.find((venue) => {
          return venue.id === parseInt(this.location.venue_mapping['id']);
        });
        this.venuesList = [];
        response.data.forEach((venue) => {
          const newVenue = new Venue();
          newVenue.setData(venue);
          this.venuesList.push(newVenue);
        });
        this.venuesList = response.data;
        if (!venueFound) {
          this.location.venue_mapping['id'] = null;
        }
      });
  }

  addUpdateLocation() {
    const action = this.location.id ? 'update' : 'create';
    this.locationService[action](this.hospital.id, this.location).subscribe(this.afterAction);
  }

  deleteLocation() {
    this.locationService.delete(this.hospital.id, this.location).subscribe(
      (response) => {
        this.isDeleted = true;
        this.modalRef.hide();
      });
  }

  confirmLocationDeleteModal() {
    this.showConfirmDeleteMessage = true;
    this.isEditing = true;
    this.modalTitle = 'Delete Location';
  }

  closeDelete() {
    this.showConfirmDeleteMessage = false;
    this.isEditing = false;
    this.setIsEditing();
  }

  editLocation() {
    this.isEditing = true;
  }

}
