import { Component, Input, OnInit } from '@angular/core';
import { CustomPayerRenderComponent } from '../../custom-payer-render/custom-payer-render.component';
import { AdminServerDataSource } from '../../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatchDetailsComponent } from '../match-details/match-details.component';
import { PayerService } from '../../payer.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { MatchNewActivePlanModalComponent } from '../../match-new-active-plan-modal/match-new-active-plan-modal.component';
import { ActivePlan, Carrier, PendingPlan } from '../../payer';
import { FlashService } from '../../../components/flash/flash.service';

@Component({
  selector: 'app-match-pending-plan',
  templateUrl: './match-pending-plan.component.html',
  styleUrls: [
    './match-pending-plan.component.scss',
    '../../../../themes/ng2-smart-table.scss'
  ]
})
export class MatchPendingPlanComponent implements OnInit {
  @Input() pendingPlan: PendingPlan;
  @Input() carriers: Array<Carrier>;
  searchString = '';
  loading = false;
  activePlans: AdminServerDataSource;
  selectedActivePlan: ActivePlan;
  matchDetailsModal: BsModalRef;
  matchNewActivePlanModal: BsModalRef;

  tableConfig = {
    columns: {
      carrier_name: {
        title: 'Carrier name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        filter: false,
        sort: false,
      },
      name: {
        title: 'Plan name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        filter: false,
        sort: false
      },
      authorization_contact: {
        title: 'Phone number',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        filter: false,
        sort: false
      },
      score: {
        title: 'Score',
        type: 'text',
        valuePrepareFunction: (value) => (parseInt(value, 10) + '%'),
        filter: false,
        sort: false
      }
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'match',
          title: '<span class="match-action"><img src="assets/images/link-2.svg" />Match</span><span class="matched-state"><img src="assets/images/check.svg" />Matched</span>'
        }
      ],
      columnTitle: null,
      position: 'right'
    },
    rowClassFunction: (row) => {
      if (this.selectedActivePlan && row.data.id === this.selectedActivePlan.id) {
        return 'matched-row';
      }
      return '';
    }
  };

  constructor(
    protected http: HttpClient,
    private modalService: BsModalService,
    private payerService: PayerService,
    private router: Router,
    private flashService: FlashService
  ) { }

  ngOnInit() {
    this.activePlans = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/insurance_plans/pending/:id/match-suggestions`.replace(':id', this.pendingPlan.id.toString()),
      metaCallbackFunction: () => this.loading = false
    });
  }

  onRowSelect(event) {
    this.matchDetailsModal = this.modalService.show(MatchDetailsComponent, {
      initialState: {
        pendingPlanId: this.pendingPlan.id,
        activePlan: event.data
      }
    });
    this.matchDetailsModal.content.onDone.subscribe((activePlan: ActivePlan) => {
      this.navigateToActivePayer(activePlan);
    });
  }

  openMatchNewActivePlanModal() {
    this.matchNewActivePlanModal = this.modalService.show(MatchNewActivePlanModalComponent, {
      initialState: {
        pendingPlanId: this.pendingPlan.id,
        carrierOptions: this.carriers
      }
    });
    this.matchNewActivePlanModal.content.onDone.subscribe((activePlan: ActivePlan) => {
      this.navigateToActivePayer(activePlan);
    });
  }

  confirmMatch() {
    this.payerService.matchPendingPlan(
      this.pendingPlan.id, { carrier_plan_id: this.selectedActivePlan.id }
    ).subscribe(res => this.navigateToActivePayer(this.selectedActivePlan));
  }

  navigateToActivePayer(activePlan: ActivePlan) {
    this.flashService.add({
      type: 'success',
      message: `${this.pendingPlan.plan_name} has been matched with ${activePlan.name}.`
    });
    this.router.navigate(['/payer/active-plans', activePlan.id], {queryParams: {selectTab: 1}});
  }

  onSearch() {
    this.loading = true;
    this.activePlans.setFilter([{
      field: 'q',
      search: this.searchString
    }]);
  }

  resetSearch() {
    this.searchString = '';
    this.onSearch();
  }

  onCustomEvent(event) {
    switch (event.action) {
      case 'match': {
        this.selectedActivePlan = event.data;
      }
    }
  }
}
