import {
  Component,
  OnInit,
  Input,
  ElementRef,
  EventEmitter,
  Output
} from '@angular/core';

import { CsvService } from './csv.service';

@Component({
  selector: 'app-csv-download',
  templateUrl: './csv-download.component.html',
  styleUrls: ['./csv-download.component.scss']
})
export class CsvDownloadComponent {
  @Input()
  title = 'Download';

  @Input()
  url: any;

  @Input()
  fileName: any;

  @Input()
  data: any;

  @Input() formatDataFunction: (value: any) => object;

  constructor(
    private el: ElementRef,
    private csv: CsvService
  ) {}

  generateCsv() {
    this.csv.getData(this.url).subscribe((response) => {
      let data = response;
      if (this.formatDataFunction) {
        data = this.formatDataFunction(data.data);
      }
      data = this.csv.convert(data);
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

}
