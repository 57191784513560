import { inject } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ResolveFn
} from '@angular/router';

import { Observable } from 'rxjs';

import { CustomerService } from './customer.service';
import { Hospital } from '../hospitals/hospital';

export const CustomerHospitalsResolver: ResolveFn<Hospital> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    customerService: CustomerService = inject(CustomerService)
  ): Observable<Hospital> => customerService.getHospitals();