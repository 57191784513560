<div class="modal-header">
  <h4 class="modal-title pull-left">
    Add carrier
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal" #newCarrierForm="ngForm">
    <div class="form-group">
      <label for="name" class="col-sm-3 control-label">Carrier name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="carrier.name" #name="ngModel" required>
        <div class="form-error" [hidden]="name.valid || name.untouched">
          <p *ngIf="name.errors?.required">This field is required.</p>
        </div>
        <div class="form-error" *ngIf="name.errors?.serverError">
          <p>{{ name.errors.serverError }}</p>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" [disabled]="!newCarrierForm.form.valid" (click)="save()">Save</button>
</div>
