import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ProviderService } from './providers.service';

export const StateResolverService: ResolveFn<any> = (
    route: ActivatedRouteSnapshot
  ): Observable<any> => {
    const providerService: ProviderService = inject(ProviderService);
    return providerService.getState();
  }
