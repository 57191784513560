import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TabDirective, TabsetComponent } from "ngx-bootstrap/tabs";
import { FlashService } from "../../components/flash/flash.service";
import { Hospital } from "../../hospitals/hospital";
import { AdminsComponent } from "../admins/admins.component";
import { CareCoordinatorsComponent } from "../care-coordinators/care-coordinators.component";
import { DispatchersComponent } from "../dispatchers/dispatchers.component";
import { NewUserModalComponent } from "../new-user-modal/new-user-modal.component";

@Component({
  selector: 'app-user-tabs',
  templateUrl: './user-tabs.component.html',
  styleUrls: ['./user-tabs.component.scss']
})
export class UserTabsComponent implements OnInit {
  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  @ViewChild(CareCoordinatorsComponent, {static: true}) careCoordinatorChild: CareCoordinatorsComponent
  @ViewChild(AdminsComponent, {static: true}) adminChild: AdminsComponent
  @ViewChild(DispatchersComponent, {static: true}) dispatcherChild: DispatchersComponent
  newUserModal: BsModalRef;
  hospitals: Hospital[];
  loading = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private flashService: FlashService) {

  }

  ngOnInit() {
    this.selectTab();

    this.route.data.subscribe((data) => {
      this.hospitals = data.hospitals.data;
    });
  }

  selectTab() {
    this.route.queryParams.subscribe(params => {
      const tabId = params['selectTab'];
      if (tabId) {
        this.tabset.tabs[tabId].active = true;
        this.router.navigate(['.'], { relativeTo: this.route });
      }
    });
  }

  tabChanged(event) {
    if (event instanceof TabDirective) {
    }
  }

  public openNewUserModal() {
    this.newUserModal = this.modalService.show(NewUserModalComponent);
    this.newUserModal.content.hospitals = this.hospitals.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      const { added, selectedRoles } = this.newUserModal.content

      if (added) {
        this.newUserModal.content.added = false;
        this.loading = true;
        
        this.flashService.add({
          type: 'success',
          message: 'User has been created. An email has been sent out for them to activate their account.'
        });
        if(this.isCareCoordinator(selectedRoles)){
          this.careCoordinatorChild.loadUsers()
        }
        if(this.isNavCenterAgent(selectedRoles)){
          this.adminChild.loadUsers()
        }
        if(this.isDispatcher(selectedRoles)){
          this.dispatcherChild.loadUsers();
        }
      }
      
      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  isDispatcher(roles: string[]) {
    return this.determineRole('dispatcher', roles);
  }

  isCareCoordinator(roles: string[]) {
    return this.determineRole('care_coordinator', roles) || this.determineRole('super_user', roles);
  }

  isNavCenterAgent(roles: string[]) {
    return this.determineRole('admin', roles);
  }

  determineRole(roleToCheck: string, roles: string[]){
    return roles.filter((item) => item === roleToCheck).length > 0;
  }
}