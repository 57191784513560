<app-loader *ngIf="loading"></app-loader>
<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="federatedIdentityGroups"
      (userRowSelect)="onRowSelect($event)">
    </ng2-smart-table>
    <button class="btn btn-primary" (click)="addGroup($event)">
      New Group
    </button>
  </div>
</div>