<div class="modal-header">
    <h4 class="modal-title pull-left" *ngIf="!editingReport">
        <app-icon icon="home" iconClass="button-icon" ></app-icon> Add Report
    </h4>
    <h4 class="modal-title pull-left" *ngIf="editingReport">
      <app-icon icon="home" iconClass="button-icon"></app-icon> Edit Report
    </h4>
  
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="form-group">
        <label for="name" class="col-sm-3 control-label">UUID</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="uuid" name="uuid" placeholder="UUID" [(ngModel)]="report.uuid" maxlength="100">
        </div>
      </div>
      <div class="form-group">
        <label for="name" class="col-sm-3 control-label">Report Name</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="report_name" name="report_name" placeholder="Report Name" [(ngModel)]="report.report_name" maxlength="100">
        </div>
      </div>
    </form>
  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="deleteReport()">Delete</button>
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
    <button type="button" class="btn btn-primary" (click)="addUpdateReport()">Save Changes</button>
  </div>
  