import {
  Component,
  OnInit,
  Input
} from '@angular/core';

import { HospitalService } from '../hospital.service';
import { UsersFile } from './users-file';

@Component({
  selector: 'app-user-upload',
  templateUrl: './user-upload.component.html',
  styleUrls: ['./user-upload.component.scss']
})
export class UserUploadComponent implements OnInit {
  @Input() hospitalId: string;

  selectedFileName: string;
  errorMessage: string;
  userFile: File = null;
  disableSave = true;
  userProcessing = false;
  users: UsersFile;

  constructor(private hospitalService: HospitalService) {}

  ngOnInit() {}

  userFileChangeEvent(files: FileList) {
    this.userFile = files.item(0);
    this.selectedFileName = this.userFile.name;
    this.disableSave = false;
  }

  onSaveClick() {
    const uploadData = new FormData();

    if (!this.userProcessing && this.userFile) {
      uploadData.append('users', this.userFile);
    }

    uploadData.append('hospital_id', this.hospitalId);

    this.hospitalService
      .bulkUserUpload(parseInt(this.hospitalId, 10), uploadData)
      .subscribe(
        (response) => {
          this.users = response.data;

          if (this.users.status === 'error') {
            this.errorMessage = `Something went wrong while processing ${this.users.users_file_name}`;
          }

          this.userProcessing = !!~['processing', 'pending'].indexOf(this.users.status);
        },

        () => {}
      );

    if (this.userFile) {
      this.errorMessage = null;
    }
  }

}
