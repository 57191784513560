<div class="row">
  <div class="col-sm-6" *ngFor="let option of options">
    <label class="multi-select-checkbox">
      <input [(ngModel)]="option.checked"
             type="checkbox"
             (ngModelChange)="onOptionClick(option, $event)"/>
      <span>{{ option.label }}</span>
    </label>
  </div>
</div>
