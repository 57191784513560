import { Injectable } from '@angular/core';

import {
  RideshareAvailability,
  RideshareAvailabilityLevels,
  RideshareAvailabilitySources,
  RideshareAvailabilityUnknownGrade,
} from './rideshare-availbility';

const HIGH_AVAILABILITY = [
  'a',
  'good',
  'excellent',
]
const MEDIUM_AVAILABILITY = [
  'b',
  'medium'
]
const LOW_AVAILABILITY = [
  'c',
  'low',
  'n/a', // Ignorable
]

@Injectable()
export class RideshareAvailabilityCSVParserService {
  /**
   * Keep track of zipcodes in the CSV since the API cannot handle duplicates in a single upsert statement
   */
  processedZipcodes: Record<string, true> = {}
  /**
   * Any duplicates found are stored here to display in a flash message after processing
   */
  duplicateZipcodes: string[] = []
  invalidZipcodes: string[] = []
  /**
   * Track which grades are currently unknown and non-processable to display as a flash message
   */
  unknownGrades: RideshareAvailabilityUnknownGrade[] = []
  /**
   * Row numbers where either the grade or zipcode is non-existent
   */
  invalidEntriesRowNumber: number[] = []

  constructor() {}

  /**
   *
   * @param csv CSV file uploaded by user
   * @returns Array of objects formatted to the API's specfication.
   */
  parse(csv: string, source: string): RideshareAvailability[] {
    this.reset()

    const rows = csv.split(/\r\n|\n/).slice(1);

    return rows.map((row, index) => {
      const [zip, grade] = row.split(',')

      if (!this.isValidEntry(zip, grade, index + 1)) { // Skip invalid entries
        return;
      }

      const availability = this.formatAvailabilityGrade(zip, grade)
      if (!availability) { // Skip unknown grades
        return;
      }

      if (this.isDuplicateZipcode(zip)) { // Skip duplicates
        return;
      }

      const zipcode = this.formatZipcode(zip)
      if (!zipcode) { // Skip invalid zipcodes
        return;
      }

      return {
        source: source as RideshareAvailabilitySources,
        zipcode,
        availability,
      }
    }).filter(Boolean)
  }

  private isValidEntry(zipcode: string, grade: string, rowNumber: number): Boolean {
    if (Boolean(grade && zipcode)) {
      return true;
    }

    this.invalidEntriesRowNumber.push(rowNumber);
    return false;
  }

  /**
   * Roundtrip's database only handles values `low`, `medium` and `high`.
   */
  private formatAvailabilityGrade(zipcode: string, grade: string): RideshareAvailabilityLevels | undefined {
    const formattedGrade = grade.toLowerCase()

    if (HIGH_AVAILABILITY.includes(formattedGrade)) {
      return 'high'
    }

    if (MEDIUM_AVAILABILITY.includes(formattedGrade)) {
      return 'medium'
    }

    if (LOW_AVAILABILITY.includes(formattedGrade)) {
      return 'low'
    }

    this.unknownGrades.push({
      zipcode,
      grade
    })
  }

  private isDuplicateZipcode(zipcode: string): Boolean {
    if (this.processedZipcodes[zipcode]) {
      this.duplicateZipcodes.push(zipcode)
      return true;
    }

    this.processedZipcodes[zipcode] = true
    return false
  }

  private formatZipcode(zipcode: string): string {
    if (zipcode.length === 5) {
      return zipcode
    }

    if (zipcode.length === 4) {
      return `0${zipcode}`
    }

    this.invalidZipcodes.push(zipcode)
  }

  private reset() {
    this.processedZipcodes = {}
    this.duplicateZipcodes = []
    this.invalidZipcodes = []
    this.unknownGrades = []
    this.invalidEntriesRowNumber = []
  }
}
