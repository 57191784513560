<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Promo Codes</li>
  <li>
    <a href="#" (click)="openNewPromoModal()">
      <app-icon icon="plus" iconClass="button-icon"></app-icon> Add Promo Code
    </a>
  </li>
</ol>

<div class="row">
  <div class="col-xs-12">
    <p-table
    [value]="promoCodes"
    selectionMode="single"
    [(selection)]="selectedPromoCode" 
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="null" 
    [paginator]="true"
    [rows]="10"
    stateStorage="session" 
    stateKey="state-session-promo-codes"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngFor="let key of keys" style="width:15%">
          <p-columnFilter 
          type="text" 
          field="{{key}}" 
          placeholder="{{tableConfig.columns[key].title}}" 
          ariaLabel="Filter {{tableConfig.columns[key].title}}" 
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-promoCode>
      <tr [pSelectableRow]="promoCode">
        <td *ngFor="let key of keys">{{ promoCode[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
