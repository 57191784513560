import { Component } from '@angular/core';
import { NewActivePlanModalComponent } from '../new-active-plan-modal/new-active-plan-modal.component';
import { ActivePlan } from '../payer';

@Component({
  selector: 'app-match-new-active-plan',
  templateUrl: '../new-active-plan-modal/new-active-plan-modal.component.html',
  styleUrls: ['../new-active-plan-modal/new-active-plan-modal.component.scss']
})
export class MatchNewActivePlanModalComponent extends NewActivePlanModalComponent {

  save() {
    this.payerService.matchPendingPlan(this.pendingPlanId, this.generatePayload())
    .subscribe((response) => {
      this.onDone.emit(<ActivePlan>{ id: response.data.carrier_plan_id, ...this.activePlan });
      this.modalRef.hide();
    },
      this.handleServerErrors
    );
  }
}
