import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import {AuthHttpService} from '../../core/auth-http.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CsvService {

  constructor(private http: AuthHttpService) {}

  getData(url: any): Observable<any> {
    return this.http.get(`${environment.apiurl}/${url}`).pipe(
      catchError((error) => this.http.handleError(error))
    );
  }

  convert(obj: any): string {
    const data = typeof obj === 'object' ? obj : JSON.parse(obj);
    const headers = Object.keys(data[0]);

    let output = `${headers.join(',')}\r\n`;

    data.forEach(row => {
      headers.forEach((header, i) => {
        output += row[header] ? row[header] : '';
        output += (i < headers.length - 1) ? ',' : '\r\n';
      });
    });

    return output;
  }

}
