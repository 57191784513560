<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="package" iconClass="button-icon"></app-icon> Add Promo Code
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<p class="alert alert-danger" *ngIf="promoAlreadyExists">
  Promo Code named <b>"{{ newPromoForm.get('promo_code').value }}"</b> already exists.
</p>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="newPromoForm" novalidate>
    <div class="form-group">
      <label class="col-sm-3 control-label">Code Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control fs-block" placeholder="Promo Code" formControlName="promo_code">
        <div class="form-error" *ngIf="newPromoForm.get('promo_code').invalid && newPromoForm.get('promo_code').touched">
          <p>Code is required</p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="discount_type" class="col-sm-3 control-label">Discount Type</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="discount_type" [ngClass]="{ 'placeholder' : !newPromoForm.get('discount_type').value }">
          <option class="" selected="" disabled="" value="">Discount Type</option>
          <option *ngFor="let type of discountTypes" [value]="type.value">
            {{type.title}}
          </option>
        </select>
        <div class="form-error" *ngIf="newPromoForm.get('discount_type').invalid && newPromoForm.get('discount_type').touched">
          <p>Promo type is required</p>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="newPromoForm.get('discount_type').value">
      <div class="col-sm-9 col-sm-offset-3">
        <div class="input-group">
          <span class="input-group-addon" *ngIf="newPromoForm.get('discount_type').value === 'dollar_amount'">$</span>
          <input type="number" class="form-control"
          placeholder="{{ newPromoForm.get('discount_type').value === 'percentage_off' ? 'Percentage' : 'Dollars' }}" formControlName="discount">
          <span class="input-group-addon" *ngIf="newPromoForm.get('discount_type').value === 'percentage_off'">%</span>
        </div>
        <div class="form-error" *ngIf="newPromoForm.get('discount').invalid &&
        newPromoForm.get('discount').touched && newPromoForm.get('discount_type').value === 'dollar_amount'">
          <p>Amount cannot be negative</p>
        </div>
        <div class="form-error" *ngIf="newPromoForm.get('discount').invalid &&
        newPromoForm.get('discount').touched && newPromoForm.get('discount_type').value === 'percentage_off'">
          <p>Percentage should be between 0 and 100</p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="discount_type" class="col-sm-3 control-label">Description (Optional)</label>
      <div class="col-sm-9">
        <textarea class="form-control" formControlName="description" rows="5" placeholder="For eg. 10% OFF, $5 OFF etc">
        </textarea>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" [disabled]="newPromoForm.invalid" (click)="addNewPromoCode()">
    Save Changes
  </button>
</div>
