import { Component, OnInit } from '@angular/core';
import {ProviderService} from '../providers.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FlashService } from '../../components/flash/flash.service';
import { AuthHttpService } from '../../core/auth-http.service';

@Component({
  selector: 'app-provider-delete',
  templateUrl: './provider-delete.component.html',
  styleUrls: ['./provider-delete.component.scss']
})
export class DeleteProviderComponent {
  id;
  deleted = false;
  constructor(
    private providerService: ProviderService,
    public modalRef: BsModalRef,
    public router: Router,
    public flashService: FlashService,
    private http: AuthHttpService
  ) { }

  delete() {
    this.providerService.delete(this.id).subscribe(
      (response) => {
        this.deleted = true;
        this.modalRef.hide();
      },
      (error) => {
        const errors = JSON.parse(error['_body']);
        if (errors.errors) {
          this.flashService.add({
            type: 'danger',
            message: errors.errors
          });
        } else {
          this.http.handleError(error);
        }
        this.modalRef.hide();
      });
  }
}
