import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PayerService } from '../../payer.service';
import { ActivePlan } from '../../payer';

@Component({
  selector: 'app-match-details',
  templateUrl: './match-details.component.html',
  styleUrls: ['./match-details.component.scss']
})
export class MatchDetailsComponent implements OnInit {
  @Input() pendingPlanId: number;
  @Input() activePlan: ActivePlan;
  @Output() public onDone: EventEmitter<ActivePlan> = new EventEmitter();

  constructor(
    private payerService: PayerService,
    public modalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  match() {
    this.payerService.matchPendingPlan(
      this.pendingPlanId, { carrier_plan_id: this.activePlan.id }
    ).subscribe(
      res => {
        this.onDone.emit(this.activePlan);
        this.modalRef.hide();
      }
    );
  }
}
