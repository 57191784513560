<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Transport Companies</li>
  <li class="active">Approved</li>
</ol>

<ul class="nav nav-tabs">
  <li class="active">
    <a routerLink="/providers">
      <app-icon icon="package" iconClass="button-icon"></app-icon> Approved
    </a>
  </li>

  <li>
    <a routerLink="/providers-pending">
      <app-icon icon="loader" iconClass="button-icon"></app-icon> Pending
    </a>
  </li>
</ul>

<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="list"
      (userRowSelect)="onRowSelect($event)">
    </ng2-smart-table>

    <app-csv-download
      title="Download Providers CSV"
      fileName="providers.csv"
      [formatDataFunction]="formatCSVdataFunction.bind(this)"
      url="providers/active-providers-report">
    </app-csv-download>
  </div>
</div>
