import { Hospital } from '../hospitals/hospital';

export const ROLES = [
  { id: 'care_coordinator', name: 'Care Coordinator' },
  { id: 'super_user', name: 'Admin CC' },
  { id: 'dispatcher', name: 'Dispatcher' },
  { id: 'admin', name: 'Nav Center Agent' }
]

export interface User {
  id?: any;
  first_name: string;
  last_name: string;
  auth_token?: string;
  role: string;
  email: string;
  emr_user_id: string;
  password?: string;
  password_changed_at?: Date;
  last_activity_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  hospital_id: number;
  phone?: string;
  time_zone?: string;
  transportation_company_id?: number;
  show_facility_rides?: boolean;
  show_patient_rides?: boolean;
  current_hospital?: Hospital;
  hospitals?: Hospital[];
  active: boolean;
  limited_access: boolean;
  is_super_user?: string;
  domestic_user?: boolean;
}
