<ol class="breadcrumb">
  <li><a routerLink="/payer/plans">Plans</a></li>
  <li class="active">Pending</li>
  <li class="active">{{ pendingPlan.plan_name }}</li>
</ol>

<h1>{{ pendingPlan.plan_name }}</h1>

<div>
  <h2>General information</h2>
  <table class="table">
    <tbody>
      <tr>
        <td><strong>Carrier name</strong></td>
        <td>{{ pendingPlan.company_name }}</td>
      </tr>

      <tr>
        <td><strong>Contact number</strong></td>
        <td>{{ pendingPlan.company_phone || '-' }}</td>
      </tr>

      <tr>
        <td><strong>Origin organization</strong></td>
        <td>{{ pendingPlan.organization_name }}</td>
      </tr>
    </tbody>
  </table>
</div>

<app-match-pending-plan [pendingPlan]="pendingPlan" [carriers]="carriers"></app-match-pending-plan>
