import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationModalComponent } from './location-modal/location-modal.component';
import { LocationsService } from './locations.service';
import { LocationsResolver } from './locations-resolver.service';
import { FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GooglePlaceModule
    ],
    declarations: [LocationModalComponent],
    exports: [
        LocationModalComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: [
        LocationsService,
        { provide: 'LocationsResolver', useValue: LocationsResolver },
    ]
})
export class LocationsModule { }
