import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {Venue} from './venues';
import {Observable} from 'rxjs';
import {VenuesService} from './venues.service';

export const VenuesDetailsResolverService: ResolveFn<Venue[]> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Venue[]> => {
    const venuesService: VenuesService = inject(VenuesService);
    return venuesService.get(route.params.id);
  }
