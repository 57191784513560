import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';

import { ReasonTypeDashboardComponent } from './dashboard/reason-type.component';
import { Ng2SmartTableModule } from 'rt-ng2-smart-table';
import { RouterModule } from '@angular/router';
import { NewReasonModalComponent } from './new-modal/new-modal.component';
import { ReasonTypesResolverService } from './reason-types-resolver.service';
import { FormsModule } from '@angular/forms';
import { ReasonTypeService } from './reason-type.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        Ng2SmartTableModule,
        RouterModule,
    ],
    declarations: [
        ReasonTypeDashboardComponent,
        NewReasonModalComponent,
    ],
    exports: [],
    providers: [
        { provide: 'ReasonTypesResolverService', useValue: ReasonTypesResolverService },
        ReasonTypeService,
    ]
})
export class ReasonTypesModule { }
