<div class="alert alert-{{message.type}}"
  *ngFor="let message of messages; let $last=last"
  [ngClass]="{'margin-bottom-20': $last}"
  [@flashState]>
  <span class="message" [innerHTML]="message.message"></span>
  <button type="button"
    class="close"
    (click)="close(message)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
