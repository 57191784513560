import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { PayerService } from './payer.service';
import { Observable } from 'rxjs';

export const ActivePlanDetailResolverService: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> =>  {
      const payerService: PayerService = inject(PayerService);
      return payerService.getActivePlan(route.params.id);
  }
