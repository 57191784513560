import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthHttpService } from '../core/auth-http.service';
import { FederatedIdentityGroup } from './federated-identity-group';

@Injectable()
export class FederatedIdentityGroupService {

  constructor(private http: AuthHttpService) {}

  create(customerId, federatedIdentityGroup: FederatedIdentityGroup): Observable<FederatedIdentityGroup | string> {
    const url = `${environment.apiv3url}/federated_identity_groups`;
    const payLoad = {
      group_name: federatedIdentityGroup.group_name,
      description: federatedIdentityGroup.description,
      limited_access: federatedIdentityGroup.limited_access,
      customer_id: customerId
    };

    return this.http.postV3<FederatedIdentityGroup>(url, payLoad).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  delete(id): Observable<any> {
    let url = `${environment.apiv3url}/federated_identity_groups/${id}`;

    return this.http
      .deleteV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getFederatedIdentityGroup(id): Observable<any> {
    let url = `${environment.apiv3url}/federated_identity_groups/${id}`;

    return this.http
      .getV3<FederatedIdentityGroup>(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getHospitals(customerId): Observable<any> {
    let url = `${environment.apiv3url}/hospitals?customer_id=${customerId}&include_children=true`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getRoles(): Observable<any> {
    let url = `${environment.apiv3url}/roles`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getCustomerGroups(customerId?: string): Observable<any> {
    let url = `${environment.apiv3url}/federated_identity_groups?customer_id=${customerId}`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  update(id?: string, payload?: any): Observable<any> {
    let url = `${environment.apiv3url}/federated_identity_groups/${id}`;

    return this.http
      .updateV3(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  createGroupHospitals(groupId, hospitals): Observable<any>[] {
    const url = `${environment.apiv3url}/federated_identity_group_hospitals`;
    
    const observableArray = [];
    hospitals.forEach(hospital => {
      let body = {
        federated_identity_group_hospital: {
          federated_identity_group_id: groupId,
          hospital_id: hospital.id
        }
      }

      observableArray.push(this.http.postV3(url, body).pipe(
        catchError((err) => this.http.handleError(err))));
    });

    return observableArray;
  }

  removeGroupHospitals(groupId, hospitals): Observable<any>[] {
    const baseUrl = `${environment.apiv3url}/federated_identity_group_hospitals/${groupId}?federated_identity_group_id=${groupId}&hospital_id=`;
    
    const observableArray = [];
    hospitals.forEach(hospital => {
      observableArray.push(this.http.deleteV3(baseUrl + hospital.id).pipe(
        catchError((err) => this.http.handleError(err))));
    });

    return observableArray;
  }

  getGroupHospitals(groupId): Observable<any> {
    let url = `${environment.apiv3url}/federated_identity_group_hospitals?federated_identity_group_id=${groupId}`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  createGroupRoles(groupId, roles): Observable<any>[] {
    const url = `${environment.apiv3url}/federated_identity_group_roles`;
    
    const observableArray = [];
    roles.forEach(role => {
      let body = {
        federated_identity_group_role: {
          federated_identity_group_id: groupId,
          role_id: role.id
        }
      }

      observableArray.push(this.http.postV3(url, body).pipe(
        catchError((err) => this.http.handleError(err))));
    });

    return observableArray;
  }

  removeGroupRoles(groupId, roles): Observable<any>[] {
    const baseUrl = `${environment.apiv3url}/federated_identity_group_roles/${groupId}?federated_identity_group_id=${groupId}&role_id=`;
    
    const observableArray = [];
    roles.forEach(role => {
      observableArray.push(this.http.deleteV3(baseUrl+role.id).pipe(
        catchError((err) => this.http.handleError(err))));
    });

    return observableArray;
  }

  getGroupRoles(groupId): Observable<any> {
    let url = `${environment.apiv3url}/federated_identity_group_roles?federated_identity_group_id=${groupId}`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }
}
