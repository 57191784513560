export type RideshareAvailabilityLevels = 'high' | 'medium' | 'low'
export type RideshareAvailabilitySources = 'roundtrip' | 'uber' | 'lyft'

export interface RideshareAvailability {
  id?: number;
  availability: RideshareAvailabilityLevels;
  source: RideshareAvailabilitySources;
  zipcode: string;
}

/**
 * Sources may change their grading system so it's possible we don't account for all possible values.
 * This is used to display to the user what zipcodes failed to upload.
 */
export interface RideshareAvailabilityUnknownGrade {
  zipcode: string;
  grade: string;
}

/**
 * `source` in Roundtrips database is an enum mapped to string values through the rails application.
 * The `id` & `name` is what the database values map too.
 */
export const SOURCES = [
  // { id: 0, name: 'roundtrip' }, // Not currently supported
  { id: 1, name: 'uber' },
  { id: 2, name: 'lyft' }
];
