import {
  Component,
  OnInit,
} from '@angular/core';

import { HospitalService } from './hospital.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-hospital-common',
  template: ''
})
export class HospitalCommonComponent implements OnInit {
  vehicleList = [];
  payerTypesList = [];
  reasonTypesList = [];
  selectedPayerTypes = [];
  selectedReasonTypes = [];
  vehicleDropDownSettings;
  reasonTypesDropDownSettings;
  payerTypeSettings;
  selectedVehicles;

  constructor(
    protected hospitalService: HospitalService
  ) {}

  ngOnInit() {
    this.payerTypesList = this.processPayerTypes(this.payerTypesList);
    this.reasonTypesList = this.hospitalService.processData(this.reasonTypesList);

    this.vehicleDropDownSettings = this.getSettings('Select Vehicle Types');
    this.reasonTypesDropDownSettings = this.getSettings('Select Reason Types');
    this.payerTypeSettings = this.getSettings('Select Payment Methods');
  }

  onVehicleItemSelect(item: any) {
    this.onItemSelect(item, this.selectedVehicles, this.vehicleList);
  }

  OnVehicleItemDeSelect(item: any) {
    this.selectedVehicles = this.onItemDeSelect(item, this.selectedVehicles);
  }

  onReasonItemSelect(item: any) {
    this.onItemSelect(item, this.selectedReasonTypes, this.reasonTypesList);
  }

  OnReasonItemDeSelect(item: any) {
    this.selectedReasonTypes = this.onItemDeSelect(item, this.selectedReasonTypes);
  }

  filterList(list, selectedItemIds) {
    return _.filter(list, listItem => selectedItemIds.indexOf(listItem['id']) !== -1);
  }

  onPayerItemDeSelect(item) {
    if (item['required']) {
      this.selectedPayerTypes = _.filter(this.payerTypesList, listItem => listItem.id === item.id).concat(this.selectedPayerTypes);
    }
  }

  onPayerDeSelectAll() {
    this.selectedPayerTypes = [];
  }

  processPayerTypes(payerTypes) {
    return _.orderBy(
      _.map(payerTypes, function(payerType) {
        return { id: payerType.id, itemName: payerType.title, required: payerType.required };
      }), 'required', 'desc');
  }


  onItemSelect(item, selectedItems, totalItems) {
    if (item.child) {
      selectedItems.push.apply(selectedItems,
        _.filter(totalItems, vehicle => {
            if (item.child.indexOf(vehicle.id) !== -1) { return vehicle; }
          }
        )
      );
    }

    selectedItems.push.apply(selectedItems, _.filter(totalItems, vehicle => {
      if (vehicle.child && vehicle.child.indexOf(item.id) !== -1) {
        return vehicle;
      }
    }));
  }

  onItemDeSelect(item, selectedItems) {
    if (item.child) {
      selectedItems = _.filter(selectedItems, vehicle => {
        if (item.child.indexOf(vehicle.id) === -1) { return vehicle; }
      });
    }

    const selected = _.map(selectedItems, 'id');
    _.each(selectedItems, vehicle => {
      if (vehicle && vehicle.child && vehicle.child.indexOf(item.id) !== -1 && _.difference(vehicle.child, selected).length === vehicle.child.length) {
        selectedItems.splice(selectedItems.indexOf(vehicle), 1);
      }
    });

    return selectedItems;
  }

  getSettings(placeholder, badgeShowLimit = 4, enableSearchFilter = false) {
    return {
      singleSelection: false,
      text: placeholder,
      selectAllText: 'Select All',
      unselectAllText: 'Unselect All',
      enableSearchFilter: enableSearchFilter,
      badgeShowLimit: badgeShowLimit,
      autoPosition: false,
      tagToBody: false
    };
  }

}
