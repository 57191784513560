import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-columnar-display',
  templateUrl: './columnar-display.component.html',
  styleUrls: ['./columnar-display.component.scss']
})
export class ColumnarDisplayComponent implements OnInit {
  @Input() data;
  @Input() defaultDisplayValue;

  constructor() { }

  ngOnInit() {
  }
}
