import { inject } from '@angular/core';
import {
  ResolveFn
} from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from './customer.service';
import { Customer } from './customer';

export const CustomerListResolver: ResolveFn <Customer[]> = (): Observable <Customer[]> => {
    const customerService = inject(CustomerService)
    return customerService.get();
  }