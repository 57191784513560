import { HIDDEN_FIELDS_DEFAULT_VALUES, PAYER_HIDDEN_KEYS } from './payer';

export class Utils {
  public static setDefaultValueToHiddenElements(payload: any, hiddenElements: any) {
    Object.keys(hiddenElements).forEach(key => {
      if (hiddenElements[key]) {
        if (key != 'authorization' && key != 'broker' && key != 'additional_requirements') {
          payload[key] = HIDDEN_FIELDS_DEFAULT_VALUES[key];
        }
      }
    });
  }

  public static toggleConditionalElements(payerData, hiddenElements: any) {
    Object.keys(PAYER_HIDDEN_KEYS).forEach(key => {
      this.setHiddenElements(key, payerData[key], hiddenElements);
    });
  }

  public static setHiddenElements(key: string, value: boolean, hiddenElements: any) {
    let hide = false;

    if (hiddenElements[key] || PAYER_HIDDEN_KEYS[key].value == value) {
      hide = true;
    }

    PAYER_HIDDEN_KEYS[key].keysHidden.forEach(element => {
      hiddenElements[element] = hide;
    });
  }
}
