import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';
import { FlashService } from '../../components/flash/flash.service';
import { NewReasonModalComponent } from '../new-modal/new-modal.component';
import { ReasonTypeService } from '../reason-type.service';
import { ReasonType } from '../reason-type';

@Component({
  selector: 'app-reason-type',
  templateUrl: './reason-type.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
  ],
})

export class ReasonTypeDashboardComponent implements OnInit {

  newReasonModal: BsModalRef;
  reasonTypes: ReasonType[];
  loading = false;

  tableConfig = {
    columns: {
      name: {
        title: 'Trip Reason',
      },

      created_at: {
        title: 'Created On',
        width: '200px',
        filter: {
          type: 'list',
          config: {
            list: [
              {value: 'january', title: 'January'},
              {value: 'february', title: 'February'},
              {value: 'march', title: 'March'},
              {value: 'april', title: 'April'},
              {value: 'may', title: 'May'},
              {value: 'june', title: 'June'},
              {value: 'july', title: 'July'},
              {value: 'august', title: 'August'},
              {value: 'september', title: 'September'},
              {value: 'october', title: 'October'},
              {value: 'november', title: 'November'},
              {value: 'december', title: 'December'},
            ]
          }
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private flashService: FlashService,
    private reasonTypeService: ReasonTypeService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.mutateReasonTypes(data.reasonTypes.data);
    });
  }

  public openNewReasonModal() {
    this.newReasonModal = this.modalService.show(NewReasonModalComponent);

    const hiddenSubscription = this.modalService.onHidden.subscribe((_reason: any) => {
      if (this.newReasonModal.content.added) {
        this.loading = true;
        this.newReasonModal.content.added = false;

        this.flashService.add({
          type: 'success',
          message:`${this.newReasonModal.content.reasonType} successfully added!`
        });
      }

      this.reasonTypeService.getReasonTypes()
        .subscribe((reasonTypes: any) => {
          this.mutateReasonTypes(reasonTypes.data);
          this.loading = false;
        });

      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  private mutateReasonTypes(reasonTypes: ReasonType[]) {
    this.reasonTypes = reasonTypes.map((reasonType) => {
      reasonType['created_at'] = moment(reasonType['created_at']).format('LLL');
      return reasonType;
    });
  }
}
