<nav class="navbar navbar-static-top fixed-navbar">
  <div class="navbar-header">
    <button type="button" class="btn btn-icon navbar-btn" (click)="onMenuButtonClicked()">
        <app-icon icon="menu" iconClass="button-icon"></app-icon>
    </button>

    <a routerLink="/">
      <img src="/assets/images/logo-mono.svg" width="100px" />
    </a>
  </div>

  <div class="navbar-collapse">
    <ul class="nav navbar-nav navbar-right">
      <li>
        <a [href]="appLink" target="_blank">
          <app-icon icon="map-pin" iconClass="nav-icon"></app-icon> App
        </a>
      </li>

      <li>
        <a href="#" class="text-danger logout" (click)="logout()">
          <app-icon icon="log-out" iconClass="nav-icon"></app-icon> Logout
        </a>
      </li>
    </ul>
  </div>
</nav>
