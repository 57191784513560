<div class="row">
  <div class="col-lg-6">
    <h3>Service Schedule</h3>
  </div>

  <div class="col-lg-6" *ngIf="!isEdit">
    <button type="button"
            class="btn btn-primary btn-wrap pull-right"
            (click)="toggleEdit()">
      Edit
    </button>
  </div>

  <div class="col-lg-6" *ngIf="isEdit">
    <button type="button"
            class="btn btn-primary btn-wrap pull-right"
            [disabled]="serviceScheduleForm.invalid || hasConflict"
            (click)="save()">
      Update
    </button>
    <button type="button"
            class="btn btn-default btn-wrap pull-right"
            (click)="toggleEdit()">
      Close
    </button>
  </div>

<div *ngIf="!isEdit">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Service</th>
        <th>Day</th>
        <th>All Day?</th>
        <th>From</th>
        <th>To</th>
      </tr>
    </thead>
    <tbody *ngFor="let service of data.services">
      <tr *ngFor="let day of service.days">
        <td>{{availableVehicleTypes[getVehicleTypeKey(service)]}}</td>
        <td>{{day.text}}</td>
        <td>{{day.all_day ? 'Yes' : 'No'}}</td>
        <td>{{day.hour_from}}</td>
        <td>{{day.hour_to}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="isEdit" class="scheduleTableWrapper">
  <form class="form" [formGroup]="serviceScheduleForm" novalidate>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Service</th>
          <th>Day</th>
          <th>All Day?</th>
          <th>From</th>
          <th>To</th>
        </tr>
      </thead>
      <tbody>
        <ng-container formArrayName="schedule">
          <tr *ngFor="let service of scheduleForm.controls; let i=index" [formGroupName]="i" >
            <td>
              <dropdown id="type"
                        [options]="serviceOptions"
                        formControlName="type"
                        (change)="validateServiceSchedules()"
              >
              </dropdown>
            </td>

            <td>
              <dropdown id="text"
                        [options]="days"
                        formControlName="text"
                        (change)="validateServiceSchedules()"
              >
              </dropdown>
            </td>

            <td>
              <ui-switch id="all_day"
                        formControlName="all_day"
                        (change)="onChange(i, $event)">
              </ui-switch>
            </td>

            <td>
              <timepicker id="hour_from" [showMeridian]="false" formControlName="hour_from"></timepicker>
            </td>

            <td>
              <timepicker id="hour_to" [showMeridian]="false" formControlName="hour_to"></timepicker>
            </td>

            <td class="align-middle">
              <a (click)="removeSchedule(i)" class="btn px-0">
                <img src="assets/images/remove_circle-24px.svg" class="img-circle"
                      alt="Remove">
              </a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>
  <div class="row">
    <div class="col-lg-12">
      <button type="button" class="btn btn-success pull-right"
              (click)="addSchedule()">
        Add Schedule
      </button>
    </div>
  </div>
</div>
