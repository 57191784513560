<ol class="breadcrumb">
  <li><a routerLink="/hospitals">Organizations</a></li>
  <li>{{hospitalName}}</li>
  <li>Community Settings</li>
  <li>Add a Transport Company Access Rule</li>
</ol>
<div class="row">
  <div class="col-sm-9">
    <h1 class="page-heading">Add a Transport Company Access Rule</h1>
    <div class="form-inline smaller-form strategy">
    <!-- Strategy field -->
      <label>Strategy</label>
      <select
        class="form-control"
        [ngClass]="{'error-input': strategy.invalid && (strategy.dirty || strategy.touched || submitted)}"
        [(ngModel)]="accessStrategy"
        [disabled]="!canEditStrategy"
        required
        #strategy="ngModel"
      >
        <option value="" selected="selected">Select a strategy</option>
        <option *ngFor="let strategy of strategyOptions" [value]="strategy.id">
          {{strategy.name}}
        </option>
      </select>
      <div *ngIf="strategy.invalid && (strategy.dirty || strategy.touched || submitted)">
        <p class="error-msg" *ngIf="strategy.errors.required">
          This field is required.
        </p>
      </div>
    </div>

    <div *ngIf="data?.length === 0">
      <p class="bold-text">No rules defined yet</p>
    </div>

    <div *ngFor="let rule of data; index as i">
      <div class="collapse-bar clearfix" (click)="rule.collapsed = !rule.collapsed">
        <h5 class="pull-left">
          Rule {{ i + 1 }}
        </h5>
        <div class="selected-values" *ngIf="rule.collapsed">
          <span *ngIf="rule.model_attribute">
            {{ modelAttributesMap[rule.model_attribute] }}
          </span>
          <span class="fa fa-chevron-right" *ngIf="rule.model_attribute && rule.value"></span>
          <span *ngIf="rule.model_attribute && rule.value">
              {{ allowedValuesMap[rule.model_attribute][rule.value] }}
            </span>
        </div>
        <app-icon *ngIf="rule.collapsed" icon="chevron-down" iconClass="pull-right"></app-icon>
        <app-icon *ngIf="!rule.collapsed" icon="chevron-up" iconClass="pull-right"></app-icon>
        <button
          class="btn btn-invert btn-danger pull-right"
          (click)="removeAttributeFromList($event, i)"
        >
          Delete
        </button>
      </div>
      <div class="collapse-block" [collapse]="rule.collapsed">
        <div class="form-inline smaller-form">
          <label>Attribute</label>
          <select
            class="form-control"
            [ngClass]="{'error-input': modelAttribute.invalid && (modelAttribute.dirty || modelAttribute.touched || submitted)}"
            [(ngModel)]="rule.model_attribute"
            required
            #modelAttribute="ngModel"
          >
            <option value="" selected="selected">Select an attribute</option>
            <option *ngFor="let attributeType of modelAttributeOptions" [value]="attributeType.id">
              {{attributeType.name}}
            </option>
          </select>
          <div *ngIf="modelAttribute.invalid && (modelAttribute.dirty || modelAttribute.touched || submitted)">
            <p class="error-msg" *ngIf="modelAttribute.errors.required">
              This field is required.
            </p>
          </div>
        </div>
        <div class="form-inline smaller-form" *ngIf="rule.model_attribute">
          <label></label>
          <select
            class="form-control"
            [ngClass]="{'error-input': (ruleValue.invalid && (ruleValue.dirty || ruleValue.touched || submitted)) || hasUniquenessError(rule.model_attribute, rule.value)}"
            [(ngModel)]="rule.value"
            required
            #ruleValue="ngModel"
          >
            <option *ngIf="rule.model_attribute=='vehicle_type'" value="" selected="selected">Select a vehicle type</option>
            <option *ngIf="rule.model_attribute=='payer_type'" value="" selected="selected">Select a payer type</option>
            <option *ngFor="let attributeValue of valueOptions[rule.model_attribute]" [value]="attributeValue.id">
              {{attributeValue.name}}
            </option>
          </select>
          <div *ngIf="ruleValue.invalid && (ruleValue.dirty || ruleValue.touched || submitted)">
            <p class="error-msg" *ngIf="ruleValue.errors.required">
              This field is required.
            </p>
          </div>
          <div *ngIf="hasUniquenessError(rule.model_attribute, rule.value)">
            <p class="error-msg">
              This attribute has already been used.
            </p>
          </div>
        </div>

        <div class="form-inline">
          <label>Select Transport Companies</label>
          <input
            type="text"
            class="form-control"
            [ngClass]="{'error-input': data[i].transportation_company_ids.length < 1 && (selectProviders.dirty || selectProviders.touched || submitted)}"
            [(ngModel)]="data[i].selectedProviderTemp"
            [typeahead]="data[i].dataSource"
            typeaheadOptionField="name"
            (typeaheadOnSelect)="onProviderSelect($event, i)"
            placeholder="Search by transportation company name"
            #selectProviders="ngModel"
          />
          <div *ngIf="data[i].transportation_company_ids.length < 1 && (selectProviders.dirty || selectProviders.touched || submitted)">
            <p class="error-msg">
              Please select at least one transportation company.
            </p>
          </div>
        </div>
        <div class="form-inline" *ngIf="rule.transportation_company_ids.length">
          <label></label>
          <ul class="form-control select-list">
            <li class="clearfix" *ngFor="let providerId of rule.transportation_company_ids">
              <span class="drag-icon fa fa-bars"></span>
              <span>{{providers[providerId]}}</span>
              <app-icon *ngIf="!rule.collapsed" icon="x" (click)="removeProvider(providerId, i)"></app-icon>
            </li>
          </ul>
        </div>

      </div>
    </div>

    <div class="button-bar clearfix">
      <button class="btn btn-invert btn-primary" (click)="addAttributeToList()">+ Add rule</button>
      <button class="btn btn-primary pull-right" (click)="save()">Save</button>
      <button class="btn btn-invert btn-default pull-right" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
