import { Observable } from 'rxjs';
import { AdminServerSourceConf } from './admin-server-source.conf';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalDataSource } from 'rt-ng2-smart-table';
import { map } from 'rxjs/operators';


export class AdminServerDataSource extends LocalDataSource {

  protected conf: AdminServerSourceConf;

  protected lastRequestCount = 0;

  constructor(protected http: HttpClient, conf: AdminServerSourceConf | {} = {}) {
    super();

    this.conf = new AdminServerSourceConf(conf);

    if (!this.conf.endPoint) {
      throw new Error('At least endPoint must be specified as a configuration of the server data source.');
    }
  }

  count(): number {
    return this.lastRequestCount;
  }

  getElements(): Promise<any> {
    return this.requestElements().pipe(
      map(res => {
        const data = res.data;
        const meta = res.meta;
        this.lastRequestCount = this.extractTotalFromResponse(meta);
        this.data = this.extractDataFromResponse(data);

        if (this.conf.formatDataFunction) {
          this.conf.formatDataFunction(data);
        }

        if (this.conf.metaCallbackFunction) {
          this.conf.metaCallbackFunction(meta);
        }
        return this.data;
      })).toPromise();
  }

  /**
   * Extracts array of data from server response
   * @param res
   * @returns {any}
   */
  protected extractDataFromResponse(res: any): Array<any> {
    const rawData = res;
    if (rawData instanceof Array) {
      return rawData;
    }

    throw new Error(`Data must be an array.
    Please check that data extracted from the server response by the key '${this.conf.dataKey}' exists and is array.`);
  }

  /**
   * Extracts total rows count from the server response
   * @param res
   * @returns {any}
   */
  protected extractTotalFromResponse(res: any): number {
    return res[this.conf.totalKey];
  }

  protected requestElements(): Observable<any> {
    const httpParams = this.createRequesParams();
    return this.http.get<any>(this.conf.endPoint, { params: httpParams });
  }

  protected createRequesParams(): HttpParams {
    let httpParams = new HttpParams();

    httpParams = this.addSortRequestParams(httpParams);

    httpParams = this.addFilterRequestParams(httpParams);

    return this.addPagerRequestParams(httpParams);
  }

  protected addSortRequestParams(httpParams: HttpParams): HttpParams {
    if (this.sortConf) {
      this.sortConf.forEach((fieldConf) => {
        httpParams = httpParams.set(this.conf.sortFieldKey, fieldConf.field);
        httpParams = httpParams.set(this.conf.sortDirKey, fieldConf.direction.toUpperCase());
      });
    }

    return httpParams;
  }

  protected addFilterRequestParams(httpParams: HttpParams): HttpParams {

    if (this.filterConf.filters) {
      this.filterConf.filters.forEach((fieldConf: any) => {
        if (fieldConf['search']) {
          if (this.conf.filterFieldDataType && this.conf.filterFieldDataType[fieldConf['field']] && this.conf.filterFieldDataType[fieldConf['field']] === Array) {
            const keys = fieldConf['search'].split(',');
            keys.forEach(key => {
              httpParams = httpParams.append(`${this.conf.filterFieldKey.replace(
                '#field#', fieldConf['field'])}[]`, key.trim()
              );
            });
          } else {
            httpParams = httpParams.set(this.conf.filterFieldKey.replace('#field#', fieldConf['field']), fieldConf['search']);
          }
        }
      });
    }

    return httpParams;
  }

  protected addPagerRequestParams(httpParams: HttpParams): HttpParams {

    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      httpParams = httpParams.set(this.conf.pagerPageKey, this.pagingConf['page']);
      httpParams = httpParams.set(this.conf.pagerLimitKey, this.pagingConf['perPage']);
    }

    return httpParams;
  }
}
