<div *ngIf="!isEdit">
  <div class="header">
    <h4>Community settings</h4>
    <button class="btn btn-invert" (click)="enableEdit()">Edit</button>
  </div>

  <div class="section">
    <div class="row">
      <div class="col-sm-4">
        <label>Auto-assign transport company</label>
      </div>

      <div class="col-sm-8">
        <p class="value">{{providerDisplayNames[data.autoassign_company_id] || 'No company selected'}}</p>
        <p class="small">
          All eligible rides booked with a vehicle type available at the selected transport company
          will bypass the Community and be automatically assigned to this transport company.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <label>General community ETA submission window</label>
      </div>

      <div class="col-sm-8">
        <p class="value">{{data.eta_submission_window}} minutes</p>
        <p class="small">
          The amount of time transport companies have to submit their ETA for an available ride
          in the general Community. The default is 10 minutes. At the end of this time period, the ride
          is awarded to the transport company with the ETA closest to the requested ride time
          (unless there are preferred rules in place).
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <label>Preferred transport company ETA submission window</label>
      </div>

      <div class="col-sm-8">
        <p class="value">{{data.pp_submission_window}} minutes</p>
        <p class="small">
          The amount of time preferred transport companies have to submit their ETA
          for an available ride. The default is 5 minutes. If a preferred transport company
          submits their ETA within that time frame, then they will automatically be
          awarded the ride (unless a preferred ETA deviation window has been set).
          If there is no winning preferred bid present on a ride, it will
          be moved to the general community and that submission window time will start.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <label>Preferred transport company ETA deviation window</label>
      </div>

      <div class="col-sm-8">
        <p class="value" *ngIf="data.bidding_tolerance_enabled">{{data.bidding_tolerance}} minutes</p>
        <p class="value" *ngIf="!data.bidding_tolerance_enabled">Disabled</p>
        <p class="small">
          The length of time the ETA submitted by the preferred transport company can deviate
          from the requested ride time in order for the preferred transport company to
          automatically be awarded a ride. It’s turned off by default.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <label>Preferred transport companies strategy</label>
      </div>

      <div class="col-sm-8">
        <p class="value">{{ppStrategyDisplayNames[data.pp_strategy]}}</p>
        <p class="small">
          The strategy opted by the organization to choose preferred transport companies
          for rides of the organization.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <label>Transport company access rules strategy</label>
      </div>

      <div class="col-sm-8">
        <p class="value">{{accessStrategyDisplayNames[data.provider_access_strategy]}}</p>
        <p class="small">
          The strategy opted by the organization to choose a sub-community that
          will only be able to view/interact with the rides of the organization.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <label>Keep ride in CAD after releasing to community</label>
      </div>
      <div class="col-sm-8">
        <p class="value">{{data.track_ride_on_release}}</p>
        <p class="small">
          Does not delete / cancel a ride within a transportation company's CAD after releasing to the community. 
          Used when a parent company has sub-contractors and wants to keep track of the ride even after releasing.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <label>Bypass brokered rules?</label>
      </div>
      <div class="col-sm-8">
        <span class="value" *ngFor="let item of brokerBypassVehicleTypes; let $last=last">{{ item.itemName }}<span *ngIf="!$last">, </span></span>
        <!-- <p class="value">{{data.broker_bypass_vehicle_types}}</p> -->
        <p class="small">
          Rides Booked with Insurance Payer with Brokered Logic on will auto push rides to the community for the above vehicle types.
        </p>
      </div>
    </div>
  </div>

  <div>
    <!-- Preferred Provider List -->
    <div *ngIf="getKeys(preferredProviderRulesets).length !== 0">
      <div class="header">
        <h5>
          Preferred transportation companies
          <span class="small">(in order of Priority)</span>
        </h5>
        <button
          class="btn btn-invert align-self-start"
          routerLink="/hospital/{{hospitalId}}/preferred-providers/new"
          *ngIf="canAddNewPPRules()"
        >
          + Add rule
        </button>
      </div>
      <!-- Preferred Provider tile start -->
      <!-- Add 'disabled' class in case of disabled rule -->
      <div class="section provider-tile" *ngFor="let rulesetGroupName of getKeys(preferredProviderRulesets)">
        <span class="drag-icon fa fa-bars"></span>
        <div class="provider-info-wrap">
          <h5>{{ ppStrategyDisplayNames[rulesetGroupName] }}</h5>
          <div class="provider-info">
            <div *ngFor="let ruleset of preferredProviderRulesets[rulesetGroupName]">
              <p>
                <span>{{ ppModelAttributeDisplayNames[ruleset.model_attribute] }}:</span>
                {{ ppValueDisplayNames[ruleset.model_attribute][ruleset.value] }}
              </p>
              <p>
                <span>Transport companies:</span>
                {{ getProvidersDisplayNames(ruleset.transportation_company_ids) }}
              </p>
            </div>
          </div>
          <div class="provider-actions">
            <button class="btn btn-invert" routerLink="/hospital/{{hospitalId}}/preferred-providers/{{rulesetGroupName}}">Edit</button>
            <div>
              <button class="btn btn-invert btn-danger" (click)="deletePPRulesGroup(rulesetGroupName)">Delete</button>
            </div>
          </div>
        </div>
      </div><!-- Preferred Provider tile ends -->
    </div>
    <!-- Preferred provider Null state -->
    <div class="section null-state" *ngIf="getKeys(preferredProviderRulesets).length === 0">
      <img src="/assets/images/flow-illustration.svg" />
      <div>
        <h3>No preferred transport company rules for this facility</h3>
        <a class="btn btn-primary" routerLink="/hospital/{{hospitalId}}/preferred-providers/new">+ Add a preferred transport company</a>
      </div>
    </div>

    <!-- Provider access List -->
    <div *ngIf="getKeys(providerAccessRules).length !== 0">
      <div class="header">
        <h5>Transport company access rules</h5>
        <button
          class="btn btn-invert align-self-start"
          routerLink="/hospital/{{hospitalId}}/provider-access/new"
          *ngIf="canAddNewAccessRules()"
        >
          + Add rule
        </button>
      </div>
      <!-- Provider access rule tile start -->
      <div class="section provider-tile" *ngFor="let rulesetGroupName of getKeys(providerAccessRules)">
        <span class="drag-icon fa fa-bars"></span>
        <div class="provider-info-wrap">
          <h5>{{ accessStrategyDisplayNames[rulesetGroupName] }}</h5>
          <div class="provider-info">
            <div *ngFor="let ruleset of providerAccessRules[rulesetGroupName]">
              <p>
                <span>{{ accessRulesModelAttributeDisplayNames[ruleset.model_attribute] }}:</span>
                {{ accessRulesValueDisplayNames[ruleset.model_attribute][ruleset.value] }}
              </p>
              <p>
                <span>Transport companies:</span>
                {{ getProvidersDisplayNames(ruleset.transportation_company_ids) }}
              </p>
            </div>
          </div>
          <div class="provider-actions">
            <button class="btn btn-invert" routerLink="/hospital/{{hospitalId}}/provider-access/{{rulesetGroupName}}">Edit</button>
            <div>
              <button class="btn btn-invert btn-danger" (click)="deleteAccessRulesGroup(rulesetGroupName)">Delete</button>
            </div>
          </div>
        </div>
      </div><!-- Provider access rule tile ends -->
    </div>
    <!-- Provider access rules Null state -->
    <div class="section null-state" *ngIf="getKeys(providerAccessRules).length === 0">
      <img src="/assets/images/flow-illustration.svg" />
      <div>
        <h3>No transport company access rules for this facility</h3>
        <a class="btn btn-primary" routerLink="/hospital/{{hospitalId}}/provider-access/new">+ Add allowlist or denylist</a>
      </div>
    </div>
  </div>
</div>

<div class="edit-form" *ngIf="isEdit">
  <div class="row">
    <div class="col-sm-5">
      <label>Auto-assign transport company</label>
    </div>
    <div class="col-sm-7">
      <select class="form-control" [(ngModel)]="data.autoassign_company_id">
        <option value="">--Select--</option>
        <option *ngFor="let provider of activeProviders" [value]="provider.id">
          {{ provider.name }}
        </option>
      </select>
      <p class="small">
        All eligible rides booked with a vehicle type available at the selected transport company
        will bypass the Community and be automatically assigned to this transport company.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <label>General community ETA submission window</label>
    </div>
    <div class="col-sm-7">
      <input class="form-control" type="number" [(ngModel)]="data.eta_submission_window"/>
      <p class="small">
        The amount of time transport companies have to submit their ETA for an available ride
        in the general Community. The default is 10 minutes. At the end of this time period, the ride
        is awarded to the transport company with the ETA closest to the requested ride time
        (unless there are preferred rules in place).
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <label>Preferred transport company ETA submission window</label>
    </div>
    <div class="col-sm-7">
      <input class="form-control" type="number" [(ngModel)]="data.pp_submission_window"/>
      <p class="small">
        The amount of time preferred transport companies have to submit their ETA
        for an available ride. The default is 5 minutes. If a preferred transport company
        submits their ETA within that time frame, then they will automatically be
        awarded the ride (unless a preferred ETA deviation window has been set).
        If there is no winning preferred bid present on a ride, it will
        be moved to the general community and that submission window time will start.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <label>Preferred transport company ETA deviation window</label>
    </div>
    <div class="col-sm-7">
      <ui-switch [(ngModel)]="data.bidding_tolerance_enabled"></ui-switch>
      <input class="form-control" type="number" [hidden]="!data.bidding_tolerance_enabled" [(ngModel)]="data.bidding_tolerance"/>
      <p class="small">
        The length of time the ETA submitted by the preferred transport company can deviate
        from the requested ride time in order for the preferred transport company to
        automatically be awarded a ride. It’s turned off by default.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <label>Preferred transport companies strategy</label>
    </div>
    <div class="col-sm-7">
      <select class="form-control" [(ngModel)]="data.pp_strategy">
        <option *ngFor="let strategy of ppStrategyOptions" [value]="strategy.id">
          {{ strategy.name }}
        </option>
      </select>
      <p class="small">
        The strategy opted by the organization to choose preferred transport companies
        for rides of the organization.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <label>Transport company access rules strategy</label>
    </div>
    <div class="col-sm-7">
      <select class="form-control" [(ngModel)]="data.provider_access_strategy">
        <option *ngFor="let strategy of accessStrategyOptions" [value]="strategy .id">
          {{ strategy.name }}
        </option>
      </select>
      <p class="small">
        The strategy opted by the organization to choose a sub-community that
        will only be able to view/interact with the rides of the organization.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <label>Keep ride in CAD after releasing to community</label>
    </div>
    <div class="col-sm-7">
      <ui-switch [(ngModel)]="data.track_ride_on_release"></ui-switch>
      <p class="small">
        Does not delete / cancel a ride within a transportation company's CAD after releasing to the community. 
        Used when a parent company has sub-contractors and wants to keep track of the ride even after releasing.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <label>Bypass brokered rules?</label>
    </div>
    
    <div class="col-sm-7">
      <angular2-multiselect [data]="vehicleList" [(ngModel)]="brokerBypassVehicleTypes"
                                    [settings]="brokerBypassDropdownSettings"
                                    (onSelect)="onBrokerBypassItemSelect($event)"
                                    (onDeSelect)="onBrokerBypassItemDeSelect($event)"
                                    >
      </angular2-multiselect>
      <p class="small">
        Rides Booked with Insurance Payer with Brokered Logic on will auto push rides to the community for the above vehicle types.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <button class="btn btn-default" (click)="disableEdit()">Close</button>
      <button class="btn btn-primary" (click)="update()">Update</button>
    </div>
  </div>
</div>
