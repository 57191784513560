import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Ng2SmartTableModule } from 'rt-ng2-smart-table';
import { ProvidersListComponent } from './providers-list/providers-list.component';
import { NewProviderComponent } from './provider-new/provider-new.component';
import { ProviderService } from './providers.service';
import { FormsModule } from '@angular/forms';
import { ViewProviderComponent } from './provider-view/provider-view.component';
import { ContactInformationComponent } from './shared/contact-information/contact-information.component';
import { ProviderDetailResolverService } from './provider-detail.resolver.service';
import { DeleteProviderComponent } from './provider-delete/provider-delete.component';
import { ProvidersPendingComponent } from './providers-pending/providers-pending.component';
import { ServiceFeesEditComponent } from './service-fees-edit/service-fees-edit.component';
import { ProviderCsvService } from './provider-csv.service';
import { StateResolverService } from './state.resolver.service';
import { DispatcherInformationComponent } from './shared/dispatcher-information/dispatcher-information.component';
import { ServiceScheduleComponent } from './shared/service-schedule/service-schedule.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { GeneralInformationComponent } from './shared/general-information/general-information.component';
import { ServiceAreasComponent } from './shared/service-areas/service-areas.component';
import { StateCountiesModalComponent } from './shared/state-counties-modal/state-counties-modal.component';
import { ActiveProviderDropdownResolver } from './active-provider-dropdown.resolver.service';
import { ProviderUsersListComponent } from './provider-users-list/provider-users-list.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ComponentsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        Ng2SmartTableModule,
        FormsModule,
        RouterModule,
        UiSwitchModule,
        TimepickerModule.forRoot()
    ],
    declarations: [
        ProvidersListComponent,
        NewProviderComponent,
        ViewProviderComponent,
        DeleteProviderComponent,
        ProvidersPendingComponent,
        ServiceFeesEditComponent,
        GeneralInformationComponent,
        DispatcherInformationComponent,
        ContactInformationComponent,
        ServiceScheduleComponent,
        ServiceAreasComponent,
        StateCountiesModalComponent,
        ProviderUsersListComponent,
    ],
    exports: [
        ProvidersListComponent,
        NewProviderComponent,
        ViewProviderComponent,
        ProvidersPendingComponent,
        ServiceFeesEditComponent,
        GeneralInformationComponent,
        ContactInformationComponent,
        ServiceAreasComponent,
        StateCountiesModalComponent,
    ],
    providers: [
        ProviderService,
        ProviderCsvService,
        { provide: 'ProviderDetailResolverService', useValue: ProviderDetailResolverService },
        { provide: 'StateResolverService', useValue: StateResolverService },
        { provide: 'ActiveProviderDropdownResolver', useValue: ActiveProviderDropdownResolver },
    ]
})
export class ProvidersModule {
}
