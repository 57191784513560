<form class="form" [formGroup]="partnerSetupForm">
  <table class="table table-striped">
    <tbody>
      <tr>
        <td class="column align-left">
          SSO Enabled
        </td>
        <td></td>
        <td>
          <ui-switch formControlName="sso_enabled"></ui-switch>
        </td>
      </tr>

      <tr>
        <td class="column">Patient Identifier value</td>
        <td class="description">Identifier Code for Patient</td>
        <td>
          <input type="text"
                class="form-control"
                placeholder="e.g. MRN, MR, EPI"
                required
                formControlName="patient_identifier">
        </td>
      </tr>

      <ng-container formGroupName="partner_auth_setting">
        <tr>
          <td class="column">Hospital Identifier</td>
          <td></td>
          <td>
            <input type="text"
                  class="form-control"
                  placeholder="Hospital's facility code"
                  required
                  formControlName="client_id">
          </td>
        </tr>

        <tr *ngIf="clientIdHasError">
          <td colspan="3">
            <p class="error float-right">{{clientIdErrorMessage}}</p>
          </td>
        </tr>

        <tr>
          <td class="column">Authentication Secret</td>
          <td>
            <button type="button" class="btn btn-primary pull-right"
                    (click)="generateClientSecret()">
              Generate
            </button>
          </td>
          <td>
            <input type="text"
                  class="form-control"
                  placeholder="Generate and share with client"
                  required
                  formControlName="client_secret">
          </td>
        </tr>

        <tr *ngIf="clientSecretHasError">
          <td colspan="3">
            <p class="error float-right">{{clientSecretErrorMessage}}</p>
          </td>
        </tr>

        <tr>
          <td class="column">Internal message processing queue</td>
          <td></td>
          <td>
            <input type="text"
                  class="form-control"
                  placeholder="Roundtrip processing queue URL"
                  formControlName="sqs_queue_url">
          </td>
        </tr>

        <tr>
          <td class="column">Webhook URL</td>
          <td></td>
          <td>
            <input type="text"
                  class="form-control"
                  placeholder="Webhook URL from client"
                  formControlName="webhook_url">
          </td>
        </tr>

        <tr>
          <td class="column">Webhook Verification Token</td>
          <td></td>
          <td>
            <input type="text"
                  class="form-control"
                  placeholder="Webhook Verification Token from client"
                  formControlName="verification_token">
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</form>

<div class="modal-footer">
  <button type="button"
          class="btn btn-primary"
          [disabled]="partnerSetupForm.invalid"
          (click)="save()">
    Save
  </button>

  <button type="button"
          class="btn btn-primary"
          (click)="close()">
    Close
  </button>
</div>
