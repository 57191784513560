<form class="form" [formGroup]="redoxSetupForm">
  <table class="table table-striped">
    <tbody>
      <tr>
        <td class="column align-left">SSO Enabled</td>
        <td></td>
        <td>
          <ui-switch #sso_enabled formControlName="sso_enabled">
          </ui-switch>
        </td>
      </tr>

      <tr>
        <td class="column align-left">Unrestricted SSO</td>
        <td></td>
        <td>
          <ui-switch formControlName="enable_unrestricted_sso_access"
                    [disabled]="!sso_enabled.checked">
          </ui-switch>
        </td>
      </tr>

      <tr>
        <td class="column align-left">CC SSO Signup Enabled</td>
        <td></td>
        <td>
          <ui-switch formControlName="enable_sso_cc_creation"
                    [disabled]="!sso_enabled.checked">
          </ui-switch>
        </td>
      </tr>

      <tr>
        <td class="column align-left">Disallow Manual Login</td>
        <td></td>
        <td>
          <ui-switch formControlName="sso_auto_provision_enabled"
                    [disabled]="!sso_enabled.checked">
          </ui-switch>
        </td>
      </tr>

      <tr>
        <td class="column">Patient Identifier value</td>
        <td class="description">Identifier Code for Patient</td>
        <td>
          <input type="text"
                  class="form-control"
                  placeholder="e.g. MRN,MR,EPI (Case Sensitive)"
                  required
                  formControlName="patient_identifier">
        </td>
      </tr>

      <tr>
        <td class="column">SSO Source ID</td>
        <td class="column">SSO Secret</td>
        <td>
          <button type="button" class="btn btn-success pull-right"
                (click)="addSSOIdentifier()">
            Add SSO Identifier
          </button>
        </td>
      </tr>

      <ng-container formArrayName="sso_identifiers">
        <p *ngIf="ssoIdentifiersHasError" class="error">{{ssoIdentifierErrorMessage}}</p>
        <tr *ngFor="let identifier of ssoIdentifiers.controls; let i=index" [formGroupName]="i">
          <td>
            <input type="text"
                    class="form-control"
                    placeholder="Obtain from Redox Implementation Manager (Case Sensitive)"
                    required
                    (change)="onIdentifierChange()"
                    formControlName="source_id">
          </td>
          <td>
            <input type="password"
                  class="form-control"
                  placeholder="Generated from Redox Destination Settings"
                  formControlName="sso_secret">
            <p *ngIf="sourceIDElementDisabled(identifier)" class="note"> *Redox SSO Secret is present. </p>
          </td>
          <td class="description">
            <p class="inline-block">Obtain from Redox</p>
            <a *ngIf="!sourceIDElementDisabled(identifier)" (click)="removeIdentifier(i)" class="btn px-0 inline-block">
              <img src="assets/images/remove_circle-24px.svg"
                  class="img-circle"
                  alt="Remove">
            </a>
          </td>
        </tr>
      </ng-container>

      <tr>
        <td *ngIf="ingestionIdentifiersHasError" colspan="3">
          <p class="error float-right">{{ingestionIdentifierErrorMessage}}</p>
        </td>
      </tr>

      <tr>
        <td class="column">HL7 Source ID(s)</td>
        <td class="description">Obtain from Redox</td>
        <td class="fixed-width">
          <tag-input theme='bootstrap'
                    formControlName="ingestion_identifiers"
                    placeholder=""
                    secondaryPlaceholder="Enter a HL7 Source ID"
                    [addOnBlur]="true"
                    (onAdd)="onIdentifierChange()"
                    (onRemove)="onIdentifierChange()">
          </tag-input>
        </td>
      </tr>

      <tr>
        <td colspan="3">
          <p class="error float-right">{{hl7UserErrorMessage}}</p>
        </td>
      </tr>
      <tr>
        <td class="column">HL7 Default User Email Address</td>
        <td class="description"></td>
        <td>
          <input type="email"
                class="form-control"
                formControlName="hl7_default_user_email"
                required
                (change)="clearError()"
                [readonly]="hl7UserEmailPresent"
                [email]= "true"
                placeholder="hl7+<hospitalname>@roundtriphealth.com">
        </td>
      </tr>

      <tr>
        <td class="column">Height Observation Code</td>
        <td class="description"></td>
        <td>
          <input type="text"
                class="form-control"
                placeholder="e.g. HT, Height"
                formControlName="height_code">
        </td>
      </tr>

      <tr>
        <td class="column">Weight Observation Code</td>
        <td class="description"></td>
        <td>
          <input type="text"
                class="form-control"
                placeholder="e.g. WT, Weight"
                formControlName="weight_code">
        </td>
      </tr>

      <tr>
        <td class="column">Redox API Endpoint - enter in Redox</td>
        <td class="description"></td>
        <td>
          <input type="text"
                [value]="redoxApiEndpoint"
                disabled
                class="form-control">
        </td>
      </tr>

      <tr>
        <td class="column">Redox SSO Endpoint - enter in Redox</td>
        <td class="description"></td>
        <td>
          <input type="text"
                [value]="redoxSSOEndpoint"
                disabled
                class="form-control">
        </td>
      </tr>
    </tbody>
  </table>
</form>

<div class="modal-footer">
  <button type="button"
          class="btn btn-primary"
          [disabled]="redoxSetupForm.invalid || ssoIdentifiersHasError || ingestionIdentifiersHasError"
          (click)="save()">
    Save
  </button>

  <button type="button"
          class="btn btn-primary"
          (click)="close()">
    Close
  </button>
</div>
