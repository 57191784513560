import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// guards
import { AuthGuard } from '@auth0/auth0-angular';

// core
import { BaseComponent } from './base/base.component';

// hospitals
import { HospitalsViewComponent } from './hospitals/view/view.component';
import { HospitalsEditComponent } from './hospitals/edit/edit.component';
import { HospitalResolver } from './hospitals/hospital-resolver.service';
import { HospitalDetailsResolver } from './hospitals/hospital-details-resolver.service';
import { HospitalPayerTypesResolverService } from './hospitals/hospital-payer-types-resolver.service';
import { HospitalReasonTypesResolverService } from './hospitals/hospital-reason-types-resolver.service';
import { HospitalBrokerBypassVehicleTypesResolverService } from './hospitals/hospital-broker-bypass-vehicle-types-resolver.service';
import { VehicleTypesResolverService } from './hospitals/vehicle-types-resolver.service';
import { InactiveProgramsResolver } from './hospitals/programs/inactive-programs-resolver.service';

// b2b users
import { UserDetailsResolver } from './users/user-details-resolver.service';

// b2c users
import { UsersB2CComponent } from './users/b2c/b2c.component';
import { B2CResolver } from './users/b2c-resolver.service';

// venues
import { VenuesViewComponent } from './venues/list/list.component';
import { VenuesResolverService } from './venues/venues.resolver.service';
import { EditVenueComponent } from './venues/edit-venue/edit-venue.component';
import { VenuesDetailsResolverService } from './venues/venues-details.resolver.service';

import { PromoCodeListComponent } from './promo-code/list/list.component';
import { PromoCodeResolverService } from './promo-code/promo-code.resolver.service';
import { PromoCodeDetailResolverService } from './promo-code/promo-code-detail.resolver.service';
import { ViewPromoCodeComponent } from './promo-code/view-promo-code/view-promo-code.component';

import { ProvidersListComponent } from './providers/providers-list/providers-list.component';
import { ViewProviderComponent } from './providers/provider-view/provider-view.component';

import { ActiveProgramsResolver } from './hospitals/programs/active-program-resolver.service';
import { ProviderDetailResolverService } from './providers/provider-detail.resolver.service';

import { LocationsResolver } from './hospitals/locations/locations-resolver.service';

import { ProvidersPendingComponent } from './providers/providers-pending/providers-pending.component';

import { B2CUserViewComponent } from './users/b2c/view/view.component';
import { VehicleResolver } from './hospitals/vehicle-resolver.service';

// payers
import { ViewActivePlanComponent } from './payer/view-active-plan/view-active-plan.component';
import { ActivePlanDetailResolverService } from './payer/active-plan-detail-resolver.service';
import { StateResolverService } from './providers/state.resolver.service';
import { ActiveProviderDropdownResolver } from './providers/active-provider-dropdown.resolver.service';
import { PreferredProvidersComponent } from './hospitals/preferred-providers/preferred-providers.component';
import { ProviderAccessComponent } from './hospitals/provider-access/provider-access.component';
import { ProviderAccessRulesResolverService } from './hospitals/provider-access-rules-resolver.service';
import { PreferredProviderRulesResolverService } from './hospitals/preferred-provider-rules-resolver.service';
import { GeofenceResolver } from './hospitals/geofence-resolver.service';
import { ViewCarrierComponent } from './payer/view-carrier/view-carrier.component';
import { CarrierDetailResolverService } from './payer/carrier-detail-resolver.service';
import { PlanDashboardComponent } from './payer/plan-dashboard/plan-dashboard.component';
import { ViewPendingPlanComponent } from './payer/view-pending-plan/view-pending-plan.component';
import { PendingPlanDetailResolverService } from './payer/pending-plan-detail-resolver.service';
import { CarriersResolverService } from './payer/carriers-resolver.service';
import { CarrierListComponent } from './payer/carrier-list/carrier-list.component';
import { CarrierNotesResolverService } from './payer/carrier-notes-resolver.service';
import { ActivePlanNotesResolverService } from './payer/active-plan-notes-resolver.service';

// reason-types
import { ReasonTypesResolverService } from './reason-types/reason-types-resolver.service';
import { ReasonTypeDashboardComponent } from './reason-types/dashboard/reason-type.component';
import { ProviderUsersListComponent } from './providers/provider-users-list/provider-users-list.component';
import { UserTabsComponent } from './users/user-tabs/user-tabs.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { HealthComponent } from './health/health.component';

// rideshare-availability
import { RidshareAvailabilityComponent } from './rideshare-availability/rideshare-availability.component';
import { RideshareAvailabilityResolver } from './rideshare-availability/rideshare-availability-resolver.service';

//reports
import { ReportsComponent } from './reports/reports.component';
import { ReportResolver } from './reports/reports-resolver';

// customers
import { NewCustomerComponent } from './customers/new/new.component';
import { CustomerHospitalsResolver } from './customers/customer-hospital-resolver.service';
import { SelectedHospitalsResolver } from './customers/selected-hospitals-resolver.service';
import { CustomersViewComponent } from './customers/view/view.component';
import { CustomerResolver } from './customers/customer-resolver.service';
import { CustomerListResolver } from './customers/customer-list-resolver.service';

// federated identity groups
import { CustomerGroupsResolver } from './federated-identity-groups/customer-groups-resolver.service';
import { CustomerTabsComponent } from './customers/customer-tabs/customer-tabs.component';
import { EditFederatedIdentityGroupComponent } from './federated-identity-groups/edit/edit.component';
import { FederatedIdentityGroupResolver } from './federated-identity-groups/federated-identity-group-resolver.service';
import { RolesResolver } from './federated-identity-groups/roles-resolver.service';
import { FederatedIdentityGroupAvailableHospitalsResolver } from './federated-identity-groups/federated-identity-group-available-hospitals-resolver.service';
import { FederatedIdentityGroupRolesResolver } from './federated-identity-groups/federated-identity-group-roles-resolver.service';
import { FederatedIdentityGroupHospitalsResolver } from './federated-identity-groups/federated-identity-group-hospitals-resolver.service';
import { NewFederatedIdentityGroupComponent } from './federated-identity-groups/new/new.component';


const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate:  [ AuthGuard ],
    children: [
      {
        path: '',
        redirectTo: 'hospitals',
        pathMatch: 'full'
      },
      {
        path: 'customers',
        component: CustomersViewComponent,
        resolve: {
          customers: CustomerResolver
        }
      },

      {
        path: 'customers/create',
        component: NewCustomerComponent,
        resolve: {
          organizations: CustomerHospitalsResolver
        }
      },

      {
        path: 'customers/:id',
        component: CustomerTabsComponent,
        resolve: {
          customers: CustomerResolver,
          organizations: CustomerHospitalsResolver,
          customerOrganizations: SelectedHospitalsResolver,
          federatedIdentityGroups: CustomerGroupsResolver
        }
      },
      {
        path: 'customers/:customerId/federated-identity-groups/create',
        component: NewFederatedIdentityGroupComponent,
        resolve: {
          customers: CustomerResolver,
          roles: RolesResolver,
          hospitals: FederatedIdentityGroupAvailableHospitalsResolver
        }
      },
      {
        path: 'customers/:customerId/federated-identity-groups/:id',
        component: EditFederatedIdentityGroupComponent,
        resolve: {
          customers: CustomerResolver,
          federatedIdentityGroup: FederatedIdentityGroupResolver,
          roles: RolesResolver,
          hospitals: FederatedIdentityGroupAvailableHospitalsResolver,
          groupRoles: FederatedIdentityGroupRolesResolver,
          groupHospitals: FederatedIdentityGroupHospitalsResolver
        }
      },
      {
        path: 'customers',
        component: CustomersViewComponent,
        resolve: {
          customers: CustomerResolver
        }
      },
      {
        path: 'hospitals',
        component: HospitalsViewComponent,
        resolve: {
          hospitals: HospitalResolver,
          payerTypes: HospitalPayerTypesResolverService,
          vehicleTypes: VehicleResolver,
          reasonTypes: ReasonTypesResolverService
        }
      },

      {
        path: 'hospitals/:id',
        component: HospitalsEditComponent,
        resolve: {
          hospital: HospitalDetailsResolver,
          hospitals: HospitalResolver,
          locations: LocationsResolver,
          payerTypes: HospitalPayerTypesResolverService,
          vehicleTypes: VehicleResolver,
          activeVehicleTypes: VehicleTypesResolverService,
          reasonTypes: ReasonTypesResolverService,
          activeProviders: ActiveProviderDropdownResolver,
          preferredProviderRules: PreferredProviderRulesResolverService,
          providerAccessRules: ProviderAccessRulesResolverService,
          geofence: GeofenceResolver,
          hospitalTripReasonTypes: HospitalReasonTypesResolverService,
          customers: CustomerListResolver,
          brokerBypassVehicleTypes: HospitalBrokerBypassVehicleTypesResolverService,
          activePrograms: ActiveProgramsResolver,
          inactivePrograms: InactiveProgramsResolver
        }
      },

      {
        path: 'users',
        component: UserTabsComponent,
        resolve: {
          hospitals: HospitalResolver,
          providers: ActiveProviderDropdownResolver,
        }
      },

      {
        path: 'reason-types',
        component: ReasonTypeDashboardComponent,
        resolve: {
          reasonTypes: ReasonTypesResolverService
        }
      },

      {
        path: 'users/:id',
        component: EditUserComponent,
        resolve: {
          user: UserDetailsResolver,
          hospitals: HospitalResolver
        }
      },

      {
        path: 'b2c',
        component: UsersB2CComponent,
        resolve: {
          users: B2CResolver,
          hospitals: HospitalResolver
        }
      },

      {
        path: 'b2c/:id',
        component: B2CUserViewComponent,
        resolve: {
          user: UserDetailsResolver,
          hospitals: HospitalResolver
        }
      },

      {
        path: 'venues',
        component: VenuesViewComponent,
        resolve: {
          venues: VenuesResolverService
        }
      },

      {
        path: 'venues/:id',
        component: EditVenueComponent,
        resolve: {
          venues: VenuesDetailsResolverService
        }
      },

      {
        path: 'promo-codes',
        component: PromoCodeListComponent,
        resolve: {
          promoCodes: PromoCodeResolverService
        }
      },

      {
        path: 'promo-codes/:id',
        component: ViewPromoCodeComponent,
        resolve: {
          promoCode: PromoCodeDetailResolverService
        }
      },

      {
        path: 'providers',
        component: ProvidersListComponent,
        resolve: {
          state: StateResolverService
        }
      },

      {
        path: 'providers/:id/users',
        component: ProviderUsersListComponent,
        resolve: {
          provider: ProviderDetailResolverService
        }
      },

      {
        path: 'providers/:id',
        component: ViewProviderComponent,
        resolve: {
          provider: ProviderDetailResolverService,
          states: StateResolverService
        }
      },

      {
        path: 'providers-pending',
        component: ProvidersPendingComponent,
        resolve: {
          state: StateResolverService
        }
      },

      {
        path: 'payer/plans',
        component: PlanDashboardComponent,
        resolve: {
          carriers: CarriersResolverService
        }
      },

      {
        path: 'payer/active-plans/:id',
        component: ViewActivePlanComponent,
        resolve: {
          payer: ActivePlanDetailResolverService,
          notes: ActivePlanNotesResolverService
        }
      },

      {
        path: 'payer/pending-plans/:id',
        component: ViewPendingPlanComponent,
        resolve: {
          pendingPlan: PendingPlanDetailResolverService,
          carriers: CarriersResolverService
        }
      },

      {
        path: 'payer/carriers',
        component: CarrierListComponent
      },

      {
        path: 'payer/carriers/:id',
        component: ViewCarrierComponent,
        resolve: {
          payer: CarrierDetailResolverService,
          notes: CarrierNotesResolverService
        }
      },
      {
        path: 'hospital/:id/preferred-providers/:type',
        component: PreferredProvidersComponent,
        resolve: {
          providers: ActiveProviderDropdownResolver,
          hospital: HospitalDetailsResolver
        }
      },

      {
        path: 'hospital/:id/provider-access/:type',
        component: ProviderAccessComponent,
        resolve: {
          providers: ActiveProviderDropdownResolver,
          hospital: HospitalDetailsResolver
        }
      },

      {
        path: 'rideshare-availability',
        component: RidshareAvailabilityComponent,
        resolve: {
          availability: RideshareAvailabilityResolver
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        resolve: {
          reports: ReportResolver
        },
        runGuardsAndResolvers: 'always'
      },
    ]
  },
  {
    path: 'health',
    component: HealthComponent
  },

  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })
  ],

  exports: [
    RouterModule
  ],

  declarations: []
})
export class AppRoutingModule { }
