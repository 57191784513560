import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PayerService } from '../../../payer.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Note } from '../../../payer';

@Component({
  selector: 'app-update-carrier-notes-modal',
  templateUrl: './update-carrier-notes-modal.component.html',
  styleUrls: ['./update-carrier-notes-modal.component.scss']
})
export class UpdateCarrierNotesModalComponent implements OnInit {
  @Input() carrierId: string;
  @Input() note: Note;
  @Output() public onUpdate: EventEmitter<Note> = new EventEmitter();
  @Output() public onDelete: EventEmitter<number> = new EventEmitter();

  constructor(public modalRef: BsModalRef, private payerService: PayerService) { }

  ngOnInit() {
  }

  updateNote() {
    this.payerService.updateCarrierNotes(this.carrierId, this.note.id, this.note)
      .subscribe((response:any) => {
        this.onUpdate.emit(<Note>response.data);
        this.modalRef.hide();
      });
  }

  deleteNote() {
    this.payerService.deleteCarrierNotes(this.carrierId, this.note.id)
      .subscribe((response) => {
        this.onDelete.emit(this.note.id);
        this.modalRef.hide();
      });
  }
}
