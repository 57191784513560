import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { AuthHttpService } from '../../core/auth-http.service';

@Injectable()
export class EligibilityTermService {

  constructor(private http: AuthHttpService) {}

  get(): Observable<any> {
    let url = `${environment.apiv3url}/eligibility_term_types`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }
}
