import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { FederatedIdentityGroupService } from './federated-identity-group.service';

export const FederatedIdentityGroupRolesResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  federatedIdentityGroupService: FederatedIdentityGroupService = inject(FederatedIdentityGroupService)
): Observable<any> => federatedIdentityGroupService.getGroupRoles(route.params.id);