<ol class="breadcrumb">
  <li><a routerLink="/payer/plans">Plans</a></li>
  <li class="active">{{ payerName }}</li>
</ol>

<h1>{{payerName}}</h1>

<tabset #tabset>
  <tab>
    <ng-template tabHeading>
      Details
    </ng-template>

    <app-plan-details (onUpdate)="onUpdate($event)"></app-plan-details>
  </tab>

  <tab>
    <ng-template tabHeading>
      Plan Aliases
    </ng-template>

    <app-plan-aliases [carrierPlanId]="payerId"></app-plan-aliases>
  </tab>

  <tab>
    <ng-template tabHeading>
      Notes
    </ng-template>

    <app-plan-notes [activePlanId]="payerId" [notes]="notes"></app-plan-notes>
  </tab>
</tabset>
