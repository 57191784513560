<div *ngIf="!isEditing" class="btn-group pull-right action-dropdown" dropdown>
  <button dropdownToggle class="btn btn-link dropdown-toggle">
    <span>Actions</span>
    <app-icon icon="more-vertical" iconClass="action-icon"></app-icon>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu">
    <li><a class="dropdown-item blue-text" (click)="toggleEdit()">Edit</a></li>
  </ul>
</div>

<h3>
  General Info
</h3>

<div *ngIf="!isEditing">
  <div>
    <table class="table table-striped">
      <tbody>
        <tr *ngFor="let element of formFields">
          <td class="view-column" width="250px"><strong>{{element.label}}</strong></td>
          <td class="view-column">{{mapValue(element.name)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="isEditing">
  <div class="row">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td class="column" width="250px"><strong>Name</strong></td>
            <td class="column">
                <input type='text'
                       class="form-control"
                       placeholder={{carrier.name}}
                       id="name"
                       [(ngModel)]="carrierName"
                >
            </td>
          </tr>
        </tbody>
      </table>
  </div>

  <div class="row">
    <button type="button"
            class="btn btn-default btn-wrap pull-left button-margin"
            (click)="toggleEdit()">
      Close
    </button>
    <button type="button"
            class="btn btn-primary btn-wrap pull-left button-margin"
            (click)="updateCarrier()">
      Save Changes
    </button>
  </div>
</div>

<app-associated-plans-table [carrierId]="carrier.id"></app-associated-plans-table>
