import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Option } from './dropdown';


@Component({
  selector: 'dropdown',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true
  }],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() options: Option[] = [];
  @Input() currentValue: string;

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  get selected() {
    return this.currentValue;
  }

  set selected(value) {
    this.currentValue = value;
    this.onChange(value);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.currentValue = value;
    }
  }
}
