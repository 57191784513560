import {
  Component,
  OnInit,
  ElementRef,
  Input
} from '@angular/core';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input('icon') icon: string;
  @Input('iconClass') iconClass: string;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    if (this.icon) {
      this.element.nativeElement.innerHTML = feather.icons[this.icon].toSvg({ class: this.iconClass });
    }
  }
}
