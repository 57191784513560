<div *ngIf="!isEdit" class="btn-group pull-right action-dropdown" dropdown>
  <button dropdownToggle class="btn btn-link dropdown-toggle">
    <span>Actions</span>
    <app-icon icon="more-vertical" iconClass="action-icon"></app-icon>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu">
    <li><a class="dropdown-item blue-text" (click)="toggleEdit()">Edit plan</a></li>
  </ul>
</div>

<div *ngIf="!isEdit">
  <div *ngFor="let field of fields">
    <table class="table" *ngIf="!isHidden(field.label)">
      <tbody>
        <h2>{{payerKeys[field.label]}}</h2>
        <tr *ngFor="let element of field.value">
          <td class="view-column" *ngIf="!isHidden(element.name, element.viewType)"><strong>{{element.label}}</strong></td>
          <ng-container [ngSwitch]="element.viewType" *ngIf="!isHidden(element.name, element.viewType)">
            <td *ngSwitchCase="'boolean'" class="view-column">{{displayValue(element.name, element.viewType)}}</td>

            <td *ngSwitchCase="'columnar'" class="view-column">
              <app-columnar-display
                [data]="displayValue(element.name, element.viewType)"
                defaultDisplayValue="-"
              ></app-columnar-display>
            </td>

            <td *ngSwitchCase="'multi-select'" class="view-column">{{displayValue(element.name, element.viewType)}}</td>
            <td *ngSwitchCase="'comma-separated'" class="view-column">{{displayValue(element.name, element.viewType)}}</td>
            <td *ngSwitchCase="'default'" class="view-column">{{displayValue(element.name, element.viewType)}}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="isEdit">
  <div class="row">
    <form class="form" [formGroup]="payerForm" novalidate>
      <ng-container *ngFor="let field of fields">
        <table class="table edit-table" *ngIf="!isHidden(field.label)">
          <tbody>
            <h2 class="edit-heading">{{payerKeys[field.label]}}</h2>
            <ng-container *ngFor="let element of field.value">
              <tr class="edit-row" *ngIf="!isHidden(element.name, element.editType)">
                <td class="edit-column"><strong>{{element.label}}</strong></td>
                <td class="edit-column">
                  <ng-container [ngSwitch]="element.editType">
                    <input *ngSwitchCase="'text'"
                          type={{element.editType}}
                          class="form-control"
                          formControlName={{element.name}}
                          placeholder={{element.label}}>

                    <input *ngSwitchCase="'number'"
                          type={{element.editType}}
                          class="form-control"
                          formControlName={{element.name}}
                          placeholder="In Days">

                    <ui-switch *ngSwitchCase="'ui-switch'"
                              formControlName={{element.name}}
                              (change)="onChange(element.name, $event)">
                    </ui-switch>

                    <angular2-multiselect *ngSwitchCase="'multi-select'"
                                          formControlName={{element.name}}
                                          [data]="element.options"
                                          [settings]="{tagToBody: false}">
                      <c-item>
                        <ng-template let-item="item">
                          <label [ngClass]="{'disabled-option': item['required']}">{{item.itemName}}</label>
                        </ng-template>
                      </c-item>
                    </angular2-multiselect>
                    <div>
                      <div class="form-error" *ngIf="payerForm.get(element.name).errors?.serverError">
                        <p>{{ payerForm.get(element.name).errors?.serverError }}</p>
                      </div>
                    </div>
                    <div *ngIf="element.name == 'authorization_contact'">
                      <div class="form-error" *ngIf="payerForm.get('authorization_contact').touched">
                        <p *ngIf="payerForm.get('authorization_contact').errors?.pattern">Contact number should be in +15555555555 format.</p>
                        <p *ngIf="!payerForm.get('authorization_contact').errors?.pattern && (payerForm.get('authorization_contact').errors?.minlength || payerForm.get('authorization_contact').errors?.maxlength)">
                          Invalid length of phone number.
                        </p>
                      </div>
                    </div>

                    <div *ngIf="element.name == 'broker_phone'">
                      <div class="form-error" *ngIf="payerForm.get('broker_phone').touched">
                        <p *ngIf="payerForm.get('broker_phone').errors?.pattern">Contact number should be in +15555555555 format.</p>
                        <p *ngIf="!payerForm.get('broker_phone').errors?.pattern && (payerForm.get('broker_phone').errors?.minlength || payerForm.get('broker_phone').errors?.maxlength)">
                          Invalid length of phone number.
                        </p>
                      </div>
                    </div>

                    <div *ngIf="element.name == 'fax_number'">
                      <div class="form-error" *ngIf="payerForm.get('fax_number').touched">
                        <p *ngIf="payerForm.get('fax_number').errors?.pattern">Fax number should be in +15555555555 format.</p>
                        <p *ngIf="!payerForm.get('fax_number').errors?.pattern && (payerForm.get('fax_number').errors?.minlength || payerForm.get('fax_number').errors?.maxlength)">
                          Invalid length of fax number.
                        </p>
                      </div>
                    </div>

                    <div *ngIf="element.name == 'brokers_advance_notice_in_days'">
                      <div class="form-error" *ngIf="payerForm.get('brokers_advance_notice_in_days').errors?.min && payerForm.get('brokers_advance_notice_in_days').touched">
                        <p>Brokers Advance Notice days cannot be negative</p>
                      </div>
                    </div>

                    <div *ngIf="element.name == 'post_transport_auth_window_in_days'">
                      <div class="form-error" *ngIf="payerForm.get('post_transport_auth_window_in_days').errors?.min && payerForm.get('post_transport_auth_window_in_days').touched">
                        <p>Post Transport Auth Window days cannot be negative</p>
                      </div>
                    </div>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </form>
  </div>

  <button type="button"
          class="btn btn-default btn-wrap pull-left button-margin"
          (click)="toggleEdit()">
    Close
  </button>
  <button type="button"
          class="btn btn-primary btn-wrap pull-left button-margin"
          [disabled]="payerForm.invalid"
          (click)="updatePayer()">
    Save changes
  </button>
</div>
