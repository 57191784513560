import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import { User, ROLES } from '../user';
import { Hospital } from '../../hospitals/hospital';
import { UserService } from '../user.service';
import { Provider } from '../../providers/provider';
import { ProviderService } from '../../providers/providers.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { FlashService } from '../../components/flash/flash.service';

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.scss']
})
export class NewUserModalComponent implements OnInit {
  user: User = this.blankUser();
  hospitals: Hospital[];
  activeProviders: Provider[];
  availableRoles: any[];
  added = false;
  data = [];
  providerOptions;
  transportation_company_name: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedRoles: string[] = [];

  constructor(
    public modalRef: BsModalRef,
    private userService: UserService,
    private providerService: ProviderService,
    private flashService: FlashService,
    private auth: AuthService
  ) {
  }

  addUser() {
    if ((this.determineRole('care_coordinator') || this.determineRole('admin') || this.determineRole('super_user')) && this.user.hospital_id === undefined){
      this.showFlashMessage("danger", "<strong>Oops!</strong> Organization value can't be blank.Please select an organization.")
    } else if (this.determineRole('dispatcher') && this.user.transportation_company_id === undefined){
      this.showFlashMessage("danger", "<strong>Oops!</strong> Transportation company name can't be blank. Please enter a transportation company.")
    }else if(this.selectedRoles.length === 0){
      this.showFlashMessage("danger", "<strong>Oops!</strong> Role can't be empty. Please select a role.")
    }else{
      this.userService.create(this.user, this.selectedRoles).subscribe();
      this.added = true;
    }
    this.modalRef.hide();
  }
  
  showFlashMessage(type: string, message: string) {
    this.flashService.add({
      type: type,
      message: message
    });
  }

  ngOnInit() {
    this.providerService.dropdownActive().subscribe(({ data }) => {
      this.activeProviders = data;
    });
    
    if (environment.adminCreation != 'EVERYONE' && this.auth.user$) {
      const authorizedUsers = environment.adminCreation.split(',');
      this.auth.user$.subscribe(user => {
        if (!user || !user.email || !authorizedUsers.includes(user.email.toLowerCase())) {
          this.availableRoles = ROLES.filter(r => r.id != 'admin');
        }
        else{
          this.availableRoles = ROLES;
        }
        this.dropdownList = this.availableRoles;
      })
    } else{
      this.availableRoles = ROLES;
      this.dropdownList = this.availableRoles;
    }
    
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select Roles",
      labelKey: "name",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      classes: "myclass custom-class",
      autoPosition: false,
      tagToBody: false
    };           
  }

  onItemSelect(selectedRole:any){
    this.selectedRoles.push(selectedRole.id)
  }

  OnItemDeSelect(selectedRole:any){
      this.selectedRoles = this.selectedRoles.filter((item) => item !== selectedRole.id);
  }

  onSelectAll(items: any){
      this.selectedRoles = [];
      items.map((item) => this.selectedRoles.push(item.id))
  }

  onDeSelectAll(items: any){
      this.selectedRoles = [];
  }

  onProviderSelect(event) {
    this.user.transportation_company_id = event.item.id
    this.transportation_company_name = event.item.name;
  }

  removeProvider(providerId, index) {
    _.remove(this.data[index].transportation_company_ids, function(id){
      return id === providerId;
    });
  }

  isDispatcher() {
    return this.determineRole('dispatcher');
  }

  isCareCoordinator() {
    return this.determineRole('care_coordinator') || this.determineRole('super_user');
  }

  isNavCenterAgent() {
    return this.determineRole('admin');
  }

  determineRole(roleToCheck: string){
    return this.selectedRoles.filter((item) => item === roleToCheck).length > 0;
  }

  private blankUser(): User {
    return <User>{
      first_name: '',
      last_name: '',
      email: '',
      emr_user_id: '',
      hospital_id: 1,
      show_patient_rides: true,
      show_facility_rides: true,
      limited_access: false,
      password: 'RoundTrip1!',
      time_zone: 'Eastern Time (US & Canada)',
      domestic_user: false,
    };
  }
}
