import { Component, OnInit } from '@angular/core';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import { HttpClient } from '@angular/common/http';
import { CustomPayerRenderComponent } from '../custom-payer-render/custom-payer-render.component';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { MONTHS_LIST } from '../payer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pending-plan-list',
  templateUrl: './pending-plan-list.component.html',
  styleUrls: [
    './pending-plan-list.component.scss',
    '../../../themes/ng2-smart-table.scss'
  ]
})
export class PendingPlanListComponent implements OnInit {
  list: AdminServerDataSource;

  tableConfig = {
    columns: {
      carrier_name: {
        title: 'Carrier name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
      },
      plan_name: {
        title: 'Plan name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
      },
      organization: {
        title: 'Organization',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
        sort: false
      },
      created_at: {
        title: 'Created on',
        filter: {
          type: 'list',
          config: {
            list: MONTHS_LIST
          }
        }
      }
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    pager: {
      perPage: 15
    },
    noDataMessage: ''
  };

  constructor(protected http: HttpClient, private router: Router) { }

  ngOnInit() {
    if(!this.list){
      this.list = new AdminServerDataSource(this.http, {
        endPoint: `${environment.apiurl}/insurance_plans/pending`,
        formatDataFunction: this.formatPendingPayerData
      });
    }
  }

  formatPendingPayerData = (payers) => {
    payers.forEach(payer => {
      payer['created_at'] = moment(payer['created_at']).format('LL');
    });
  }

  onRowSelect(event) {
    this.router.navigate(['/payer/pending-plans', event.data.id]);
  }
}
