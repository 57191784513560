<div class="modal-header">
  <h4 class="modal-title pull-left">
    Add plan
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal" #newPlanForm="ngForm">
    <div class="form-group">
      <label for="name" class="col-sm-3 control-label">Plan name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="activePlan.name" #name="ngModel" required>
        <div class="form-error" *ngIf="name.invalid && name.touched">
          <p *ngIf="name.errors?.required">This field is required.</p>
        </div>
        <div class="form-error" *ngIf="name.errors?.serverError">
          <p>{{ name.errors.serverError }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="carrier_id" class="col-sm-3 control-label">Carrier name</label>
      <div class="col-sm-9">
        <select class="form-control" id="carrier_id" name="carrier_id" [(ngModel)]="activePlan.carrier_id" #carrierId="ngModel" required>
          <option *ngFor="let carrier of carrierOptions" [value]="carrier.id">
            {{ carrier.name }}
          </option>
        </select>
        <div class="form-error" *ngIf="carrierId.invalid && carrierId.touched">
          <p *ngIf="carrierId.errors?.required">This field is required.</p>
        </div>
        <div class="form-error" *ngIf="carrierId.errors?.serverError">
          <p>{{ carrierId.errors.serverError }}</p>
        </div>
        <p><a (click)="openNewCarrierModal()">Add new carrier</a></p>
      </div>
    </div>

    <div class="form-group">
      <label for="authorization_contact" class="col-sm-3 control-label">Phone number</label>
      <div class="col-sm-9">
        <input type="text"
               class="form-control"
               id="authorization_contact"
               name="authorization_contact"
               pattern="[1-9]{1}[0-9]{9}"
               required
               #phoneNumber="ngModel"
               [(ngModel)]="activePlan.authorization_contact">
        <div class="form-error" *ngIf="phoneNumber.invalid && phoneNumber.touched">
          <p *ngIf="phoneNumber.errors.required">This field is required.</p>
          <p *ngIf="phoneNumber.errors.pattern">Phone number is invalid.</p>
        </div>
        <div class="form-error" *ngIf="phoneNumber.errors?.serverError">
          <p>{{ phoneNumber.errors.serverError }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="insurance_categories" class="col-sm-3 control-label">Insurance category</label>
      <div class="col-sm-9">
        <angular2-multiselect
          id="insurance_categories"
          name="insurance_categories"
          [data]="insuranceCategoryOptions"
          [(ngModel)]="activePlan.insurance_categories"
          [ngModelOptions]="{standalone: true}"
          [settings]="{tagToBody: false}"
        >
          <c-item>
            <ng-template let-item="item">
              <label>{{item.itemName}}</label>
            </ng-template>
          </c-item>
        </angular2-multiselect>
      </div>
    </div>

    <div class="form-group">
      <label for="covered_vehicles" class="col-sm-3 control-label">Covered vehicle types</label>
      <div class="col-sm-9">
        <angular2-multiselect
          id="covered_vehicles"
          name="covered_vehicles"
          [data]="vehicleTypeOptions"
          [(ngModel)]="activePlan.covered_vehicles"
          [ngModelOptions]="{standalone: true}"
          [settings]="{tagToBody: false}"
        >
          <c-item>
            <ng-template let-item="item">
              <label>{{item.itemName}}</label>
            </ng-template>
          </c-item>
        </angular2-multiselect>
      </div>
    </div>

    <div class="form-group">
      <label for="auth_required" class="col-sm-3 control-label">Authorization Required</label>
      <div class="col-sm-9">
        <ui-switch
          id="auth_required"
          name="auth_required"
          [(ngModel)]="activePlan.auth_required">
        </ui-switch>
      </div>
    </div>

    <div class="form-group">
      <label for="brokered_transport" class="col-sm-3 control-label">Brokered transport</label>
      <div class="col-sm-9">
        <ui-switch
          id="brokered_transport"
          name="brokered_transport"
          [(ngModel)]="activePlan.brokered_transport">
        </ui-switch>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" [disabled]="!newPlanForm.form.valid" (click)="save()">Save</button>
</div>
