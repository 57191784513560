<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="user-plus" iconClass="button-icon"></app-icon> Add User
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal">
    <div class="form-group">
      <label for="first_name" class="col-sm-3 control-label">First Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="first_name" name="first_name" placeholder="First Name" [(ngModel)]="user.first_name">
      </div>
    </div>

    <div class="form-group">
      <label for="last_name" class="col-sm-3 control-label">Last Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="last_name" name="last_name" placeholder="Last Name" [(ngModel)]="user.last_name">
      </div>
    </div>

    <div class="form-group">
      <label for="email" class="col-sm-3 control-label">Email</label>
      <div class="col-sm-9">
        <input type="email" class="form-control" id="email" name="email" placeholder="Email" [(ngModel)]="user.email">
      </div>
    </div>

    <div class="form-group">
      <label for="phone" class="col-sm-3 control-label">Phone</label>
      <div class="col-sm-9">
        <input type="text"
               class="form-control"
               id="phone"
               name="phone"
               placeholder="Phone"
               pattern="[1-9]{1}[0-9]{9}"
               [(ngModel)]="user.phone">
          <div class="form-error" *ngIf="user.phone && user.phone.length !== 10">
            <p>Phone number should be 10 digits.</p>
          </div>
      </div>
    </div>

    <div class="form-group">
      <label for="timezone" class="col-sm-3 control-label">Time Zone</label>
      <div class="col-sm-9">
        <timezone-dropdown id="timezone" name="timezone"
                           [(ngModel)]="user.time_zone">
        </timezone-dropdown>
      </div>
    </div>
    <div class="form-group">
      <label for="role" class="col-sm-3 control-label">Role</label>
      <div class="col-sm-9">
        <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" name="roles"
                              [settings]="dropdownSettings" 
                              (onSelect)="onItemSelect($event)" 
                              (onDeSelect)="OnItemDeSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
        </angular2-multiselect>
        <!-- <div class="form-error" *ngIf="newHospitalForm.get('enabled_vehicles').dirty && newHospitalForm.get('enabled_vehicles').invalid">
          <p>Please select at least one option.</p>
        </div> -->
      </div>
    </div>
    <div *ngIf="isCareCoordinator()" class="form-group">
      <label for="emr_user_id" class="col-sm-3 control-label">EMR User ID</label>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control"
          id="emr_user_id"
          name="emr_user_id"
          placeholder="EMR User ID"
          [(ngModel)]="user.emr_user_id">
      </div>
    </div>
    <div *ngIf="isCareCoordinator() || isNavCenterAgent()" class="form-group">
      <label for="hospital_id" class="col-sm-3 control-label">Organization</label>
      <div class="col-sm-9">
        <select class="form-control" id="hospital_id" name="hospital_id" [(ngModel)]="user.hospital_id">
          <option *ngFor="let hospital of hospitals" [value]="hospital.id">
            {{hospital.name}}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="isNavCenterAgent()" class="form-group">
      <label for="domestic_user" class="col-sm-3 control-label">Domestic User?</label>
      <div class="col-sm-9">
        <ui-switch
          id="domestic_user"
          name="domestic_user"
          [(ngModel)]="user.domestic_user">
        </ui-switch>
      </div>
    </div>
    <div *ngIf="isCareCoordinator()" class="form-group">
      <label for="show_patient_rides" class="col-sm-3 control-label">Show Patient Rides?</label>
      <div class="col-sm-9">
        <ui-switch
          id="show_patient_rides"
          name="show_patient_rides"
          [(ngModel)]="user.show_patient_rides">
        </ui-switch>
        <p class="help-block">Should the Care Coordinator see <strong>all</strong> rides booked for the patient across tier 2 organizations.</p>
      </div>
    </div>
    <div *ngIf="isCareCoordinator()" class="form-group">
      <label for="show_facility_rides" class="col-sm-3 control-label">Show Facility Rides?</label>
      <div class="col-sm-9">
        <ui-switch
          id="show_facility_rides"
          name="show_facility_rides"
          [(ngModel)]="user.show_facility_rides">
        </ui-switch>
        <p class="help-block">Should the Care Coordinator see <strong>all</strong> rides booked for the facility they are currently assigned to?</p>
      </div>
    </div>
    <div *ngIf="isCareCoordinator()" class="form-group">
      <label for="limited_access" class="col-sm-3 control-label">Limited Access?</label>
      <div class="col-sm-9">
        <ui-switch
          id="limited_access"
          name="limited_access"
          [(ngModel)]="user.limited_access">
        </ui-switch>
      </div>
    </div>
    <div *ngIf="isDispatcher()" class="form-group">
      <label for="transportation_company_id" class="col-sm-3 control-label">Transportation Company</label>
      <div class="col-sm-9">
        <input
        name="transportation_company_id"
        type="text"
        class="form-control"
        [(ngModel)]="transportation_company_name"
        [typeahead]="activeProviders"
        typeaheadOptionField="name"
        (typeaheadOnSelect)="onProviderSelect($event)"
        placeholder="Search by transportation company name"
        #selectProviders="ngModel" />
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="addUser()">Save Changes</button>
</div>
