<app-loader *ngIf="loading"></app-loader>

<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="users"
      (userRowSelect)="onRowSelect($event)">
    </ng2-smart-table>
  </div>
</div>
