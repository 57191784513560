<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Organizations</li>

  <li>
    <a href="#" (click)="openNewHospitalModal()">
      <app-icon icon="plus" iconClass="button-icon"></app-icon> Add Organization
    </a>
  </li>
</ol>

<div class="row">
  <div class="col-xs-12">
    <p-table *ngIf="isFeatureEnabled; else noFeature"
    [value]="hospitals"
    selectionMode="single"
    [(selection)]="selectedHospital" 
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="null" 
    [paginator]="true"
    [rows]="10"
    (onRowSelect)="onRowSelect($event)" 
    stateStorage="session" 
    stateKey="state-session"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngFor="let key of keys" style="width:22%">
          <p-columnFilter 
          type="text" 
          field="{{key}}" 
          placeholder="{{tableConfig.columns[key].title}}" 
          ariaLabel="Filter {{tableConfig.columns[key].title}}" 
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-hospital>
      <tr [pSelectableRow]="hospital">
        <td *ngFor="let key of keys">{{ hospital[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template #noFeature>
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="hospitals"
      (userRowSelect)="onRowSelect($event)">
    </ng2-smart-table>
  </ng-template>
</div>
</div>
