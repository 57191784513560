import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { HospitalService } from '../hospital.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRules } from '../models/access-rules';
import { FlashService } from '../../components/flash/flash.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-provider-access',
  templateUrl: './provider-access.component.html',
  styleUrls: ['./provider-access.component.scss']
})
export class ProviderAccessComponent implements OnInit {
  strategyOptions = AccessRules.strategies;
  modelAttributeOptions = AccessRules.modelAttributes;
  modelAttributesMap = AccessRules.modelAttributesMap;
  allowedValuesMap = AccessRules.allowedValuesMap;
  valueOptions = AccessRules.allowedValues;
  data = [];
  hospitalId;
  hospitalName;
  type;
  providerOptions;
  accessStrategy;
  canEditStrategy;
  providers;
  uniqueRules = true;
  submitted = false;

  constructor(
    private hospitalService: HospitalService,
    private route: ActivatedRoute,
    private flashService: FlashService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.hospitalId = data.id;
      this.type = data.type;
    });

    this.route.data.subscribe((data) => {
      this.providerOptions = data.providers.data;
      this.hospitalName = data.hospital.data.hospital.name;
      this.providers = _.zipObject(
        _.map(this.providerOptions, 'id'),
        _.map(this.providerOptions, 'name')
      );
    });

    if (_.map(this.strategyOptions, 'id').indexOf(this.type) !== -1) {
      this.accessStrategy = this.type;
      this.canEditStrategy = false;
    } else {
      this.accessStrategy = null;
      this.canEditStrategy = true;
    }
    this.hospitalService
      .getProviderAccessRules(this.hospitalId)
      .subscribe((response: any) => {
        const allRules = AccessRules.formatProviderAccessRules(response.data);
        this.restrictStrategies(Object.keys(allRules));
        this.data = allRules[this.type] || [this.getBlankModel()];
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].dataSource = this.getTypeaheaDataSource(this.data[i]);
        }
      });
  }

  getTypeaheaDataSource(rule) {
    return new Observable((observer: any) => {
      observer.next(rule.selectedProviderTemp);
    }).pipe(mergeMap((token: string) => this.getProvidersAsObservable(rule, token)));
  }

  getProvidersAsObservable(rule: any, token: string): Observable<any> {
    const query = new RegExp(
      token.replace(/\\/g, '\\\\'),
      'ig'
    );
    const matchingResults = this.providerOptions.filter((provider) => {
      return query.test(provider.name);
    });
    const availableOptions = matchingResults.filter(
      (result) => !(rule.transportation_company_ids.includes(result.id))
    );
    return of(availableOptions);
  }

  save() {
    this.submitted = true;
    this.uniqueRules = this.areRulesUnique();
    if (!this.areFieldsValid() || !this.uniqueRules) {
      return;
    }
    const payload = AccessRules.generatePayload({
      strategy: this.accessStrategy,
      ruleset_data: this.data
    });

    this.hospitalService
      .saveProviderAccessRules(this.hospitalId, payload)
      .subscribe(response => {
        this.flashService.add({
          type: 'success',
          message: 'Access Rules have been updated successfully.'
        });
        this.router.navigate(['hospitals', this.hospitalId], {queryParams: {selectTab: 5}});
      });
  }

  cancel() {
    this.router.navigate(['hospitals', this.hospitalId], {queryParams: {selectTab: 5}});
  }

  restrictStrategies(allowedStrategies) {
    if (this.canEditStrategy) {
      this.strategyOptions = this.strategyOptions.filter(x => !allowedStrategies.includes(x.id));
    }
  }

  areFieldsValid() {
    if (!this.accessStrategy) {
      return false;
    }

    for (const row of this.data) {
      if (!row['model_attribute'] || !row['value'] || row['transportation_company_ids'].length === 0) {
        return false;
      }
    }

    return true;
  }

  areRulesUnique() {
    const rules = this.evaluateDuplicateRulesMap();
    return _.values(rules).indexOf(true) === -1;
  }

  hasUniquenessError(attribute, value) {
    if (!attribute || !value) {
      return false;
    }
    const key = `${attribute}-${value}`;
    const rules = this.evaluateDuplicateRulesMap();
    this.uniqueRules = !rules[key];

    return rules[key];
  }

  evaluateDuplicateRulesMap() {
    const rules = {};

    for (const rule of this.data) {
      if (rule['model_attribute'] && rule['value']) {
        const identifier = `${rule['model_attribute']}-${rule['value']}`;
        rules[identifier] = (identifier in rules);
      }
    }

    return rules;
  }

  addAttributeToList() {
    this.data.push(this.getBlankModel());
    const lastIndex = this.data.length - 1;
    this.data[lastIndex].dataSource = this.getTypeaheaDataSource(this.data[lastIndex]);
  }

  removeAttributeFromList(event, index: number) {
    event.stopPropagation();
    this.data.splice(index, 1);
  }

  onProviderSelect(event, index) {
    this.data[index].transportation_company_ids.push(event.item.id);
    this.data[index].selectedProviderTemp = '';
  }

  removeProvider(providerId, index) {
    _.remove(this.data[index].transportation_company_ids, function(id){
      return id === providerId;
    });
  }

  getBlankModel() {
    return {
      model_attribute: '',
      value: '',
      transportation_company_ids: [],
      collapsed: false,
      selectedProviderTemp: '',
      dataSource: null
    };
  }
}
