<div *ngIf="!editingBenefits">
  <table class="table table-striped">
    <tbody>
      <tr>
        <th class="width-250">Eligibility Term Type</th>
        <td>
          {{findEligibilityTermType(hospital.eligibility_term_type_id)}}
        </td>
      </tr> 
      <tr>
        <th class="width-250">Apply Ride Limit</th>
        <td>
          <ui-switch 
          [(ngModel)]="hospital.patient_rides_limit_enabled"
          disabled="true"></ui-switch>
        </td>
      </tr> 
      <tr>
        <th>Ride Limit </th>
        <td>{{hospital.default_patient_rides_limit}}</td>
      </tr> 
      <tr>
          <th>Apply Budget Limit</th>
          <td>
            <ui-switch 
            [(ngModel)]="hospital.patient_budget_enabled"
            disabled="true"></ui-switch>
          </td>
      </tr> 
      <tr>
        <th>Budget Limit (dollars)</th>
        <td>{{hospital.default_patient_budget}}</td>
      </tr> 
      <tr>
          <th>Apply Maximum Trip Cost</th>
          <td>
            <ui-switch 
            [(ngModel)]="hospital.max_trip_cost_enabled"
            disabled="true"></ui-switch>
          </td>
      </tr>
      <tr>
          <th>Maximum Trip Cost (dollars)</th>
          <td>{{hospital.max_trip_cost}}</td>
      </tr>
      <tr>
        <th>Show Long Ride Alert</th>
        <td>{{longRideThresholdMap[hospital.show_long_ride_alert]}}</td>
      </tr>  
      <tr>
          <th>Long Ride Threshold (miles)</th>
          <td>{{hospital.long_ride_threshold_in_miles}}</td>
      </tr>
      <tr>
        <th>Ride Share Risk Surcharge (dollars)</th>
        <td>{{hospital.risk_surcharge}}</td>
    </tr>
    <tr>
      <th>Enable Advanced Booking Threshold</th>
      <td>
        <ui-switch 
        [(ngModel)]="hospital.advanced_booking_threshold_enabled"
        disabled="true"></ui-switch>
      </td>
  </tr> 
  <tr>
      <th>Advanced Booking Threshold (hours)</th>
      <td>{{hospital.advanced_booking_threshold}}</td>
  </tr>
    </tbody>
  </table>
  <div class="text-left">
      <button class="btn btn-primary" (click)="editBenefits()">Edit</button>
  </div>
</div>
<div *ngIf="editingBenefits" class="row">
  <form [formGroup]="benefitManagementForm">
    <table class="table table-striped">
      <tbody>
        <tr>
          <th class="width-250">Eligibility Term Type</th>
          <td>
            <select class="form-control" formControlName="eligibility_term_type_id">
              <option *ngFor="let type of eligibilityTermTypes" [value]="type.id">
                {{type.description}}
              </option>
            </select>
          </td>
        </tr> 
        <tr>
          <th class="width-250">Apply Ride Limit</th>
          <td>
            <ui-switch id="patient_rides_limit_enabled"
            name="patient_rides_limit_enabled"
            formControlName="patient_rides_limit_enabled"
            ></ui-switch>
          </td>
        </tr> 
        <tr>
            <th>Ride Limit</th>
            <td>
                <input type="number" class="form-control" formControlName="default_patient_rides_limit" placeholder="Ride Limit">
                <div class="form-error"
                    *ngIf="benefitManagementForm.get('default_patient_rides_limit') && benefitManagementForm.get('default_patient_rides_limit').invalid && benefitManagementForm.get('default_patient_rides_limit').touched">
                  <p>Ride Limit should be greater than zero.</p>
                </div>
            </td>
        </tr> 
        <tr>
            <th class="width-20">Apply Budget Limit</th>
            <td>
                <ui-switch id="patient_budget_enabled"
                name="patient_budget_enabled"
                formControlName="patient_budget_enabled"
                ></ui-switch>
            </td>
        </tr> 
        <tr>
            <th class="width-20">Budget Limit (dollars)</th>
          <td>
              <input type="number" class="form-control" formControlName="default_patient_budget" placeholder="Base Budget Limit">
              <div class="form-error"
                    *ngIf="benefitManagementForm.get('default_patient_budget') && benefitManagementForm.get('default_patient_budget').invalid && benefitManagementForm.get('default_patient_budget').touched">
                <p>Budget limit can not be empty or zero</p>
              </div>
          </td>
        </tr> 
        <tr>
            <th class="width-20">Apply Maximum Trip Cost</th>
            <td>
              <ui-switch id="max_trip_cost_enabled"
              name="max_trip_cost_enabled"
              formControlName="max_trip_cost_enabled"
              ></ui-switch>
            </td>
        </tr>
        <tr>
            <th class="width-20">Maximum Trip Cost (dollars)</th>
            <td>
              <input type="number" class="form-control" formControlName="max_trip_cost" placeholder="Max Trip Cost">
              <div class="form-error"
                    *ngIf="benefitManagementForm.get('max_trip_cost') && benefitManagementForm.get('max_trip_cost').invalid && benefitManagementForm.get('max_trip_cost').touched">
                <p>Max Trip Cost can not be empty.</p>
              </div>
            </td>
        </tr>  
        <tr>
          <th class="width-20">Show Long Ride Alert?</th>
          <td>
              <select class="form-control" formControlName="show_long_ride_alert">
                <option *ngFor="let type of longRideThresholdOptions" [value]="type.id">
                  {{type.name}}
                </option>
              </select>
            </td>
          </tr> 
          <tr>
            <th class="width-20">Long Ride Threshold (miles)</th>
          <td>
              <input type="number" class="form-control" formControlName="long_ride_threshold_in_miles" placeholder="Ride Threshold">
              <div class="form-error"
                    *ngIf="benefitManagementForm.get('long_ride_threshold_in_miles') && benefitManagementForm.get('long_ride_threshold_in_miles').invalid && benefitManagementForm.get('long_ride_threshold_in_miles').touched">
                <p>Ride threshold can not be empty,</p>
              </div>
          </td>
        </tr>
        <tr>
          <th class="width-20">Ride Share Risk Surcharge (dollars)</th>
        <td>
            <input type="number" class="form-control" formControlName="risk_surcharge" placeholder="Risk Surcharge">
            <div class="form-error"
                  *ngIf="benefitManagementForm.get('risk_surcharge') && benefitManagementForm.get('risk_surcharge').invalid && benefitManagementForm.get('risk_surcharge').touched">
              <p>Risk surcharge can not be empty,</p>
            </div>
        </td>
      </tr>
      <tr>
        <th class="width-20">Enable Advanced Booking Threshold</th>
        <td>
          <ui-switch id="advanced_booking_threshold_enabled"
          name="advanced_booking_threshold_enabled"
          formControlName="advanced_booking_threshold_enabled"
          ></ui-switch>
        </td>
    </tr>
    <tr>
        <th class="width-20">Advanced Booking Threshold (hours)</th>
        <td>
          <input type="number" class="form-control" formControlName="advanced_booking_threshold" placeholder="Advanced Booking Threshold">
          <div class="form-error"
                *ngIf="benefitManagementForm.get('advanced_booking_threshold') && benefitManagementForm.get('advanced_booking_threshold').invalid && benefitManagementForm.get('advanced_booking_threshold').touched">
            <p>Advanced Booking Threshold can not be empty.</p>
          </div>
        </td>
    </tr>  
      </tbody>
    </table>
  </form>
  <div class="action-wrap text-left">
    <button type="button" class="btn btn-default" (click)="cancelEditing()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!benefitManagementForm.valid" (click)="update()">Update</button>
  </div>
</div>

