import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Provider } from './provider';
import { Observable } from 'rxjs';
import { ProviderService } from './providers.service';

export const ProviderDetailResolverService: ResolveFn<Provider> = (
    route: ActivatedRouteSnapshot
  ): Observable<Provider> => {
    const providerService: ProviderService = inject(ProviderService);
    return providerService.get(route.params.id);
  }
