import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Hospital } from '../hospital';
import { HospitalService } from '../hospital.service';
import { FlashService } from '../../components/flash/flash.service';

@Component({
  selector: 'app-tiger-connect-integration',
  templateUrl: './tiger-connect-integration.component.html',
  styleUrls: ['./tiger-connect-integration.component.scss']
})
export class TigerConnectIntegrationComponent implements OnInit {
  showFields: boolean
  tigerConnectInfoForm: FormGroup;
  isEdit = false;
  @Input() hospital: Hospital;

  constructor(
    private hospitalService: HospitalService,
    private flashService: FlashService,
  ) { }

  ngOnInit() {
    this.generateFormControls();
    this.showFields = this.hospital.tiger_connect_active;
  }

  update() {
    this.hospitalService.updateTigerConnectInfo(this.hospital.id, this.tigerConnectInfoForm.value).subscribe(
      (response) => {
      this.hospital = response.data;
      this.flashService.add({
        type: 'success',
        message: '<strong>Success!</strong> TigerConnect info has been updated.'
      });
      this.toggleEdit();
    },
    (error) => {
      this.toggleEdit();
    });
  }

  generateFormControls() {
    const formControls = {};
    formControls['tiger_connect_active'] = new FormControl(this.hospital.tiger_connect_active);
    formControls['tiger_connect_api_key'] = new FormControl(this.hospital.tiger_connect_api_key);
    formControls['tiger_connect_api_secret'] = new FormControl(null);
    formControls['tiger_connect_default_organization_id'] = new FormControl(this.hospital.tiger_connect_default_organization_id);
    this.tigerConnectInfoForm = new FormGroup(formControls);
  }

  onChange(event) {
    this.showFields = event;
  }

  toggleEdit() {
    this.isEdit = !this.isEdit;
    this.showFields = this.hospital.tiger_connect_active;
    if (this.isEdit) {
      this.generateFormControls();
    }
  }
}
