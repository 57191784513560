import {ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule } from '@angular/router';
import {ComponentsModule} from '../components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule} from 'ngx-bootstrap/tabs';
import { TableModule } from 'primeng/table';
import {PromoCodeListComponent} from './list/list.component';
import {NewPromoCodeComponent} from './new-promo-code/new-promo-code.component';
import {PromoCodeService} from './promo-code.service';
import {PromoCodeResolverService} from './promo-code.resolver.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import {FormsModule } from '@angular/forms';
import {ViewPromoCodeComponent} from './view-promo-code/view-promo-code.component';
import { PromoCodeDetailResolverService } from './promo-code-detail.resolver.service';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ComponentsModule,
        RouterModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TableModule,
        UiSwitchModule,
        FormsModule
    ],
    declarations: [
        PromoCodeListComponent,
        NewPromoCodeComponent,
        ViewPromoCodeComponent
    ],
    exports: [
        PromoCodeListComponent,
        NewPromoCodeComponent,
        ViewPromoCodeComponent
    ],
    providers: [
        PromoCodeService,
        { provide: 'PromoCodeResolverService', useValue: PromoCodeResolverService },
        { provide: 'PromoCodeDetailResolverService', useValue: PromoCodeDetailResolverService },
    ]
})
export class PromoCodeModule { }
