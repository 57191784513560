import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { FlashService } from '../../../components/flash/flash.service';
import { PayerService } from '../../payer.service';

@Component({
  selector: 'app-carrier-details',
  templateUrl: './carrier-details.component.html',
  styleUrls: ['./carrier-details.component.scss']
})

export class CarrierDetailsComponent implements OnInit {
  @Input() carrier: any;
  isEditing = false;
  @Output() carrierUpdated = new EventEmitter();
  originalCarrier: any;
  carrierName: string;

  formFields = [
    {
      label: 'Created',
      name: 'created_at',
    },
    {
      label: 'Last updated',
      name: 'updated_at',
    },
  ];

  constructor(
    private payerService: PayerService,
    private flashService: FlashService
  ) {}

  ngOnInit() {
    this.formatCarrierData();
    this.carrierName = this.carrier.name.slice();
    this.originalCarrier = { ...this.carrier };
  }

  formatCarrierData() {
    this.carrier.updated_at = moment(this.carrier.updated_at).format('LL');
    this.carrier.created_at = moment(this.carrier.created_at).format('LL');
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  payload() {
    return {
      name: this.carrierName
    };
  }

  updateCarrier() {
    this.payerService.updateCarrier(this.carrier.id, this.payload()).subscribe(
      (response) => {
        this.carrier = response.data;
        this.formatCarrierData();
        this.carrierUpdated.emit(this.carrier);
        this.flashService.add({
          type: 'success',
          message: `${this.originalCarrier.name} has been updated.`
        });
        this.originalCarrier = { ...this.carrier };
        this.toggleEdit();
      },
      err => this.toggleEdit()
    );
  }

  mapValue(key) {
    return this.carrier[key];
  }
}
