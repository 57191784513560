import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FederatedIdentityGroupService } from './federated-identity-group.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FederatedIdentityGroupsViewComponent } from './view/view.component';
import { EditFederatedIdentityGroupComponent } from './edit/edit.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NewFederatedIdentityGroupComponent } from './new/new.component';

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        TableModule,
        DropdownModule,
        RouterModule,
        AngularMultiSelectModule,
        UiSwitchModule,
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        SortableModule.forRoot(),
        CollapseModule.forRoot(),
    ],
    declarations: [
        NewFederatedIdentityGroupComponent,
        FederatedIdentityGroupsViewComponent,
        EditFederatedIdentityGroupComponent
    ],
    exports: [
        FederatedIdentityGroupsViewComponent
    ],
    providers: [
        FederatedIdentityGroupService
    ]
})
export class FederatedIdentityGroupsModule { }
