<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="compass" iconClass="button-icon"></app-icon> State Counties
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form" [formGroup]="stateCountiesForm" novalidate>

    <dropdown *ngIf="!stateId" [options]="filteredStateList"
              formControlName="state"
              (ngModelChange)="onStateSelect($event)">
    </dropdown>

    <!--<label *ngIf="state" class="form-control">{{state}}</label>-->

    <multi-select *ngIf="counties.length > 0; else noCounties"
                  [options]="counties"
                  formControlName="counties">
    </multi-select>

  </form>
</div>

<ng-template #noCounties>
  <span *ngIf="!selectedStateId && !countiesLoaded">No State is selected</span>
  <span *ngIf="selectedStateId && !countiesLoaded">Loading...</span>
  <span *ngIf="selectedStateId && countiesLoaded">No counties exist for this state.</span>
</ng-template>

<div class="modal-footer">
  <button type="button"
          class="btn btn-default"
          (click)="modalRef.hide()">
    Close
  </button>
  <button *ngIf="selectedStateId && counties.length > 0"
          type="button"
          class="btn btn-success"
          [disabled]="!stateId && stateCountiesForm.invalid"
          (click)="done()">
    Done
  </button>
</div>
