import { Injectable } from '@angular/core';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { environment } from '../../environments/environment';

@Injectable()
export class LaunchDarklyService {
  ldClient:LDClient;
  currentUser: {
    id: string,
    firstName: string,
    lastName: string,
    role: string,
    hospitalId: number,
    transportationCompanyId: number
  };

  constructor() {
    if(window.localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(window.localStorage.getItem('currentUser'));
    }

    if(this.currentUser) {
      this.ldClient = initialize(environment.launchDarklyClientApiKey,
        { key: this.currentUser.id, anonymous: false});
    } else {
      this.ldClient = initialize(environment.launchDarklyClientApiKey,
        { key: 'Anonymous', anonymous: true});
    }
  }

  subscribeFlag({flagName, user = null, callback, flagOptions = {}}:any={}) {
    let flagParams = this.setFlagParams(flagName, user, flagOptions);

    const promise = this.ldClient.waitForInitialization().then(() => {
      let flagValue = this.ldClient.variation(
        flagName,
        flagParams
      )
      callback(flagValue);
    });

    this.ldClient.on(`change:${flagName}`, (flagValue) => {
      callback(flagValue)
    })

    return promise;
  }

  setFlagParams(flagName, user, flagOptions) {
    let flagParams = {};

    if(!user) {
      flagParams['key'] = flagOptions['key'] || flagName
    } else {
      flagParams['key'] = user.id
    }

    if(flagOptions && flagOptions.hasOwnProperty('flagName')) {
      delete flagOptions.flagName
    }
    
    if(user) {
      flagParams['firstName'] = user.firstName;
      flagParams['lastName'] = user.lastName;
      flagParams['custom'] = {
        hospitalId: user.hospitalId,
        transportationCompanyId: user.transportationCompanyId,
        role: user.role
      }
    }

    if(flagOptions) {
      flagParams['custom'] = {
        ...flagParams['custom'],
        ...flagOptions
      }
    }

    return flagParams
  }
}
