import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as moment from 'moment';

import {AuthHttpService} from '../core/auth-http.service';
import {environment} from '../../environments/environment';
import { ContactInformation, Provider } from './provider';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ProviderService {

  constructor(private http: AuthHttpService) {}

  list(): Observable<any> {
    return this.http.get(`${environment.apiurl}/providers`).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  getState(): Observable<any> {
    return this.http.get(`${environment.apiurl}/providers/state`).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  pending(): Observable<any> {
    return this.http.get(`${environment.apiurl}/providers/pending`).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  dropdownActive(params?: any): Observable<any> {
    return this.http.get(`${environment.apiurl}/providers/active-list`, params).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  create(provider: Provider): Observable<Provider | string> {
    provider['phone'] = '+1' + provider['phone'];
    return this.http.post<Provider>(`${environment.apiurl}/providers`, provider).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  get(id): Observable<any> {
    return this.http.get(`${environment.apiurl}/providers/:id`.replace(':id', id)).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  delete(id): Observable<any> {
    return this.http.delete(`${environment.apiurl}/providers/:id`.replace(':id', id));
  }

  update(id, provider): Observable<Provider | string> {
    const apiUrl = `${environment.apiurl}/providers/${id}`;

    return this.http.update<Provider>(apiUrl, provider).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  updateServiceSchedules(providerId: any, serviceSchedules: any): Observable<Provider | string> {
    const apiUrl = `${environment.apiurl}/providers/${providerId}/service-schedules`;

    return this.http.update<Provider>(apiUrl, serviceSchedules).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  updateProviderServices(providerId: any, providerServices: any): Observable<Provider | string> {
    const apiUrl = `${environment.apiurl}/providers/${providerId}/provider-services`;

    return this.http.update<Provider>(apiUrl, providerServices).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  updateContactInformation(providerId: any, contactInformation: ContactInformation): Observable<Provider | string> {
    const apiUrl = `${environment.apiurl}/providers/${providerId}`;

    return this.http.update<Provider>(apiUrl, contactInformation.deserialize()).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  getCounties(state_id: any): Observable<any> {
    const apiUrl = `${environment.apiurl}/providers/states/${state_id}/counties`;

    return this.http.get(apiUrl).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  updateServiceAreas(providerId: number, payload): Observable<any> {
    const apiUrl = `${environment.apiurl}/providers/${providerId}/service-areas`;

    return this.http.update(apiUrl, payload).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  approve(provider): Observable<Provider | string> {
    const url = `${environment.apiurl}/providers/approve`;

    return this.http
      .update<Provider>(url, { id: provider.id }).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  files(id): Observable<any> {
    return this.http.get(`${environment.apiurl}/providers/files/:id`.replace(':id', id)).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  formatProvider(provider) {
    provider['created_at'] = moment(provider['created_at']).format('LLL');
    provider['approved_at'] = moment(provider['approved_at']).format('LLL');
  }

  formatProviders(providers) {
    providers.forEach(provider => {
      provider['created_at'] = moment(provider['created_at']).format('LLL');
      provider['approved_at'] = moment(provider['approved_at']).format('LLL');
    });
  }

  formatData(providers) {
    providers.forEach(provider => {
      provider['created_at'] = moment(provider['created_at']).format('LLL');
      provider['approved_at'] = moment(provider['approved_at']).format('LLL');
    });
    return providers;
  }

  arrayToString(value: any[], sliceCount: number = 5, overflowText: string = '...') {
    if (!value.length) {
      return '-';
    }
    let valueStr = value.slice(0, sliceCount).join(', ');
    if (value.length > sliceCount) {
      valueStr += overflowText;
    }
    return valueStr;
  }
}
