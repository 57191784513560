import { inject } from '@angular/core';

import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { LocationsService } from './locations.service';

export const LocationsResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> => {
    const locationsService: LocationsService = inject(LocationsService);
    return locationsService.list(route.params.id);
  }
