import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Note } from '../payer';

@Component({
  selector: 'app-view-active-plan',
  templateUrl: './view-active-plan.component.html',
  styleUrls: ['./view-active-plan.component.scss']
})

export class ViewActivePlanComponent implements OnInit {
  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  DETAILS_TAB = 0;
  ALIASES_TAB = 1;
  NOTES_TAB = 2;
  payerName: string;
  payerId: number;
  notes: Array<Note>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.payerName = data.payer.data.name;
      this.payerId = data.payer.data.id;
      this.notes = data.notes.data;
    });
    this.route.queryParams.subscribe(params => {
      if (params['selectTab'] && parseInt(params['selectTab'], 10) === this.ALIASES_TAB) {
        this.tabset.tabs[this.ALIASES_TAB].active = true;
      }
    });
  }

  onUpdate(payer) {
    this.payerName = payer.name;
  }
}
