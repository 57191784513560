<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Venues</li>
  <li>
    <a href="#" (click)="openNewVenueModal()">
      <app-icon icon="map-pin" iconClass="button-icon"></app-icon> Add Venue
    </a>
  </li>
</ol>

<div class="row">
  <div class="col-xs-12">
    
    <p-table
    [value]="venues"
    selectionMode="single"
    [(selection)]="selectedVenue" 
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="null" 
    [paginator]="true"
    [rows]="10"
    stateStorage="session" 
    stateKey="state-session-venues"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngFor="let key of keys" style="width:15%">
          <p-columnFilter 
          type="text" 
          field="{{key}}" 
          placeholder="{{tableConfig.columns[key].title}}" 
          ariaLabel="Filter {{tableConfig.columns[key].title}}" 
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-venue>
      <tr [pSelectableRow]="venue">
        <td *ngFor="let key of keys">{{ venue[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
