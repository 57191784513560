<ol class="breadcrumb">
  <li class="active">Carriers</li>
  <li>
    <a (click)="openNewCarrierModal()">
      <app-icon icon="plus" iconClass="button-icon"></app-icon> Add
    </a>
  </li>
</ol>

<h1>Carriers</h1>

<tabset>
  <tab>
    <ng-template tabHeading>
      Active <span class="badge badge-secondary">{{activeCount}}</span>
    </ng-template>

    <div class="row">
      <div class="col-xs-12">
        <p-table
        [value]="carriers"
        [lazy]="true"
        [totalRecords]="totalRecords"
        [paginator]="true"
        (onLazyLoad)="loadCarriers($event)"
        [rows]="rows"
        [first]="first"
        [showCurrentPageReport]="true"
        selectionMode="single"
        [(selection)]="selectedCarrier"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="null" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        (onPage)="pageChange($event)"
        [rowsPerPageOptions]="[10, 25, 50]"
        stateStorage="session" 
        stateKey="state-session-carriers"
      >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th style="width:50%">
          <p-columnFilter 
          type="text" 
          field="name"
          [placeholder]="placeholders['name']"
          ariaLabel="Filter carrier name" 
          [showMenu]="false"
          [showClearButton]="false"
          />
          </th>
          <th>
            <p-dropdown 
                [ngModel]="selectedUpdatedAt" 
                [placeholder]="placeholders['updated_at']"
                [options]="monthList" 
                optionLabel="title"
                [showClear]="true"
                (onChange)="pageChange($event, 'updated_at')"
                >
            </p-dropdown>
          </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-carrier>
      <tr [pSelectableRow]="carrier">
        <td *ngFor="let key of keys">{{ carrier[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
      </div>
    </div>
  </tab>
</tabset>
