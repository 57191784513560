import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';



import { environment } from '../../environments/environment';

import { AuthHttpService } from '../core/auth-http.service';
import { ReasonType } from './reason-type';

@Injectable()
export class ReasonTypeService {
  constructor(private http: AuthHttpService) {}

  getReasonTypes() {
    const url = `${environment.apiurl}/reason-types`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  create(reasonType: ReasonType): Observable<any> {
    const url = `${environment.apiurl}/reason-types`;

    return this.http
      .post(url, reasonType).pipe(
        catchError((err) => this.http.handleError(err)));
  }
}
