import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {PromoCode} from './promo-code';
import {Observable} from 'rxjs';
import {PromoCodeService} from './promo-code.service';

export const PromoCodeResolverService: ResolveFn <PromoCode[]> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PromoCode[]> => {
    const promoCodeService: PromoCodeService = inject(PromoCodeService)
    return promoCodeService.list();
  }
