<h2 class="pull-left">Possible matches</h2>

<div class="search-box-div pull-right">
  <input
    type="text"
    class="search-box"
    (keyup.enter)="onSearch()"
    [(ngModel)]="searchString"
    placeholder="Search by plan and/or carrier name"
  />

  <span class="pull-right">
    <img *ngIf="loading" src="assets/images/loader.svg" />
  </span>

  <button class="pull-right" *ngIf="!loading && searchString?.length > 0" (click)="resetSearch()">
    <img src="assets/images/cancel-x.svg" />
  </button>
</div>

<div class="clearfix"></div>

<div class="bottom-margin" [hidden]="activePlans?.count() == 0">
  <div class="row">
    <div class="col-xs-12">
      <ng2-smart-table
        [settings]="tableConfig"
        [source]="activePlans"
        (userRowSelect)="onRowSelect($event)"
        (custom)="onCustomEvent($event)"
      >
      </ng2-smart-table>
    </div>
  </div>

  <button
    class="btn pull-right"
    [ngClass]="{'btn-secondary': !selectedActivePlan?.id, 'btn-primary': !!selectedActivePlan?.id}"
    (click)="confirmMatch()"
    [disabled]="!selectedActivePlan?.id"
  >
    Confirm match
  </button>
  <button class="btn btn-link pull-right" (click)="openMatchNewActivePlanModal()">New plan</button>
</div>

<div class="bottom-margin" [hidden]="activePlans?.count() > 0">
  <div class="empty-state">
    <div class="empty-state-content">
      <span>No plans match your search</span>
    </div>
  </div>
</div>
