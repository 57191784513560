<ol class="breadcrumb">
  <li class="active">Carriers</li>
  <li>
    <a (click)="openNewCarrierModal()">
      <app-icon icon="plus" iconClass="button-icon"></app-icon> Add
    </a>
  </li>
</ol>

<h1>Carriers</h1>

<tabset>
  <tab>
    <ng-template tabHeading>
      Active <span class="badge badge-secondary">{{activeCount}}</span>
    </ng-template>

    <div class="row">
      <div class="col-xs-12">
        <ng2-smart-table
          [settings]="tableConfig"
          [source]="carriers"
          (userRowSelect)="onRowSelect($event)"
        >
        </ng2-smart-table>
      </div>
    </div>
  </tab>
</tabset>
