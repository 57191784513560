import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from "@angular/forms";

import { NavbarComponent } from './navbar/navbar.component';
import { IconComponent } from './icon/icon.component';
import { LoaderComponent } from './loader/loader.component';

import { FlashComponent } from './flash/flash.component';
import { FlashService } from './flash/flash.service';

import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmService } from './confirm/confirm.service';

import { CsvDownloadComponent } from './csv-download/csv-download.component';
import { CsvService } from './csv-download/csv.service';

import { SidebarComponent } from './sidebar/sidebar.component';

import { DropdownComponent } from './dropdown/dropdown.component';

import { MultiSelectComponent } from './multi-select/multi-select.component';

import { TimezoneDropdownComponent } from "./timezone-dropdown/timezone-dropdown.component";
import { TimezoneDropdownService } from "./timezone-dropdown/timezone-dropdown.service";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
    ],
    declarations: [
        NavbarComponent,
        IconComponent,
        LoaderComponent,
        FlashComponent,
        ConfirmComponent,
        CsvDownloadComponent,
        SidebarComponent,
        DropdownComponent,
        MultiSelectComponent,
        TimezoneDropdownComponent,
    ],
    exports: [
        NavbarComponent,
        IconComponent,
        LoaderComponent,
        FlashComponent,
        ConfirmComponent,
        CsvDownloadComponent,
        SidebarComponent,
        DropdownComponent,
        MultiSelectComponent,
        TimezoneDropdownComponent,
    ],
    providers: [
        FlashService,
        ConfirmService,
        CsvService,
        TimezoneDropdownService
    ]
})
export class ComponentsModule { }
