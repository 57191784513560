import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FlashMessage } from './flash-message';

@Injectable()
export class FlashService {
  defaultTimeout = 5000;
  data: FlashMessage[] = [];
  messages = new Subject<FlashMessage[]>();

  constructor() {}

  add(message: FlashMessage) {
    message.id = Date.now();

    this.data.push(message);

    if (typeof message.timeout === 'undefined') {
      message.timeout = this.defaultTimeout;
    }

    if (message.timeout) {
      this.removeAfterTimeout(message);
    }

    this.messages.next(this.data);
  }

  remove(message: FlashMessage) {
    const index = this.data.findIndex(x => x.id === message.id);

    if (index > -1) {
      this.data.splice(index, 1);
      this.messages.next(this.data);
    }
  }

  private removeAfterTimeout(message: FlashMessage) {
    setTimeout(() => {
      this.remove(message);
    }, message.timeout);
  }

}
