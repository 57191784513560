import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderService } from '../providers.service';
import { ProviderCsvService } from '../provider-csv.service';

import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';

@Component({
  selector: 'app-providers-pending',
  templateUrl: './providers-pending.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
    './providers-pending.component.scss'
  ]
})
export class ProvidersPendingComponent implements OnInit {
  list: AdminServerDataSource;
  loading = false;
  statesFilterList = [];

  tableConfig = {
    columns: {
      name: {
        title: 'Name'
      },

      phone: {
        title: 'Phone',
      },

      states: {
        title: 'States',
        sort: false,
        filter: {
          type: 'list',
          config: {
            list: this.statesFilterList
          }
        },
        valuePrepareFunction: (value) => this.providerService.arrayToString(value)
      },

      counties: {
        title: 'Counties',
        sort: false,
        valuePrepareFunction: (value) => this.providerService.arrayToString(value)
      },

      zipcodes: {
        title: 'Zip Codes',
        width: '250px',
        sort: false,
        valuePrepareFunction: (value) => this.providerService.arrayToString(value)
      },

      created_at: {
        title: 'Created On',
        filter: {
          type: 'list',
          config: {
            list: [
              {value: 'january', title: 'January'},
              {value: 'february', title: 'February'},
              {value: 'march', title: 'March'},
              {value: 'april', title: 'April'},
              {value: 'may', title: 'May'},
              {value: 'june', title: 'June'},
              {value: 'july', title: 'July'},
              {value: 'august', title: 'August'},
              {value: 'september', title: 'September'},
              {value: 'october', title: 'October'},
              {value: 'november', title: 'November'},
              {value: 'december', title: 'December'}
            ]
          }
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private providerService: ProviderService,
    private providerCsvService: ProviderCsvService,
    protected http: HttpClient
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.statesFilterList = data.state.data.map(state => {
        return { value: state['id'], title: state['name'] };
      });
      this.tableConfig.columns.states.filter.config.list = this.statesFilterList;
      if(!this.list) { 
        this.list = new AdminServerDataSource(this.http, {
        endPoint: `${environment.apiurl}/providers/pending`,
        formatDataFunction: this.providerService.formatProviders.bind(this),
        filterFieldDataType: {
          name: String,
          phone: String,
          states: String,
          counties: Array,
          zipcodes: Array,
          created_at: Date
        }
      });
    }
    });
  }

  public formatCSVdataFunction(data) {
    return this.providerCsvService.generateDataFromList(data);
  }

  public onRowSelect(event) {
    this.router.navigate(['/providers', event.data.id]);
  }

}
