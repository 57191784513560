import * as _ from 'lodash';

export interface GeofenceRulesInfo {
  geofence_rules_enabled: boolean,
  geofence_rules: GeofenceRule[]
}

export interface GeofenceRule {
  pickup_boundary_type_id: number,
  dropoff_boundary_type_id: number,
  reasons: number[]
}

export interface GeofenceRulesInfoData {
  geofenceRulesEnabled: boolean,
  boundaryTypes: {
    value: string,
    label: string
  }[],
  geofenceRules: GeofenceRuleData[]
}

export interface GeofenceRuleData {
  pickupBoundaryType: {
    value: string,
    label: string
  },
  dropoffBoundaryType: {
    value: string,
    label: string
  },
  reasons: {
    id: number,
    itemName: string
  }[]
}

export class GeofenceRules {
	geofenceRulesData = {} as GeofenceRulesInfoData;

  constructor(boundaryTypes: { id: string, name: string }[]) {
    this.setBoundaryTypesInGeofenceRulesData(boundaryTypes);
  }

  setGeofenceRulesData(data: GeofenceRulesInfo, reasonList: { id: number, itemName: string }[]) {    
    this.geofenceRulesData.geofenceRulesEnabled = data.geofence_rules_enabled;
    this.geofenceRulesData.geofenceRules = data.geofence_rules.map(rule => {
      let formattedRule = {} as GeofenceRuleData;
      formattedRule.pickupBoundaryType = _.find(this.geofenceRulesData.boundaryTypes, { value: rule.pickup_boundary_type_id });
      formattedRule.dropoffBoundaryType = _.find(this.geofenceRulesData.boundaryTypes, { value: rule.dropoff_boundary_type_id });
      formattedRule.reasons = rule.reasons.map(reason => {
        return _.find(reasonList, { id: reason });
      });
      return formattedRule;
    });
  }

	setBoundaryTypesInGeofenceRulesData(boundaryTypes: { id: string, name: string }[]) {
    this.geofenceRulesData.boundaryTypes = _.map(boundaryTypes, function(boundaryType: { id: string, name: string }) {
      return { value: boundaryType.id, label: boundaryType.name };
    });
	}

	getGeofenceRulesData() {
		return this.geofenceRulesData;
  }
  
  generatePayload(data) {
    let payload = {} as GeofenceRulesInfo;
    payload.geofence_rules_enabled = data.geofenceRulesEnabled;
    payload.geofence_rules = data.geofenceRules.map(rule => {
      let formattedRule = {} as GeofenceRule;
      formattedRule.pickup_boundary_type_id = Number(rule.pickupBoundaryTypeId);
      formattedRule.dropoff_boundary_type_id = Number(rule.dropoffBoundaryTypeId);
      formattedRule.reasons = _.map(rule.reasons, 'id');
      return formattedRule;
    });
    return payload;
  }
}
