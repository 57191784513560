import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

import { environment } from '../../../../environments/environment';

import { Hospital } from '../../hospital';
import { HospitalService } from '../../hospital.service';
import { FlashService } from '../../../components/flash/flash.service';
import { AuthHttpService } from '../../../core/auth-http.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-redox-setup',
  templateUrl: './redox-setup.component.html',
  styleUrls: ['./redox-setup.component.scss']
})
export class RedoxSetupComponent implements OnInit {
  @Input() hospital: Hospital;
  redoxSetupForm: FormGroup;
  redoxApiEndpoint: String;
  redoxSSOEndpoint: String;
  ssoIdentifiersHasError = false;
  ingestionIdentifiersHasError = false;
  ssoIdentifierErrorMessage = null;
  ingestionIdentifierErrorMessage = null;
  hl7UserErrorMessage = null;
  hl7UserEmailPresent=false;

  constructor(
    private hospitalService: HospitalService,
    private flashService: FlashService,
    private http: AuthHttpService
  ) { }

  ngOnInit() {
    this.redoxApiEndpoint = `${environment.appurl}integrations/redox/webhook`
    this.redoxSSOEndpoint = `${environment.appurl}integrations/redox/callback`
    this.generateFormControls();
  }

  generateFormControls() {
    const data =_.cloneDeep(this.hospital);
    const formControls = {};
    const ssoIdentifiersControls = [];

    formControls['sso_enabled'] = new FormControl(data.sso_enabled);
    formControls['enable_unrestricted_sso_access'] = new FormControl(data.enable_unrestricted_sso_access);
    formControls['enable_sso_cc_creation'] = new FormControl(data.enable_sso_cc_creation);
    formControls['sso_auto_provision_enabled'] = new FormControl(data.sso_auto_provision_enabled);
    formControls['patient_identifier'] = new FormControl(data.patient_identifier);

    data.sso_identifiers.forEach(identifier => {
      ssoIdentifiersControls.push(
        new FormGroup(
          {
            source_id: new FormControl({ value: identifier.id_value, disabled: true }),
            sso_secret: new FormControl(null)
          }
        )
      );
    });

    formControls['sso_identifiers'] = new FormArray(ssoIdentifiersControls);
    formControls['ingestion_identifiers'] = new FormControl(this.configureIngestionIdentifiers());
    formControls['height_code'] = new FormControl(
      this.hospital.redox_observation_codes ? this.hospital.redox_observation_codes.height : null
    )
    formControls['weight_code'] = new FormControl(
      this.hospital.redox_observation_codes ? this.hospital.redox_observation_codes.weight : null
    )

    if(this.hospital.hl7_default_user_email) {
      this.hl7UserEmailPresent = true;
      formControls['hl7_default_user_email'] = new FormControl(this.hospital.hl7_default_user_email)
    } else {
      this.hl7UserEmailPresent = false;
      let value = this.hospital.name.split(' ')[0].toLowerCase();
      formControls['hl7_default_user_email'] = new FormControl(`hl7+${value}@roundtriphealth.com`);
    }

    this.redoxSetupForm = new FormGroup(formControls);
  }

  getErrorMessage(errorsObject) {
    let message = '';

    Object.keys(errorsObject).forEach(key => {
      message += `${key} ${errorsObject[key].id_value},`;
    });

    if(message.length > 0) {
      // Remove extra comma (,) from the string.
      message = message.slice(0, -1);
    }

    return message;
  }

  configureIngestionIdentifiers() {
    let ingestion_identifiers = _.map(this.hospital.ingestion_identifiers, identifier => (
      {
        display: identifier.id_value,
        value: identifier.id_value,
        removable: false,
        disable: true,
        readonly: true
      }
    ));

    return ingestion_identifiers;
  }

  get ssoIdentifiers() {
    return this.redoxSetupForm.get('sso_identifiers') as FormArray;
  }

  get ingestionIdentifiers() {
    return this.redoxSetupForm.get('ingestion_identifiers') as FormControl;
  }

  validateDuplicateSSOIdentifiers() {
    const values = _.map(this.ssoIdentifiers.getRawValue(), s => (s.source_id));
    this.ssoIdentifiersHasError = (values.length !== _.uniq(values).length);

    if(this.ssoIdentifiersHasError) {
      this.ssoIdentifierErrorMessage = 'Please remove the duplicate Identifiers';
    }
  }

  validateConflictingIdentifiers() {
    const ssoValues = _.map(this.ssoIdentifiers.getRawValue(), s => (s.source_id));
    const ingestionValues = _.map(this.ingestionIdentifiers.value, s => (s.value));
    const conflictingValues = _.intersection(ssoValues, ingestionValues);

    return conflictingValues.length != 0;
  }

  sourceIDElementDisabled(identifier) {
    return identifier.get('source_id').disabled;
  }

  validateSSOIdentifiers() {
    this.validateDuplicateSSOIdentifiers();

    if(!this.ssoIdentifiersHasError) {
      let conflict = this.validateConflictingIdentifiers();

      if(conflict) {
        this.ssoIdentifiersHasError = true;
        this.ssoIdentifierErrorMessage = 'Please remove the conflicting values with hl7 source ids';
      }
    }
  }

  validateIngestionIdentifers() {
    let conflict = this.validateConflictingIdentifiers();
    this.ingestionIdentifiersHasError = false;

    if(conflict) {
      this.ingestionIdentifiersHasError = true;
      this.ingestionIdentifierErrorMessage = 'Please remove the conflicting values with SSO Source ids';
    }
  }

  onIdentifierChange() {
    this.validateSSOIdentifiers();
    this.validateIngestionIdentifers();
  }

  clearError() {
    this.hl7UserErrorMessage = null;
  }

  removeIdentifier(index: number) {
    this.ssoIdentifiers.removeAt(index);
    this.validateSSOIdentifiers();
    this.validateIngestionIdentifers();
  }

  addSSOIdentifier() {
    this.ssoIdentifiers.push(new FormGroup({
      source_id: new FormControl(null, Validators.required),
      sso_secret: new FormControl(null, Validators.required),
    }));
  }

  formatIngestionIdentifiers(identifiers) {
    return _.map(identifiers, identifier => ({source_id: identifier.value}));
  }

  formatHl7DefaultUserEmail(email) {
    let values = email.split('+');

    if(values[0] != 'hl7') {
      email = `hl7+${email}`;
    }

    return email;
  }

  save() {
    let data = _.cloneDeep(this.redoxSetupForm.getRawValue());
    data.ingestion_identifiers = this.formatIngestionIdentifiers(data.ingestion_identifiers);
    data.hl7_default_user_email = this.formatHl7DefaultUserEmail(data.hl7_default_user_email);
    this.hospitalService.redoxSetup(this.hospital.id, data).subscribe(
      (response) => {
      this.flashService.add({
        type: 'success',
        message: '<strong>Success!</strong> Redox Setup is completed for the organization'
      });
      this.hospital = response.data;
      this.generateFormControls();
    }, (error) => {
      this.http.handleError(error);

      if(error._body) {
        const err = (typeof error._body === 'string') ? JSON.parse(error._body) : error._body;

        if(err.errors && err.errors.sso_identifiers) {
          let errorsObject = err.errors.sso_identifiers;
          this.ssoIdentifierErrorMessage = this.getErrorMessage(errorsObject);
          this.ssoIdentifiersHasError = true;
        }

        if(err.errors && err.errors.ingestion_identifiers) {
          let errorsObject = err.errors.ingestion_identifiers;
          this.ingestionIdentifierErrorMessage = this.getErrorMessage(errorsObject);
          this.ingestionIdentifiersHasError = true;
        }

        if(err.errors && err.errors.hl7_default_user) {
          let errorsObject = err.errors.hl7_default_user;
          this.hl7UserErrorMessage = `Email ${errorsObject.email}`
        }
      }
    });
  }

  clearErrors() {
    this.ingestionIdentifierErrorMessage=null;
    this.ssoIdentifierErrorMessage=null;
    this.hl7UserErrorMessage=null;
    this.ssoIdentifiersHasError=false;
    this.ingestionIdentifiersHasError=false;
  }

  close() {
    this.generateFormControls();
    this.clearErrors();
  }
}
