import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Output()
  menuButtonClicked: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  appLink = environment.appurl;

  constructor(private authService: AuthService) {
    }

  logout(): boolean {
    this.authService.logout();
    return false;
  }

  onMenuButtonClicked() {
    this.menuButtonClicked.emit();
  }

}
