import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { FlashService } from '../../../components/flash/flash.service';
import { ProviderService } from '../../providers.service';
import { CERTIFICATIONS, DISPATCH_SOFTWARE_OPTIONS, LANGUAGE_SPOKEN } from '../../provider';
import { MultiSelectOption } from '../../../components/multi-select/multi-select';
import { Option } from '../../../components/dropdown/dropdown';
import { LaunchDarklyService } from '../../../core/launchdarkly.service';
import {
  measurementUnitOptions,
  measurementUnitMap
} from '../../../hospitals/hospital';

@Component({
  selector: 'provider-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
  readonly dispatchSoftwareOptions: Option[] = _.map(DISPATCH_SOFTWARE_OPTIONS, (key, value) => ({
    value: value,
    label: key
  }));
  readonly certificationsOptions: MultiSelectOption[] = CERTIFICATIONS.map((value) => ({
    name: value,
    label: value,
  }));
  readonly languageSpokenOptions: MultiSelectOption[] = LANGUAGE_SPOKEN.map((value) => ({
    name: value,
    label: value
  }));

  @Input() data: any;
  @Output() onUpdate = new EventEmitter<string>();

  generalInformationForm: FormGroup;
  isEditMode: boolean = false;

  unknownDispatchSoftware: boolean = false;
  unknownLanguageSpoken: boolean = false;
  dispatchSoftwareSelected: boolean = false;
  measurementUnitOptions = measurementUnitOptions;
  measurementUnitMap = measurementUnitMap;

  constructor(
    private providerService: ProviderService,
    private flashService: FlashService,
    private ld: LaunchDarklyService
  ) {
  }

  ngOnInit() {
  }

  private buildFormData(data) {
    const formData = {
      companyName: data.name,
      generalLiability: data.properties.general_liability,
      automobileLiability: data.properties.automobile_liability,
      workersCompensation: data.properties.workers_compensation,
      activeMedicaidCredentials: data.properties.has_active_medicaid_creds,
      dispatchSoftware: data.properties.type_of_dispatch_software,
      dispatchSoftwareAlt: data.properties.dispatch_software,
      providerCode: data.properties.provider_code,
      taxCode: data.tax_code,
      npi: data.properties.npi_number,
      appliedAt: data.applied_at && data.applied_at.substring(0, 10),
      approvedAt: data.approved_at && data.approved_at.substring(0, 10),
      certifications: data.properties.certifications,
      languagesSpoken: data.properties.languages_spoken,
      languagesSpokenAlt: '',
      measurementUnit: data.properties.measurement_unit,
    };

    if (formData.dispatchSoftware) {
      this.dispatchSoftwareSelected = true;

      if (formData.dispatchSoftware == 'other') {
        this.unknownDispatchSoftware = true;
      } else {
        this.unknownDispatchSoftware = false;
      }
    } else {
      this.dispatchSoftwareSelected = false;
    }

    if (formData.languagesSpoken && formData.languagesSpoken.length) {
      const knownLanguages = [];
      formData.languagesSpoken.forEach((value) => {
        const language = LANGUAGE_SPOKEN.find(key => key.toLowerCase() === value.toLowerCase());
        if (language) {
          knownLanguages.push(language);
        } else {
          this.unknownLanguageSpoken = true;
          formData.languagesSpokenAlt = value;
          knownLanguages.push('Other');
        }
      });
      formData.languagesSpoken = knownLanguages;
    }

    return formData;
  }

  private generatePayload(updatedData) {
    const payload = <any>{};
    payload.name = updatedData.companyName;
    payload.general_liability = updatedData.generalLiability;
    payload.automotive_liability = updatedData.automobileLiability;
    payload.workers_compensation = updatedData.workersCompensation;
    payload.has_active_medicaid_creds = updatedData.activeMedicaidCredentials;
    payload.dispatch_software = this.getDispatchSoftware(updatedData);
    payload.provider_code = this.getProviderCode(updatedData);
    payload.type_of_dispatch_software = updatedData.dispatchSoftware;
    payload.tax_code = updatedData.taxCode;
    payload.npi_number = updatedData.npi;
    payload.applied_at = updatedData.appliedAt;
    payload.approved_at = updatedData.approvedAt;
    payload.certifications = updatedData.certifications;
    payload.languages_spoken = this.getLanguagesSpoken(updatedData);
    payload.measurement_unit = updatedData.measurement_unit;

    return payload;
  }

  public save() {
    const payload = this.generatePayload(this.generalInformationForm.value);

    this.providerService.update(this.data.id, payload)
      .subscribe(
        (response) => {
          this.data = response['data'];
          this.onUpdate.emit(response['data']['name']);
          this.toggleEdit();
          this.flashService.add({
            type: 'success',
            message: `<strong>Success!</strong> General information has been updated.`
          },
          );
        },
        error => this.toggleEdit()
      );
  }

  generateForm() {
    const data = this.buildFormData(this.data);
    this.generalInformationForm = new FormGroup({
      companyName: new FormControl(data.companyName),
      generalLiability: new FormControl(data.generalLiability),
      automobileLiability: new FormControl(data.automobileLiability),
      workersCompensation: new FormControl(data.workersCompensation),
      activeMedicaidCredentials: new FormControl(data.activeMedicaidCredentials),
      dispatchSoftware: new FormControl(data.dispatchSoftware),
      dispatchSoftwareAlt: new FormControl(
        data.dispatchSoftwareAlt,
        this.unknownDispatchSoftware ? Validators.required : null
      ),
      providerCode: new FormControl(data.providerCode),
      taxCode: new FormControl(data.taxCode),
      npi: new FormControl(data.npi),
      appliedAt: new FormControl(data.appliedAt),
      approvedAt: new FormControl(data.approvedAt),
      certifications: new FormControl(data.certifications),
      measurement_unit: new FormControl(data.measurementUnit),
      languagesSpoken: new FormControl(data.languagesSpoken),
      languagesSpokenAlt: new FormControl(
        data.languagesSpokenAlt,
        this.unknownLanguageSpoken ? Validators.required : null
      ),
    });

    const dispatchSoftwareAltControl = this.generalInformationForm.get('dispatchSoftwareAlt');
    this.generalInformationForm.get('dispatchSoftware').valueChanges.subscribe((value) => {
      this.unknownDispatchSoftware = (value === 'other');
      this.dispatchSoftwareSelected = true;
      let validations = null;
      if (this.unknownDispatchSoftware) {
        validations = [Validators.required];
      }
      dispatchSoftwareAltControl.setValidators(validations);
      dispatchSoftwareAltControl.updateValueAndValidity();
    });
    const languagesSpokenAltControl = this.generalInformationForm.get('languagesSpokenAlt');
    this.generalInformationForm.get('languagesSpoken').valueChanges.subscribe((values) => {
      this.unknownLanguageSpoken = values.includes('Other');
      let validations = null;
      if (this.unknownLanguageSpoken) {
        validations = [Validators.required];
      }
      languagesSpokenAltControl.setValidators(validations);
      languagesSpokenAltControl.updateValueAndValidity();
    });
  }

  public toggleEdit() {
    this.isEditMode = !this.isEditMode;
    this.generateForm();
  }

  getDispatchSoftware(data) {
    if (data.dispatchSoftware === 'other') {
      return data.dispatchSoftwareAlt;
    }

    return null;
  }

  getDispatchSoftwareDisplayValue() {
    if (this.data.properties.type_of_dispatch_software !== 'other') {
      return DISPATCH_SOFTWARE_OPTIONS[this.data.properties.type_of_dispatch_software]
    }

    return this.data.properties.dispatch_software;
  }

  getProviderCode(data) {
    if (!data.dispatchSoftware) {
      return null;
    }

    return data.providerCode;
  }

  getLanguagesSpoken(data) {
    return data.languagesSpoken.map((language) => {
      if (language === 'Other') {
        return data.languagesSpokenAlt;
      }
      return language;
    });
  }
}
