import { Component, OnInit } from '@angular/core';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-provider-users-list',
  templateUrl: './provider-users-list.component.html',
  styleUrls: [
    './provider-users-list.component.scss',
    '../../../themes/ng2-smart-table.scss'
  ]
})
export class ProviderUsersListComponent implements OnInit {
  provider: any;
  dispatchers: AdminServerDataSource;

  tableConfig = {
    columns: {
      last_name: {
        title: 'Last name',
        filter: false
      },
      first_name: {
        title: 'First name',
        filter: false
      },
      email: {
        title: 'Email',
        filter: false
      },
      confirmed: {
        title: 'Confirmed?',
        valuePrepareFunction: (value) => (value ? 'Yes' : 'No'),
        filter: false
      },
      active: {
        title: 'Active?',
        valuePrepareFunction: (value) => (value ? 'Yes' : 'No'),
        filter: false
      },
      created_at: {
        title: 'Created On',
        valuePrepareFunction: (value) => moment(value).format('LL h:mm A'),
        filter: false
      }
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    pager: {
      perPage: 10
    },
    noDataMessage: ''
  };

  constructor(protected http: HttpClient, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.provider = data.provider.data;
    });
    this.dispatchers = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/providers/${this.provider.id}/dispatchers`,
      formatDataFunction: this.formatDispatcherData,
    });
  }

  formatDispatcherData = (dispatchers) => {
    dispatchers.forEach(dispatcher => {
      dispatcher['confirmed'] = !!dispatcher['confirmed_at'];
    });
  }

  onRowSelect(event) {
    this.router.navigate(['provider', 1, 'dispatchers', event.id]);
  }
}
