import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashService} from '../../components/flash/flash.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Venue} from '../venues';
import {VenuesService} from '../venues.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import {NewVenueComponent} from '../new-venue/new-venue.component';

@Component({
  selector: 'app-venues',
  templateUrl: './list.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
    './list.component.scss'
  ]
})
export class VenuesViewComponent implements OnInit {
  
  venues: Venue[];
  selectedVenue: Venue;
  loading = false;
  newVenueModal: BsModalRef;
  tableConfig = {
    columns: {
      location_name: {
        title: 'Location Name'
      },
      
      zone_name: {
        title: 'Venue Name'
      },
      
      latitude: {
        title: 'Latitude'
      },
      
      longitude: {
        title: 'Longitude'
      },
      
      zone_latitude: {
        title: 'Venue Latitude'
      },
      
      zone_longitude: {
        title: 'Venue Longitude'
      }
    },
    
    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  titles = Object.keys(this.tableConfig.columns).map(key => this.tableConfig.columns[key].title);
  keys = Object.keys(this.tableConfig.columns)

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private flashService: FlashService,
    private venueService: VenuesService
  ) {  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if(!this.venues) {
        this.venues = this.venueService.formatData(data.venues.data);
      }
    });
  }

  public openNewVenueModal() {
    this.newVenueModal = this.modalService.show(NewVenueComponent);

    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.newVenueModal.content.added) {
        this.newVenueModal.content.added = false;
        this.loading = true;

        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> New Venue Added.'
        });

        const updatedList: Venue[] = this.venues.concat(this.newVenueModal.content.venue);
        this.venues = [];
        setTimeout(() => {
          this.venues = updatedList;
          this.loading = false;
        }, 10);
      }

      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  public onRowSelect(event) {
    this.router.navigate(['/venues', event.data.id]);
  }


}

