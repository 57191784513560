<div class="row">

  <div class="col-lg-6">
    <h3>Contact Information</h3>
  </div>

  <div class="col-lg-6">
    <button *ngIf="isEditMode" type="button"
            class="btn btn-primary btn-wrap pull-right"
            [disabled]="contactInformationForm.invalid"
            (click)="save()">
      Update
    </button>
    <button *ngIf="!isEditMode" type="button"
            class="btn btn-primary btn-wrap pull-right"
            (click)="toggleEdit()">
      Edit
    </button>
    <button *ngIf="isEditMode" type="button"
            class="btn btn-default btn-wrap pull-right"
            (click)="toggleEdit()">
      Close
    </button>
  </div>

</div>

<div *ngIf="!isEditMode">

  <table class="table table-striped">
    <tbody>
      <ng-container *ngFor="let field of FIELDS">
        <tr>
          <td width="250px">{{field.label}}</td>
          <td>{{formData[field.name]}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>

</div>

<div *ngIf="isEditMode">

  <form class="form" [formGroup]="contactInformationForm" novalidate>
    <table class="table table-striped">
      <tbody>
        <ng-container *ngFor="let field of FIELDS">
          <tr>
            <td width="250px">{{field.label}}</td>
            <td>
              <ng-container [ngSwitch]="field.type">
                <dropdown *ngSwitchCase="'dropdown'"
                          [options]="field.options"
                          formControlName={{field.name}}>
                </dropdown>
                <input *ngSwitchCase="'text'"
                      type={{field.type}}
                      class="form-control"
                      formControlName={{field.name}}
                      placeholder={{field.label}}>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>

</div>
