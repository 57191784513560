import {Injectable} from '@angular/core';
import {AuthHttpService} from '../core/auth-http.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators'
import * as moment from 'moment';
import {Hospital} from '../hospitals/hospital';
import {Venue} from './venues';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class VenuesService {
  constructor(private http: AuthHttpService) {}

  list(): Observable<any> {
    return this.http.get(`${environment.apiurl}/venues`).pipe(
      catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  create(venue: Venue): Observable<Venue | string> {
    return this.http.post<Venue>(`${environment.apiurl}/venues`, venue).pipe(
      catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  get(id): Observable<any> {
    return this.http.get(`${environment.apiurl}/venues/:id`.replace(':id', id)).pipe(
      catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  delete(id): Observable<any> {
    return this.http.delete(`${environment.apiurl}/venues/:id`.replace(':id', id)).pipe(
      catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  update(venue: Venue): Observable<Venue | string> {
    return this.http.update<Venue>(`${environment.apiurl}/venues/:id`.replace(':id', venue.id), venue).pipe(
      catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  formatVenue(venue: Venue) {
    venue['created_at'] = moment(venue['created_at'] as string).format('LLL');
  }

  formatData(venues) {
    venues.forEach(venue => {
      this.formatVenue(venue);
    });
    return venues;
  }
}
