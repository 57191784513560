<h3 class="pull-left">Notes</h3>

<button class="btn btn-link pull-right" (click)="openAddNoteModal()">Add note</button>

<div class="clearfix"></div>

<div [hidden]="count === 0">
  <div *ngFor="let note of notes" class="note">
    <div class="row">
      <div class="col-sm-8">
        <span class="word-wrap-break">{{ note.content }}</span>
      </div>
      <div class="col-sm-3">
        <span><strong>{{ note.updated_at }}</strong></span>
        <br/>
        <span class="word-wrap-break">{{ note.user_email }}</span>
      </div>
      <div class="col-sm-1">
        <span class="edit-action pull-right" *ngIf="note.is_editable" (click)="openEditNoteModal(note)"><app-icon icon="edit-2" iconClass="action-icon"></app-icon></span>
      </div>
    </div>
  </div>
</div>

<div [hidden]="count > 0">
  <div class="empty-state">
    <div class="empty-state-content">
      <div>No notes for this plan</div>
      <div><button class="btn btn-link"(click)="openAddNoteModal()">Add note</button></div>
    </div>
  </div>
</div>
