import {Pipe} from '@angular/core';

@Pipe({
  name: 'humanizeString'
})
export class HumanizeString {
  transform(value: String): String {
    if (!value) {
      return value;
    }

    return this.humanize(value);
  }

  capitalize(string) {
    return string.replace(/^\w/, c => c.toUpperCase());
  }

  underscore(string){
    string = string || '';
    string = string.toString();
    string = string.trim();
    string = string.replace(/([a-z\d])([A-Z]+)/g, '$1_$2');
    string = string.replace(/[-\s]+/g, '_').toLowerCase();

    return string;
  }

  extname(string) {
    const index = string.lastIndexOf('.');
    const ext = string.substring(index, string.length);

    return (index === -1) ? '' : ext;
  }

  humanize(string) {
    string = string || '';
    string = string.toString();
    string = string.trim();
    string = string.replace(this.extname(string), '');
    string = this.underscore(string);
    string = string.replace(/[\W_]+/g, ' ');

    return this.capitalize(string);
  }

}
