<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Trip Reasons</li>

  <li>
    <a href="#" (click)="openNewReasonModal()">
      <app-icon icon="plus" iconClass="button-icon"></app-icon> Add Trip Reason
    </a>
  </li>
</ol>

<div class="row">
  <div class="col-xs-12">
    <p-table
        [value]="reasonTypes"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="rows"
        [first]="first"
        [showCurrentPageReport]="true"
        selectionMode="single"
        [(selection)]="selectedReasonType"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
        stateStorage="session" 
        stateKey="state-session-reasons"
      >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th style="width:50%">
          <p-columnFilter 
          type="text" 
          field="name"
          [placeholder]="placeholders['name']"
          ariaLabel="Filter reason name" 
          [showMenu]="false"
          [showClearButton]="false"
          />
          </th>
          <th>
            <p-dropdown 
                [ngModel]="selectedCreatedAt" 
                [placeholder]="placeholders['created_at']"
                [options]="monthList" 
                optionLabel="title"
                [showClear]="true"
                (onChange)="pageChange($event)"
                >
            </p-dropdown>
          </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-reasonTypes>
      <tr [pSelectableRow]="reasonType">
        <td *ngFor="let key of keys">{{ reasonTypes?.[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
