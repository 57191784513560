<div *ngIf="!isEdit">
  <table class="table table-striped">
    <tbody>
      <tr>
        <td class="width-250">Active</td>
        <td class="capitalize">{{hospital.tiger_connect_active}}</td>
      </tr>

      <tr *ngIf="hospital.tiger_connect_active">
        <td>API Key</td>
        <td>{{hospital.tiger_connect_api_key}}</td>
      </tr>

      <tr *ngIf="hospital.tiger_connect_active">
        <td>API Secret</td>
        <td></td>
      </tr>

      <tr *ngIf="hospital.tiger_connect_active">
        <td>Default Organization ID</td>
        <td>{{hospital.tiger_connect_default_organization_id}}</td>
      </tr>
    </tbody>
  </table>

  <button type="button"
          class="btn btn-primary btn-wrap pull-right"
          (click)="toggleEdit()">
    Edit
  </button>
</div>

<div *ngIf="isEdit">
  <form class="form" [formGroup]="tigerConnectInfoForm">
    <table class="table table-striped">
      <tbody>
        <tr>
          <td class="width-250">Active</td>
          <td>
            <ui-switch formControlName="tiger_connect_active"
                      (change)="onChange($event)">
            </ui-switch>
          </td>
        </tr>

        <tr *ngIf="showFields">
          <td class="width-250">API Key</td>
          <td>
            <input type="text"
                  class="form-control"
                  required
                  formControlName="tiger_connect_api_key">
          </td>
        </tr>

        <tr *ngIf="showFields">
          <td class="width-250">API Secret</td>
          <td>
            <input type="text"
                  class="form-control"
                  [required]="tigerConnectInfoForm.get('tiger_connect_api_key').dirty"
                  formControlName="tiger_connect_api_secret">
          </td>
        </tr>

        <tr *ngIf="showFields">
          <td class="width-250">Default Organization ID</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="tiger_connect_default_organization_id">
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary"
            [disabled]="tigerConnectInfoForm.invalid && showFields"
            (click)="update()">
      Update
    </button>

    <button type="button"
            class="btn btn-default btn-wrap pull-right"
            (click)="toggleEdit()">
      Close
    </button>
  </div>
</div>
