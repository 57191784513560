import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';
import { LaunchDarklyService } from '../../../app/core/launchdarkly.service';

import { NewHospitalModalComponent } from '../new-modal/new-modal.component';
import { HospitalService } from '../hospital.service';
import { FlashService } from '../../components/flash/flash.service';
import { Hospital } from '../hospital';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
    '../../../themes/primeng-table.scss',
    './view.component.scss'
  ]
})
export class HospitalsViewComponent implements OnInit {
  newHospitalModal: BsModalRef;
  hospitals: Hospital[];
  loading = false;
  yesNoList = [];
  payerTypesList = [];
  reasonTypesList = [];
  vehicleList = [];
  selectedHospital: Hospital;
  

  tableConfig = {
    columns: {
      id: {
        title: 'ID',
        width: '75px'
      },

      name: {
        title: 'Name'
      },

      ride_share_buffer: {
        title: 'Advanced Notice for Ride Share Rides'
      },

      password_expiration: {
        title: 'Password Expiration'
      },

      created_on: {
        title: 'Created On',
        width: '200px',
        compareFunction: (direction, a, b) => {
          return (moment(a).isAfter(b) ? 1 : -1) * direction;
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  titles = Object.keys(this.tableConfig.columns).map(key => this.tableConfig.columns[key].title);
  keys = Object.keys(this.tableConfig.columns)
  isFeatureEnabled: boolean = false;  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private flashService: FlashService,
    private hospitalModal: HospitalService,
    private ld: LaunchDarklyService,

  ) {
  }

  ngOnInit() {
    this.yesNoList.push({value: 'Yes', title: 'Yes'});
    this.yesNoList.push({value: 'No', title: 'No'});

    this.route.data.subscribe((data) => {
      this.mutateHospitals(data.hospitals.data);
      this.payerTypesList = data.payerTypes.data;
      this.reasonTypesList = data.reasonTypes.data;
      this.vehicleList = this.hospitalModal.formatVehicles(data.vehicleTypes.data.data);
    });

    this.ld.subscribeFlag({
      flagName: 'Replace-Ng2-SmartTable',
      callback: (flagValue) => {
        this.isFeatureEnabled = flagValue;
      }
    });
  }

  public openNewHospitalModal() {
    const initialState = {
      payerTypesList: this.payerTypesList,
      reasonTypesList: this.reasonTypesList
    };
    this.newHospitalModal = this.modalService.show(NewHospitalModalComponent, {initialState, class: 'show-modal'});
    this.newHospitalModal.content.hospitals = this.hospitals;
    this.newHospitalModal.content.vehicleList = this.vehicleList;
    const hiddenSubscription = this.modalService.onHide.subscribe(reason => {
      if (this.newHospitalModal.content.added) {
        this.newHospitalModal.content.added = false;
        this.loading = true;

        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> Organization has been added.'
        });

        this.hospitalModal.get().subscribe(hospitals => {
          this.loading = false;
          this.mutateHospitals(hospitals.data);
          this.hospitals = hospitals.data;
        });
      }
    });
    return false;
  }

  public onRowSelect(event) {
    this.router.navigate(['/hospitals', event.data.id]);
  }
  
  private mutateHospitals(hospitals: Hospital[]) {
    if(!this.hospitals){
      this.hospitals = hospitals;
    }

    this.hospitals.forEach(hospital => {
      hospital['created_on'] = moment(hospital['created_at']).format('LLL');
      this.hospitalModal.convertRideShareBufferToMinutes(hospital);
      hospital['ride_share_buffer'] = hospital['advance_notice_for_ride_share_rides'];
      hospital['password_expiration'] = hospital.password_expiration_interval + ' ' +
        hospital.password_expiration_interval_type + '(s)';
      hospital['is_send_will_call_text'] = hospital.send_will_call_text ? 'Yes' : 'No';
    });
  }

}
