<form [formGroup]="quicksightReportForm">
    <table class="table table-striped">
        <ng-container formArrayName="reports">
            <tbody>
                <tr *ngFor='let report of reportMapping; let i = index' [formGroupName]="i">
                    <th class="width-250">{{report.report_name}}</th>
                    <td>
                        <ui-switch 
                        [(ngModel)]="report.enabled"
                        [disabled]="!editingMapping"
                        formControlName="value"></ui-switch>
                    </td>
                </tr>
            </tbody>
        </ng-container>
    </table>
</form>
<div *ngIf="!editingMapping">
    <div class="text-left">
        <button class="btn btn-primary" (click)="editMapping()">Edit</button>
    </div>
</div>
<div *ngIf="editingMapping" class="action-wrap text-left">
    <button type="button" class="btn btn-default" (click)="cancelEditing()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!quicksightReportForm.valid" (click)="update()">Update</button>
</div>