import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';

import { Customer } from '../customer';

@Component({
  selector: 'app-customer-view',
  templateUrl: './view.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
    '../../../themes/primeng-table.scss',
    './view.component.scss'
  ]
})
export class CustomersViewComponent implements OnInit {
  customers: Customer[];
  loading = false;
  selectedCustomer: Customer;


  tableConfig = {
    columns: {
      name: {
        title: 'Name'
      },
      connection_name: {
        title: 'Connection Name'
      },
      partner_api_client_id: {
        title: 'Partner API Client ID'
      },
      created_on: {
        title: 'Created On',
        width: '200px',
        compareFunction: (direction, a, b) => {
          return (moment(a).isAfter(b) ? 1 : -1) * direction;
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
    
  };
  
  titles = Object.keys(this.tableConfig.columns).map(key => this.tableConfig.columns[key].title);
  keys = Object.keys(this.tableConfig.columns)

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {

    this.route.data.subscribe((data) => {
      this.mutateCustomers(data.customers.customers);
    });
  }

  public onRowSelect(event) {
    this.router.navigate(['/customers', event.data.id]);
  }
  
  private mutateCustomers(customers: Customer[]) {
    if(!this.customers){
      this.customers = customers;
    }

    this.customers.forEach(customer => {
      customer['created_on'] = moment(customer['created_at']).format('LLL');
    });
  }

}
