interface defaultVehicleRate {
    vehicle: string;
    base: number;
    mileage: number; 
}
export const taxiDefaultRate:defaultVehicleRate = {
    vehicle: 'taxi',
    base: 10,
    mileage: 3.75
};
export const medicalSedanDefaultRate:defaultVehicleRate = {
    vehicle: 'medical_sedan',
    base: 25,
    mileage: 3.75
};
export const wheelchairDefaultRate:defaultVehicleRate = {
    vehicle: 'wheelchair',
    base: 60,
    mileage: 5.50
};