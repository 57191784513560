export interface HospitalIdentifier {
  id: number;
  id_value: String;
  id_type: String;
  encrypted_secret_key: String;
}

export interface RedoxObservationCodes {
  height: String;
  weight: String;
}

export interface PartnerAuthSetting {
  id: number;
  client_id: string;
  sqs_queue_url: string;
  webhook_url: string;
}

export interface Hospital {
  address: string;
  advance_notice_for_ride_share_rides: number;
  service_phone_number: string;
  bidding_tolerance: number;
  bidding_tolerance_enabled: boolean;
  children?: Hospital[];
  created_at?: Date;
  eligibility_term_type_id?: number;
  email_offer_text: string;
  enabled_vehicles: any;
  autoassign_company_id: number;
  autoassign_company_name: string;
  eta_submission_window: number;
  id: number;
  long_ride_threshold_in_miles: number;
  max_trip_cost: number;
  default_patient_budget: number;
  default_patient_rides_limit: number;
  name: string;
  parent?: Hospital;
  parent_id?: number;
  customer_name: string;
  customer_id: number;
  password_expiration_interval?: string;
  password_expiration_interval_type?: string;
  user_timeout_interval?: string;
  payer_types: any;
  pp_submission_window: number;
  reason_types: any;
  send_will_call_text: string;
  pcs_disabled: boolean;
  advanced_cancelation_enabled: boolean;
  advanced_cancelation_threshold: number;
  advanced_cancelation_reason: string;
  calling_providers_ticket_enabled: boolean;
  calling_providers_ticket_threshold: number;
  auth_disabled: boolean;
  show_long_ride_alert: string;
  patient_rides_limit_enabled: boolean;
  patient_budget_enabled: boolean;
  max_trip_cost_enabled: boolean;

  tax_id: string;
  transport_rates: any;
  updated_at?: Date;
  tiger_connect_active: boolean;
  tiger_connect_api_key: string;
  tiger_connect_api_secret: string;
  tiger_connect_default_organization_id: string;
  sso_enabled: boolean;
  patient_identifier: string;
  redox_observation_codes: RedoxObservationCodes;
  sso_identifiers: Array<HospitalIdentifier>;
  ingestion_identifiers: Array<HospitalIdentifier>;
  hl7_default_user_email: string;
  enable_sso_cc_creation: boolean;
  enable_unrestricted_sso_access: boolean;
  partner_auth_setting: PartnerAuthSetting;
  sso_auto_provision_enabled: boolean;
  organization_type: string;
  allow_rider_creation: boolean;
  measurement_unit: string;
  risk_surcharge: number;
  advanced_booking_threshold_enabled: boolean;
  advanced_booking_threshold: number;
}

const organizationTypesMap = {
  health_system: 'Health System',
  transportation_benefit: 'Transportation Benefit'
};

const organizationTypeOptions = [
  { id: 'health_system', name: 'Health System' },
  { id: 'transportation_benefit', name: 'Transportation Benefit' }
];

const measurementUnitOptions = [
  { id: 'metric', name: 'Metric' },
  { id: 'imperial', name: 'Imperial' }
];

const longRideThresholdOptions = [
  { id: 'long_ride_alert_disabled', name: 'Disabled' },
  { id: 'long_ride_alert_soft_stop', name: 'Soft Stop' },
  { id: 'long_ride_alert_hard_stop', name: 'Hard Stop' }
];

const measurementUnitMap = {
  metric: 'Metric',
  imperial: 'Imperial'
};

const longRideThresholdMap = {
  long_ride_alert_disabled: 'Disabled',
  long_ride_alert_soft_stop: 'Soft Stop',
  long_ride_alert_hard_stop: 'Hard Stop'
};

export { organizationTypesMap, organizationTypeOptions, measurementUnitOptions, longRideThresholdOptions, measurementUnitMap, longRideThresholdMap };
