import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { MultiSelectOption } from './multi-select';


@Component({
  selector: 'multi-select',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true
  }],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input() options: MultiSelectOption[] = [];

  checked = new Set();

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  private updateOptions() {
    this.options.forEach(option => option.checked = this.checked.has(option.name));
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(options) {
    if (options) {
      this.checked = new Set(options);
      this.updateOptions();
    }
  }

  onOptionClick(option: MultiSelectOption, checked: boolean) {
    if (checked) {
      this.checked.add(option.name)
    } else {
      this.checked.delete(option.name)
    }
    this.updateOptions();
    this.onChange(Array.from(this.checked));
  }
}
