export interface Payer {
  id: number;
  carrier: any;
  name: string;
  authorization_contact: string;
  nemt_benefit: boolean;
  category: string;
  display_name: string;
  normalized_insurance_data: Array<NormalizedInsuranceData>;
  planAlias: any;
  created_at?: Date;
  updated_at?: Date;
}

export interface ActivePlan {
  id: number;
  name: string;
  carrier_id: number;
  carrier_name?: string;
  plan_name?: string;
  authorization_contact: string;
  insurance_categories: Array<number>;
  covered_vehicles: Array<number>;
  auth_required: boolean;
  brokered_transport: boolean;
}

export interface PendingPlan {
  id: number;
  plan_name: string;
  company_name: string;
  company_phone: string;
  organization_name: string;
}

export interface Carrier {
  id: number;
  name: string;
}

export interface Note {
  updated_at: string;
  id: number;
  content: string;
  created_at: string;
  user_id: number;
  user_email: string;
  is_editable: boolean;
}

export const PAYER_HIDDEN_KEYS = {
  nemt_benefit: {
    value: false,
    keysHidden: [
      'authorization',
      'broker',
      'additional_requirements',
      'covered_vehicles',
      'auth_required',
      'preauth_required',
      'in_network_benefit',
      'in_network_auth_required',
      'out_of_network_benefit',
      'out_of_network_auth_required',
      'vehicles_requiring_auth',
      'same_day_auth_required',
      'post_transport_auth_window_in_days',
      'brokered_transport',
      'broker_name',
      'brokered_vehicle_types',
      'brokers_advance_notice_in_days',
      'broker_phone',
      'auth_clinical_form_required',
      'fax_number'
    ]
  },
  auth_required: {
    value: false,
    keysHidden: [
      'preauth_required',
      'in_network_benefit',
      'in_network_auth_required',
      'out_of_network_benefit',
      'out_of_network_auth_required',
      'vehicles_requiring_auth',
      'same_day_auth_required',
      'post_transport_auth_window_in_days'
    ]
  },
  out_of_network_benefit: {
    value: false,
    keysHidden: [
      'out_of_network_auth_required'
    ]
  },
  in_network_benefit: {
    value: false,
    keysHidden: [
      'in_network_auth_required'
    ]
  },
  same_day_auth_required: {
    value: true,
    keysHidden: [
      'post_transport_auth_window_in_days'
    ]
  },
  brokered_transport: {
    value: false,
    keysHidden: [
      'broker_name',
      'brokered_vehicle_types',
      'brokers_advance_notice_in_days',
      'broker_phone'
    ]
  },
  auth_clinical_form_required: {
    value: false,
    keysHidden: [
      'fax_number'
    ]
  }
};

export const HIDDEN_FIELDS_DEFAULT_VALUES = {
  covered_vehicles: [],
  auth_required: false,
  preauth_required: false,
  in_network_benefit: false,
  in_network_auth_required: false,
  out_of_network_benefit: false,
  out_of_network_auth_required: false,
  vehicles_requiring_auth: [],
  same_day_auth_required: false,
  post_transport_auth_window_in_days: null,
  brokered_transport: false,
  broker_name: null,
  brokered_vehicle_types: [],
  brokers_advance_notice_in_days: null,
  broker_phone: null,
  auth_clinical_form_required: false,
  fax_number: null
};

export const PAYER_TYPES = [
  {
    id: 0,
    itemName: 'Facility'
  },
  {
    id: 1,
    itemName: 'Medicaid'
  },
  {
    id: 2,
    itemName: 'Medicare'
  },
  {
    id: 3,
    itemName: 'Patient'
  },
  {
    id: 4,
    itemName: 'Private Insurance'
  },
  {
    id: 5,
    itemName: 'No Category'
  }
];

export const VEHICLE_TYPES_DISPLAY = {
  0: {
    displayName: 'No Car Seat',
    type: 'Sedan'
  },
  1: {
   displayName: 'Car Seat',
   type: 'Sedan'
  },
  2: {
    displayName: 'Oxygen & Wheelchair',
    type: 'Wheelchair'
  },
  3: {
    displayName: 'Oxygen & No Wheelchair',
    type: 'Wheelchair'
  },
  4: {
    displayName: 'No Oxygen & Wheelchair',
    type: 'Wheelchair'
  },
  5: {
    displayName: 'No Oxygen & No Wheelchair',
    type: 'Wheelchair'
  },
  6: {
    displayName: 'Car Seat',
    type: 'BLS'
  },
  7: {
    displayName: 'No Car Seat',
    type: 'BLS'
  },
  8: {
    displayName: 'Car Seat',
    type: 'ALS'
  },
  9: {
    displayName: 'No Car Seat',
    type: 'ALS'
  },
  10: {
    displayName: 'Car Seat',
    type: 'CCT'
  },
  11: {
    displayName: 'No Car Seat',
    type: 'CCT'
  },
  12: {
    displayName: 'No Oxygen',
    type: 'Stretcher'
  },
  13: {
    displayName: 'Oxygen',
    type: 'Stretcher'
  },
  14: {
    displayName: 'Taxi',
    type: 'Others'
  },
  15: {
    displayName: 'Secure Psych Transport',
    type: 'Others'
  }
};

// TODO: `als_car_seat` is missing from the list. Thus the ids from 8 to 12 are wrong.
export const VEHICLE_TYPES = [
  {
    id: 0,
    itemName : 'Sedan - No Car Seat'
  },
  {
    id: 1,
    itemName : 'Sedan - Car Seat'
  },
  {
    id: 2,
    itemName : 'Wheelchair - Oxygen, Wheelchair'
  },
  {
    id: 3,
    itemName : 'Wheelchair - Oxygen, No Wheelchair'
  },
  {
    id: 4,
    itemName : 'Wheelchair - No Oxygen, Wheelchair'
  },
  {
    id: 5,
    itemName : 'Wheelchair - No Oxygen, No Wheelchair'
  },
  {
    id: 6,
    itemName : 'BLS - Car Seat'
  },
  {
    id: 7,
    itemName : 'BLS - No Car Seat'
  },
  {
    id: 8,
    itemName : 'ALS - Car Seat'
  },
  {
    id: 9,
    itemName : 'ALS - No Car Seat'
  },
  {
    id: 10,
    itemName : 'CCT - Car Seat'
  },
  {
    id: 11,
    itemName : 'CCT - No Car Seat'
  },
  {
    id: 12,
    itemName : 'Stretcher Van - No Oxygen'
  },
  {
    id: 13,
    itemName : 'Stretcher Van - Oxygen'
  },
  {
    id: 14,
    itemName: 'Taxi'
  },
  {
    id: 15,
    itemName: 'Secure Psych Transport'
  }
];

export interface NormalizedInsuranceData {
  id: number;
  company_name: string;
  display_name: string;
  plan_name: string;
}

export const PAYER_KEYS = {
  general: 'General Info',
  authorization: 'Authorization',
  broker: 'Broker information',
  additional_requirements: 'Additional Requirements'
};

export const MONTHS_LIST = [
  {value: 'January', title: 'January'},
  {value: 'February', title: 'February'},
  {value: 'March', title: 'March'},
  {value: 'April', title: 'April'},
  {value: 'May', title: 'May'},
  {value: 'June', title: 'June'},
  {value: 'July', title: 'July'},
  {value: 'August', title: 'August'},
  {value: 'September', title: 'September'},
  {value: 'October', title: 'October'},
  {value: 'November', title: 'November'},
  {value: 'December', title: 'December'}
]
