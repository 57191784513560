import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ResolveFn
} from '@angular/router';
import { Observable } from 'rxjs';
import { PayerService } from './payer.service';
import { PendingPlan } from './payer';

export const PendingPlanDetailResolverService: ResolveFn<PendingPlan> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PendingPlan> => {
    const payerService: PayerService = inject(PayerService)

    return payerService.getPendingPlan(route.params.id);
  }
