<h2>
  Associated Plans
</h2>
<!--<div class="add-plan">-->
<!--  Add Plan-->
<!--</div>-->

<div class="row" [hidden]="associatedPlans.count() == 0">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="associatedPlans"
      (userRowSelect)="onRowSelect($event)"
    >
    </ng2-smart-table>
  </div>
</div>

<div [hidden]="associatedPlans.count() > 0">
  <div class="empty-state">
    <div class="empty-state-content">
      <span>No associated plans for this carrier</span>
    </div>
  </div>
</div>
