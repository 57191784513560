import * as _ from 'lodash';

export class AccessRules {
  static strategies = [
    {id: 'whitelist', name: 'Allowlist'},
    {id: 'blacklist', name: 'Denylist'}
  ];
  static strategiesWithDefault = [
    {id: 'default', name: 'Default'},
    {id: 'whitelist', name: 'Allowlist'},
    {id: 'blacklist', name: 'Denylist'}
  ];
  static strategiesMap = _.zipObject(
    _.map(AccessRules.strategies, 'id'),
    _.map(AccessRules.strategies, 'name')
  );
  static strategiesMapWithDefault = _.zipObject(
    _.map(AccessRules.strategiesWithDefault, 'id'),
    _.map(AccessRules.strategiesWithDefault, 'name')
  );
  static modelAttributes = [
    {id: 'vehicle_type', name: 'Vehicle type'},
    {id: 'payer_type', name: 'Payer type'}
  ];
  static modelAttributesMap = _.zipObject(
    _.map(AccessRules.modelAttributes, 'id'),
    _.map(AccessRules.modelAttributes, 'name')
  );
  static allowedValues = {
    vehicle_type: [
      {id: 'medical_sedan', name: 'Medical Sedan'},
      {id: 'taxi', name: 'Taxi'},
      {id: 'psych_transport', name: 'Secure Psychiatric Transport'},
      {id: 'wheelchair', name: 'Wheelchair'},
      {id: 'als', name: 'ALS'},
      {id: 'bls', name: 'BLS'},
      {id: 'cct', name: 'CCT'},
      {id: 'stretcher_van', name: 'Stretcher Van'}
    ],
    payer_type: [
      {id: 'facility', name: 'Facility'},
      {id: 'medicaid', name: 'Medicaid'},
      {id: 'medicare', name: 'Medicare'},
      {id: 'patient', name: 'Patient'},
      {id: 'private_insurance', name: 'Private Insurance'}
    ]
  };

  static allowedValuesMap = _.zipObject(
    _.keys(AccessRules.allowedValues),
    _.map(
      _.values(AccessRules.allowedValues),
      value => _.zipObject(
        _.map(value, 'id'),
        _.map(value, 'name')
      )
    )
  );

  static formatProviderAccessRules(data) {
    const filteredData = data.map(function(ruleset) {
      return {
        'strategy': ruleset['strategy'],
        'model_attribute': ruleset['model_attribute'],
        'value': ruleset['value'],
        'transportation_company_ids': ruleset['transportation_company_id'],
        'collapsed': false,
        'selectedProviderTemp': '',
        'dataSource': null
      };
    });

    // The following code groups the data so that all transportation_company_ids
    // get grouped as per strategy and then (model_attribute, value).
    let groupedData = _.groupBy(filteredData, (x) => x['strategy']);
    groupedData  = _.mapValues(groupedData, arr => {
      const a = _.groupBy(arr, x => `${x['model_attribute']}-${x['value']}`);
      const b = _.mapValues(a, c => ({
        model_attribute: c[0].model_attribute,
        value: c[0].value,
        transportation_company_ids: _.map(c, 'transportation_company_ids'),
        collapsed: c[0].collapsed,
        selectedProviderTemp: c[0].selectedProviderTemp,
        dataSource: c[0].dataSource
      }));

      return _.values(b);
    });

    return groupedData;
  }

  static generatePayload(data) {
    const payload = _.cloneDeep(data);
    const rulesetData = _.each(payload['ruleset_data'], x => {
      delete x['collapsed'];
      delete x['dataSource'];
      delete x['selectedProviderTemp'];
      return x;
    });

    return {
      strategy: payload['strategy'],
      ruleset_data: rulesetData
    };
  }
}
