<ol class="breadcrumb">
  <li class="active">Users</li>

  <li>
    <a href="#" (click)="openNewUserModal()">
      <app-icon icon="user-plus" iconClass="button-icon"></app-icon> Add User
    </a>
  </li>
</ol>
<tabset #tabset>
  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Care Coordinators
    </ng-template>
    <app-care-coordinators></app-care-coordinators>
  </tab>
  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Dispatchers
    </ng-template>
    <app-dispatchers></app-dispatchers>
  </tab>
  <tab (select)="tabChanged($event)">
    <ng-template tabHeading>
      Nav Center Users
    </ng-template>
    <app-admins></app-admins>
  </tab>
</tabset>