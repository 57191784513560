<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="compass" iconClass="button-icon"></app-icon> Add Provider
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal" [formGroup]="newProviderForm" novalidate>
    <div class="form-group">
      <label class="col-sm-3 control-label">Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" placeholder="Provider Name" formControlName="name">
        <div class="form-error" *ngIf="newProviderForm.get('name').invalid && newProviderForm.get('name').touched">
          <p>Name is required</p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="state" class="col-sm-3 control-label">State</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="state" [ngClass]="{ 'placeholder' : !newProviderForm.get('state').value }">
          <option class="" selected="" disabled="" value="">State</option>
          <option *ngFor="let type of statesOptions" [value]="type.value">
            {{type.title}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Phone</label>
      <div class="col-sm-9">
        <div class="input-group">
          <span class="input-group-addon">+1</span>
          <input type="tel" class="form-control" placeholder="Phone Number" formControlName="phone">
        </div>
        <div class="form-error" *ngIf="newProviderForm.get('phone').touched">
          <p *ngIf="newProviderForm.get('phone').errors?.pattern">Phone number should be in +15555555555 format.</p>
          <p *ngIf="newProviderForm.get('phone').errors?.required">Phone number is required</p>
          <p *ngIf="newProviderForm.get('phone').errors?.minlength || newProviderForm.get('phone').errors?.maxlength">
            Invalid length of phone number.
          </p>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" [disabled]="newProviderForm.invalid" (click)="addNewProvider()">
    Save Changes
  </button>
</div>
