<ol class="breadcrumb">
    <li><a routerLink="/b2c">B2C/B2B2C</a></li>
    <li class="active">{{user.first_name}} {{user.last_name}}</li>
  </ol>

<h1>{{user.first_name}} {{user.last_name}}</h1>

<tabset>
  <tab>
    <ng-template tabHeading>
      User Details
    </ng-template>
    <div *ngIf="!isEdit">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th width="200px">ID</th>
            <td>{{user.id}}</td>
          </tr>

          <tr>
            <th>Name</th>
            <td class="fs-block">{{user.first_name}} {{user.last_name}}</td>
          </tr>

          <tr>
            <th>Phone</th>
            <td class="fs-block">{{user.phone}}</td>
          </tr>

          <tr>
            <th>Email</th>
            <td class="fs-block">{{user.email}}</td>
          </tr>

          <tr>
            <th valign="top">Organization</th>
            <td valign="top">
              <span>{{user['hospital_name']}}</span>
            </td>
          </tr>

          <tr>
            <th>Created On</th>
            <td>{{user.created_at | date}}</td>
          </tr>

          <tr>
            <th>Updated On</th>
            <td>{{user.updated_at | date}}</td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary" (click)="toggleEdit()">
        Edit User
      </button>
    </div>
      <div *ngIf="isEdit">
        <form class="form-horizontal col col-sm-6" >
          <table class="table table-striped">
            <tbody>
              <tr>
                <th width="200px">ID</th>
                <td>{{user.id}}</td>
              </tr>

              <tr>
                <th>First Name</th>
                <td *ngIf="!isFederated">
                  <input type="text"
                        class="form-control"
                        name="first_name"
                        [(ngModel)]="user.first_name"
                        placeholder="First Name">
                </td>
                <td *ngIf="isFederated">
                  {{user.first_name}}
                </td>
              </tr>

              <tr>
                <th>Last Name</th>
                <td *ngIf="!isFederated">
                  <input type="text"
                        class="form-control"
                        name="last_name"
                        [(ngModel)]="user.last_name"
                        placeholder="Last Name">
                </td>
                <td *ngIf="isFederated">
                  {{user.last_name}}
                </td>
              </tr>

              <tr>
                <th>Phone</th>
                <td><input type="text"
                  class="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  pattern="[1-9]{1}[0-9]{9}"
                  [(ngModel)]="user.phone">
                  <div class="form-error" *ngIf="user.phone && (user.phone.length < 10 || user.phone.length > 12)">
                    <p>Phone number should be 10 digits.</p>
                  </div>
                </td>
              </tr>

              <tr>
                <th>Email</th>
                <td *ngIf="!isFederated">
                  <input type="email" class="form-control" id="email" name="email" placeholder="Email" [(ngModel)]="user.email">
                </td>
                <td *ngIf="isFederated">{{user.email}}</td>
              </tr>

              <tr>
                <th>Created On</th>
                <td>{{user.created_at | date}}</td>
              </tr>

              <tr>
                <th>Updated On</th>
                <td>{{user.updated_at | date}}</td>
              </tr>
            </tbody>
          </table>
          
          <div class="action-wrap text-left">
            <button type="button" class="btn btn-default" (click)="toggleEdit()">Close</button>
            <button type="button" class="btn btn-primary" (click)="update()">Update</button>
          </div>
        </form>
      </div>
  </tab>

</tabset>

