import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CustomPayerRenderComponent } from '../custom-payer-render/custom-payer-render.component';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NewCarrierModalComponent } from '../new-carrier-modal/new-carrier-modal.component';
import { Carrier } from '../payer';

@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
    './carrier-list.component.scss'
  ]
})
export class CarrierListComponent implements OnInit {
  carriers: AdminServerDataSource;
  activeCount: number;
  newCarrierModal: BsModalRef;

  tableConfig = {
    columns: {
      name: {
        title: 'Name',
        type: 'custom',
        renderComponent: CustomPayerRenderComponent,
      },
      updated_at: {
        title: 'Last Updated',
        filter: {
          type: 'list',
          config: {
            list: [
              {value: 'January', title: 'January'},
              {value: 'February', title: 'February'},
              {value: 'March', title: 'March'},
              {value: 'April', title: 'April'},
              {value: 'May', title: 'May'},
              {value: 'June', title: 'June'},
              {value: 'July', title: 'July'},
              {value: 'August', title: 'August'},
              {value: 'September', title: 'September'},
              {value: 'October', title: 'October'},
              {value: 'November', title: 'November'},
              {value: 'December', title: 'December'}
            ]
          }
        }
      }
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    noDataMessage: 'No Active carriers match your search.'
  };

  constructor(
    protected http: HttpClient,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.carriers = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/carriers`,
      formatDataFunction: this.formatCarrierData,
      metaCallbackFunction: this.updateBadgeCount
    });
  }

  formatCarrierData = (carriers) => {
    carriers.forEach((carrier) => {
      carrier.updated_at = moment(carrier.updated_at).format('LL');
    });
  }

  updateBadgeCount = (meta) => {
    this.activeCount = meta['x-total-count'];
  }

  onRowSelect(event) {
    this.router.navigate(['/payer/carriers', event.data.id]);
  }

  openNewCarrierModal() {
    this.newCarrierModal = this.modalService.show(NewCarrierModalComponent, {class: 'show-modal'});
    this.newCarrierModal.content.onDone.subscribe((carrier: Carrier) => {
      this.router.navigate(['/payer/carriers', carrier.id]);
    });
  }
}
