<div class="modal-header">
  <h4 class="modal-title pull-left">Suggested match</h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <table class="table">
    <tbody>
      <tr>
        <td class="view-column"><strong>Carrier name</strong></td>
        <td class="view-column">{{ activePlan.carrier_name || '-' }}</td>
      </tr>
      <tr>
        <td class="view-column"><strong>Plan name</strong></td>
        <td class="view-column">{{ activePlan.name || '-' }}</td>
      </tr>
      <tr>
        <td class="view-column"><strong>Phone number</strong></td>
        <td class="view-column">{{ activePlan.authorization_contact || '-' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="match()">Match</button>
</div>
