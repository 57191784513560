<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="home" iconClass="button-icon"></app-icon> Add Organization
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="newHospitalForm">
    <div class="form-group">
      <label for="name" class="col-sm-3 control-label">Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" placeholder="Name" formControlName="name">
        <div class="form-error" *ngIf="(newHospitalForm.get('name').touched || formInvalid) && newHospitalForm.get('name').invalid">
          <p>Name cannot be empty.</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="ride-share-buffer" class="col-sm-3 control-label">Advanced Notice for Ride Share Rides</label>
      <div class="col-sm-9">
        <input type="number" class="form-control" formControlName="advance_notice_for_ride_share_rides" placeholder="0 (in minutes)">
        <div class="form-error" *ngIf="(newHospitalForm.get('advance_notice_for_ride_share_rides').touched || formInvalid) && newHospitalForm.get('advance_notice_for_ride_share_rides').invalid">
          <p>Notice period cannot be empty.</p>
        </div>
        <p class="small">
          The Advanced Notice setting for Ride Share rides lets us know how far in advance we should request a Ride Share ride for patients. For a large city we recommend the default time period of 5 minutes. For suburban and rural areas we recommend changing this to at least 15 minutes.
        </p>
      </div>
    </div>

    <div class="form-group">
      <label for="service_phone_number" class="col-sm-3 control-label">Client Specific Service Phone Number</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" formControlName="service_phone_number">
        <div class="form-error" *ngIf="(newHospitalForm.get('service_phone_number').touched || formInvalid) && newHospitalForm.get('service_phone_number').invalid">
          <p>Service phone number cannot be empty.</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="customer_id" class="col-sm-3 control-label">Customer</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="customer_id">
          <option [ngValue]="null">None</option>
          <option *ngFor="let customer of customers" [value]="customer.id">
            {{customer.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label for="hospital_id" class="col-sm-3 control-label">Parent Organization</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="parent_id">
          <option *ngFor="let h of parentHospitals" [value]="h.id">
            {{h.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Organization Type</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="organization_type">
          <option *ngFor="let type of organizationTypeOptions" [value]="type.id">
            {{type.name}}
          </option>
        </select>
        <div class="form-error" *ngIf="(newHospitalForm.get('organization_type').touched || formInvalid) && newHospitalForm.get('organization_type').invalid">
          <p>Organization type cannot be empty.</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Payer Types</label>
      <div class="col-sm-9">
        <angular2-multiselect
          formControlName="payer_types"
          [data]="payerTypesList"
          [settings]="payerTypeSettings"
          (onDeSelect)="onPayerItemDeSelect($event)"
          (onDeSelectAll)="onPayerDeSelectAll()"
        >
          <c-item>
            <ng-template let-item="item">
              <label [ngClass]="{'disabled-option': item['required']}">{{item.itemName}}</label>
            </ng-template>
          </c-item>
        </angular2-multiselect>
        <div class="form-error" *ngIf="(newHospitalForm.get('payer_types').touched || formInvalid) && newHospitalForm.get('payer_types').invalid">
          <p>Please select at least one payer type.</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="hospital_id" class="col-sm-3 control-label">Password Expiration</label>
      <div class="col-sm-9">
        <div class="row">
          <div class="col col-sm-6">
            <input class="form-control" type="number" formControlName="password_expiration_interval">
          </div>
          <div class="col col-sm-6">
            <select class="form-control" formControlName="password_expiration_interval_type">
              <option value="day">Day(s)</option>
              <option value="week">Week(s)</option>
              <option value="year">Year(s)</option>
            </select>
          </div>
          <div class="col col-sm-12 form-error"
          *ngIf="(newHospitalForm.get('password_expiration_interval').touched ||
             newHospitalForm.get('password_expiration_interval_type').touched || formInvalid) &&
             newHospitalForm.get('password_expiration_interval').invalid ||
             newHospitalForm.get('password_expiration_interval_type').invalid" >
            <p>Invalid expiration period.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">User Session Timeout</label>
      <div class="col-sm-9">
        <input type="number" class="form-control" formControlName="user_timeout_interval">
        <div class="form-error" *ngIf="(newHospitalForm.get('user_timeout_interval').invalid || formInvalid) && newHospitalForm.get('user_timeout_interval').invalid">
          <p *ngIf="newHospitalForm.get('user_timeout_interval').errors?.min">The value should be greater than or equal to 15.</p>
          <p *ngIf="newHospitalForm.get('user_timeout_interval').errors?.max">The value should be less than or equal to 480.</p>
        </div>
        <p class="small">
          Please enter a value in minutes.
        </p>
      </div>
    </div>

    <div class="form-group">
      <label for="name" class="col-sm-3 control-label">Enabled Vehicles</label>
      <div class="col-sm-9">
        <angular2-multiselect [data]="vehicleList" [(ngModel)]="selectedVehicles"
                              [settings]="vehicleDropDownSettings"
                              (onSelect)="onVehicleItemSelect($event)"
                              (onDeSelect)="OnVehicleItemDeSelect($event)"
                              formControlName="enabled_vehicles">
        </angular2-multiselect>
        <div class="form-error" *ngIf="(newHospitalForm.get('enabled_vehicles').dirty || formInvalid) && newHospitalForm.get('enabled_vehicles').invalid">
          <p>Please select at least one option.</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Unit of Measurement</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="measurement_unit">
          <option *ngFor="let type of measurementUnitOptions; let i = index" [value]="type.id">
            {{type.name}}
          </option>
        </select>
        <div class="form-error" *ngIf="(newHospitalForm.get('measurement_unit').touched || formInvalid) && newHospitalForm.get('measurement_unit').invalid">
          <p>Unit of Measurement cannot be empty.</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Auto-Assign Transport Company</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="autoassign_company_id">
          <option value="" selected="selected">--Select--</option>
          <option *ngFor="let provider of activeProviders" [value]="provider.id">
            {{ provider.name }}
          </option>
        </select>
        <p class="small">
          All eligible rides booked with a vehicle type available at the selected transport company will bypass the Community and be automatically assigned to that transport company.
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Trip Reasons</label>
      <div class="col-sm-9">
        <angular2-multiselect [data]="reasonTypesList" [(ngModel)]="selectedReasonTypes"
                              [settings]="reasonTypesDropDownSettings"
                              (onSelect)="onReasonItemSelect($event)"
                              (onDeSelect)="OnReasonItemDeSelect($event)"
                              formControlName="reason_types">
        </angular2-multiselect>
        <div class="form-error" *ngIf="(newHospitalForm.get('reason_types').dirty || formInvalid) && newHospitalForm.get('reason_types').invalid">
          <p>Please select at least one option.</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Email Offer Text</label>
      <div class="col-sm-9">
        <textarea class="form-control" formControlName="email_offer_text" rows="5" placeholder="You are eligible for two free rides through the Facility program.">
        </textarea>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-lable">Send Will Call Ready Text?</label>
      <div class="col-sm-9">
        <ui-switch id="will_call" name="will_call" formControlName="send_will_call_text"></ui-switch>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-lable">Disabled PCS?</label>
      <div class="col-sm-9">
        <ui-switch id="pcs_disabled" name="pcs_disabled" formControlName="pcs_disabled"></ui-switch>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Address</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" placeholder="Address" formControlName="address">
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Tax ID</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" placeholder="xx-xxxxxxx" formControlName="tax_id">
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="addHospital()" [disabled]="formInvalid && newHospitalForm.invalid">Save Changes</button>
</div>
