import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { HospitalProgramService } from './hospital-program.service';
import { HospitalProgram } from './hospital-program';

export const ActiveProgramsResolver: ResolveFn<HospitalProgram[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<HospitalProgram[]> => {
  const hospitalProgramService = inject(HospitalProgramService);
  return hospitalProgramService.listActive(route.params.id);
};
