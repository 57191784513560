
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AuthHttpService } from '../core/auth-http.service';
import { Carrier } from './payer';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators'

@Injectable()
export class PayerService {

  constructor(
    private http: AuthHttpService
  ) { }

  // Active Plan related methods.
  getActivePlan(id): Observable<any> {
    return this.http
      .get(`${environment.apiurl}/carrier_plans/:id`.replace(':id', id)).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  createActivePlan(payload) {
    return this.http
      .post(`${environment.apiurl}/carrier_plans/`, payload).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  updateActivePlan(id, payload: any): Observable<any> {
    return this.http.update(`${environment.apiurl}/carrier_plans/:id`.replace(':id', id), payload).pipe(
      catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  getActivePlanNotes(activePlanId) {
    return this.http
      .get(`${environment.apiurl}/carrier_plans/:carrier_plan_id/notes/`.replace(':carrier_plan_id', activePlanId)).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  createActivePlanNotes(activePlanId, payload) {
    return this.http
      .post(`${environment.apiurl}/carrier_plans/:carrier_plan_id/notes/`.replace(':carrier_plan_id', activePlanId), payload).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  updateActivePlanNotes(activePlanId, noteId, payload) {
    return this.http
      .update(`${environment.apiurl}/carrier_plans/:carrier_plan_id/notes/:note_id`.replace(':carrier_plan_id', activePlanId).replace(':note_id', noteId), payload).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  deleteActivePlanNotes(activePlanId, noteId) {
    return this.http
      .delete(`${environment.apiurl}/carrier_plans/:carrier_plan_id/notes/:note_id`.replace(':carrier_plan_id', activePlanId).replace(':note_id', noteId)).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  // Pending Plan related methods.
  getPendingPlan(id): Observable<any> {
    return this.http
      .get(`${environment.apiurl}/insurance_plans/pending/:id`.replace(':id', id)).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  matchPendingPlan(id, payload: any): Observable<any> {
    return this.http
      .post(`${environment.apiurl}/insurance_plans/pending/:id/match`.replace(':id', id), payload).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  // Carrier related methods.
  listCarriers() {
    const url = `${environment.apiurl}/carriers/options`;
    return this.http
      .get<Carrier[]>(url).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  createCarrier(payload) {
    return this.http
      .post(`${environment.apiurl}/carriers`, payload).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  getCarrier(id): Observable<any> {
    return this.http
      .get(`${environment.apiurl}/carriers/:id`.replace(':id', id)).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  updateCarrier(id, payload: any): Observable<any> {
    return this.http.update(`${environment.apiurl}/carriers/:id`.replace(':id', id), payload).pipe(
      catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  getCarrierNotes(carrierId) {
    return this.http
      .get(`${environment.apiurl}/carriers/:carrier_id/notes/`.replace(':carrier_id', carrierId)).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  createCarrierNotes(carrierId, payload) {
    return this.http
      .post(`${environment.apiurl}/carriers/:carrier_id/notes/`.replace(':carrier_id', carrierId), payload).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  updateCarrierNotes(carrierId, noteId, payload) {
    return this.http
      .update(`${environment.apiurl}/carriers/:carrier_id/notes/:note_id`.replace(':carrier_id', carrierId).replace(':note_id', noteId), payload).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }

  deleteCarrierNotes(carrierId, noteId) {
    return this.http
      .delete(`${environment.apiurl}/carriers/:carrier_id/notes/:note_id`.replace(':carrier_id', carrierId).replace(':note_id', noteId)).pipe(
        catchError((error: HttpErrorResponse) => this.http.handleError(error)));
  }
}
