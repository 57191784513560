<app-navbar (menuButtonClicked)="menuButtonClicked()"></app-navbar>

<div class="flex-container">
  <app-sidebar [active]="showSidebar"></app-sidebar>

  <div class="container-fluid" [ngClass]="{'margin-left-200': showSidebar}">
    <app-flash></app-flash>
    <router-outlet></router-outlet>
  </div>
</div>
