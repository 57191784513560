import { Component, Input, OnInit } from '@angular/core';

import { Hospital, longRideThresholdMap, longRideThresholdOptions } from '../hospital';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { FlashService } from '../../components/flash/flash.service';
import { HospitalService } from '../hospital.service';
import { EligibilityTermService } from '../eligibility-term-type/eligibility-term-service';

import * as _ from 'lodash';


@Component({
  selector: 'app-benefit-management',
  templateUrl: './benefit-management.component.html',
  styleUrls: ['./benefit-management.component.scss'],
  providers:[EligibilityTermService]
})
export class BenefitManagementComponent implements OnInit {
  @Input() hospital: Hospital;
  benefitManagementForm: FormGroup;
  editingBenefits = false;
  isLoading = false;
  longRideThresholdOptions = longRideThresholdOptions;
  longRideThresholdMap = longRideThresholdMap;
  eligibilityTermTypes;


  constructor(private flashService: FlashService,
    protected hospitalService: HospitalService,
    protected eligibilityTermService: EligibilityTermService) {
      this.benefitManagementForm = new FormGroup({
        default_patient_rides_limit: new FormControl('', Validators.min(1)),
        show_long_ride_alert: new FormControl(false),
        long_ride_threshold_in_miles: new FormControl('', Validators.min(0)),
        max_trip_cost_enabled: new FormControl(false),
        default_patient_budget: new FormControl('', Validators.min(1)),
        patient_rides_limit_enabled: new FormControl(false),
        max_trip_cost: new FormControl('', Validators.min(0)),
        patient_budget_enabled: new FormControl(false),
        risk_surcharge: new FormControl('', Validators.min(0)),
        advanced_booking_threshold_enabled: new FormControl(false),
        advanced_booking_threshold: new FormControl('', Validators.min(0)),
        eligibility_term_type_id: new FormControl('')
      });
  }

  editBenefits() {
    this.editingBenefits = true;
    this.benefitManagementForm.get('eligibility_term_type_id')
    .setValue(this.hospital.eligibility_term_type_id);
    this.benefitManagementForm.get('max_trip_cost_enabled')
    .setValue(this.hospital.max_trip_cost_enabled);
    this.benefitManagementForm.get('max_trip_cost')
    .setValue(this.hospital.max_trip_cost);

    this.benefitManagementForm.get('show_long_ride_alert')
    .setValue(this.hospital.show_long_ride_alert);
    this.benefitManagementForm.get('long_ride_threshold_in_miles')
    .setValue(this.hospital.long_ride_threshold_in_miles);

    this.benefitManagementForm.get('patient_rides_limit_enabled')
    .setValue(this.hospital.patient_rides_limit_enabled);
    this.benefitManagementForm.get('default_patient_rides_limit')
    .setValue(this.hospital.default_patient_rides_limit);

    this.benefitManagementForm.get('patient_budget_enabled')
    .setValue(this.hospital.patient_budget_enabled);
    this.benefitManagementForm.get('default_patient_budget')
    .setValue(this.hospital.default_patient_budget);
    
    this.benefitManagementForm.get('risk_surcharge')
    .setValue(this.hospital.risk_surcharge);

    this.benefitManagementForm.get('advanced_booking_threshold_enabled')
    .setValue(this.hospital.advanced_booking_threshold_enabled);
    this.benefitManagementForm.get('advanced_booking_threshold')
    .setValue(this.hospital.advanced_booking_threshold);
  }

  cancelEditing() {
    this.benefitManagementForm.reset();
    this.editingBenefits = false;
  }
  update() {
    let payload = {
      eligibility_term_type_id: this.benefitManagementForm.get('eligibility_term_type_id').value,
      max_trip_cost_enabled : this.benefitManagementForm.get('max_trip_cost_enabled').value,
      patient_rides_limit_enabled : this.benefitManagementForm.get('patient_rides_limit_enabled').value,
      patient_budget_enabled : this.benefitManagementForm.get('patient_budget_enabled').value,
      show_long_ride_alert : this.benefitManagementForm.get('show_long_ride_alert').value,
      max_trip_cost : this.benefitManagementForm.get('max_trip_cost').value,
      long_ride_threshold_in_miles: this.benefitManagementForm.get('long_ride_threshold_in_miles').value,
      default_patient_budget: this.benefitManagementForm.get('default_patient_budget').value,
      default_patient_rides_limit: this.benefitManagementForm.get('default_patient_rides_limit').value,
      risk_surcharge: this.benefitManagementForm.get('risk_surcharge').value,
      advanced_booking_threshold_enabled : this.benefitManagementForm.get('advanced_booking_threshold_enabled').value,
      advanced_booking_threshold : this.benefitManagementForm.get('advanced_booking_threshold').value
    };
    this.hospitalService.benefitsUpdate(this.hospital.id.toString(), payload)
       .subscribe(
      (response) => {
        this.hospital = response.data;
        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> Benefits info has been updated.'
        });
        this.editingBenefits = false;
      },
      (error) => {
        this.editingBenefits = false;
      });
  }

  findEligibilityTermType(id:number){
    if(!this.eligibilityTermTypes && !this.isLoading){
      this.isLoading = true;
      this.eligibilityTermService.get().subscribe((response)=>{
        this.eligibilityTermTypes = response.eligibility_term_types
        this.isLoading = false;
      })
    }
    const eligibilityType = this.eligibilityTermTypes?.filter((type) => type.id === id)[0]
    return eligibilityType?.description
  }

  ngOnInit() {
    this.cancelEditing()
  }  
}

