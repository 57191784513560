import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthHttpService } from '../core/auth-http.service';
import { Customer, CustomerListResponse, CustomerResponse } from './customer';
import { Hospital } from '../hospitals/hospital';

@Injectable()
export class CustomerService {

  constructor(private http: AuthHttpService) {}

  create(customer: Customer): Observable<any> {
    const url = `${environment.apiv3url}/customers`;
    const payLoad = {
      customer: {
        name: customer.name,
        connection_name: customer.connectionName,
        partner_api_client_id: customer.clientId,
      }
    };

    return this.http.postV3<CustomerResponse>(url, payLoad).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  get(id?: string): Observable<any> {
    let url = `${environment.apiv3url}/customers`;

    if (id) {
      url += `/${id}`;
    }

    return this.http
      .getV3<CustomerListResponse>(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  update(payload?: any): Observable<any> {
    let url = `${environment.apiv3url}/customers`;

    if (payload.id) {
      url += `/${payload.id}`;
    }

    return this.http
      .updateV3<CustomerResponse>(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getHospitals(): Observable<Hospital> {
    let url = `${environment.apiv3url}/hospitals?hospital_type=organization`;
    return this.http.getV3(url);
  }

  getCustomerHospitals(customerId): Observable<Hospital> {
    let url = `${environment.apiv3url}/hospitals?customer_id=${customerId}`;
    return this.http.getV3(url);
  }

  updateHospitalCustomers(customerId, hospitals): Observable<any>[] {
    let body = {
      hospital: {
        customer_id: customerId
      }
    }
    
    const observableArray = [];
    hospitals.forEach(hospital => {
      let url = `${environment.apiv3url}/hospitals/${hospital.id}`;
      observableArray.push(this.http.updateV3(url, body).pipe(
        catchError((err) => this.http.handleError(err))));
    });

    return observableArray;
  }
}
