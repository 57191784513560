<div class="margin-10 fixed-width">
  <p class="margin-10 column">Integration Type</p>
  <dropdown [currentValue]="REDOX"
            [options]="integrationOptions"
            (change)="changeIntegrationType($event)">
  </dropdown>
</div>

<span *ngIf="redoxIntegrationType()">
  <app-redox-setup [hospital]="hospital"></app-redox-setup>
</span>

<span *ngIf="partnerIntegrationType()">
  <app-partner-setup [hospital]="hospital"></app-partner-setup>
</span>
