import { Component } from '@angular/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {
  showSidebar = true;

  constructor() { }

  menuButtonClicked() {
    this.showSidebar = !this.showSidebar;
  }

}
