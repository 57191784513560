import { inject } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Carrier } from './payer';
import { PayerService } from './payer.service';

export const CarriersResolverService: ResolveFn <Carrier[] | string> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Carrier[] | string> => {
    const payerService: PayerService = inject(PayerService);
    return payerService.listCarriers();
  }
