import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { HospitalService } from '../hospital.service';
import { PreferredProviders } from '../models/preferred-providers';
import { AccessRules } from '../models/access-rules';
import { FlashService } from '../../components/flash/flash.service';
import { LaunchDarklyService } from '../../core/launchdarkly.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-community-settings',
  templateUrl: './community-settings.component.html',
  styleUrls: ['./community-settings.component.scss']
})
export class CommunitySettingsComponent implements OnInit {
  isEdit = false;
  data = {
    autoassign_company_id: null,
    eta_submission_window: null,
    pp_submission_window: null,
    bidding_tolerance: null,
    bidding_tolerance_enabled: null,
    pp_strategy: null,
    provider_access_strategy: null,
    track_ride_on_release: null,
    broker_bypass_vehicle_types: null
  };
  cachedData;
  preferredProviderRulesets;
  providerAccessRules;
  hospitalId;
  activeProviders = [];
  providerDisplayNames = {};
  ppStrategyOptions = PreferredProviders.strategies;
  accessStrategyOptions = AccessRules.strategiesWithDefault;
  ppStrategyDisplayNames = PreferredProviders.strategiesMap;
  accessStrategyDisplayNames = AccessRules.strategiesMapWithDefault;
  ppModelAttributeDisplayNames = PreferredProviders.modelAttributesMap;
  accessRulesModelAttributeDisplayNames = AccessRules.modelAttributesMap;
  ppValueDisplayNames = PreferredProviders.allowedValuesMap;
  accessRulesValueDisplayNames = AccessRules.allowedValuesMap;
  vehicleList;
  brokerBypassVehicleTypes = [];
  brokerBypassDropdownSettings;
  vehicleTypesToAdd = [];
  vehicleTypesToRemove = [];

  constructor(
    private route: ActivatedRoute,
    private hospitalService: HospitalService,
    private flashService: FlashService,
    private ld: LaunchDarklyService
  ) { 

  }

  ngOnInit() {
    this.brokerBypassDropdownSettings = {
      singleSelection: false,
      text: 'Select Vehicle Types',
      selectAllText: 'Select All',
      unselectAllText: 'Unselect All',
      enableSearchFilter: false,
      badgeShowLimit: 4,
      autoPosition: false,
      tagToBody: false
    };
    this.route.params.subscribe((data) => {
      this.hospitalId = data.id;
    });

    this.route.data.subscribe((response) => {
      const hospitalData = response.hospital.data.hospital;
      this.setCommunityData(hospitalData);

      this.activeProviders = response.activeProviders.data;
      this.providerDisplayNames = _.zipObject(
        _.map(this.activeProviders, 'id'),
        _.map(this.activeProviders, 'name')
      );
      this.preferredProviderRulesets = PreferredProviders.formatPreferredProviderRules(
        response.preferredProviderRules.data
      );
      this.providerAccessRules = AccessRules.formatProviderAccessRules(
        response.providerAccessRules.data
      );
      this.vehicleList = this.mapVehicleTypes(response.activeVehicleTypes.vehicle_types);
      this.brokerBypassVehicleTypes = this.mapVehicleTypes(response.brokerBypassVehicleTypes.broker_bypass_vehicle_types);
    });
  }

  update() {
    forkJoin(this.hospitalService.addBrokerBypassVehicleTypes(this.hospitalId, this.vehicleTypesToAdd)).subscribe(() => {
      this.vehicleTypesToAdd = [];
    });
    forkJoin(this.hospitalService.removeBrokerBypassVehicleTypes(this.hospitalId, this.vehicleTypesToRemove)).subscribe(() => {
      this.vehicleTypesToRemove = [];
    });
    this.hospitalService
      .partialUpdate(this.hospitalId, this.data)
      .subscribe((response) => {
        this.isEdit = false;
        this.flashService.add({
          type: 'success',
          message: 'Community Settings have been updated successfully.'
        });
      });
  }

  setCommunityData(data) {
    this.data.autoassign_company_id = data.autoassign_company_id;
    this.data.eta_submission_window = data.eta_submission_window;
    this.data.pp_submission_window = data.pp_submission_window;
    this.data.bidding_tolerance = data.bidding_tolerance;
    this.data.bidding_tolerance_enabled = data.bidding_tolerance_enabled;
    this.data.pp_strategy = data.pp_strategy;
    this.data.provider_access_strategy = data.provider_access_strategy;
    this.data.track_ride_on_release = data.track_ride_on_release;
  }

  deletePPRulesGroup(strategy) {
    const payload = {
      strategy: strategy,
      ruleset_data: []
    };

    this.hospitalService
      .savePreferredProviderRules(this.hospitalId, payload)
      .subscribe(response => {
        delete this.preferredProviderRulesets[strategy];
        this.flashService.add({
          type: 'success',
          message: 'Preferred Provider Ruleset has been removed successfully.'
        });
      });
  }

  deleteAccessRulesGroup(strategy) {
    const payload = {
      strategy: strategy,
      ruleset_data: []
    };

    this.hospitalService
      .saveProviderAccessRules(this.hospitalId, payload)
      .subscribe(response => {
        delete this.providerAccessRules[strategy];
        this.flashService.add({
          type: 'success',
          message: 'Provider Access Ruleset has been removed successfully.'
        });
      });
  }

  enableEdit() {
    this.isEdit = true;
    this.cachedData = Object.assign({}, this.data);
  }

  disableEdit() {
    this.isEdit = false;
    this.setCommunityData(this.cachedData);
  }

  getKeys(obj) {
    return Object.keys(obj);
  }

  canAddNewPPRules() {
    const allowedPPrules = Object.keys(this.ppStrategyDisplayNames);
    const definedPPRules = Object.keys(this.preferredProviderRulesets);

    return _.difference(allowedPPrules, definedPPRules).length > 0;
  }

  canAddNewAccessRules() {
    const allowedAccessRules = Object.keys(AccessRules.strategiesMap);
    const definedAccessRules = Object.keys(this.providerAccessRules);

    return _.difference(allowedAccessRules, definedAccessRules).length > 0;
  }

  getProvidersDisplayNames(providerIds) {
    return providerIds.map(id => this.providerDisplayNames[id]).join(', ');
  }

  onBrokerBypassItemSelect(item: any) {
    if (this.vehicleTypesToRemove.some(v => v.id === item.id)) {
      this.vehicleTypesToRemove = this.vehicleTypesToRemove.filter(v => v.id === item.id);
    } else {
      this.vehicleTypesToAdd.push(item);
    }
  }

  onBrokerBypassItemDeSelect(item: any) {
    if (this.vehicleTypesToAdd.some(v => v.id === item.id)) {
      this.vehicleTypesToAdd = this.vehicleTypesToAdd.filter(v => v.id === item.id);
    } else {
      this.vehicleTypesToRemove.push(item);
    }
  }

  mapVehicleTypes(data) {
    return _.orderBy(
      _.map(data, function(item) {
        return { id: item.id, itemName: item.description };
      }), 'itemName', 'asc');
  }
}
