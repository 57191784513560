<div class="modal-header">
  <h4 class="modal-title pull-left">Add reason</h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal">
    <div class="form-group">
      <label for="name" class="col-sm-3 control-label">Reason name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="reason.name" required>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="addReasonType()" [disabled]="!reason.name.trim()">Add</button>
</div>
