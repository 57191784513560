import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FlashService } from '../components/flash/flash.service';
import * as moment from 'moment';
import { ReportsService } from './reports.service'
import { NewReportModalComponent } from './new-report/new-report.component';
import { Report } from './report';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['../../themes/primeng-table.scss',
                './reports.component.scss',]
  })


export class ReportsComponent implements OnInit{
    reports:Report[];
    selectedReport: Report;
    newReportModal: BsModalRef;
    action;
    loading = false;
    tableConfig = {
    columns: {
      uuid: {
        title: 'uuid',
        width: '250px'
      },

      report_name: {
        title: 'report_name',
        width: '250px'
      },

      created_at: {
        title: 'created_at',
        width: '200px',
        compareFunction: (direction, a, b) => {
          return (moment(a).isAfter(b) ? 1 : -1) * direction;
        }
      }
    },
    actions: {
        add: false,
        edit: false,
        delete: false
      }
    }

    titles = Object.keys(this.tableConfig.columns).map(key => this.tableConfig.columns[key].title);
    keys = Object.keys(this.tableConfig.columns)

    constructor(
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private flashService: FlashService,
        private reportsService: ReportsService,
      ) {}

    ngOnInit() {
        this.route.data.subscribe((data) => {
            this.reports = data.reports.data
          });
    }
    
    onRowSelect(event) {
        this.newReportModal = this.modalService.show(NewReportModalComponent, { initialState: { editingReport: true, report: event.data }, class: 'show-modal'});
        this.hiddenSubscription(this.modalService)
      }

    public openNewReportModal() {
        this.newReportModal = this.modalService.show(NewReportModalComponent, { initialState: { editingReport: false }, class: 'show-modal'});
        this.hiddenSubscription(this.modalService)
    }

    public determineAction(){
        for(const [key, value] of Object.entries(this.newReportModal.content)){
            if((key === 'added' || key === 'updated' || key === 'deleted') && (value)){
                this.action = key
            }
        }
    }

    public hiddenSubscription(modalService){
        const hiddenSubscription = modalService.onHidden.subscribe(reason => {
            this.determineAction();
              if(this.action){
                  this.newReportModal.content[this.action] = false
                    this.loading = true
          
                  this.flashService.add({
                    type: 'success',
                    message: `<strong>Success!</strong> Report has been ${this.action}.`
                  })
          
                  this.reportsService.list().subscribe(reports => {
                    this.reports = reports.data;
                    this.loading = false;
                  })
                  hiddenSubscription.unsubscribe()
              }
          });
    }
}