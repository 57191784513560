import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PayerService } from '../payer.service';
import * as _ from 'lodash';
import { Carrier } from '../payer';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-carrier-modal',
  templateUrl: './new-carrier-modal.component.html',
  styleUrls: ['./new-carrier-modal.component.scss']
})
export class NewCarrierModalComponent implements OnInit {
  @Output() public onDone: EventEmitter<Carrier> = new EventEmitter();
  @ViewChild('newCarrierForm', { static: false }) public newCarrierForm: NgForm;
  carrier = <Carrier>{
    name: ''
  };

  constructor(
    public modalRef: BsModalRef,
    private payerService: PayerService
  ) { }

  ngOnInit() {
  }

  save() {
    const payload = _.cloneDeep(this.carrier);

    this.payerService.createCarrier(payload)
    .subscribe((response: any) => {
      this.onDone.emit(<Carrier>{ id: response.data.id, name: response.data.name });
      this.modalRef.hide();
    },
      this.handleServerErrors
    );
  }

  handleServerErrors = (response) => {
    if (response.status === 400) {
      const errors = JSON.parse(response['_body']).errors;
      Object.keys(errors).forEach(prop => {
        const formControl = this.newCarrierForm.form.get(prop);
        if (formControl) {
          formControl.setErrors({
            serverError: errors[prop]
          });
        }
      });
    }
  }
}
