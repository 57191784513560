import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { HospitalService } from '../hospital.service';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { GeofenceRules, GeofenceRulesInfoData } from './geofence-rules';

@Component({
  selector: 'app-geofence-rules',
  templateUrl: './geofence-rules.component.html',
  styleUrls: ['./geofence-rules.component.scss']
})
export class GeofenceRulesComponent implements OnInit, OnChanges {
  geofenceRulesForm: FormGroup;
  isEditMode = false;
  duplicateRulesPresent = false;
  disable = false;
  reasonList: {
    id: number,
    itemName: string
  }[];
  selectedBoundaryTypes: string[];
  geofenceRules: GeofenceRules;
  data: GeofenceRulesInfoData;
  reasonTypesDropDownSettings = {
    singleSelection: false,
    text: 'Select Reason Types',
    selectAllText: 'Select All',
    unselectAllText: 'Unselect All',
    enableSearchFilter: false,
    badgeShowLimit: 4,
    tagToBody: false
  };

  @Input() hospitalId: number;
  @Input() geofenceStatus: string;
  constructor(
    private route: ActivatedRoute,
    private hospitalService: HospitalService
  ) {}

  ngOnInit() {
    if(this.geofenceStatus !== 'processed') {
      this.disable = true;
    }
    this.route.data.subscribe(response => {
      this.reasonList = _.map(response.hospitalTripReasonTypes.data, function(reason: { id: number, reason: string }) {
        return { id: reason.id, itemName: reason.reason };
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['geofenceStatus']) {
      this.geofenceStatus = changes['geofenceStatus']['currentValue'];
      this.getGeofenceRulesAndBoundaryTypes();
    }
  }

  getGeofenceRulesAndBoundaryTypes() {
    this.isEditMode = false;
    this.disable = true;
    this.data = {} as GeofenceRulesInfoData;
    if(this.geofenceStatus === 'processed') {
      let geofenceRules = this.hospitalService.getGeofenceRulesInfo(this.hospitalId);
      let boundaryTypes = this.hospitalService.getHospitalBoundaries(this.hospitalId);

      forkJoin([geofenceRules, boundaryTypes]).subscribe(results => {
        if(results[1].data.length > 0) {
          this.geofenceRules = new GeofenceRules(results[1].data);
          this.geofenceRules.setGeofenceRulesData(results[0].data, this.reasonList);
          this.data = this.geofenceRules.getGeofenceRulesData();
          this.disable = false;
        }
      });
    }
  }

  get rulesForm() {    
    return this.geofenceRulesForm.get('geofenceRules') as FormArray;
  }

  save() {
    let payload = this.geofenceRules.generatePayload(this.geofenceRulesForm.value);

    this.hospitalService.updateGeofenceRules(this.hospitalId, payload)
      .subscribe(
        response => {
          this.geofenceRules.setGeofenceRulesData(response.data, this.reasonList);
          this.toggleEdit();
        },
        () => {
          this.toggleEdit();
        }
      );
  }

  noGeofenceRulePresent() {
    return !this.data.geofenceRules || this.data.geofenceRules.length === 0;
  }

  toggleEdit() {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.generateFormControls();
    }
    else {
      this.selectedBoundaryTypes = null;
    }
    this.duplicateRulesPresent = false;
  }

  generateFormControls() {
    let formControls = {};

    let geofenceRules = this.data.geofenceRules.map(rule => {
      let formGroupOptions = {};
      formGroupOptions['pickupBoundaryTypeId'] = new FormControl(rule['pickupBoundaryType'].value, Validators.required);
      formGroupOptions['dropoffBoundaryTypeId'] = new FormControl(rule['dropoffBoundaryType'].value, Validators.required);
      formGroupOptions['reasons'] = new FormControl(rule['reasons']);
      return new FormGroup(formGroupOptions);
    });
    
    formControls['geofenceRulesEnabled'] = new FormControl(this.data.geofenceRulesEnabled);
    formControls['geofenceRules'] = new FormArray(geofenceRules);
    this.geofenceRulesForm = new FormGroup(formControls);
    this.setSelectedBoundaryTypes();
  }

  setSelectedBoundaryTypes() {
    let geofenceRules = this.geofenceRulesForm.getRawValue().geofenceRules;
    this.selectedBoundaryTypes = geofenceRules.map(function(rule) {
      return rule.pickupBoundaryTypeId + ' ' + rule.dropoffBoundaryTypeId;
    });
  }

  addRestrictionRules() {   
    this.rulesForm.push(new FormGroup({
      pickupBoundaryTypeId: new FormControl(null, Validators.required),
      dropoffBoundaryTypeId: new FormControl(null, Validators.required),
      reasons: new FormControl(null)
    }));
    this.selectedBoundaryTypes.push('');
  }

  removeRestrictionRules(index: number) {
    this.rulesForm.removeAt(index);
    this.selectedBoundaryTypes.splice(index, 1);
    this.checkDuplicateRulesPresent(-1);
  }

  checkDuplicateRulesPresent(index: number) {
    if(index !== -1) {
      let geofenceRule = this.geofenceRulesForm.getRawValue().geofenceRules[index];
      this.selectedBoundaryTypes[index] = geofenceRule.pickupBoundaryTypeId + ' ' + geofenceRule.dropoffBoundaryTypeId;
    }
    this.duplicateRulesPresent = (this.selectedBoundaryTypes.length !== _.uniq(this.selectedBoundaryTypes).length);
  }

  showReasons(reasons: { id: number, itemName: string }[]) {
    return _.map(reasons, 'itemName').join(', ');
  }
}
