import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LaunchDarklyService } from '../../core/launchdarkly.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input()
  active = true;

  selectedRoute: string;

  constructor(
    private router: Router,
    private ld: LaunchDarklyService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectedRoute = event.url;
      }
    });
  }
  

  isSelected(route: string | string[]): boolean {
    if (!this.selectedRoute) {
      return false;
    }

    if (typeof route === 'string') {
      return this.selectedRoute === route;
    } else if (Array.isArray(route)) {
      let match = false;
      let curIndex = 0;

      const routeArray = this.selectedRoute.split('/').filter(x => x);

      while (!match && curIndex < routeArray.length) {
        match = !!(route.indexOf(`/${routeArray[curIndex]}`) > -1);

        if (!match) {
          curIndex += 1;
        }
      }

      return match;
    }
  }

}
