import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ComponentsModule } from '../components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Ng2SmartTableModule } from 'rt-ng2-smart-table';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CareCoordinatorsComponent } from './care-coordinators/care-coordinators.component';
import { UsersB2CComponent } from './b2c/b2c.component';

import { UserService } from './user.service';
import { B2CResolver } from './b2c-resolver.service';
import { UnconfirmedUserResolver } from './unconfirmed-user-resolver.service';
import { UserDetailsResolver } from './user-details-resolver.service';
import { HospitalsModule } from '../hospitals/hospitals.module';
import { HospitalService } from '../hospitals/hospital.service';
import { B2CUserViewComponent } from './b2c/view/view.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UserTabsComponent } from './user-tabs/user-tabs.component';
import { DispatchersComponent } from './dispatchers/dispatchers.component';
import { AdminsComponent } from './admins/admins.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    Ng2SmartTableModule,
    TableModule,
    DropdownModule,
    UiSwitchModule,
    HospitalsModule,
    ReactiveFormsModule,
    RouterModule,
    TypeaheadModule,
    AngularMultiSelectModule,
  ],

  declarations: [
    UsersB2CComponent,
    NewUserModalComponent,
    EditUserComponent,
    B2CUserViewComponent,
    CareCoordinatorsComponent,
    DispatchersComponent,
    UserTabsComponent,
    AdminsComponent
  ],

  exports: [
    UsersB2CComponent,
    B2CUserViewComponent,
    CareCoordinatorsComponent,
    DispatchersComponent,
    AdminsComponent,
    UserTabsComponent
  ],

  providers: [
    UserService,
    { provide: 'B2CResolver', useValue: B2CResolver },
    HospitalService,
    { provide: 'UnconfirmedUserResolver', useValue: UnconfirmedUserResolver },
    { provide: 'UserDetailsResolver', useValue: UserDetailsResolver },
  ]
})

export class UsersModule { }
