import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '../components/components.module';

import { BaseComponent } from './base.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule
  ],

  declarations: [
    BaseComponent
  ],

  exports: [
    BaseComponent
  ],

  providers: []
})
export class BaseModule { }
