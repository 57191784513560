<ol class="breadcrumb">
  <li><a routerLink="/promo-codes">Promo Codes</a></li>
  <li class="active fs-block">{{promoCodeForm.get('promo_code').value}}</li>
</ol>

<h1 class="fs-block">Promo Code - {{promoCodeForm.get('promo_code').value}}</h1>

<div class="row">
  <div class="col-sm-10">
    <form class="form-horizontal">
      <div class="form-group">
        <label class="col-sm-3 control-label">Active?</label>
        <div class="col-sm-9">
          <ui-switch id="active" name="active" [(ngModel)]="promoCodeActive" (click)="this.toggleActive()">
          </ui-switch>
        </div>
      </div>
    </form>
    <form class="form-horizontal" [formGroup]="promoCodeForm" >
      <div class="form-group">
        <label class="col-sm-3 control-label">Code</label>
        <div class="col-sm-9">
          <input type="text" class="form-control fs-block" name="code" required
                  placeholder="Code" formControlName="promo_code">
          <div class="form-error" *ngIf="promoCodeForm.get('promo_code').invalid && promoCodeForm.get('promo_code').touched">
            <p>Code is required</p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="discount_type" class="col-sm-3 control-label">Discount Type</label>
        <div class="col-sm-9">
          <select class="form-control" formControlName="discount_type">
            <option selected="" disabled="" value="">Discount Type</option>
            <option *ngFor="let type of discountTypes" [value]="type.value">
              {{type.title}}
            </option>
          </select>
          <div class="form-error" *ngIf="promoCodeForm.get('discount_type').invalid && promoCodeForm.get('discount_type').touched">
            <p>Promo type is required</p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-9 col-sm-offset-3">
          <div class="input-group">
            <span class="input-group-addon" *ngIf="promoCodeForm.get('discount_type').value === 'dollar_amount'">$</span>
            <input type="number" class="form-control" *ngIf="promoCodeForm.get('discount_type').value"
            placeholder="{{ promoCodeForm.get('discount_type').value === 'percentage_off' ? 'Percentage' : 'Dollars'}}" formControlName="discount">
            <span class="input-group-addon" *ngIf="promoCodeForm.get('discount_type').value === 'percentage_off'">%</span>
          </div>
          <div class="form-error" *ngIf="promoCodeForm.get('discount').invalid &&
          promoCodeForm.get('discount').touched && promoCodeForm.get('discount_type').value === 'dollar_amount'">
            <p>Amount cannot be negative</p>
          </div>
          <div class="form-error" *ngIf="promoCodeForm.get('discount').invalid &&
          promoCodeForm.get('discount').touched && promoCodeForm.get('discount_type').value === 'percentage_off'">
            <p>Percentage should be between 0 and 100</p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label">Description (Optional)</label>
        <div class="col-sm-9">
          <textarea class="form-control" formControlName="description" rows="5" placeholder="For eg. 10% OFF, 5$ OFF etc">
          </textarea>
        </div>
      </div>
    </form>
    <div class="row action-wrap">
      <div class="col" *ngIf="!isEditingPromoCode">
        <button type="button" class="btn btn-primary" (click)="editPromoCode()">Edit</button>
      </div>
      <div class="col" *ngIf="isEditingPromoCode">
        <button type="button" class="btn btn-default" (click)="cancelEditing()">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="promoCodeForm.invalid" (click)="updatePromoCode()">Update</button>
      </div>
    </div>
  </div>
</div>
