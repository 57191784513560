import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';

interface Filter {
  value: any;
}

interface Event {
  first: number;
  rows: number;
  filters: { [key: string]: Filter };
  originalEvent:any;
  value?: {label: string, value: any};
}

@Component({
  selector: 'app-care-coordinators',
  templateUrl: './care-coordinators.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
    './care-coordinators.component.scss'
  ]
})


export class CareCoordinatorsComponent implements OnInit, OnDestroy {
  users: [];
  selectedUser: AdminServerDataSource;
  selectedHospital: any;
  selectedIsAdminUser: any;
  selectedIsConfirmed: any;
  selectedIsActive: any;
  selectedMonth: any;
  loading = false;
  hospitalMapper: any;
  hospitalFilterList = [];
  first = 0;
  last: number;
  rows = 10;
  totalRecords: number = 0;
  pageOffset: number = 0;
  page: number = 1;
  searchCriteria: any[] = [];

  placeholders = {
    hospital_id: 'Select Organization',
    role: ' ',
    confirmed_at: ' ',
    active: ' ',
    created_at: ' ',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email'
  };

  isAdminList = [{value: true, title: 'Yes', label: 'role'},
    {value: false, title: 'No', label: 'role'}]

  confirmedList = [{value: true, title: 'Yes', label: 'confirmed_at'},
    {value: false, title: 'No', label: 'confirmed_at'}]

  activeList = [{value: true, title: 'Yes', label: 'active'},
    {value: false, title: 'No', label: 'active'}]

  monthList = [
    {value: 'january', title: 'January', label: 'created_at'},
    {value: 'february', title: 'February', label: 'created_at'},
    {value: 'march', title: 'March', label: 'created_at'},
    {value: 'april', title: 'April', label: 'created_at'},
    {value: 'may', title: 'May', label: 'created_at'},
    {value: 'june', title: 'June', label: 'created_at'},
    {value: 'july', title: 'July', label: 'created_at'},
    {value: 'august', title: 'August', label: 'created_at'},
    {value: 'september', title: 'September', label: 'created_at'},
    {value: 'october', title: 'October', label: 'created_at'},
    {value: 'november', title: 'November', label: 'created_at'},
    {value: 'december', title: 'December', label: 'created_at'}
  ]

  tableConfig = {
    columns: {
      first_name: {
        title: 'First Name'
      },

      last_name: {
        title: 'Last Name'
      },

      email: {
        title: 'Email'
      },

      hospital_id: {
        title: 'Organization',
      },

      role: {
        title: 'Admin User?',
      },

      confirmed_at: {
        title: 'Confirmed?',
      },

      active: {
        title: 'Active?',
      },

      created_at: {
        title: 'Created On',
        width: '200px',
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  keys = Object.keys(this.tableConfig.columns);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected http: HttpClient
  ) {}

  resetPlaceHolder(filterName){
    if(filterName === 'hospital_id'){
      this.placeholders[filterName] = 'Select Organization';
    }
    else{
      this.placeholders[filterName] = ' ';
    }
  }
  
  addOrUpdateSearchCriteria(searchTerm, value){
    const existingEntry = this.searchCriteria.find(entry => entry[searchTerm] !== undefined);
      if(existingEntry){
        existingEntry[searchTerm] = value;
      } else {
        this.searchCriteria.push({[searchTerm]: value});
      }
  }

  appendPagination(event?: Event){
    this.pageOffset = event.first / event.rows;
    if(!this.pageOffset){
      this.pageOffset = 0;
    }
    this.page = this.pageOffset + 1;
    this.addOrUpdateSearchCriteria('page', this.page);
    this.addOrUpdateSearchCriteria('per_page', event.rows ? event.rows : this.rows);
  }

  removeSearchCriteria(filterName){
    const index = this.searchCriteria.findIndex(entry => entry[filterName] !== undefined);
    if (index !== -1) {
      this.searchCriteria.splice(index, 1);
    }
  }

  pageChange(event, filterName?: string) {
    if(event.value === null && filterName){
      this.removeSearchCriteria(filterName)
      this.resetPlaceHolder(filterName);
    }
    this.loadUsers(event);
  }

  processEventFilters(event?: Event){
    // Depending on the filter clicked, the event object will have a different structure
    console.log('event', event);
    if (event.filters){
      Object.entries(event.filters).forEach(([key, value]) => {
        if(value?.value !== null){
          this.addOrUpdateSearchCriteria(key, value.value);
        }
      });
    }
    if(event.value){
      this.addOrUpdateSearchCriteria(event.value.label, event.value.value);
    }
  }

  buildURL(event?: Event ){
    let url = 'users?user_filter=care_coordinators'
    this.appendPagination(event);
    this.processEventFilters(event);
    this.searchCriteria.map((criteria) => {
      Object.entries(criteria).forEach(([key, value]) => {
        if(value !== null && value !== undefined && value !== ''){
          url += `&${key}=${value}`;
        }
      })
    })
    return url;
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.hospitalMapper = data.hospitals.data.sort((a, b) => (a.name < b.name ? -1 : 1)).reduce((map, hospital) => {
        map[hospital.id] = hospital.name;
  
        this.hospitalFilterList.push({
          value: hospital.id,
          title: hospital.name,
          label: 'hospital_id'
        });
  
        return map;
      }, {});
    });    
  }

  ngOnDestroy() {
    sessionStorage.setItem('searchCriteriaCC', JSON.stringify(this.searchCriteria));
  }

  processSavedCriteria(criterionKey: string, filterListKey: string, selectedKey: string) {
    const criterion = this.searchCriteria.find(criteria => criteria[criterionKey] !== undefined);
    if (criterion) {
      this[selectedKey] = this[filterListKey].find(criteria => criteria.value === criterion[criterionKey]).title;
      this.placeholders[criterionKey] = this[selectedKey];
    }
  }

  loadUsers(event?:Event) {
    if (!this.searchCriteria || this.searchCriteria.length === 0) {
      const savedSearchCriteria = sessionStorage.getItem('searchCriteriaCC');
      if (savedSearchCriteria) {
        this.searchCriteria = JSON.parse(savedSearchCriteria);
        this.processSavedCriteria('hospital_id', 'hospitalFilterList', 'selectedHospital');
        this.processSavedCriteria('role', 'isAdminList', 'selectedIsAdminUser');
        this.processSavedCriteria('confirmed_at', 'confirmedList', 'selectedIsConfirmed');
        this.processSavedCriteria('active', 'activeList', 'selectedIsActive');
        this.processSavedCriteria('created_at', 'monthList', 'selectedMonth');
      }
    }
    const url = this.buildURL(event);
    const dataSource = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/${url}`,
      formatDataFunction: this.mutateUsers.bind(this)
    });
    dataSource.getElements().then(data => {
      this.totalRecords = dataSource.count();
      this.users = data;
    });
  }  

  public onRowSelect(event) {
    this.router.navigate(['/users', event.data.id]);
  }

  private mutateUsers(users) {
    users.forEach(user => {
      user['hospital_id'] =  this.hospitalMapper[user['hospital_id']] || '-';
      user['created_at'] = moment(user['created_at']).format('LLL');
      user['confirmed_at'] = user['confirmed_at'] ? 'Yes' : 'No';
      user['active'] = user['active'] ? 'Yes' : 'No';
      user['role'] = user['is_admin_cc'] === true ? 'Yes' : 'No';
    });
  }
}
