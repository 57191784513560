import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NewCarrierModalComponent } from '../new-carrier-modal/new-carrier-modal.component';
import { Carrier } from '../payer';

interface Filter {
  value: any;
}

interface Event {
  first: number;
  rows: number;
  filters: { [key: string]: Filter };
  originalEvent:any;
  value?: {label: string, value: any};
}

@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
    './carrier-list.component.scss'
  ]
})
export class CarrierListComponent implements OnDestroy {
  carriers: Carrier[] = [];
  selectedCarrier: Carrier;
  activeCount: number;
  newCarrierModal: BsModalRef;
  loading = false;
  selectedUpdatedAt: any;
  first = 0;
  last: number;
  rows = 10;
  totalRecords: number = 0;
  pageOffset: number = 0;
  page: number = 1;
  searchCriteria: any[] = [];

  placeholders = {
    name: 'Carrier name',
    updated_at: ' ',
  };

  monthList = [
    {value: 'January', title: 'January', label: 'updated_at'},
    {value: 'February', title: 'February', label: 'updated_at'},
    {value: 'March', title: 'March', label: 'updated_at'},
    {value: 'April', title: 'April', label: 'updated_at'},
    {value: 'May', title: 'May', label: 'updated_at'},
    {value: 'June', title: 'June', label: 'updated_at'},
    {value: 'July', title: 'July', label: 'updated_at'},
    {value: 'August', title: 'August', label: 'updated_at'},
    {value: 'September', title: 'September', label: 'updated_at'},
    {value: 'October', title: 'October', label: 'updated_at'},
    {value: 'November', title: 'November', label: 'updated_at'},
    {value: 'December', title: 'December', label: 'updated_at'}
  ]

  tableConfig = {
    columns: {
          name: {
            title: 'Name'
          },
    
          updated_at: {
            title: 'Last Updated'
          },
        },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
  };

  constructor(
    protected http: HttpClient,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  keys = Object.keys(this.tableConfig.columns);

  resetPlaceHolder(filterName){
      this.placeholders[filterName] = ' ';
    }
    
    addOrUpdateSearchCriteria(searchTerm, value){
      const existingEntry = this.searchCriteria.find(entry => entry[searchTerm] !== undefined);
        if(existingEntry){
          existingEntry[searchTerm] = value
        }
        else {
          this.searchCriteria.push({[searchTerm]: value});
        }
    }
  
    appendPagination(event?: Event){
      this.pageOffset = event.first / event.rows;
      if(!this.pageOffset){
        this.pageOffset = 0;
      }
      this.page = this.pageOffset + 1;
      this.addOrUpdateSearchCriteria('page', this.page);
      this.addOrUpdateSearchCriteria('per_page', event.rows ? event.rows : this.rows);
    }
  
    removeSearchCriteria(filterName){
      const index = this.searchCriteria.findIndex(entry => entry[filterName] !== undefined);
      if (index !== -1) {
        this.searchCriteria.splice(index, 1);
      }
    }
  
    pageChange(event, filterName?: string) {
      if(event.value === null && filterName){
        this.removeSearchCriteria(filterName)
        this.resetPlaceHolder(filterName);
      }
      this.loadCarriers(event);
    }
  
    processEventFilters(event?: Event){
      // Depending on the filter clicked, the event object will have a different structure
      if (event.filters){
        Object.entries(event.filters).forEach(([key, value]) => {
          if(value?.value !== null){
            this.addOrUpdateSearchCriteria(key, value.value);
          }
        });
      }
      if(event.value){
        this.addOrUpdateSearchCriteria(event.value.label, event.value.value);
      }
    }
  
    buildURL(event?: Event ){
      let url = '/carriers?'
      this.appendPagination(event);
      this.processEventFilters(event);
      this.searchCriteria.map((criteria) => {
        Object.entries(criteria).forEach(([key, value]) => {
          if(value !== null && value !== undefined && value !== ''){
            url += `&${key}=${value}`;
          }
        })
      })
      return url;
    }
  
    ngOnDestroy() {
      sessionStorage.setItem('searchCriteriaCarriers', JSON.stringify(this.searchCriteria));
    }
  
    processSavedCriteria(criterionKey: string, filterListKey: string, selectedKey: string) {
      const criterion = this.searchCriteria.find(criteria => criteria[criterionKey] !== undefined);
      if (criterion) {
        this[selectedKey] = this[filterListKey].find(criteria => criteria.value === criterion[criterionKey]).title;
        this.placeholders[criterionKey] = this[selectedKey];
      }
    }
  
    loadCarriers(event?:Event) {
      if (!this.searchCriteria || this.searchCriteria.length === 0) {
        const savedSearchCriteria = sessionStorage.getItem('searchCriteriaCarriers');
        if (savedSearchCriteria) {
            this.searchCriteria = JSON.parse(savedSearchCriteria);
            this.processSavedCriteria('updated_at', 'monthList', 'selectedUpdatedAt');
        }
      }
      const url = this.buildURL(event);
      this.http.get(`${environment.apiurl}/${url}`, { observe: 'response' }).subscribe((response: any) => {
        this.totalRecords = response.body.meta['x-total-count'];
        this.carriers = this.formatCarrierData(response.body.data);
        this.updateBadgeCount(response.body.meta);
      });
    }

  formatCarrierData = (carriers) => {
    carriers.forEach((carrier) => {
      carrier.updated_at = moment(carrier.updated_at).format('LL');
    });
    return carriers;
  }

  updateBadgeCount = (meta) => {
    this.activeCount = meta['x-total-count'];
  }

  onRowSelect(event) {
    this.router.navigate(['/payer/carriers', event.data.id]);
  }

  openNewCarrierModal() {
    this.newCarrierModal = this.modalService.show(NewCarrierModalComponent, {class: 'show-modal'});
    this.newCarrierModal.content.onDone.subscribe((carrier: Carrier) => {
      this.router.navigate(['/payer/carriers', carrier.id]);
    });
  }
}
