import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { HospitalService } from './hospital.service';

@Injectable()
export class HospitalPayerTypesResolverService  {
  constructor(private hospitalService: HospitalService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.hospitalService.getPayerTypes();
  }
}
