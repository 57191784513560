import {Component, NgZone, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {PromoCode} from '../promo-code';
import DiscountTypes from '../promo-code';
import {PromoCodeService} from '../promo-code.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {AuthHttpService} from '../../core/auth-http.service';

@Component({
  selector: 'app-new-promo-code',
  templateUrl: './new-promo-code.component.html',
  styleUrls: ['./new-promo-code.component.scss']
})
export class NewPromoCodeComponent implements OnInit {
  code = new PromoCode();
  added = false;
  discountTypes = DiscountTypes;
  newPromoForm: FormGroup;
  error: String;
  promoAlreadyExists = false;
  constructor(
    public modalRef: BsModalRef,
    private promoCodeService: PromoCodeService,
    private http: AuthHttpService
  ) {}

  ngOnInit() {
    this.newPromoForm = new FormGroup ({
      promo_code: new FormControl('', Validators.required),
      discount_type: new FormControl('', Validators.required),
      discount: new FormControl(''),
      description: new FormControl('')
    });

    this.newPromoForm.valueChanges
    .subscribe(() => {
      this.promoAlreadyExists = false;
    });

    this.newPromoForm.get('discount_type').valueChanges
    .subscribe(val => {
      const discount = this.newPromoForm.get('discount');
      if (val === 'percentage_off') {
        discount.setValidators([Validators.required, Validators.min(0.00), Validators.max(100)]);
      }
      if (val === 'dollars_amount') {
        discount.setValidators([Validators.required, Validators.min(0.00)]);
      }
      discount.updateValueAndValidity();
    });
  }

  addNewPromoCode() {
    this.promoCodeService.create(this.newPromoForm.value).subscribe(
      (response) => {
        this.added = true;
        this.modalRef.hide();
        this.code.setData(response['data']);
      },
      (response) => {
        const errors = JSON.parse(response['_body']).errors;
        if (errors.promo_code) {
          this.promoAlreadyExists = true;
        } else {
          this.modalRef.hide();
          this.http.handleError(response);
        }
      });
  }
}
