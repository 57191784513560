<div class="row">

  <div class="col-lg-6">
    <h3>Service Areas</h3>
  </div>

  <div class="col-lg-6">
    <button type="button"
            class="btn btn-primary btn-wrap pull-right"
            *ngIf="!isEditMode"
            (click)="toggleEdit()">
      Edit
    </button>
    <button type="button"
            class="btn btn-default btn-wrap pull-right"
            *ngIf="isEditMode"
            (click)="toggleEdit()">
      Close
    </button>
    <button type="button"
            class="btn btn-primary btn-wrap pull-right"
            *ngIf="isEditMode"
            (click)="save()">
      Update
    </button>
    <button type="button"
            class="btn btn-success btn-wrap pull-right"
            *ngIf="isEditMode"
            (click)="openAddStateCountiesModal()">
      Add State
    </button>
  </div>

</div>

<ng-template #noDataFound>
  No Data Found
</ng-template>

<div *ngIf="serviceAreas.length; else noDataFound">
  <div class="panel panel-default"
       *ngFor="let area of serviceAreas">
    <div class="panel-heading">
      <h3 class="panel-title">{{area.state.name}}
        <a class="pull-right cursor-pointer" *ngIf="isEditMode"
            (click)="removeState(area.state.id)">
          <img src="/assets/images/remove_state_circle-24px.svg" class = "remove-icon">
        </a>
        <a class="pull-right cursor-pointer" *ngIf="isEditMode"
            (click)="updateAddStateCountiesModal(area.state.id)">
          <img src="/assets/images/add-circle.svg" class = "add-icon">
        </a>
      </h3>
    </div>

    <table class="table">
      <tbody>
        <tr *ngFor="let county of area.counties">
          <td>{{county.name}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
