import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { FlashService } from './flash.service';
import { FlashMessage } from './flash-message';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss'],
  animations: [
    trigger('flashState', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 }))
      ]),

      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class FlashComponent implements OnInit, OnDestroy {
  messageSubscription: Subscription;
  messages: FlashMessage[];

  constructor(private flashService: FlashService) {}

  ngOnInit() {
    this.messageSubscription = this.flashService.messages.subscribe((messages: FlashMessage[]) => {
      this.messages = messages;
    });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  close(message: FlashMessage) {
    this.flashService.remove(message);
  }

}
