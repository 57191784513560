const DiscountTypes = [
    {
        value: 'percentage_off',
        title: 'Percentage Off'
    },
    {
        value: 'dollar_amount',
        title: 'Dollar Amount'
    }
];
export default DiscountTypes;


export class PromoCode {
    id?: any;
    promo_code: String;
    discount_type: String;
    discount: number;
    description: String;
    active: boolean;

    constructor() {
      const keys = [
        'id', 'promo_code', 'discount_type', 'discount', 'description', 'active'
      ];
      keys.forEach((key) => this[key] = null);
    }

    setData(promoCodeData) {
      Object.keys(promoCodeData).map((item) => {
        this[item] = promoCodeData[item];
      });
    }
  }
