<div class="row">
  <div class="col-lg-6">
    <h3>GeoFence Setting</h3>
  </div>
  <div class="col-lg-6 pull-right geofence-save">
    <button class="btn btn-primary btn-wrap pull-right" (click)="onSaveClick()" [disabled]="disableSave">
      Save
    </button>
  </div>
</div>
<div>
  <table class="table table-striped">
    <tbody>
      <tr>
        <th class="width-20">Enable Geofencing</th>
        <td>
          <ui-switch (change)="onGeofenceStatusChange($event)" [ngModel]="enableGeofencing"
            [disabled]="!geofenceExists"></ui-switch>
        </td>
      </tr>
      <tr>
        <th class="width-20">
          Upload geojson file
        </th>
        <td *ngIf="geofenceProcessing" class="col-green">
          You will be notified by email when the map has been successfully uploaded.
        </td>
        <td *ngIf="(canShowFileUploadButton && !geofenceProcessing)">
          <div class="input-group">
            <label class="input-group-btn">
              <span class="btn btn-primary">
                Choose Map <input type="file" class="input-file"
                  (change)="geofenceFileChangeEvent($event.target.files)">
              </span>
            </label>
            <input type="text" class="form-control width-80" readonly [(ngModel)]="selectedFileName"
              placeholder="Upload geojson File">
          </div>
          <div *ngIf="errorMessage" class="error">
            {{errorMessage}}
          </div>
        </td>
        <td *ngIf="!canShowFileUploadButton && !geofenceProcessing">
          <div class="file-info-wrap">
            <i class="fa fa-check col-green"></i>
            <span class="file-name-wrap margin-right-20">
              {{geofence.geofence_file_name}}
            </span>
            <span class="margin-right-20 col-light-gray">
              {{geofenceFileSize | number:'1.1-2'}} {{geofenceFileSizeUnit}}
            </span>
            <i class="fa fa-close cross-sign" (click)="removeGeofence()"></i>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<hr>

<div class="geofence-rules-container">
  <app-geofence-rules [hospitalId]="hospitalId" [geofenceStatus]="geofence.status">
  </app-geofence-rules>
</div>
