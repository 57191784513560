import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthHttpService} from '../core/auth-http.service';
import {environment} from '../../environments/environment';
import { catchError } from 'rxjs/operators';

import { Report } from './report';


@Injectable()
export class ReportsService {

  constructor(private http: AuthHttpService) {}

  list(): Observable<any> {
    return this.http.get(`${environment.apiurl}/reports/quicksight_reports`).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  create(report: Report): Observable<any> {
    return this.http.post(`${environment.apiurl}/reports/quicksight_reports/create`, report).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  update(id:string, report: Report): Observable<any> {
    return this.http.update(`${environment.apiurl}/reports/quicksight_reports/update/${id}`, report).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  delete(id): Observable<any> {
    return this.http.delete(`${environment.apiurl}/reports/quicksight_reports/delete/${id}`);
  }
}
