<ol class="breadcrumb">
  <li><a routerLink="/providers">Transport Companies</a></li>
  <li *ngIf="providerData.active"><a routerLink="/providers">Approved</a></li>
  <li *ngIf="!providerData.active"><a routerLink="/providers-pending">Pending</a></li>
  <li class="active">{{providerData.name}}</li>
</ol>

<h1>{{providerData.name}}</h1>

<div class="row">
  <div class="col-sm-12">

    <provider-general-information [data]="providerData" (onUpdate)="onUpdate($event)"></provider-general-information>

    <hr>

    <provider-contact-information
      [providerId]="providerData.id"
      [contactInformation]="contactInformation">
    </provider-contact-information>

    <hr>

    <provider-dispatcher-information [data]="providerData"></provider-dispatcher-information>

    <hr>

    <provider-service-areas [providerId]="providerData.id"
                            [serviceAreas]="providerData.stateCounties"
                            [states]="states">
    </provider-service-areas>

    <hr>

    <provider-service-schedule
      [providerId]="providerData.id"
      [data]="providerData.properties">
    </provider-service-schedule>

    <hr>

    <app-service-fees-edit [data]="providerData" [vehicleTypes]="vehicleTypes" [vehicleValueTypeMap]="vehicleValueTypeMap"></app-service-fees-edit>

    <hr>

    <div class="well" *ngIf="files">
      <h3>Uploaded Files</h3>
      <div class="row">
        <div class="col-md-3 text-center" *ngFor="let file of files">
          <a [href]="file.url" target="_blank" class="btn btn-link">
            <app-icon icon="file" icon-class="file-icon"></app-icon>
            <p>{{file.name}}</p>
          </a>
        </div>
      </div>
    </div>

    <div class="row action-wrap">
      <div class="col">
        <button type="button"
          class="btn btn-primary"
          *ngIf="!providerData.active"
          (click)="approveProvider()">
          Approve Provider
        </button>

        <button type="button"
          class="btn btn-danger"
          (click)="confirmProviderDeleteModal()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>

