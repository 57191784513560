<div class="modal-header">
  <h4 class="modal-title pull-left" *ngIf="!editingProgram">
    <app-icon icon="home" iconClass="button-icon" ></app-icon> Add Program
  </h4>
  <h4 class="modal-title pull-left" *ngIf="editingProgram">
    <app-icon icon="home" iconClass="button-icon"></app-icon> Edit Program
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal">
    <div class="form-group">
      <label for="name" class="col-sm-3 control-label">Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="name" name="name" placeholder="Name" [(ngModel)]="hospitalProgram.name" maxlength="100">
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="deleteHospitalProgram()">Delete</button>
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="addUpdateHospitalProgram()">Save Changes</button>
</div>
