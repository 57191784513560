<div class="row" [hidden]="aliases.count() == 0">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="aliases"
    >
    </ng2-smart-table>
  </div>
</div>

<div [hidden]="aliases.count() > 0">
  <div class="empty-state">
    <div class="empty-state-content">
      <span>No plan aliases for this plan</span>
      <br />
      <a routerLink="/payer/plans" [queryParams]="{selectTab: 1}">View pending plans</a>
    </div>
  </div>
</div>
