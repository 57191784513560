<div>
  <div *ngIf="!isEdit">
    <table class="table table-striped">
      <tbody>
        <tr>
          <th>Customer Name</th>
          <td>{{customer.name}}</td>
        </tr>

        <tr>
          <th>Partner API Client ID</th>
          <td>{{customer.clientId}}</td>
        </tr>

        <tr>
          <th>Connection Name</th>
          <td>{{customer.connectionName}}</td>
        </tr>
        <tr>
          <th>Linked Organizations</th>
          <td>
            <span class="value" *ngFor="let item of selectedOrganizations; let $last=last">{{ item.itemName }}<span *ngIf="!$last">, </span></span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-left">
      <button class="btn btn-primary" (click)="toggleEdit()">Edit</button>
    </div>      
  </div>
  <div *ngIf="isEdit">
    <form class="form-horizontal col col-sm-6" >
      <table class="table table-striped">
        <tbody>
          <tr>
            <th>Customer Name</th>
            <td>
              <input type="text"
                    class="form-control"
                    name="name"
                    [(ngModel)]="customer.name"
                    placeholder="Name">
            </td>
          </tr>

          <tr>
            <th>Partner API Client ID</th>
            <td>
              <input type="text"
                    class="form-control"
                    name="clientId"
                    [(ngModel)]="customer.clientId"
                    placeholder="Client Id">
            </td>
          </tr>

          <tr>
            <th>Connection Name</th>
            <td>
              <input type="text"
                    class="form-control"
                    name="connectionName"
                    [(ngModel)]="customer.connectionName"
                    placeholder="Connection Name">
            </td>
          </tr>
          <tr>
            <th>Linked Organizations</th>
            <td>
              <angular2-multiselect [data]="parentOrganizations" [(ngModel)]="selectedOrganizations"
                                      [settings]="organizationDropdownSettings"
                                      (onSelect)="onOrganizationItemSelect($event)"
                                      (onDeSelect)="onOrganizationItemDeSelect($event)"
                                      (onSelectAll)="onOrganizationSelectAll($event)"
                                      (onDeSelectAll)="onOrganizationDeSelectAll($event)"
                                      (onFilterSelectAll)="onOrganizationSelectAll($event)"
                                      (onFilterDeSelectAll)="onOrganizationDeSelectAll($event)"
                                      name="selectedOrganizations"
                                      >
                <c-item>
                  <ng-template let-item="item">
                    <label [ngClass]="{'disabled-option': item['disabled']}">{{item.itemName}}</label>
                  </ng-template>
                </c-item>
              </angular2-multiselect>
            </td>
          </tr>
        </tbody>
      </table>
      
      <div class="action-wrap text-left">
        <button type="button" class="btn btn-default" (click)="toggleEdit()">Close</button>
        <button type="button" class="btn btn-primary" (click)="update()">Update</button>
      </div>
    </form>
  </div>
</div>
