import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../components/components.module';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { ReportsService } from './reports.service';
import { ReportResolver } from './reports-resolver';
import { ReportsComponent } from './reports.component';
import { NewReportModalComponent } from './new-report/new-report.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ComponentsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TableModule,
        FormsModule,
        RouterModule,
        UiSwitchModule,
    ],
    declarations: [
        NewReportModalComponent,
        ReportsComponent
    ],
    exports: [
        ReportsComponent
    ],
    providers: [
        ReportsService,
        { provide: 'ReportResolver', useValue: ReportResolver },
        BsModalService,
    ]
})
export class ReportsModule {
}
