import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthHttpService } from './auth-http.service';

@NgModule({
  imports: [
    CommonModule
  ],

  declarations: [],

  exports: [],

  providers: [
    AuthHttpService
  ]
})
export class CoreModule { }
