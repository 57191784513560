import {Component, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import { ReportsService } from '../reports.service';
import { Report } from '../report';

@Component({
  selector: 'app-new-report-modal',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.scss']
})
export class NewReportModalComponent implements OnInit {
  afterCreate = {
    next: (v) => {
      this.data = v.data;
      this.added = true;
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };
  afterUpdate = {
    next: () => {
      this.updated = true;
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };
  afterDelete = {
    next: () => {
      this.deleted = true;
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };

  report: Report;
  editingReport: boolean;
  added = false;
  deleted = false;
  updated = false;
  data = {}

  constructor(
    public modalRef: BsModalRef,
    private ReportsService: ReportsService
  ) {}

  ngOnInit() {
      if (!this.report) {
        this.report = this.blankReport();
      }
  }

  addUpdateReport() {
    if (this.report.id) {
      this.ReportsService.update(this.report.id, this.report).subscribe(this.afterUpdate);
    } else {
      this.ReportsService.create(this.report).subscribe(this.afterCreate);
    }
  }

  deleteReport(){
    if (this.report.id){
      this.ReportsService.delete(this.report.id).subscribe(this.afterDelete);
    }
  }

  private blankReport(): Report {
    return <Report>{
      uuid:'',
      report_name:''
    };
  }
}
