import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PayerService } from '../../../payer.service';
import { Note } from '../../../payer';

@Component({
  selector: 'app-add-carrier-notes-modal',
  templateUrl: './add-carrier-notes-modal.component.html',
  styleUrls: ['./add-carrier-notes-modal.component.scss']
})
export class AddCarrierNotesModalComponent implements OnInit {
  @Input() carrierId: string;
  @Output() public onCreate: EventEmitter<Note> = new EventEmitter();
  note = {
    content: ''
  };
  submitted = false;
  constructor(public modalRef: BsModalRef, private payerService: PayerService) { }

  ngOnInit() {
  }

  disabled() {
    return (!this.note.content || this.submitted);
  }

  createNote() {
    this.submitted = true;
    this.payerService.createCarrierNotes(this.carrierId, this.note)
      .subscribe((response:any) => {
        this.onCreate.emit(<Note>response.data);
        this.modalRef.hide();
      });
  }
}
