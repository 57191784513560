import { Component, forwardRef, Input, } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Option } from "../dropdown/dropdown";
import { TimezoneDropdownService } from "./timezone-dropdown.service";


@Component({
  selector: 'timezone-dropdown',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimezoneDropdownComponent),
    multi: true
  }],
  templateUrl: './timezone-dropdown.component.html',
  styleUrls: ['./timezone-dropdown.component.scss']
})
export class TimezoneDropdownComponent implements ControlValueAccessor {
  @Input() currentZone: string;
  zones: Option[] = [];

  constructor(private timeZoneSelectService: TimezoneDropdownService) {
  }

  ngOnInit() {
    this.timeZoneSelectService.get()
      .subscribe((response) => {
        this.zones = response.priority_zones || [];
        this.zones.push({
          value: "",
          label: '-------------------------------------------------------',
          disabled: true
        });
        this.zones = this.zones.concat(response.zones || []);
      });
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  get selected() {
    return this.currentZone;
  }

  set selected(zone) {
    this.currentZone = zone;
    this.onChange(zone);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(zone) {
    if (zone) {
      this.currentZone = zone;
    }
  }
}
