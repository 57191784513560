import { Component, OnInit } from '@angular/core';
import {PromoCode} from '../promo-code';
import {ActivatedRoute, Router} from '@angular/router';
import {PromoCodeService} from '../promo-code.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FlashService} from '../../components/flash/flash.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import DiscountTypes from '../promo-code';

@Component({
  selector: 'app-view-promo-code',
  templateUrl: './view-promo-code.component.html',
  styleUrls: ['./view-promo-code.component.scss']
})
export class ViewPromoCodeComponent implements OnInit {
  promoCodeOriginal: any;
  promoCode = new PromoCode();
  copyPromoCode = new PromoCode();
  deletePromoCodeModal;
  isEditingPromoCode = false;
  discountTypes = DiscountTypes;
  promoCodeForm: FormGroup;
  promoCodeActive: boolean;

  constructor(
    private route: ActivatedRoute,
    private promoCodeService: PromoCodeService,
    private modalService: BsModalService,
    private flashService: FlashService,
    private router: Router
  ) {
    this.promoCodeForm = new FormGroup ({
      promo_code: new FormControl('', Validators.required),
      discount_type: new FormControl('', Validators.required),
      discount: new FormControl(''),
      description: new FormControl('')
    });

    this.promoCodeForm.get('discount_type').valueChanges
    .subscribe(val => {
      const discount = this.promoCodeForm.get('discount');
      if (val === 'percentage_off') {
        discount.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
      }
      if (val === 'dollars_amount') {
        discount.setValidators([Validators.required, Validators.min(0)]);
      }
      discount.updateValueAndValidity();
    });

   }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.promoCodeOriginal = data.promoCode.data;
      this.setData(this.promoCodeOriginal);
      this.promoCodeForm.disable();
    });
  }

  setData(data: any) {
    this.promoCodeForm.get('promo_code').setValue(data.promo_code);
    this.promoCodeForm.get('discount_type').setValue(data.discount_type);
    this.promoCodeForm.get('discount').setValue(data.discount);
    this.promoCodeForm.get('description').setValue(data.description);
    this.promoCodeActive = data.active;
  }

  toggleActive() {
    if (this.promoCodeActive) {
      this.promoCodeService
        .activatePromoCode(this.promoCodeOriginal.id)
        .subscribe(user => {
          this.flashService.add({
            type: 'success',
            message:
              `<strong>Nice!</strong>
              ${this.promoCodeOriginal.promo_code} is now an active promo code.`
          });
        });
    } else {
      this.promoCodeService
        .deactivatePromoCode(this.promoCodeOriginal.id)
        .subscribe(user => {
          this.flashService.add({
            type: 'warning',
            message: `${this.promoCodeOriginal.promo_code} is deactivated.`
          });
        }, error => {
          this.flashService.add({
            type: 'warning',
            message: error.data.errors.cannot_update
          });
        });
    }
  }

  updatePromoCode() {
    const payload = Object.assign({id: this.promoCodeOriginal.id}, this.promoCodeForm.value);
    this.promoCodeService.update(payload).subscribe(
      (response) => {
        this.flashService.add({
          type: 'success',
          message: `<strong>Success!</strong> You've updated ${this.promoCodeOriginal.promo_code}`
        });
        this.isEditingPromoCode = false;
        this.promoCodeForm.disable();
      });
  }

  public cancelEditing() {
    this.isEditingPromoCode = false;
    this.setData(this.promoCodeOriginal);
    this.promoCodeForm.disable();
  }

  public editPromoCode() {
    this.isEditingPromoCode = true;
    this.promoCodeForm.enable();
  }

}
