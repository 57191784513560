import { inject } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { HospitalService } from './hospital.service';
import { Geofence } from './geofence/geofence';

export const GeofenceResolver: ResolveFn<Geofence> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Geofence> => {
  const hospitalService = inject(HospitalService);
  return hospitalService.getGeofenceData(route.params.id);
};
