<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="home" iconClass="button-icon"></app-icon> Add/Update Rideshare Availability Locations
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="newRideshareAvailabilityForm">
    <div class="form-group">
      <label class="col-sm-3 control-label">Source</label>
      <div class="col-sm-9">
        <select class="form-control" formControlName="source">
          <option *ngFor="let type of sources; let i = index" [value]="type.name">
            {{type.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">Choose File</label>
      <div class="col-sm-9">
        <input type="file" (change)="handleFileSelect($event)" />
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="handleSubmit()"
    [disabled]="newRideshareAvailabilityForm.invalid">Save Changes</button>
</div>
