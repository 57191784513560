export class Venue {
  id: any;
  location_name: String;
  zone_name: String;
  latitude: any;
  longitude: any;
  zone_latitude: Number;
  zone_longitude: Number;
  created_at?: String;
  updated_at?: String;
  current_address?: String;

  constructor() {
    const keys = [
      'id', 'location_name', 'zone_name', 'latitude', 'longitude', 'zone_latitude',
      'zone_longitude', 'created_at', 'updated_at', 'current_address'
    ];
    keys.forEach((key) => this[key] = null);
  }

  setData(venueData) {
    Object.keys(venueData).map((item) => {
      this[item] = venueData[item];
    });
  }
}
