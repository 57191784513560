import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Ng2SmartTableModule } from 'rt-ng2-smart-table';
import { PayerService } from './payer.service';
import { ViewActivePlanComponent } from './view-active-plan/view-active-plan.component';
import { ActivePlanDetailResolverService } from './active-plan-detail-resolver.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ComponentsModule } from '../components/components.module';
import { CustomPayerRenderComponent } from './custom-payer-render/custom-payer-render.component';
import { DataService } from '../core/data.service';
import { ViewCarrierComponent } from './view-carrier/view-carrier.component';
import { CarrierDetailResolverService } from './carrier-detail-resolver.service';
import { PlanDashboardComponent } from './plan-dashboard/plan-dashboard.component';
import { ActivePlanListComponent } from './active-plan-list/active-plan-list.component';
import { PendingPlanListComponent } from './pending-plan-list/pending-plan-list.component';
import { PlanAliasesComponent } from './view-active-plan/plan-aliases/plan-aliases.component';
import { PlanDetailsComponent } from './view-active-plan/plan-details/plan-details.component';
import { CarrierDetailsComponent } from './view-carrier/carrier-details/carrier-details.component';
import { AssociatedPlansTableComponent } from './view-carrier/carrier-details/associated-plans-table/associated-plans-table.component';
import { CarrierListComponent } from './carrier-list/carrier-list.component';
import { ColumnarDisplayComponent } from './view-active-plan/plan-details/columnar-display/columnar-display.component';
import { ViewPendingPlanComponent } from './view-pending-plan/view-pending-plan.component';
import { PendingPlanDetailResolverService } from './pending-plan-detail-resolver.service';
import { CarriersResolverService } from './carriers-resolver.service';
import { MatchPendingPlanComponent } from './view-pending-plan/match-pending-plan/match-pending-plan.component';
import { MatchDetailsComponent } from './view-pending-plan/match-details/match-details.component';
import { NewActivePlanModalComponent } from './new-active-plan-modal/new-active-plan-modal.component';
import { NewCarrierModalComponent } from './new-carrier-modal/new-carrier-modal.component';
import { MatchNewActivePlanModalComponent } from './match-new-active-plan-modal/match-new-active-plan-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PlanNotesComponent } from './view-active-plan/plan-notes/plan-notes.component';
import { AddPlanNotesModalComponent } from './notes/add-plan-notes-modal/add-plan-notes-modal.component';
import { UpdatePlanNotesModalComponent } from './notes/update-plan-notes-modal/update-plan-notes-modal.component';
import { AddCarrierNotesModalComponent } from './notes/carrier-notes/add-carrier-notes-modal/add-carrier-notes-modal.component';
import { UpdateCarrierNotesModalComponent } from './notes/carrier-notes/update-carrier-notes-modal/update-carrier-notes-modal.component';
import { CarrierNotesComponent } from './view-carrier/carrier-notes/carrier-notes.component';
import { CarrierNotesResolverService } from './carrier-notes-resolver.service';
import { ActivePlanNotesResolverService } from './active-plan-notes-resolver.service';

@NgModule({
    imports: [
        CommonModule,
        Ng2SmartTableModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UiSwitchModule,
        AngularMultiSelectModule,
        TypeaheadModule,
        TabsModule.forRoot(),
        ComponentsModule,
        BsDropdownModule.forRoot()
    ],
    declarations: [
        ViewActivePlanComponent,
        CustomPayerRenderComponent,
        ViewCarrierComponent,
        PlanDashboardComponent,
        ActivePlanListComponent,
        PendingPlanListComponent,
        PlanAliasesComponent,
        PlanDetailsComponent,
        CarrierDetailsComponent,
        AssociatedPlansTableComponent,
        CarrierListComponent,
        ColumnarDisplayComponent,
        ViewPendingPlanComponent,
        MatchPendingPlanComponent,
        MatchDetailsComponent,
        NewActivePlanModalComponent,
        NewCarrierModalComponent,
        MatchNewActivePlanModalComponent,
        PlanNotesComponent,
        AddPlanNotesModalComponent,
        UpdatePlanNotesModalComponent,
        AddCarrierNotesModalComponent,
        UpdateCarrierNotesModalComponent,
        CarrierNotesComponent,
        ColumnarDisplayComponent,
    ],
    providers: [
        PayerService,
        { provide: 'ActivePlanDetailResolverService', useValue: ActivePlanDetailResolverService },
        DataService,
        { provide: 'CarrierDetailResolverService', useValue: CarrierDetailResolverService },
        { provide: 'PendingPlanDetailResolverService', useValue: PendingPlanDetailResolverService },
        { provide: 'CarriersResolverService', useValue: CarriersResolverService },
        { provide: 'CarrierNotesResolverService', useValue: CarrierNotesResolverService },
        { provide: 'ActivePlanNotesResolverService', useValue: ActivePlanNotesResolverService },
    ]
})
export class PayerModule { }
