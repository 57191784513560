import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Venue } from '../venues';
import { VenuesService } from '../venues.service';

@Component({
  selector: 'app-new-venue',
  templateUrl: './new-venue.component.html',
  styleUrls: ['./new-venue.component.scss']
})
export class NewVenueComponent implements OnInit {
  @ViewChild('search', { static: false })
    public searchElementRef: ElementRef;
  venue = new Venue();
  added = false;
  options = {
    types: ['address'],
    componentRestrictions: {country: 'us'}
  };

  constructor(
    public modalRef: BsModalRef,
    private venueService: VenuesService
  ) {}

  ngOnInit() {
  }

  addressChanged(place: any) {
    if (place.geometry === undefined || place.geometry === null) {
      return;
    }
    const lat = String(place.geometry.location.lat());
    const lng = String(place.geometry.location.lng());
    this.venue.latitude = parseFloat(lat).toFixed(7);
    this.venue.longitude = parseFloat(lng).toFixed(7);
  }

  resetLatLng() {
    this.venue.latitude = null;
    this.venue.longitude = null;
  }

  addVenue() {
    this.venueService.create(this.venue).subscribe(
      (response) => {
        this.added = true;
        this.modalRef.hide();
        this.venueService.formatVenue(response['data']);
        this.venue.setData(response['data']);
      },
      () => {
        this.modalRef.hide();
      });
  }
}
