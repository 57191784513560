<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="list"
      (userRowSelect)="onRowSelect($event)"
    >
    </ng2-smart-table>
  </div>
</div>

<div [hidden]="list?.count() > 0">
  <div class="empty-state">
    <div class="empty-state-content">
      <span>No active plans match your search</span>
    </div>
  </div>
</div>
