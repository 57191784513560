import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashService} from '../../components/flash/flash.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import { ProviderService } from '../providers.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {NewProviderComponent} from '../provider-new/provider-new.component';
import { ProviderCsvService } from '../provider-csv.service';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';

interface Filter {
  value: any;
}

interface Event {
  first: number;
  rows: number;
  filters: { [key: string]: Filter };
  originalEvent:any;
  value?: {label: string, value: any};
}

@Component({
  selector: 'app-providers',
  templateUrl: './providers-list.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
    './providers-list.component.scss'
  ]
})
export class ProvidersListComponent implements OnInit, OnDestroy {
  companies: AdminServerDataSource;
  selectedCompany: AdminServerDataSource;
  loading = false;
  statesFilterList = [];
  newProviderModal: BsModalRef;

  selectedState: any;
  selectedApprovedAt: any;
  first = 0;
  last: number;
  rows = 10;
  totalRecords: number = 0;
  pageOffset: number = 0;
  page: number = 1;
  searchCriteria: any[] = [];

  placeholders = {
    name: 'Name',
    phone: 'Phone',
    states: ' ',
    counties: 'Counties',
    zipcodes: 'Zip Codes',
    approved_at: ' ',
  };

  monthList = [
    {value: 'january', title: 'January', label: 'created_at'},
    {value: 'february', title: 'February', label: 'created_at'},
    {value: 'march', title: 'March', label: 'created_at'},
    {value: 'april', title: 'April', label: 'created_at'},
    {value: 'may', title: 'May', label: 'created_at'},
    {value: 'june', title: 'June', label: 'created_at'},
    {value: 'july', title: 'July', label: 'created_at'},
    {value: 'august', title: 'August', label: 'created_at'},
    {value: 'september', title: 'September', label: 'created_at'},
    {value: 'october', title: 'October', label: 'created_at'},
    {value: 'november', title: 'November', label: 'created_at'},
    {value: 'december', title: 'December', label: 'created_at'}
  ]

  tableConfig = {
    columns: {
      name: {
        title: 'Name'
      },

      phone: {
        title: 'Phone'
      },

      states: {
        title: 'States'
      },

      counties: {
        title: 'Counties',
      },

      zipcodes: {
        title: 'Zip Codes',
      },

      approved_at: {
        title: 'Approved On',
      },
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private flashService: FlashService,
    private providerService: ProviderService,
    private providerCsvService: ProviderCsvService,
    protected http: HttpClient
  ) {
  }

  keys = Object.keys(this.tableConfig.columns);

  resetPlaceHolder(filterName){
      this.placeholders[filterName] = ' ';
    }
    
    addOrUpdateSearchCriteria(searchTerm, value){
      const existingEntry = this.searchCriteria.find(entry => entry[searchTerm] !== undefined);
        if(existingEntry){
          existingEntry[searchTerm] = value
        }
        else {
          this.searchCriteria.push({[searchTerm]: value});
        }
    }
  
    appendPagination(event?: Event){
      this.pageOffset = event.first / event.rows;
      if(!this.pageOffset){
        this.pageOffset = 0;
      }
      this.page = this.pageOffset + 1;
      this.addOrUpdateSearchCriteria('page', this.page);
      this.addOrUpdateSearchCriteria('per_page', event.rows ? event.rows : this.rows);
    }
  
    removeSearchCriteria(filterName){
      const index = this.searchCriteria.findIndex(entry => entry[filterName] !== undefined);
      if (index !== -1) {
        this.searchCriteria.splice(index, 1);
      }
    }
  
    pageChange(event, filterName?: string) {
      if(event.value === null && filterName){
        this.removeSearchCriteria(filterName)
        this.resetPlaceHolder(filterName);
      }
      this.loadCompanies(event);
    }
  
    processEventFilters(event?: Event){
      // Depending on the filter clicked, the event object will have a different structure
      if (event.filters){
        Object.entries(event.filters).forEach(([key, value]) => {
          if(value?.value !== null){
            this.addOrUpdateSearchCriteria(key, value.value);
          }
        });
      }
      if(event.value){
        this.addOrUpdateSearchCriteria(event.value.label, event.value.value);
      }
    }
  
    buildURL(event?: Event ){
      let url = 'providers?'
      this.appendPagination(event);
      this.processEventFilters(event);
      this.searchCriteria.map((criteria) => {
        Object.entries(criteria).forEach(([key, value]) => {
          if(value !== null && value !== undefined && value !== ''){
            if(key === 'counties' || key === 'zipcodes'){
              (value as string).split(',').forEach((val) => {
                url += `&${key}[]=${val}`;
              });
            }
            else {
              url += `&${key}=${value}`;
            }
          }
        })
      })
      return url;
    }
  
    ngOnInit() {
      this.route.data.subscribe((data) => {
        this.statesFilterList = data.state.data.map(state => {
        return { value: state['id'], title: state['name'], label: 'states' };
      });
      });    
    }
  
    ngOnDestroy() {
      sessionStorage.setItem('searchCriteriaApprovedProvider', JSON.stringify(this.searchCriteria));
    }
  
    processSavedCriteria(criterionKey: string, filterListKey: string, selectedKey: string) {
      const criterion = this.searchCriteria.find(criteria => criteria[criterionKey] !== undefined);
      if (criterion) {
        this[selectedKey] = this[filterListKey].find(criteria => criteria.value === criterion[criterionKey]).title;
        this.placeholders[criterionKey] = this[selectedKey];
      }
    }
  
    loadCompanies(event?:Event) {
      if (!this.searchCriteria || this.searchCriteria.length === 0) {
        const savedSearchCriteria = sessionStorage.getItem('searchCriteriaApprovedProvider');
        if (savedSearchCriteria) {
            this.searchCriteria = JSON.parse(savedSearchCriteria);
            this.processSavedCriteria('states', 'statesFilterList', 'selectedState');
            this.processSavedCriteria('approved_at', 'monthList', 'selectedApprovedAt');
        }
      }
      const url = this.buildURL(event);
      const dataSource = new AdminServerDataSource(this.http, {
        endPoint: `${environment.apiurl}/${url}`,
        formatDataFunction: this.providerService.formatProviders.bind(this),
          filterFieldDataType: {
            name: String,
            phone: String,
            states: String,
            counties: Array,
            zipcodes: Array,
            approved_at: Date
          }
      });
      dataSource.getElements().then(data => {
        this.totalRecords = dataSource.count();
        this.companies = data;
      });
    }  


  public formatCSVdataFunction(data) {
    return this.providerCsvService.generateDataFromList(data);
  }

  public openNewProviderModal() {
    this.newProviderModal = this.modalService.show(NewProviderComponent);
    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.newProviderModal.content.added) {
        this.newProviderModal.content.added = false;
        this.loading = true;

        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> Provider has been added.'
        });
        this.providerService.list()
        .subscribe((data) => {
          this.loading = false;
          this.companies = this.providerService.formatData(data.data);
        });
      }
      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  public onRowSelect(event) {
    this.router.navigate(['/providers', event.data.id]);
  }

}

