import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Provider } from '../../providers/provider';

interface Filter {
  value: any;
}

interface Event {
  first: number;
  rows: number;
  filters: { [key: string]: Filter };
  originalEvent:any;
  value?: {label: string, value: any};
}
interface Dispatcher {
  first_name: string;
  last_name: string;
  email: string;
  transportation_company_id: number;
  role: boolean;
  confirmed_at: boolean;
  active: boolean;
  created_at: string;
}
@Component({
  selector: 'app-dispatchers',
  templateUrl: './dispatchers.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
    './dispatchers.component.scss'
  ]
})
export class DispatchersComponent implements OnInit, OnDestroy {
  users: Dispatcher[];
  selectedUser: Dispatcher;
  loading = false;
  transportationCompanyMapper: any;
  transportationCompanyFilterList = [];
  transportationCompanies: Provider[];

  selectedTransportationCompany: any;
  selectedIsConfirmed: any;
  selectedIsActive: any;
  selectedMonth: any;
  first = 0;
  last: number;
  rows = 10;
  totalRecords: number = 0;
  pageOffset: number = 0;
  page: number = 1;
  searchCriteria: any[] = [];

  placeholders = {
    transportation_company_id: 'Select Transportation Company',
    confirmed_at: ' ',
    active: ' ',
    created_at: ' ',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email'
  };

  confirmedList = [{value: true, title: 'Yes', label: 'confirmed_at'},
    {value: false, title: 'No', label: 'confirmed_at'}]

  activeList = [{value: true, title: 'Yes', label: 'active'},
    {value: false, title: 'No', label: 'active'}]

  monthList = [
    {value: 'january', title: 'January', label: 'created_at'},
    {value: 'february', title: 'February', label: 'created_at'},
    {value: 'march', title: 'March', label: 'created_at'},
    {value: 'april', title: 'April', label: 'created_at'},
    {value: 'may', title: 'May', label: 'created_at'},
    {value: 'june', title: 'June', label: 'created_at'},
    {value: 'july', title: 'July', label: 'created_at'},
    {value: 'august', title: 'August', label: 'created_at'},
    {value: 'september', title: 'September', label: 'created_at'},
    {value: 'october', title: 'October', label: 'created_at'},
    {value: 'november', title: 'November', label: 'created_at'},
    {value: 'december', title: 'December', label: 'created_at'}
  ]

  tableConfig = {
    columns: {
      first_name: {
        title: 'First Name'
      },

      last_name: {
        title: 'Last Name'
      },

      email: {
        title: 'Email'
      },

      transportation_company_id: {
        title: 'Transportation Company',
      },

      confirmed_at: {
        title: 'Confirmed?',
      },

      active: {
        title: 'Active?',
      },

      created_at: {
        title: 'Created On',
        width: '200px',
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

keys = Object.keys(this.tableConfig.columns);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected http: HttpClient
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.transportationCompanyMapper = data.providers.data.reduce((map, transporation_company) => {
        map[transporation_company.id] = transporation_company.name;

        this.transportationCompanyFilterList.push({
          value: transporation_company.id,
          title: transporation_company.name,
          label: 'transportation_company_id'
        });

        return map;
      }, {});

      this.transportationCompanies = data.providers.data;
    });
  }  

  
resetPlaceHolder(filterName){
  if(filterName === 'transportation_company_id'){
    this.placeholders[filterName] = 'Select Transportation Company';
  }
  else{
    this.placeholders[filterName] = ' ';
  }
}

addOrUpdateSearchCriteria(searchTerm, value){
  const existingEntry = this.searchCriteria.find(entry => entry[searchTerm] !== undefined);
  if(existingEntry){
    existingEntry[searchTerm] = value;
  } else {
    this.searchCriteria.push({[searchTerm]: value});
  }
}

appendPagination(event?: Event){
  this.pageOffset = event.first / event.rows;
  if(!this.pageOffset){
    this.pageOffset = 0;
  }
  this.page = this.pageOffset + 1;
  this.addOrUpdateSearchCriteria('page', this.page);
  this.addOrUpdateSearchCriteria('per_page', event.rows ? event.rows : this.rows);
}

removeSearchCriteria(filterName){
  const index = this.searchCriteria.findIndex(entry => entry[filterName] !== undefined);
  if (index !== -1) {
    this.searchCriteria.splice(index, 1);
  }
}

pageChange(event, filterName?: string) {
  if(event.value === null && filterName){
    this.removeSearchCriteria(filterName)
    this.resetPlaceHolder(filterName);
  }
  this.loadUsers(event);
}

processEventFilters(event?: Event){
  // Depending on the filter clicked, the event object will have a different structure
  if (event.filters){
    Object.entries(event.filters).forEach(([key, value]) => {
      if(value?.value !== null){
        this.addOrUpdateSearchCriteria(key, value.value);
      }
    });
  }
  if(event.value){
    this.addOrUpdateSearchCriteria(event.value.label, event.value.value);
  }
}

buildURL(event?: Event ){
  let url = 'users?user_filter=dispatchers'
  this.appendPagination(event);
  this.processEventFilters(event);
  this.searchCriteria.map((criteria) => {
    Object.entries(criteria).forEach(([key, value]) => {
      if(value !== null && value !== undefined && value !== ''){
        url += `&${key}=${value}`;
      }
    })
  })
  return url;
}

ngOnDestroy() {
  sessionStorage.setItem('searchCriteriaDispatcher', JSON.stringify(this.searchCriteria));
}

processSavedCriteria(criterionKey: string, filterListKey: string, selectedKey: string) {
  const criterion = this.searchCriteria.find(criteria => criteria[criterionKey] !== undefined);
  if (criterion) {
    this[selectedKey] = this[filterListKey].find(criteria => criteria.value === criterion[criterionKey]).title;
    this.placeholders[criterionKey] = this[selectedKey];
  }
}

loadUsers(event?:Event) {
  if (!this.searchCriteria || this.searchCriteria.length === 0) {
    const savedSearchCriteria = sessionStorage.getItem('searchCriteriaDispatcher');
    if (savedSearchCriteria) {
      this.searchCriteria = JSON.parse(savedSearchCriteria);
      this.processSavedCriteria('transporation_company_id', 'transportationCompanyFilterList', 'selectedTransportationCompany');
      this.processSavedCriteria('confirmed_at', 'confirmedList', 'selectedIsConfirmed');
      this.processSavedCriteria('active', 'activeList', 'selectedIsActive');
      this.processSavedCriteria('created_at', 'monthList', 'selectedMonth');
    }
  }
  
  const url = this.buildURL(event);
  this.http.get(`${environment.apiurl}/${url}`, { observe: 'response' }).subscribe((response: any) => {
    this.totalRecords = response.body.meta['x-total-count'];
    this.users = this.mutateUsers(response.body.data);
  });
}  

public onRowSelect(event) {
  this.router.navigate(['/users', event.data.id]);
}

private mutateUsers(users) {
  users.forEach(user => {
    user['transportation_company_id'] =  this.transportationCompanyMapper[user['transportation_company_id']] || '-';
    user['created_at'] = moment(user['created_at']).format('LLL');
    user['confirmed_at'] = user['confirmed_at'] ? 'Yes' : 'No';
    user['active'] = user['active'] ? 'Yes' : 'No';
  });
  return users;
  }
}
