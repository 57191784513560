<div class="modal-header">
  <h4 class="modal-title pull-left">Edit note</h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <textarea class="notes-textarea" rows="10" [(ngModel)]="note.content"></textarea>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="deleteNote()">Delete</button>
  <button type="button" class="btn btn-primary" (click)="updateNote()" [disabled]="!note.content">Edit note</button>
</div>
