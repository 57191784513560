import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Hospital } from '../../hospital';
import { HospitalService } from '../../hospital.service';
import { FlashService } from '../../../components/flash/flash.service';
import { AuthHttpService } from '../../../core/auth-http.service';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import * as uuid from 'uuid';

@Component({
  selector: 'app-partner-setup',
  templateUrl: './partner-setup.component.html',
  styleUrls: ['./partner-setup.component.scss']
})
export class PartnerSetupComponent implements OnInit {
  @Input() hospital: Hospital;
  partnerSetupForm: FormGroup;
  sqs_queue_url: String;
  clientIdHasError = false;
  clientSecretHasError = false;
  clientIdErrorMessage = null;
  clientSecretErrorMessage = null;

  constructor(
    private hospitalService: HospitalService,
    private flashService: FlashService,
    private http: AuthHttpService
  ) {
  }

  ngOnInit() {
    this.sqs_queue_url =
      environment.hasOwnProperty('sqs_queue_url') ? environment['sqs_queue_url'] : '';
    this.generateFormControls();
  }

  generateFormControls() {
    const data = _.cloneDeep(this.hospital);
    const formControls = {};
    const partner_auth = data.partner_auth_setting;

    formControls['sso_enabled'] = new FormControl(data.sso_enabled);
    formControls['patient_identifier'] = new FormControl(data.patient_identifier);

    formControls['partner_auth_setting'] = new FormGroup({
      client_id: new FormControl(partner_auth.client_id),
      sqs_queue_url: new FormControl(
        partner_auth.sqs_queue_url ? partner_auth.sqs_queue_url : this.sqs_queue_url
      ),
      webhook_url: new FormControl(partner_auth.webhook_url),
      client_secret: new FormControl(partner_auth.client_secret),
      verification_token: new FormControl(partner_auth.verification_token)
    });

    this.partnerSetupForm = new FormGroup(formControls);
  }

  generateClientSecret() {
    this.partnerSetupForm.get('partner_auth_setting').get('client_secret')
      .setValue(uuid.v4());
  }

  save() {
    const data = _.cloneDeep(this.partnerSetupForm.getRawValue());
    this.clearErrors();
    this.hospitalService.partnerSsoSetup(this.hospital.id, data).subscribe(
      (response) => {
        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> Partner Setup is completed for the organization'
        });
        this.hospital = response.data;
        this.generateFormControls();
      }, (error) => {
        this.http.handleError(error);

        if (error._body) {
          const err = (typeof error._body === 'string') ? JSON.parse(error._body) : error._body;

          if (err.errors && err.errors.hospital && err.errors.hospital.partner_auth_setting) {
            const partner_auth_error = err.errors.hospital.partner_auth_setting;

            if (partner_auth_error.client_id) {
              this.clientIdErrorMessage = partner_auth_error.client_id.toString();
              this.clientIdHasError = true;
            }
            if (partner_auth_error.client_secret) {
              this.clientSecretErrorMessage = partner_auth_error.client_secret.toString();
              this.clientSecretHasError = true;
            }
          }
        }
      });
  }

  clearErrors() {
    this.clientIdHasError = false;
    this.clientSecretHasError = false;
    this.clientIdErrorMessage = null;
    this.clientSecretErrorMessage = null;
  }

  close() {
    this.generateFormControls();
    this.clearErrors();
  }
}
