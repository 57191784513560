import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { User } from '../../user';
import { Hospital } from '../../../hospitals/hospital';

import { UserService } from '../../user.service';
import { FlashService } from '../../../components/flash/flash.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class B2CUserViewComponent implements OnInit {
  user: User;
  hospitals: Hospital[];

  isLocked = false;
  isConfirmed = false;
  isEdit = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private flashService: FlashService
  ) {
  }
  showFlashMessage(type: string, message: string){
    this.flashService.add({
      type: type,
      message: message
    });
  }
  ngOnInit() {
    this.route.data.subscribe(data => {
      this.user = data.user.data.user;
      this.isLocked = data.user.data.is_locked;
      this.isConfirmed = data.user.data.is_confirmed;
      this.user['hospital_name'] = this.user.current_hospital ? this.user.current_hospital.name : 'B2C';
    });
  }

  constructUserRequest(): User {
    return {
      id: this.user.id,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      emr_user_id: this.user.emr_user_id,
      hospital_id: this.user.hospital_id,
      active: this.user.active,
      limited_access: this.user.limited_access,
      role: this.user.role,
      transportation_company_id: this.user.transportation_company_id,
      phone: this.user.phone,
      show_facility_rides: this.user.show_facility_rides,
      show_patient_rides: this.user.show_patient_rides,
      time_zone: this.user.time_zone,
      domestic_user: this.user.domestic_user
    }
  }
  update(): void {
      this.userService.updateB2C(this.constructUserRequest()).subscribe(() => {
        this.showFlashMessage("success", `${this.user.first_name} ${this.user.last_name} has been updated.`)
      })
  }
  toggleEdit(): void {
    this.isEdit = !this.isEdit;
  }
}
