<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="compass" iconClass="button-icon"></app-icon> Delete Provider
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>Are you sure, you want to delete this provider?</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-danger" (click)="delete()">Delete</button>
</div>
