<div *ngIf="!hideTitleArea" class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>

<div class="modal-footer" *ngIf="buttons">
  <button *ngFor="let button of buttons"
    type="button"
    class="btn"
    [ngClass]="'btn-' + button.type"
    (click)="close(button.label)">
    {{button.label}}
  </button>
</div>
