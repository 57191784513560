// angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from "@angular/router"
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';

// third party
import { UiSwitchModule } from 'ngx-ui-switch';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { BaseModule } from './base/base.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { ComponentsModule } from './components/components.module';
import { UsersModule } from './users/users.module';
import { HospitalsModule } from './hospitals/hospitals.module';
import { VenuesModule } from './venues/venues.module';
import { PromoCodeModule } from './promo-code/promo-code.module';
import { ProvidersModule } from './providers/providers.module';
import { PayerModule } from './payer/payer.module';
import { ReasonTypesModule } from './reason-types/reason-types.module';
import { ReportsModule } from './reports/reports.module';
import { RideshareAvailabilityModule } from './rideshare-availability/rideshare-availability.module';

// app component
import { AppComponent } from './app.component';
import {environment} from '../environments/environment';
import {LocationsModule} from './hospitals/locations/locations.module';
import { LaunchDarklyService } from './core/launchdarkly.service';
import { FederatedIdentityGroupsModule } from './federated-identity-groups/federated-identity-groups.module';
import { CustomersModule } from './customers/customers.module';


@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    UiSwitchModule,
    AppRoutingModule,
    CoreModule,
    BaseModule,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      audience: environment.appurl,
      httpInterceptor: {
        allowedList: [
          {
            // Match any request that starts 'https://YOUR_DOMAIN/api/v2/' (note the asterisk)
            uri: environment.appurl + '*',
            tokenOptions: {
              // The attached token should target this audience
              audience: environment.appurl
            }
          }
        ]
      }
    }),
    ComponentsModule,
    UsersModule,
    GooglePlaceModule,
    HospitalsModule,
    ReasonTypesModule,
    LocationsModule,
    VenuesModule,
    PromoCodeModule,
    ProvidersModule,
    PayerModule,
    ReportsModule,
    RideshareAvailabilityModule,
    FederatedIdentityGroupsModule,
    CustomersModule,
  ],

  providers: [
    LaunchDarklyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],

  bootstrap: [ AppComponent ]
})
export class AppModule { }
