import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

import { Option } from '../../../components/dropdown/dropdown';
import { MultiSelectOption } from '../../../components/multi-select/multi-select';
import { ProviderService } from '../../providers.service';
import { State } from '../../provider';


@Component({
  selector: 'provider-state-counties-modal',
  templateUrl: './state-counties-modal.component.html',
  styleUrls: ['./state-counties-modal.component.scss']
})
export class StateCountiesModalComponent implements OnInit {
  @Input() providerId: number;
  @Input() stateList: State[] = [];
  @Input() stateId?: number;
  @Input() countyIds?: number[] = [];
  @Input() existingStateIds?: number[] = [];
  @Output() public onDone: EventEmitter<object> = new EventEmitter();

  stateCountiesForm: FormGroup;

  filteredStateList: Option[];
  counties: MultiSelectOption[] = [];
  selectedStateId: number;
  countiesLoaded: boolean;

  constructor(
    public modalRef: BsModalRef,
    private providerService: ProviderService,
  ) {
  }

  ngOnInit() {
    if (this.stateId) {
      this.selectedStateId = this.stateId;
      this.loadCounties(this.stateId);
    } else {
      this.filteredStateList = this.stateList
        .filter(s => this.existingStateIds.indexOf(s.id) < 0)
        .map(state => ({label: state.name, value: state.id.toString()}));
    }
    this.buildFormGroup();
  }

  loadCounties(stateId) {
    this.countiesLoaded = false;
    this.providerService.getCounties(stateId).subscribe(({data}) => {
      this.counties = data.map((county) => ({
        label: county.name,
        name: county.id
      }));
      this.countiesLoaded = true;
    });
  }

  buildFormGroup() {
    this.stateCountiesForm = new FormGroup({
      state: new FormControl(this.stateId, Validators.required),
      counties: new FormControl(this.countyIds, Validators.required)
    });
  }

  onStateSelect(stateId) {
    this.loadCounties(stateId);
    this.selectedStateId = stateId;
  }

  generateOutputData(value) {
    const countiesMap = _.keyBy(this.counties, 'name');
    const state = this.stateList.find(s => s.id == value.state);

    return {
      state: {
        id: state.id,
        name: state.name
      },
      counties: value.counties.map(
        countyId => {
          const county = countiesMap[countyId];
          return {id: county.name, name: county.label};
        })
    };
  }

  done() {
    this.onDone.emit(this.generateOutputData(this.stateCountiesForm.value));
    this.modalRef.hide();
  }
}
