import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { ServiceArea, State } from '../../provider';
import { FlashService } from '../../../components/flash/flash.service';
import { ProviderService } from '../../providers.service';
import { StateCountiesModalComponent } from '../state-counties-modal/state-counties-modal.component';


@Component({
  selector: 'provider-service-areas',
  templateUrl: './service-areas.component.html',
  styleUrls: ['./service-areas.component.scss']
})
export class ServiceAreasComponent implements OnInit {

  @Input() providerId: any;
  @Input() serviceAreas: ServiceArea[];
  @Input() states: State[];

  cleanData: ServiceArea[] = [];
  dirtyData: ServiceArea[] = [];
  isEditMode = false;

  addStateCountiesModal: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private flashService: FlashService,
    private providerService: ProviderService,
  ) {
  }

  ngOnInit() {
    this.cleanData = this.serviceAreas;
    this.dirtyData = _.cloneDeep(this.serviceAreas);
  }

  public toggleEdit() {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.serviceAreas = this.dirtyData;
    } else {
      this.serviceAreas = this.cleanData;
      this.dirtyData = _.cloneDeep(this.serviceAreas);
    }
  }

  public openAddStateCountiesModal() {
    const initialState = {
      providerId: this.providerId,
      existingStateIds: this.dirtyData.map(area => area.state.id),
      stateList: this.states,
    };
    this.addStateCountiesModal = this.modalService.show(
      StateCountiesModalComponent,
      <ModalOptions>{initialState}
    );

    this.addStateCountiesModal.content.onDone.subscribe((stateCounties) => {
      this.dirtyData.push(stateCounties);
    });
  }

  public removeState(stateId) {
    this.dirtyData.splice(this.dirtyData.findIndex(x => x.state.id === stateId), 1);
  }

  public updateAddStateCountiesModal(stateId) {
    const stateCounty = this.dirtyData.find(data => data.state.id === stateId);
    const initialState = {
      providerId: this.providerId,
      stateId: stateId,
      countyIds: stateCounty.counties.map(county => county.id),
      stateList: this.states
    };
    this.addStateCountiesModal = this.modalService.show(
      StateCountiesModalComponent,
      <ModalOptions>{initialState}
    );

    this.addStateCountiesModal.content.onDone.subscribe(({counties}) => {
      stateCounty.counties = counties;
    });
  }

  getPayload() {
    return this.dirtyData.map(data => ({
      state: data.state.id,
      counties: data.counties.map(county => county.id)
    }));
  }

  public save() {
    this.providerService.updateServiceAreas(this.providerId, {data: this.getPayload()})
      .subscribe(
        () => {
          this.cleanData = this.dirtyData.filter(data => data.counties.length);
          this.toggleEdit();
          this.flashService.add({
              type: 'success',
              message: `<strong>Success!</strong> Contact information has been updated.`
            },
          );
        },
        error => this.toggleEdit()
      );
  }
}
