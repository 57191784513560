import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { TabDirective } from 'ngx-bootstrap/tabs';

import {
  Hospital,
  organizationTypesMap,
  organizationTypeOptions,
  measurementUnitOptions,
  measurementUnitMap
} from '../hospital';
import { FlashService } from '../../components/flash/flash.service';
import { HospitalService } from '../hospital.service';
import { HospitalProgram } from '../programs/hospital-program';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NewHospitalProgramModalComponent } from '../programs/new-program/new-program.component';
import { HospitalProgramService } from '../programs/hospital-program.service';
import { AuthHttpService } from '../../core/auth-http.service';
import { Locations } from '../locations/locations';
import { LocationModalComponent } from '../locations/location-modal/location-modal.component';
import { LocationsService } from '../locations/locations.service';
import { HospitalCommonComponent } from '../hospital-common';
import { AddVehicleRatesModalComponent } from '../vehicle-rates/modal/modal.component';
import { Provider } from '../../providers/provider';
import { PreferredProviders } from '../models/preferred-providers';
import { AccessRules } from '../models/access-rules';
import { Geofence } from '../geofence/geofence';
import { BenefitManagementComponent } from '../benefit-management/benefit-management.component';
import { LaunchDarklyService } from '../../core/launchdarkly.service';
import { Customer } from '../../customers/customer';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['../../../themes/ng2-smart-table.scss', './edit.component.scss']
})
export class HospitalsEditComponent extends HospitalCommonComponent implements OnInit {
  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  @ViewChild(BenefitManagementComponent, { static: false }) benefitManagement: BenefitManagementComponent;
  hospital: Hospital;
  hospitals: Hospital[];
  parentHospitals: Hospital[];
  customers: Customer[];
  activeProviders: Provider[];
  hospitalPrograms: HospitalProgram[];
  deactivatedHospitalPrograms: HospitalProgram[];
  hospitalLocations: Locations[];
  addVehicleRatesModal: BsModalRef;
  editBuffer = false;
  advanceBufferValue = null;
  previousEnabledVehiclesIds = [];
  vehiclesWithChilds = [];
  newHospitalProgramModal: BsModalRef;
  newHospitalLocationModal: BsModalRef;
  loading = false;
  editingHospital = false;
  hospitalEditForm: FormGroup;
  locationTableConfig;
  selectedVehiclesText = '';
  enabledVehicles = [];
  parentHospitalName = '';
  ppStrategyDisplayNames = PreferredProviders.strategiesMap;
  accessStrategyDisplayNames = AccessRules.strategiesMapWithDefault;
  organizationTypesMap = organizationTypesMap;
  organizationTypeOptions = organizationTypeOptions;
  geofence: Geofence;
  measurementUnitOptions = measurementUnitOptions;
  measurementUnitMap = measurementUnitMap;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private flashService: FlashService,
    protected hospitalService: HospitalService,
    private modalService: BsModalService,
    private hospitalProgramService: HospitalProgramService,
    private datePipe: DatePipe,
    private http: AuthHttpService,
    private locationService: LocationsService,
    private ld: LaunchDarklyService
  ) {
    super(hospitalService);

    this.hospitalEditForm = new FormGroup({
      address: new FormControl(''),
      advanced_cancelation_enabled: new FormControl(false),
      advanced_cancelation_threshold: new FormControl(24),
      advanced_cancelation_reason: new FormControl(''),
      calling_providers_ticket_enabled: new FormControl(true),
      calling_providers_ticket_threshold: new FormControl(''),
      advance_notice_for_ride_share_rides: new FormControl('', Validators.required),
      created_at: new FormControl('', Validators.required),
      email_offer_text: new FormControl(''),
      enabled_vehicles: new FormControl([], Validators.required),
      id: new FormControl(''),
      long_ride_threshold_in_miles: new FormControl(''),
      measurement_unit: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      customer_id: new FormControl(Validators.required),
      parent_id: new FormControl(Validators.required),
      password_expiration_interval: new FormControl('', Validators.required),
      password_expiration_interval_type: new FormControl(''),
      user_timeout_interval: new FormControl('', [Validators.min(15), Validators.max(480)]),
      payer_types: new FormControl([]),
      reason_types: new FormControl([], Validators.required),
      send_will_call_text: new FormControl(false),
      pcs_disabled: new FormControl(false),
      auth_disabled: new FormControl(false),
      show_long_ride_alert: new FormControl(false),
      tax_id: new FormControl(''),
      transport_rates: new FormControl([]),
      updated_at: new FormControl('', Validators.required),
      service_phone_number: new FormControl('', Validators.required),
      organization_type: new FormControl('', Validators.required),
      risk_surcharge: new FormControl(''),
      advanced_booking_threshold: new FormControl(''),
      advanced_booking_threshold_enabled: new FormControl(''),
    });

    this.updateAllowRiderCreationControl();
  }

  ngOnInit() {
    this.editingHospital = false;
    this.locationTableConfig = this.locationService.tableConfig();
    this.selectTab();
    this.route.data.subscribe(data => {
      this.geofence = data.geofence.data;
      
      this.hospital = data.hospital.data.hospital;
      this.customers = data.customers.customers;

      this.activeProviders = data.activeProviders.data;
      this.hospital['transport_rates'] = data.hospital.data.transport_rates;

      if (this.hospital.parent_id) {
        this.parentHospitalName = this.hospital.name.split(' > ')[0];
      }

      this.hospitalService.convertRideShareBufferToMinutes(this.hospital);
      this.setPaymentMethods(data.payerTypes.data, this.hospital.payer_types);
      this.setReasonMethods(data.reasonTypes.data, this.hospital.reason_types);

      this.hospitals = data.hospitals.data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }

        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }

        return 0;
      });
      if (data.activePrograms.programs) {
        this.hospitalPrograms = data.activePrograms.programs.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.hospitalPrograms = [];
      }

      if (data.inactivePrograms.programs) {
        this.deactivatedHospitalPrograms = data.inactivePrograms.programs.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.deactivatedHospitalPrograms = [];
      }

      this.hospitalLocations = [];
      if (data.locations) {
        data.locations.data.forEach((location) => {
          const newLocation = new Locations();
          newLocation.setData(location);
          this.locationService.mutateData(newLocation);
          this.hospitalLocations.push(newLocation);
        });
      }

      this.vehicleList = this.hospitalService.formatVehicles(data.vehicleTypes.data.data);
      this.vehiclesWithChilds = _.map(_.filter(this.vehicleList, (vehicle) => {
        if (vehicle.child) {
          return vehicle;
        }
      }), 'id');
      this.vehiclesWithChilds.push('ride_share');
      this.vehicleDropDownSettings = this.getSettings('Select Vehicle Types');
      this.reasonTypesDropDownSettings = this.getSettings('Select Reason Types');
      this.payerTypeSettings = this.getSettings('Select Payment Methods');

      this.enabledVehicles = data.hospital.data.enabled_vehicles;
      this.formatSelectedVehicles();
    });

    this.hospitalEditForm.get('advanced_cancelation_enabled').valueChanges
    .subscribe(value => {
      const threshold = this.hospitalEditForm.get('advanced_cancelation_threshold');
      const cancelation_reason = this.hospitalEditForm.get('advanced_cancelation_reason');
      if(value) {
        threshold.setValidators([Validators.required, Validators.min(1), Validators.max(72)]);
        cancelation_reason.setValidators(Validators.required);
      } else {
        threshold.clearValidators();
        threshold.setErrors(null);
        cancelation_reason.clearValidators();
        cancelation_reason.setErrors(null);
      }
    });

    this.hospitalEditForm.get('calling_providers_ticket_enabled').valueChanges
    .subscribe(value => {
      const triggerThreshold = this.hospitalEditForm.get('calling_providers_ticket_threshold');
      if(value) {
        triggerThreshold.setValidators([Validators.required, Validators.min(0), Validators.max(96)]);
      } else {
        triggerThreshold.clearValidators();
        triggerThreshold.setErrors(null);
      }
    });

    this.hospitalEditForm.get('advanced_cancelation_enabled').valueChanges
    this.getParentHospitals();
  }

  updateAllowRiderCreationControl() {
    if (!this.hospitalEditForm.get('allow_rider_creation')) {
      this.hospitalEditForm.addControl('allow_rider_creation', new FormControl(false));
    }
  }

  selectTab() {
    this.route.queryParams.subscribe(params => {
      const tabId = params['selectTab'];
      if (tabId) {
        this.tabset.tabs[tabId].active = true;
        this.router.navigate(['.'], { relativeTo: this.route });
      }
    });
  }

  isHospitalChild(hospital): boolean {
    if (!this.hospital.children) {
      return false;
    }

    return <boolean>(this.hospital.children.find(x => x.id === hospital.id) !== undefined);
  }

  toggleHospitalChild(hospital) {
    const data = {
      id: hospital.id,
      parent_id: this.hospital.id
    };

    if (hospital.id === this.hospital.id) {
      this.flashService.add({
        type: 'danger',
        message: '<strong>Oops!</strong> You can\'t add the current organization to the network!'
      });

      return;
    }

    if (this.hospital.parent_id !== null && hospital.parent_id === this.hospital.parent_id) {
      this.flashService.add({
        type: 'danger',
        message: '<strong>Oops!</strong> You can\'t the parent organization to the network!'
      });

      return;
    }

    if (this.isHospitalChild(hospital)) {
      this.hospitalService.removeChild(data).subscribe(h => {
        this.updateHospitals(h.data);
      });
    } else {
      this.hospitalService.addChild(data).subscribe(h => {
        this.updateHospitals(h.data);
      });
    }
  }

  getParentHospitals() {
    this.hospitalService.get().subscribe(({ data }) => {
      this.parentHospitals = data.filter((hospital) => hospital.parent_id === null);
    });
  }

  private updateHospitals(hospitals: Hospital[]) {
    if (this.hospital.children) {
      this.hospital.children.length = 0;
    } else {
      this.hospital.children = [];
    }

    this.hospital.children.push.apply(this.hospital.children, hospitals);

    this.flashService.add({
      type: 'success',
      message: `You've updated ${this.hospital.name}'s network`
    });
  }

  public editHospital() {
    this.editingHospital = true;
    const allHospitals = this.hospital.name.split('>');
    this.hospitalEditForm.get('address').setValue(this.hospital.address);
    this.hospitalEditForm.get('advance_notice_for_ride_share_rides')
        .setValue(this.hospital.advance_notice_for_ride_share_rides);
    this.hospitalEditForm.get('created_at')
        .setValue(this.datePipe.transform(this.hospital.created_at));
    this.hospitalEditForm.get('email_offer_text')
        .setValue(this.hospital.email_offer_text);
    this.hospitalEditForm.get('enabled_vehicles')
        .setValue(this.hospital.enabled_vehicles);
    this.hospitalEditForm.get('id').setValue(this.hospital.id);
    this.hospitalEditForm.get('name')
        .setValue(allHospitals[allHospitals.length - 1].trim());
    this.hospitalEditForm.get('password_expiration_interval')
        .setValue(this.hospital.password_expiration_interval);
    this.hospitalEditForm.get('password_expiration_interval_type')
        .setValue(this.hospital.password_expiration_interval_type);
    this.hospitalEditForm.get('user_timeout_interval')
        .setValue(this.hospital.user_timeout_interval);
    this.hospitalEditForm.get('parent_id')
        .setValue(this.hospital.parent_id);
    this.hospitalEditForm.get('customer_id')
        .setValue(this.hospital.customer_id);
    this.hospitalEditForm.get('payer_types')
        .setValue(this.filterList(this.payerTypesList, this.hospital.payer_types));
    this.hospitalEditForm.get('reason_types')
        .setValue(this.filterList(this.reasonTypesList, this.hospital.reason_types));
    this.hospitalEditForm.get('send_will_call_text')
        .setValue(this.hospital.send_will_call_text);
    this.hospitalEditForm.get('pcs_disabled')
        .setValue(this.hospital.pcs_disabled);
    this.hospitalEditForm.get('auth_disabled')
        .setValue(this.hospital.auth_disabled);
    this.hospitalEditForm.get('tax_id').setValue(this.hospital.tax_id);
    this.hospitalEditForm.get('transport_rates')
        .setValue(this.hospital.transport_rates);
    this.hospitalEditForm.get('updated_at')
        .setValue(this.datePipe.transform(this.hospital.updated_at));
    this.previousEnabledVehiclesIds =
      _.difference(this.hospital.enabled_vehicles, this.vehiclesWithChilds);
    this.hospitalEditForm.get('service_phone_number')
      .setValue(this.hospital.service_phone_number);
      this.hospitalEditForm.get('advanced_cancelation_enabled')
      .setValue(this.hospital.advanced_cancelation_enabled);
    this.hospitalEditForm.get('advanced_cancelation_threshold')
        .setValue(this.hospital.advanced_cancelation_threshold);
    this.hospitalEditForm.get('advanced_cancelation_reason')
        .setValue(this.hospital.advanced_cancelation_reason);
    this.hospitalEditForm.get('calling_providers_ticket_enabled')
        .setValue(this.hospital.calling_providers_ticket_enabled);
    this.hospitalEditForm.get('calling_providers_ticket_threshold')
        .setValue(this.hospital.calling_providers_ticket_threshold);
    this.hospitalEditForm.get('organization_type')
      .setValue(this.hospital.organization_type);
    this.hospitalEditForm.get('measurement_unit')
      .setValue(this.hospital.measurement_unit);
    this.hospitalEditForm.get('allow_rider_creation')
      .setValue(this.hospital.allow_rider_creation);
    
  }

  public cancelEditing() {
    if (this.hospital) {
      this.hospitalEditForm.reset({
        payer_types: this.filterList(this.payerTypesList, this.hospital.payer_types),
        enabled_vehicles: this.filterList(this.vehicleList, this.hospital.enabled_vehicles),
        reason_types: this.filterList(this.reasonTypesList, this.hospital.reason_types)
      });
    }
    this.editingHospital = false;
  }

  public vehicleRatesUpdate(event) {
    this.hospital['transport_rates'] = event;
    const payload = {
      transport_rates: event
    };
    this.hospitalService.updateVehicleRates(String(this.hospital.id), payload)
      .subscribe(
        (response) => {
          this.flashService.add({
            type: 'success',
            message: 'Updated organization.'
          });
        },
        (reason) => {
          this.http.handleError(reason);
        }
      );
  }

  private isTransportRateRequired(enabledVehicles) {
    // vehicles type which do not have transport rates
    const noTransportRates = ['ambulatory', 'ride_share']
    const vehiclesList = enabledVehicles.map(vehicle => vehicle.id);
    return !_.isEqual(vehiclesList.sort(), noTransportRates)
  }

  public update() {
    if (this.hospitalEditForm.invalid) {
      return;
    }
    const newVehicleIds = this.hospitalService.getVehicleIds(
      this.hospitalEditForm.get('enabled_vehicles').value
    );
    const transportRates = this.hospitalEditForm.get('transport_rates').value;
    const transportRatesNotAdded = _.find(transportRates, (item) => {
      return !item.base || !item.mileage;
    });

    // don't check for empty transportRates when only vehicle with no transport rates is present
    const checkEmptyTransportRates = this.isTransportRateRequired(
      this.hospitalEditForm.get('enabled_vehicles').value
    ) && _.isEmpty(transportRates)

    if (
      !_.isEmpty(newVehicleIds) &&
      !_.isEqual(newVehicleIds.sort(), this.previousEnabledVehiclesIds.sort()) ||
      transportRatesNotAdded ||
      checkEmptyTransportRates
    ) {
      const initialState = {
        hospital: <Hospital>{
          name: this.hospitalEditForm.get('name').value,
          enabled_vehicles: this.hospitalEditForm.get('enabled_vehicles').value,
          transport_rates: _.cloneDeep(this.hospitalEditForm.get('transport_rates').value)
        }
      };

      this.addVehicleRatesModal = this.modalService.show(AddVehicleRatesModalComponent, {initialState});

      const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
        if (this.addVehicleRatesModal.content.addedRates) {
          this.previousEnabledVehiclesIds = newVehicleIds;
          this.hospitalEditForm.get('transport_rates').setValue(
            this.addVehicleRatesModal.content.hospital.transport_rates
          );
        }

        hiddenSubscription.unsubscribe();
      });
    } else {
      let transport_rates = this.hospitalEditForm.get('transport_rates').value;
      if (_.isEmpty(newVehicleIds)) {
        transport_rates = {};
      }

      const payload = {
        address: this.hospitalEditForm.get('address').value,
        advance_notice_for_ride_share_rides: this.hospitalService.convertRideSharebufferInSeconds(this.hospitalEditForm.get('advance_notice_for_ride_share_rides').value),
        email_offer_text: this.hospitalEditForm.get('email_offer_text').value,
        enabled_vehicles: _.map(this.selectedVehicles, 'id'),
        name: this.hospitalEditForm.get('name').value,
        password_expiration_interval: this.hospitalEditForm.get('password_expiration_interval').value,
        password_expiration_interval_type: this.hospitalEditForm.get('password_expiration_interval_type').value,
        user_timeout_interval: this.hospitalEditForm.get('user_timeout_interval').value,
        payer_types: _.map(this.hospitalEditForm.get('payer_types').value, 'id'),
        parent_id: this.hospitalEditForm.get('parent_id').value,
        customer_id: this.hospitalEditForm.get('customer_id').value,
        reason_types: _.map(this.hospitalEditForm.get('reason_types').value, 'id'),
        send_will_call_text: this.hospitalEditForm.get('send_will_call_text').value,
        pcs_disabled: this.hospitalEditForm.get('pcs_disabled').value,
        advanced_cancelation_enabled: this.hospitalEditForm.get('advanced_cancelation_enabled').value,
        advanced_cancelation_threshold: this.hospitalEditForm.get('advanced_cancelation_threshold').value,
        advanced_cancelation_reason: this.hospitalEditForm.get('advanced_cancelation_reason').value,
        calling_providers_ticket_enabled: this.hospitalEditForm.get('calling_providers_ticket_enabled').value,
        calling_providers_ticket_threshold: this.hospitalEditForm.get('calling_providers_ticket_threshold').value,
        auth_disabled: this.hospitalEditForm.get('auth_disabled').value,
        tax_id: this.hospitalEditForm.get('tax_id').value,
        transport_rates: transport_rates,
        service_phone_number: this.hospitalEditForm.get('service_phone_number').value,
        organization_type: this.hospitalEditForm.get('organization_type').value,
        measurement_unit: this.hospitalEditForm.get('measurement_unit').value.toLowerCase(),
      };

      payload['allow_rider_creation'] = this.hospitalEditForm.get('allow_rider_creation').value;
      
      this.hospitalService.update(this.hospitalEditForm.get('id').value, payload)
        .subscribe(
          (response) => {
            this.flashService.add({
              type: 'success',
              message: 'Updated organization.'
            });
            const updateHospital = _.cloneDeep(this.hospital);
            this.editingHospital = false;
            this.enabledVehicles = response.data.enabled_vehicles;
            this.formatSelectedVehicles();
            this.hospital = updateHospital;
            updateHospital.address = response.data.address;
            updateHospital.advance_notice_for_ride_share_rides = response.data.advance_notice_for_ride_share_rides;
            updateHospital.email_offer_text = response.data.email_offer_text;
            updateHospital.enabled_vehicles = response.data.enabled_vehicles;
            updateHospital.long_ride_threshold_in_miles = response.data.long_ride_threshold_in_miles;
            updateHospital.name = response.data.name;
            updateHospital.password_expiration_interval = response.data.password_expiration_interval;
            updateHospital.password_expiration_interval_type = response.data.password_expiration_interval_type;
            updateHospital.user_timeout_interval = response.data.user_timeout_interval;
            updateHospital.payer_types = response.data.payer_types;
            updateHospital.parent_id = response.data.parent_id;
            updateHospital.customer_id = response.data.customer_id;
            updateHospital.customer_name = response.data.customer_name;
            updateHospital.reason_types = response.data.reason_types;
            updateHospital.send_will_call_text = response.data.send_will_call_text;
            updateHospital.pcs_disabled = response.data.pcs_disabled;
            updateHospital.advanced_cancelation_enabled = response.data.advanced_cancelation_enabled;
            updateHospital.advanced_cancelation_threshold = response.data.advanced_cancelation_threshold;
            updateHospital.advanced_cancelation_reason = response.data.advanced_cancelation_reason;
            updateHospital.calling_providers_ticket_enabled = response.data.calling_providers_ticket_enabled;
            updateHospital.calling_providers_ticket_threshold = response.data.calling_providers_ticket_threshold;
            updateHospital.auth_disabled = response.data.auth_disabled;
            updateHospital.show_long_ride_alert = response.data.show_long_ride_alert;
            updateHospital.tax_id = response.data.tax_id;
            updateHospital.transport_rates = response.data.transport_rates;
            this.hospitalService.convertRideShareBufferToMinutes(updateHospital);
            updateHospital.service_phone_number = response.data.service_phone_number;
            updateHospital.organization_type = response.data.organization_type;
            updateHospital.measurement_unit = response.data.measurement_unit;
            updateHospital.allow_rider_creation = response.data.allow_rider_creation;
            updateHospital.risk_surcharge = response.data.risk_surcharge;
            updateHospital.advanced_booking_threshold_enabled = response.data.advanced_booking_threshold_enabled;
            updateHospital.advanced_booking_threshold = response.data.advanced_booking_threshold;
            this.parentHospitalName = this.hospital.name.split(' > ')[0]
          },
          (reason) => {
            this.http.handleError(reason);
          }
        );
    }
  }

  addProgram() {
    this.newHospitalProgramModal = this.modalService.show(NewHospitalProgramModalComponent, {initialState:{ editingProgram: false }});
    this.newHospitalProgramModal.content.hospital = this.hospital;
    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.newHospitalProgramModal.content.added) {
        this.newHospitalProgramModal.content.added = false;
        this.loading = true;

        this.flashService.add({
          type: 'success',
          message: 'That organization program been added.'
        });

        const updatedList: HospitalProgram[] = this.hospitalPrograms.concat(this.newHospitalProgramModal.content.data);
        this.hospitalPrograms = [];
        setTimeout(() => {
          this.hospitalPrograms = updatedList;
          this.loading = false;
        }, 10);

      }

      hiddenSubscription.unsubscribe();
    });
    return false;
  }

  tabChanged(event) {
    if (event instanceof TabDirective) {
      this.cancelEditing();
      this.benefitManagement.cancelEditing();
    }
  }

  restoreProgram(program: HospitalProgram) {
    this.loading = true;
    this.hospitalProgramService.restore(this.hospital.id, program).subscribe({
      next: (restoredProgram) => {
        this.hospitalPrograms.push(program);
        this.deactivatedHospitalPrograms = this.deactivatedHospitalPrograms.filter(p => p.id !== program.id);
        this.loading = false;
        this.flashService.add({
          type: 'success',
          message: `The program "${program.name}" has been reactivated.`
        });
      },
      error: (err) => {
        this.loading = false;
        this.flashService.add({
          type: 'danger',
          message: `Failed to reactivate the program "${program.name}".`
        });
      }
    });
  }

  updateProgram(program: HospitalProgram) {
    this.newHospitalProgramModal = this.modalService.show(NewHospitalProgramModalComponent, {initialState:{ editingProgram: true }});
    this.newHospitalProgramModal.content.hospital = this.hospital;
    Object.assign(this.newHospitalProgramModal.content.hospitalProgram, program);
    const updatedProgram = this.newHospitalProgramModal.content.hospitalProgram;

    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.newHospitalProgramModal.content.added) {
        this.newHospitalProgramModal.content.added = false;
        this.loading = true;

        this.hospitalProgramService.setProgramData(program, updatedProgram);
        this.hospitalProgramService.mutateData(program);
        this.flashService.add({
          type: 'success',
          message: `The program "${updatedProgram.name}" been updated.`
        });
      }
      else if (this.newHospitalProgramModal.content.deleted) {
        this.newHospitalProgramModal.content.deleted = false;
        this.loading = true;        
        const remainingHospitals = this.hospitalPrograms.filter((item)=> item.id !== program.id)
        this.deactivatedHospitalPrograms.push(program);
        setTimeout(() => {
          this.hospitalPrograms = remainingHospitals;
          this.loading = false;
        }, 10);
        this.flashService.add({
          type: 'success',
          message: `The program "${updatedProgram.name}" been deleted.`
        });
      }
      hiddenSubscription.unsubscribe();
    });
    return false;
  }

  addLocation($event) {
    this.newHospitalLocationModal = this.modalService.show(LocationModalComponent);
    this.newHospitalLocationModal.content.hospital = this.hospital;
    let selectedLocation = null;

    if ($event && $event.data) {
      selectedLocation = <Locations>this.hospitalLocations.find((location) => location.id === $event.data.id);
      Object.assign(this.newHospitalLocationModal.content.location, selectedLocation);
      this.newHospitalLocationModal.content.setIsEditing();
      this.newHospitalLocationModal.content.getVenues();
    }

    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.newHospitalLocationModal.content.added) {
        this.newHospitalLocationModal.content.added = false;
        let updatedList: Locations[];
        let message = 'That organization location been added.';
        if (selectedLocation) {
          this.hospitalLocations.splice(this.hospitalLocations.indexOf(selectedLocation), 1);
          message = 'That organization location been updated.';
        }

        updatedList = this.hospitalLocations.concat(this.newHospitalLocationModal.content.location);
        this.hospitalLocations = [];
        this.locationService.sortData(updatedList);
        setTimeout(() => {
          this.hospitalLocations = updatedList;
        }, 0);
        this.flashService.add({
          type: 'success',
          message: `${message}`
        });
      }

      if (this.newHospitalLocationModal.content.isDeleted) {
        const updatedList = this.hospitalLocations.filter(loc => loc.id !== selectedLocation.id);
        this.hospitalLocations = updatedList;

        this.flashService.add({
          type: 'success',
          message: 'That organization location has been deleted'
        });

        return false;
      }
      hiddenSubscription.unsubscribe();
    });
    return false;
  }

  setPaymentMethods(payerTypes, selectedItemIds) {
    // Set payer_type options list.
    this.payerTypesList = this.processPayerTypes(payerTypes);
    // Set selected payer_types list.
    this.selectedPayerTypes = this.filterItems(this.payerTypesList, selectedItemIds);
  }

  filterItems(list, selectedItemIds) {
    return _.filter(list, listItem => selectedItemIds.indexOf(listItem['id']) !== -1);
  }

  setReasonMethods(payerTypes, selectedItemIds) {
    this.reasonTypesList = this.hospitalService.processData(payerTypes);
    this.selectedReasonTypes = this.filterItems(this.reasonTypesList, selectedItemIds);
  }

  formatSelectedVehicles() {
    this.selectedVehicles = [];
    this.selectedVehiclesText = '';
    this.selectedVehicles.push.apply(this.selectedVehicles, _.filter(this.vehicleList, vehicle => {
      if (this.enabledVehicles.includes(vehicle.id)) {
        return vehicle;
      }
    }));
    this.selectedVehiclesText = _.map(this.selectedVehicles, 'itemName').join(', ');
  }
}
