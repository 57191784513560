import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { PayerService } from '../../payer.service';
import * as moment from 'moment';
import { Note } from '../../payer';
import { AddCarrierNotesModalComponent } from '../../notes/carrier-notes/add-carrier-notes-modal/add-carrier-notes-modal.component';
import { UpdateCarrierNotesModalComponent } from '../../notes/carrier-notes/update-carrier-notes-modal/update-carrier-notes-modal.component';

@Component({
  selector: 'app-carrier-notes',
  templateUrl: './carrier-notes.component.html',
  styleUrls: ['./carrier-notes.component.scss']
})
export class CarrierNotesComponent implements OnInit {
  @Input() carrierId: string;
  @Input() notes: Array<Note>;
  addNotesModal: BsModalRef;
  updateNotesModal: BsModalRef;
  count: number;

  constructor(private payerService: PayerService, private modalService: BsModalService) { }

  ngOnInit() {
    this.notes = this.formatNotes(this.notes);
    this.count = this.notes.length;
  }

  formatNotes(notes: Array<Note>) {
    return notes.map(this.formatNote);
  }

  formatNote(note: Note) {
    note['created_at'] = moment(note['created_at']).format('LL');
    note['updated_at'] = moment(note['updated_at']).format('LL');

    return note;
  }

  openAddNoteModal() {
    this.addNotesModal = this.modalService.show(AddCarrierNotesModalComponent, {
      initialState: {
        carrierId: this.carrierId
      }
    });
    this.addNotesModal.content.onCreate.subscribe((newNote: Note) => {
      this.notes.unshift(this.formatNote(newNote));
      this.count = this.notes.length;
    });
  }

  openEditNoteModal(note: Note) {
    this.updateNotesModal = this.modalService.show(UpdateCarrierNotesModalComponent, {
      initialState: {
        carrierId: this.carrierId,
        note: Object.assign({}, note)
      }
    });
    this.updateNotesModal.content.onUpdate.subscribe((updatedNote: Note) => {
      const noteToUpdate = this.notes.find((noteObj) => noteObj.id === updatedNote.id);
      noteToUpdate.content = updatedNote.content;
    });
    this.updateNotesModal.content.onDelete.subscribe((deletedNoteId: number) => {
      const indexToRemove = this.notes.findIndex((noteObj) => noteObj.id === deletedNoteId);
      this.notes.splice(indexToRemove, 1);
      this.count = this.notes.length;
    });
  }

}
