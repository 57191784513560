import { Component, OnInit } from '@angular/core';
import {Venue} from '../venues';
import {ActivatedRoute, Router} from '@angular/router';
import {VenuesService} from '../venues.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FlashService} from '../../components/flash/flash.service';
import {VenueDeleteComponent} from '../venue-delete/venue-delete.component';

@Component({
  selector: 'app-edit-venue',
  templateUrl: './edit-venue.component.html',
  styleUrls: ['./edit-venue.component.scss']
})
export class EditVenueComponent implements OnInit {
  venue = new Venue();
  copyVenue = new Venue();
  deleteVenueModal;
  isEditingVenue = false;
  constructor(
    private route: ActivatedRoute,
    private venueService: VenuesService,
    private modalService: BsModalService,
    private flashService: FlashService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.venueService.formatVenue(data.venues.data);
      this.venue.setData(data.venues.data);
    });
  }

  public confirmVenueDeleteModal() {
    this.deleteVenueModal = this.modalService.show(VenueDeleteComponent);
    this.deleteVenueModal.content.venueId = this.route.params['value']['id'];
    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.deleteVenueModal.content.deleted) {
        this.deleteVenueModal.content.deleted = false;

        this.flashService.add({
          type: 'success',
          message: 'Venue Deleted.'
        });
        this.router.navigate(['/venues']);
      }

      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  updateVenue() {
    this.venueService.update(this.venue).subscribe(
      (response) => {
        this.flashService.add({
          type: 'success',
          message: `You've updated ${this.venue.location_name}`
        });
        this.isEditingVenue = false;
      });
  }

  public cancelEditing() {
    this.venue.setData(this.copyVenue);
    this.isEditingVenue = false;
  }

  public editVenue() {
    this.copyVenue = new Venue();
    this.copyVenue.setData(this.venue);
    this.isEditingVenue = true;
  }

}
