<div class="row">
  <div class="col-lg-6">
    <h3>Bulk User Upload</h3>
  </div>
</div>
<div>
  <table class="table table-striped">
    <tbody>
      <tr>
        <th class="col-xs-3">
          Upload user spreadsheet
        </th>
        <td *ngIf="userProcessing" class="text-success">
          You will be notified by email when the users have been created.
        </td>
        <td *ngIf="!userProcessing">
          <div class="input-group">
            <label class="input-group-btn">
              <span class="btn btn-primary">
                Select File
                <input type="file"
                  class="hidden"
                  (change)="userFileChangeEvent($event.target.files)" />
              </span>
            </label>
            <input type="text"
              class="form-control"
              readonly [(ngModel)]="selectedFileName"
              placeholder="Upload User File" />
          </div>
          <div *ngIf="errorMessage" class="error">
            {{ errorMessage }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row">
  <div class="col-lg-6 pull-right user-save">
    <button class="btn btn-primary btn-wrap pull-right" (click)="onSaveClick()" [disabled]="disableSave">
      Upload
    </button>
  </div>
</div>