<div class="row">
  <div class="col-lg-6">
    <h3>General Information</h3>
  </div>

  <div class="col-lg-6">
    <button *ngIf="isEditMode"
            type="button"
            class="btn btn-primary btn-wrap pull-right"
            [disabled]="generalInformationForm.invalid"
            (click)="save()">
      Update
    </button>
    <button *ngIf="!isEditMode" type="button"
            class="btn btn-primary btn-wrap pull-right"
            (click)="toggleEdit()">
      Edit
    </button>
    <button *ngIf="isEditMode" type="button"
            class="btn btn-default btn-wrap pull-right"
            (click)="toggleEdit()">
      Close
    </button>
  </div>
</div>

<div *ngIf="!isEditMode">
  <table class="table table-striped">
    <tbody>
      <tr>
        <td>Company Name</td>
        <td>{{data.name}}</td>
      </tr>

      <tr>
        <td width="250px">General Liability</td>
        <td>{{data.properties.general_liability}}</td>
      </tr>

      <tr>
        <td>Automobile Liability</td>
        <td>{{data.properties.automobile_liability}}</td>
      </tr>

      <tr>
        <td>Workers Compensation</td>
        <td>{{data.properties.workers_compensation}}</td>
      </tr>

      <tr>
        <td>Active Medicaid Credentials?</td>
        <td>{{data.properties.has_active_medicaid_creds ? 'Yes' : 'No'}}</td>
      </tr>

      <tr>
        <td>Dispatch Software</td>
        <td>{{getDispatchSoftwareDisplayValue()}}</td>
      </tr>

      <tr *ngIf="data.properties.type_of_dispatch_software">
        <td>Provider Code</td>
        <td>{{data.properties.provider_code}}</td>
      </tr>

      <tr>
        <td>Tax Code</td>
        <td>{{data.tax_code}}</td>
      </tr>

      <tr>
        <td>National Provider Identifier</td>
        <td>{{data.properties.npi_number}}</td>
      </tr>

      <tr>
        <td>Application Date</td>
        <td>{{data.applied_at | date}}</td>
      </tr>

      <tr *ngIf="data.active">
        <td>Approval Date</td>
        <td>{{data.approved_at | date}}</td>
      </tr>

      <tr>
        <td>Certifications</td>
        <td>
          <ul>
            <li *ngFor="let certification of data.properties.certifications">{{certification}}</li>
          </ul>
        </td>
      </tr>

      <tr>
        <td>Languages Spoken</td>
        <td>
          <ul>
            <li *ngFor="let language of data.properties.languages_spoken">{{language}}</li>
          </ul>
        </td>
      </tr>

      <tr>
        <td>Unit of Measurement</td>
        <td>{{measurementUnitMap[data.properties.measurement_unit]}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="isEditMode">
  <form class="form" [formGroup]="generalInformationForm">
    <table class="table table-striped">
      <tbody>
        <tr>
          <td>Company Name</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="companyName"
                  placeholder="Company Name">
          </td>
        </tr>

        <tr>
          <td width="250px">General Liability</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="generalLiability"
                  placeholder="General Liability">
          </td>
        </tr>

        <tr>
          <td>Automobile Liability</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="automobileLiability"
                  placeholder="Automobile Liability">
          </td>
        </tr>

        <tr>
          <td>Workers Compensation</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="workersCompensation"
                  placeholder="Workers Compensation">
          </td>
        </tr>

        <tr>
          <td>Active Medicaid Credentials?</td>
          <td>
            <ui-switch formControlName="activeMedicaidCredentials">
            </ui-switch>
          </td>
        </tr>

        <tr>
          <td>Dispatch Software</td>
          <td>
            <dropdown [options]="dispatchSoftwareOptions"
                      formControlName="dispatchSoftware">
            </dropdown>
            <input *ngIf="unknownDispatchSoftware" type="text"
                  class="form-control"
                  formControlName="dispatchSoftwareAlt"
                  placeholder="Please specify">
          </td>
        </tr>

        <tr *ngIf="dispatchSoftwareSelected">
          <td>Provider Code</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="providerCode"
                  placeholder="Please specify">
          </td>
        </tr>

        <tr>
          <td>Tax Code</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="taxCode"
                  placeholder="Tax Code">
          </td>
        </tr>

        <tr>
          <td>National Provider Identifier</td>
          <td>
            <input type="text"
                  class="form-control"
                  formControlName="npi"
                  placeholder="National Provider Identifier">
          </td>
        </tr>

        <tr>
          <td>Application Date</td>
          <td>
            <input type="date"
                  class="form-control"
                  formControlName="appliedAt"
                  placeholder="Applicaton Date">
          </td>
        </tr>

        <tr *ngIf="data.active">
          <td>Approved Date</td>
          <td>
            <input type="date"
                  class="form-control"
                  formControlName="approvedAt"
                  placeholder="Approved Date">
          </td>
        </tr>

        <tr>
          <td>Certifications</td>
          <td>
            <multi-select [options]="certificationsOptions"
                          formControlName="certifications">
            </multi-select>
          </td>
        </tr>

        <tr>
          <td>Languages Spoken</td>
          <td>
            <multi-select [options]="languageSpokenOptions"
                          formControlName="languagesSpoken">
            </multi-select>
            <input *ngIf="unknownLanguageSpoken" type="text"
                  class="form-control"
                  formControlName="languagesSpokenAlt"
                  placeholder="Please specify">
          </td>
        </tr>

        <tr class="form-group">
          <td>Unit of Measurement</td>
          <td>
            <select class="form-control" formControlName="measurement_unit">
              <option *ngFor="let type of measurementUnitOptions" [value]="type.id">
                {{type.name}}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>
