import { Component, OnInit } from '@angular/core';
import {VenuesService} from '../venues.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-venue-delete',
  templateUrl: './venue-delete.component.html',
  styleUrls: ['./venue-delete.component.scss']
})
export class VenueDeleteComponent{
  venueId;
  deleted = false;
  constructor(
    private venueService: VenuesService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute
  ) { }

  deleteVenue() {
    this.venueService.delete(this.venueId).subscribe(
      (response) => {
        this.deleted = true;
        this.modalRef.hide();
      },
      () => {
        this.modalRef.hide();
      });
  }
}
