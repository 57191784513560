import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TabDirective, TabsetComponent } from "ngx-bootstrap/tabs";
import { FlashService } from "../../components/flash/flash.service";
import { EditCustomerComponent } from "../edit/edit.component";
import { FederatedIdentityGroupsViewComponent } from "../../federated-identity-groups/view/view.component";
import { Customer } from "../customer";

@Component({
  selector: 'app-customer-tabs',
  templateUrl: './customer-tabs.component.html',
  styleUrls: ['./customer-tabs.component.scss']
})
export class CustomerTabsComponent implements OnInit {
  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  @ViewChild(EditCustomerComponent, {static: true}) editCustomerChild: EditCustomerComponent
  @ViewChild(FederatedIdentityGroupsViewComponent, {static: true}) federatedIdentityGroupsChild: FederatedIdentityGroupsViewComponent
  customer: Customer;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private flashService: FlashService) {
  }

  ngOnInit() {
    this.selectTab();

    this.route.data.subscribe((data) => {
      const customer = data.customers.customer;
      this.customer = {
        id: customer.id,
        name: customer.name,
        clientId: customer.partner_api_client_id,
        connectionName: customer.connection_name,
        createdAt: customer.created_at,
        updatedAt: customer.updated_at
      }
    });
  }

  selectTab() {
    this.route.queryParams.subscribe(params => {
      const tabId = params['selectTab'];
      if (tabId) {
        this.tabset.tabs[tabId].active = true;
        this.router.navigate(['.'], { relativeTo: this.route });
      }
    });
  }

  tabChanged(event) {
    if (event instanceof TabDirective) {
    }
  }
}