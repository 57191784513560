import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NewCarrierModalComponent } from '../new-carrier-modal/new-carrier-modal.component';
import { ActivePlan, Carrier, PAYER_TYPES, VEHICLE_TYPES } from '../payer';
import { PayerService } from '../payer.service';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-active-plan-modal',
  templateUrl: './new-active-plan-modal.component.html',
  styleUrls: ['./new-active-plan-modal.component.scss']
})
export class NewActivePlanModalComponent implements OnInit {
  @Input() pendingPlanId;
  @Input() carrierOptions: Array<Carrier>;
  @Output() public onDone: EventEmitter<ActivePlan> = new EventEmitter();
  @ViewChild('newPlanForm', { static: false }) public newPlanForm: NgForm;
  activePlan = <ActivePlan>{
    name: '',
    carrier_id: null,
    authorization_contact: '',
    insurance_categories: [],
    covered_vehicles: [],
    auth_required: false,
    brokered_transport: false
  };
  insuranceCategoryOptions = PAYER_TYPES;
  vehicleTypeOptions = VEHICLE_TYPES;
  newCarrierModal: BsModalRef;

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    protected payerService: PayerService
  ) { }

  ngOnInit() {
  }

  generatePayload() {
    const payload = _.cloneDeep(this.activePlan);
    payload.insurance_categories = _.map(payload.insurance_categories, 'id');
    payload.covered_vehicles = _.map(payload.covered_vehicles, 'id');

    return payload;
  }

  save() {
    this.payerService.createActivePlan(this.generatePayload())
    .subscribe((response: any) => {
      this.onDone.emit(<ActivePlan>{ id: response.data.id, ...this.activePlan });
      this.modalRef.hide();
    },
      this.handleServerErrors
    );
  }

  handleServerErrors = (response) => {
    if (response.status === 400) {
      const errors = JSON.parse(response['_body']).errors;
      Object.keys(errors).forEach(prop => {
        const formControl = this.newPlanForm.form.get(prop);
        if (formControl) {
          formControl.setErrors({
            serverError: errors[prop]
          });
        }
      });
    }
  }

  openNewCarrierModal() {
    this.newCarrierModal = this.modalService.show(NewCarrierModalComponent);
    this.newCarrierModal.content.onDone.subscribe((carrier: Carrier) => {
      this.carrierOptions.unshift(carrier);
      this.activePlan.carrier_id = carrier.id;
    });
  }
}
