import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { PayerService } from './payer.service';
import { Observable } from 'rxjs';

export const ActivePlanNotesResolverService: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> => { 
      const payerService: PayerService = inject(PayerService);
      return payerService.getActivePlanNotes(route.params.id);
  }
