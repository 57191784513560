<div class="modal-header">
  <h4 class="modal-title pull-left">
    <app-icon icon="home" iconClass="button-icon"></app-icon> Add Venue
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-horizontal" action="" #addVenueForm="ngForm" >
    <div class="form-group">
      <label class="col-sm-3 control-label">Current Address</label>
      <div class="col-sm-9">
        <input ngx-google-places-autocomplete [options]='options' class="form-control" name="currentAddress" required
               placeholder="Current Address" #search [(ngModel)]="venue.current_address"
               (ngModelChange)="resetLatLng();" #currentAddress="ngModel"
               autocorrect="off" autocapitalize="off" spellcheck="off" (onAddressChange)="addressChanged($event)">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Latitude</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" name="latitude" required name="latitude"
               placeholder="Latitude" [(ngModel)]="venue.latitude" [readonly]="true" #latitude="ngModel">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Longitude</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" name="longitude" required
               placeholder="Longitude" [(ngModel)]="venue.longitude" [readonly]="true">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Location Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" name="location-name" required
               placeholder="Location Name" [(ngModel)]="venue.location_name">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Venue Name</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" name="zone-name" required
               placeholder="Venue Name" [(ngModel)]="venue.zone_name">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Venue Latitude </label>
      <div class="col-sm-9">
        <input type="text" class="form-control" name="zone-latitude" required
               placeholder="Venue Latitude" [(ngModel)]="venue.zone_latitude">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">Venue Longitude</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" name="zoneLongitude" required #zoneLongitude="ngModel"
               placeholder="Venue Longitude" [(ngModel)]="venue.zone_longitude">
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-primary" (click)="addVenue()" [disabled]="addVenueForm.form.invalid">
    Save Changes
  </button>
</div>
