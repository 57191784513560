<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Transport Companies</li>
  <li class="active">Approved</li>
</ol>

<ul class="nav nav-tabs">
  <li class="active">
    <a routerLink="/providers">
      <app-icon icon="package" iconClass="button-icon"></app-icon> Approved
    </a>
  </li>

  <li>
    <a routerLink="/providers-pending">
      <app-icon icon="loader" iconClass="button-icon"></app-icon> Pending
    </a>
  </li>
</ul>

<div class="row">
  <div class="col-xs-12">

    <p-table
    [value]="companies"
    [lazy]="true"
    [totalRecords]="totalRecords"
    [paginator]="true"
    (onLazyLoad)="loadCompanies($event)"
    [rows]="rows"
    [first]="first"
    [showCurrentPageReport]="true"
    selectionMode="single"
    [(selection)]="selectedCompany"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="null" 
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    (onPage)="pageChange($event)"
    [rowsPerPageOptions]="[10, 25, 50]"
    stateStorage="session" 
    stateKey="state-session-providers-approved"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th style="width:10%">
          <p-columnFilter 
          type="text" 
          field="name"
          [placeholder]="placeholders['name']"
          ariaLabel="Filter name" 
          [showMenu]="false"
          [showClearButton]="false"
          />
          </th>
          <th style="width:10%">
            <p-columnFilter 
            type="text" 
            field="phone"
            [placeholder]="placeholders['phone']"
            ariaLabel="Filter phone" 
            [showMenu]="false"
            [showClearButton]="false"
            />
          </th>
        <th>
          <p-dropdown 
              [ngModel]="selectedState" 
              [placeholder]="placeholders['states']"
              [options]="statesFilterList" 
              optionLabel="title"
              [showClear]="true"
              (onChange)="pageChange($event, 'states')"
              >
          </p-dropdown>
        </th>
        <th style="width:10%">
          <p-columnFilter 
          type="text" 
          field="counties"
          [placeholder]="placeholders['counties']"
          ariaLabel="Filter county" 
          [showMenu]="false"
          [showClearButton]="false"
          />
        </th>
        <th style="width:10%">
          <p-columnFilter 
          type="text" 
          field="zipcodes"
          [placeholder]="placeholders['zipcodes']"
          ariaLabel="Filter zipcode" 
          [showMenu]="false"
          [showClearButton]="false"
          />
        </th>
        <th>
          <p-dropdown 
              [ngModel]="selectedApprovedAt" 
              [placeholder]="placeholders['approved_at']"
              [options]="monthList" 
              optionLabel="title"
              [showClear]="true"
              (onChange)="pageChange($event, 'approved_at')"
              >
          </p-dropdown>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company>
      <tr [pSelectableRow]="company">
        <td *ngFor="let key of keys">{{ company[key] }}</td>
      </tr>
    </ng-template>
  </p-table>

    <app-csv-download
      title="Download Providers CSV"
      fileName="providers.csv"
      [formatDataFunction]="formatCSVdataFunction.bind(this)"
      url="providers/active-providers-report">
    </app-csv-download>
  </div>
</div>
