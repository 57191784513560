import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthHttpService } from '../core/auth-http.service';
import { Hospital } from './hospital';

@Injectable()
export class HospitalService {

  constructor(private http: AuthHttpService) {}

  create(hospital: Hospital): Observable<Hospital | string> {
    const url = `${environment.apiurl}/hospitals/create`;
    const payLoad = {
      address: hospital.address,
      advance_notice_for_ride_share_rides: this.convertRideSharebufferInSeconds(hospital.advance_notice_for_ride_share_rides),
      service_phone_number: hospital.service_phone_number,
      autoassign_company_id: hospital.autoassign_company_id,
      bidding_tolerance: hospital.bidding_tolerance,
      bidding_tolerance_enabled: hospital.bidding_tolerance_enabled,
      email_offer_text: hospital.email_offer_text,
      enabled_vehicles: hospital.enabled_vehicles,
      eta_submission_window: hospital.eta_submission_window,
      measurement_unit: hospital.measurement_unit,
      name: hospital.name,
      parent_id: hospital.parent_id,
      customer_id: hospital.customer_id,
      password_expiration_interval: hospital.password_expiration_interval,
      password_expiration_interval_type: hospital.password_expiration_interval_type,
      user_timeout_interval: hospital.user_timeout_interval,
      payer_types: _.map(hospital.payer_types, 'id'),
      pp_submission_window: hospital.pp_submission_window,
      reason_types: _.map(hospital.reason_types, 'id'),
      send_will_call_text: hospital.send_will_call_text,
      pcs_disabled: hospital.pcs_disabled,
      auth_disabled: hospital.auth_disabled,
      tax_id: hospital.tax_id,
      transport_rates: hospital.transport_rates,
      organization_type: hospital.organization_type
    };

    return this.http.post<Hospital>(url, payLoad).pipe(
      catchError((err) => this.http.handleError(err)));
  }

  get(id?: string): Observable<any> {
    let url = `${environment.apiurl}/hospitals`;

    if (id) {
      url += `/${id}`;
    }

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  update(id?: string, payload?: any): Observable<any> {
    let url = `${environment.apiurl}/hospitals`;

    if (id) {
      url += `/${id}`;
    }

    return this.http
      .update(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  updateTigerConnectInfo(id: number, payload: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${id}/configure_tiger_connect`;

    return this.http
      .update(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  partialUpdate(id: string, payload: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${id}`;

    return this.http
      .patch(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  benefitsUpdate(id: string, payload: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${id}/benefit-management`;

    return this.http
      .patch(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

 reportsUpdate(hospitalId: number, payload: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/update-reports`;

    return this.http
      .patch(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  quicksightReports(hospitalId: number): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/reports`;
    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  updateVehicleRates(id: string, payload: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${id}/vehicle_rates`;

    return this.http
      .post(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  addChild(data: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/add_child`;

    return this.http
      .post(url, data).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  removeChild(data: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/remove_child`;

    return this.http
      .post(url, data).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getVehicleTypes(): Observable<any> {
    const url = `${environment.apiurl}/vehicles/types`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getV3VehicleTypes(): Observable<any> {
    const url = `${environment.apiv3url}/vehicle_types`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getGeofenceData(hospitalId: number): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/geofence`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  updateGeofence(hospitalId: number, data: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/geofence`;

    return this.http
      .update(url, data).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getGeofenceRulesInfo(hospitalId: number): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/geofence/rules`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getHospitalTripReasons(hospitalId: number): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/reason-types`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getHospitalBoundaries(hospitalId: number): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/geofence/boundary-types`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  updateGeofenceRules(hospitalId: number, data: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/geofence/upsert-rules`;

    return this.http
      .update(url, data).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getPayerTypes() {
    const url = `${environment.apiurl}/payer-types`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getProviderAccessRules(id: number) {
    const url = `${environment.apiurl}/hospitals/${id}/community-rulesets`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getPreferredProviderRules(id: number) {
    const url = `${environment.apiurl}/hospitals/${id}/preferred-provider-rulesets`;

    return this.http
      .get(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  savePreferredProviderRules(id: number, payload: any) {
    const url = `${environment.apiurl}/hospitals/${id}/preferred-provider-rulesets`;

    return this.http
      .post(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  saveProviderAccessRules(id: number, payload: any) {
    const url = `${environment.apiurl}/hospitals/${id}/community-rulesets`;

    return this.http
      .post(url, payload).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  getBrokerBypassVehicleTypes(id: number) {
    const url = `${environment.apiv3url}/hospitals/${id}/broker_bypass_vehicle_types`;

    return this.http
      .getV3(url).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  addBrokerBypassVehicleTypes(id: number, vehicleTypes: any[]): Observable<any>[] {
    const url = `${environment.apiv3url}/hospitals/${id}/broker_bypass_vehicle_types`;
    const observableArray = [];

    vehicleTypes.forEach(v => {
      let body = {
        broker_bypass_hospital_vehicle_type: {
          hospital_id: id,
          vehicle_type_id: v.id
        }
      };
      observableArray.push(this.http.postV3(url, body).pipe(
        catchError((err) => this.http.handleError(err))));
    });

    return observableArray;
  }

  removeBrokerBypassVehicleTypes(id: number, vehicleTypes: any[]): Observable<any>[] {
    const url = `${environment.apiv3url}/hospitals/${id}/broker_bypass_vehicle_types`;
    const observableArray = [];

    vehicleTypes.forEach(v => {
      observableArray.push(this.http.deleteV3(`${url}/${v.id}`).pipe(
        catchError((err) => this.http.handleError(err))));
    });

    return observableArray;
  }

  formatVehicles(data) {
    const formattedData = [];
    data.forEach(vehicle => {
      if (vehicle.label.length === 3) {
        vehicle.label = vehicle.label.toUpperCase();
      }
      formattedData.push({itemName: vehicle.label, id: vehicle.value, child: vehicle.child});
    });
    return _.orderBy(formattedData, ['itemName'], ['asc']);
  }

  processData(data) {
    return _.orderBy(
      _.map(data, function(item) {
        return { id: item.id, itemName: item.name };
      }), 'itemName', 'asc');
  }

  convertRideShareBufferToMinutes(hospital) {
    hospital.advance_notice_for_ride_share_rides = hospital.advance_notice_for_ride_share_rides / 60;
  }

  convertRideSharebufferInSeconds(buffer) {
    return buffer * 60;
  }

  getVehicleIds(enabledVehicles) {
    return enabledVehicles.reduce((ids, vehicle) => {
      if (!vehicle.child && vehicle.id !== 'ride_share') {
        ids.push(vehicle.id);
      }

      return ids;
    }, []);
  }

  bulkUserUpload(hospitalId: number, data: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/users-upload`;

    return this.http
      .update(url, data).pipe(
        catchError((err) => this.http.handleError(err)));
  }

  redoxSetup(hospitalId: number, data: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/redox-setup`;

    return this.http
      .post(url, data);
  }

  partnerSsoSetup(hospitalId: number, data: any): Observable<any> {
    const url = `${environment.apiurl}/hospitals/${hospitalId}/partner-sso-setup`;

    return this.http
      .post(url, data);
  }
}
