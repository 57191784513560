import {Component, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Hospital } from '../../hospital';
import { taxiDefaultRate, medicalSedanDefaultRate, wheelchairDefaultRate } from '../vehicle-default-rates';
import * as _ from 'lodash';

@Component({
  selector: 'app-vehicle-rate-modal',
  templateUrl: './modal.component.html'
})
export class AddVehicleRatesModalComponent implements OnInit {
  hospital: Hospital;
  addedRates = false;
  title: string;

  constructor(
    public modalRef: BsModalRef
  ) {}

  populateTransportRates() {
    const transport_rates = [];
    this.hospital.enabled_vehicles.forEach((vehicle) => {
      if (!vehicle.child && vehicle.id !== 'ride_share') {
        const rateInfo = _.find(this.hospital['transport_rates'], (item) => {
          return item.vehicle === vehicle.id;
        });
        let base = null;
        let mileage = null;
        if (rateInfo) {
          base = rateInfo.base;
          mileage = rateInfo.mileage;
        }
        if(vehicle.id === 'taxi'){
          transport_rates.push(taxiDefaultRate);
        }else if (vehicle.id === 'medical_sedan'){
          transport_rates.push(medicalSedanDefaultRate);
        }else if (vehicle.id === 'wheelchair'){
          transport_rates.push(wheelchairDefaultRate);
        }else{
          transport_rates.push({
            vehicle: vehicle.id,
            base: base,
            mileage: mileage
          })
        }
      }
    });

    return transport_rates;
  }

  ngOnInit() {
    this.hospital['transport_rates'] = this.populateTransportRates();
  }

  modalClosed() {
    this.modalRef.hide();
  }

  vehicleRatesUpdate(event) {
    this.hospital['transport_rates'] = event;
    this.addedRates = true;
    this.modalRef.hide();
  }
}
